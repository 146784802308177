var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('br'),_c('center',[_c('div',{staticClass:"settings-personal-data-edit-card"},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('h4',[_vm._v("Balance:")]),_c('span',{staticStyle:{"font-weight":"bolder"}},[_vm._v(_vm._s(_vm.balance.toFixed(2))+"$")])]),_c('br'),_c('center',[_c('vuetify-money',{attrs:{"color":"#0989EF","label":"$ Amount to add to your wallet (min. 30$)","clearable":"","valueWhenIsEmpty":_vm.valueWhenIsEmpty,"filled":"","options":_vm.options},model:{value:(_vm.amountDeposit),callback:function ($$v) {_vm.amountDeposit=$$v},expression:"amountDeposit"}}),_c('v-chip',{staticStyle:{"color":"#0989ef","background-color":"rgba(0, 123, 255, 0.1)","margin-right":"4px"},style:({
              border:
                _vm.amountDeposit == 10 ? '2px dotted rgba(0,123,255,0.7)' : '',
            }),on:{"click":function($event){return _vm.setSelectedTopupAmount(30)}}},[_vm._v("30$ ")]),_c('v-chip',{staticStyle:{"color":"#0989ef","background-color":"rgba(0, 123, 255, 0.1)","margin-right":"4px"},style:({
              border:
                _vm.amountDeposit == 50 ? '2px dotted rgba(0,123,255,0.7)' : '',
            }),on:{"click":function($event){return _vm.setSelectedTopupAmount(50)}}},[_vm._v("50$ ")]),_c('v-chip',{staticStyle:{"color":"#0989ef","background-color":"rgba(0, 123, 255, 0.1)","margin-right":"4px"},style:({
              border:
                _vm.amountDeposit == 100 ? '2px dotted rgba(0,123,255,0.7)' : '',
            }),on:{"click":function($event){return _vm.setSelectedTopupAmount(100)}}},[_vm._v("100$ ")]),_c('v-chip',{staticStyle:{"color":"#0989ef","background-color":"rgba(0, 123, 255, 0.1)","margin-right":"4px"},style:({
              border:
                _vm.amountDeposit == 200 ? '2px dotted rgba(0,123,255,0.7)' : '',
            }),on:{"click":function($event){return _vm.setSelectedTopupAmount(200)}}},[_vm._v("200$ ")]),_c('br'),_c('br'),_c('small',[_vm._v(" Adding funds to your wallet, means you are buying siders, which is the platform currency. 1 sider = 1$. ")])],1)],1),(_vm.notAddingCard)?_c('v-btn',{staticClass:"btn-topup",staticStyle:{"letter-spacing":"0.5px","font-weight":"normal","font-family":"'Montserrat'","height":"51px","font-size":"16px","text-transform":"none","margin-top":"1em","width":"100%","padding":"1em","background-color":"rgb(0, 123, 255)","color":"white","border-radius":"5px"},attrs:{"loading":_vm.isLoading,"disabled":_vm.canAddFunds},on:{"click":_vm.topup}},[_vm._v(" Add funds with "),_c('img',{staticStyle:{"width":"96px","margin-left":"16px"},attrs:{"src":"/assets/images/MoonPay.png"}})]):_c('v-btn',{staticStyle:{"letter-spacing":"0.5px","font-weight":"normal","font-family":"'Montserrat'","height":"51px","font-size":"16px","text-transform":"none","margin-top":"1em","width":"100%","padding":"1em","background-color":"rgb(0, 123, 255)","color":"white","border-radius":"5px"},attrs:{"loading":_vm.isLoading},on:{"click":_vm.saveCard}},[_vm._v(" Add card ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }