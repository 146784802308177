var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"video-container paused",attrs:{"data-volume-level":_vm.volumeIcon},on:{"click":function($event){!_vm.playedOnce ? !_vm.subscriberOnly ? _vm.togglePlay() : null : null}}},[(_vm.isSlider && _vm.windowWidth <= 1000)?_c('v-btn',{staticClass:"btn-fab post-dialog__close",staticStyle:{"left":"unset","right":"2%","background-color":"transparent !important","text-shadow":"1px 1px 1px black"},attrs:{"fab":"","absolute":"","small":"","elevation":"0"},on:{"click":function($event){_vm.$store.state.isVideoMuted = !_vm.$store.state.isVideoMuted}}},[(_vm.$store.state.isVideoMuted)?_c('i',{staticClass:"fal fa-volume-slash text-white"}):_c('i',{staticClass:"fal fa-volume-up text-white"})]):_vm._e(),(_vm.isLoading && !_vm.wasPaused)?_c('v-progress-circular',{staticClass:"video-container__loader",staticStyle:{},attrs:{"indeterminate":"","color":"grey lighten-5"}}):_vm._e(),_c('div',{staticClass:"video-container__blur",style:({
      backgroundImage: ("url('" + _vm.blur + "')"),
      maxWidth: _vm.isSlider ? 'unset' : _vm.windowWidth > 768 && !_vm.isInFullscreen ? '768px' : '100%',
      width: _vm.isSlider ? '100%' : _vm.windowWidth > 768 ? '768px' : '100%',
    })}),_c('div',{staticClass:"video-container__thumbnail",style:({
      backgroundImage: ("url('" + _vm.thumbnail + "')"),
      maxWidth: _vm.isSlider ? 'unset' : '768px',
      width: '100%',
      position: !_vm.showVideo ? 'relative' : 'absolute',
      height: !_vm.showVideo ? _vm.isFeed ? '550px' : '100%' : 'auto',
    })}),(!_vm.subscriberOnly && _vm.wasPaused)?_c('div',{staticClass:"video-container__buttons-screen"},[_c('button',{staticClass:"video-container__play",on:{"click":_vm.togglePlay}},[_c('i',{staticClass:"fad fa-play"})]),_c('button',{staticClass:"video-container__pause",on:{"click":_vm.togglePlay}},[_c('i',{staticClass:"fad fa-pause"})])]):_vm._e(),(!_vm.isLoading)?_c('div',{staticClass:"video-container__controls",class:{
    'video-container__controls--slider-mobile': _vm.isSlider && _vm.windowWidth <= 1000,
    'video-container__controls--slider-mobile--paused': _vm.wasPaused
  },style:({
    // bottom: !isSlider ? isMobile ? '13%' : '0' : 'unset'
    opacity: _vm.hovering ? 1 : 0,
  })},[_c('div',{staticClass:"video-container__timeline-wrapper",class:{
      'video-container__timeline-wrapper--paused': _vm.wasPaused
    },on:{"mousemove":_vm.onTimeLineHover,"mousedown":_vm.toggleScrubbing,"touchmove":_vm.toggleScrubbingTouch}},[_vm._m(0)]),(!_vm.hideControls)?_c('div',{staticClass:"video-container__buttons",class:{
    'video-container__buttons--hidden': _vm.isSlider && _vm.windowWidth <= 1000
  }},[_c('button',{staticClass:"video-container__play",on:{"click":_vm.togglePlay}},[_c('i',{staticClass:"fad fa-play"})]),_c('button',{staticClass:"video-container__pause",on:{"click":_vm.togglePlay}},[_c('i',{staticClass:"fad fa-pause"})]),_c('div',{staticClass:"video-container__volume-wrapper"},[_c('v-menu',{attrs:{"close-on-content-click":false,"open-on-hover":"","offset-y":"","top":"","eager":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"video-container__volume",on:{"click":_vm.toggleMute}},'button',attrs,false),on),[_c('i',{staticClass:"fad fa-volume-up volume-up"}),_c('i',{staticClass:"fad fa-volume-down volume-down"}),_c('i',{staticClass:"fad fa-volume-off volume-off"}),_c('i',{staticClass:"fad fa-volume-mute volume-mute"})])]}}],null,false,2238878298),model:{value:(_vm.menuVolume),callback:function ($$v) {_vm.menuVolume=$$v},expression:"menuVolume"}},[_c('v-card',{staticStyle:{"height":"100px"}},[_c('input',{ref:"ranger",staticClass:"video-container__volume-slider",attrs:{"type":"range","min":"0","max":"1","step":"any","value":"0"},on:{"input":_vm.volumeChange}})])],1)],1),(!_vm.hideTimestamp)?_c('div',{staticClass:"video-container__duration"},[_c('span',{ref:"duration-current",staticClass:"video-container__duration-current"},[_vm._v("00:00")]),_c('span',{staticClass:"video-container__duration-separator"},[_vm._v("/")]),_c('span',{staticClass:"video-container__duration-total"},[_vm._v(_vm._s(_vm.durationTotal))])]):_vm._e(),_c('div',{staticClass:"spacer"}),_c('button',{staticClass:"video-container__picture-in-picture",on:{"click":_vm.pictureInPicture}},[_c('i',{staticClass:"fad fa-window-restore"})]),_c('button',{staticClass:"video-container__fullscreen",on:{"click":_vm.fullscreen}},[_c('i',{staticClass:"fad fa-expand fullscreen"}),_c('i',{staticClass:"fad fa-compress exit-fullscreen"})])]):_vm._e()]):_vm._e(),(_vm.showVideo && !_vm.subscriberOnly)?_c('video',{ref:"videoPlayer",staticClass:"video-container__video",class:{
    'video--slider': _vm.isSlider
  },style:({maxHeight: _vm.maxHeight}),attrs:{"preload":"auto","id":_vm._uid,"loop":"","playsinline":""},domProps:{"muted":_vm.$store.state.isVideoMuted},on:{"canplay":_vm.finishedLoading,"play":_vm.onPlay,"pause":_vm.onPause,"click":_vm.togglePlay,"dblclick":_vm.onDoubleClick,"timeupdate":_vm.onTimeUpdate,"ended":_vm.onVideoEnd}},[_c('source',{attrs:{"src":_vm.src,"type":"video/mp4"},on:{"error":_vm.onVideoError}})]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"video-container__timeline"},[_c('div',{staticClass:"video-container__thumb-indicator"})])}]

export { render, staticRenderFns }