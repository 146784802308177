/**
 * Here contains the routes of the application.
 * And is imported in the main.js file.
*/

import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes';


Vue.use(VueRouter);

const router = new VueRouter({
    routes,
    mode: 'history'
});

const blockedPathsIfAuthenticated = ['/login', '/register'];

const notAuthorized = [ '/settings', '/settings/notifications', '/settings/affiliates', '/settings/personal-data', '/settings/payment', '/settings/membership', '/become-a-creator', '/chat'];

router.beforeEach(async (to, from, next) => {

    if(to.meta.title){
        document.title = to.meta.title;
    }
    
    const store = router.app.$options.store;

    const lastToken = localStorage.getItem('sider-token');

    if(lastToken){
        const { iat } = Vue.prototype.decryptData(lastToken);
        const now = new Date().getTime() / 1000;

        // if diff is 1 hour get new token to /auth/me
        if(now - iat > 3600){
            const { data } = await Vue.prototype.$http.post('/api/v1/auth/renew', {
                accessToken: lastToken
            });
            const accessToken = data.accessToken;
            store.commit('setToken', accessToken);
            Vue.prototype.me = Vue.prototype.decryptData(accessToken);
        }
    }

    //set page title with meta title
    // if(to.meta.title){
    //     document.title = to.meta.title;
    // }
    
    if (Vue.prototype.me.id && blockedPathsIfAuthenticated.includes(to.path)) {
        return next({ path: '/' });
    }

    if(!Vue.prototype.me.id && notAuthorized.includes(to.path)) {
        return next({ path: '/' });
    }

    if(to.path.includes('/chat')){
        if(!Vue.prototype.me.id){
            return next({ path: '/login' });
        }
        document.body.style.overflowY = "hidden";
    }else{
        document.body.style.overflowY = "unset";
        store.state.channelOpen = {};
    }

    if(to.path.includes('/become-a-creator')){
        if(Vue.prototype.me.id && Vue.prototype.me.creator_category_id != null){
            return next({ path: '/' + Vue.prototype.me.slug });
        }
    }

    if(to.path.startsWith('/r/')){

        const godFatherCode = to.path.split('/r/')[1];

        // create a 30 days cookie
        const date = new Date();
        date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));
        const expires = "; expires=" + date.toUTCString();

        document.cookie = "sider-godFatherCode=" + godFatherCode + expires + "; path=/";

        return next({ 
            path: '/' + godFatherCode,
        })
    }

    // check if has token query
    if(to.path == '/login' && to.query.token) {
        // get vuex store
        store.commit('setToken', to.query.token);
        if(!Vue.prototype.me.id){
            Vue.prototype.me = Vue.prototype.decryptData(to.query.token);
        }
        return next({ path: '/' + Vue.prototype.me.slug});
    }

    if(!Vue.prototype.me.id && to.path == '/'){
        if(from.path.startsWith('/discover/') || from.path.startsWith('/p/') || from.path == '/') {
            return next({ path: '/discover' });
        }else{
            return next({ path: '/login' });
        }
    }
    
    // scroll to top
    window.scrollTo(0, 0);

    return next();
});

export default router;