<template>
  <div class="home-container" v-if="me" data-app>
    
      <template v-if="posts.length === 0">
      <center style="margin-top: 64px">
        <v-progress-circular
          v-if="!$store.state.search"
          indeterminate
          color="#007bff"
        ></v-progress-circular>
      </center>
    </template>
    <Feed
      v-if="posts.length > 0"
      :posts="posts"
      ref="feedComponent"
      @refresh="refreshPosts(`/getFeed/?limit=8page=${page}`)"
      @deletePost="deletePost"
      @newComment="onNewComment"
      @deleteComment="onDeleteComment"
    ></Feed>
    <center v-if="showWelcome">
      <div class="welcome-card-wrapper" style="margin-top: 16px">
        <div class="welcome-card">
          <div class="welcome-card__close">
            <v-btn icon @click="closeWelcomeCard()">
              <i class="fa-light fa-times"></i>
            </v-btn>
          </div>
          <div class="welcome-card__image">
            <v-img
              src="https://sidermoney.nyc3.digitaloceanspaces.com/logos/undraw_happy_news_re_tsbd.svg"
              width="150px"
            >
            </v-img>
          </div>
          <div class="welcome-card__title">
            <h2>Welcome to your feed!</h2>
          </div>
          <div class="welcome-card__description">
            <p>Here, you'll find posts from your subscribed creators.</p>
          </div>
          <v-btn
            class="welcome-card__buttons btn-sider-money"
            elevation="0"
            @click="$router.push('/discover')"
          >
            Discover creators
          </v-btn>
        </div>
      </div>
    </center>
  </div>
</template>

<script>
import Feed from "@/components/shared/Feed.vue";

import postMixin from "@/mixins/post";
import payperview from "@/mixins/payperview";
export default {
  mixins: [postMixin, payperview],
  components: {
    Feed,
  },
  async beforeMount() {
    this.posts = await this.$http
      .get("/api/v1/getFeed/?limit=8&page=" + this.page)
      .then((res) => res.data);

    if (this.posts.length == 0) {
      this.showWelcome = true;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (this.stopLazy) {
        return;
      }
      if (this.isLoading) return;
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 1000
      ) {
        this.page++;
        this.isLoading = true;
        this.$http
          .get("/api/v1/getFeed/?limit=8&page=" + this.page)
          .then((res) => {
            this.posts = this.posts.concat(res.data);
            this.isLoading = false;
            if (res.data.length == 0) {
              this.stopLazy = true;
            }
          });
      }
    },
  },
  data: () => ({
    showWelcome: false,
    posts: [],
    page: 1,
    stopLazy: false,
    isLoading: true,
  }),
};
</script>

<style lang="scss">
.home-container {
  // center svg
  .logo-container {
    display: flex;
    justify-content: center;
  }

  .title-container {
    display: flex;
    justify-content: center;
    margin-top: 49px;

    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 27px;
    }
  }

  .tutorial {
    margin-top: 57px;

    & > div {
      margin-top: 28px;
    }

    .step-1 {
      width: 100%;
      display: flex;
      flex-flow: wrap;
      align-items: center;
      justify-content: flex-start;

      .circle {
        width: 40px;
        height: 40px;
        background: #f2f2f2;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 27px;
        color: #000000;
        margin-right: 20px;
      }

      .explanation {
        display: inline;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #4f4f4f;
      }

      .avatars-list {
        width: 100%;
        margin-top: 27px;
        grid-template-columns: repeat(auto-fit, minmax(56px, 1fr));
        display: grid;
        place-items: center;
      }

      .description {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #4f4f4f;
      }
    }
  }
}
</style>

