<template>
    <div style="position: relative;overflow: auto;" id="dialogScrollView">
        <div class="nav-dialog"
            :style="windowY >= 60 ? 'position: sticky;top:-1.1em;left: 0;z-index: 1000;padding: 1em;padding-right: 0;' : ''">
            <span class="header-dialog">
                <span style="font-weight: bold;" class="text-white">Editing Item</span>
                <br>
                <small>{{editingJob.category.title}}</small>
            </span>
            <v-btn class="btn-fab" fab elevation="0" @click="close" small>
                <i class="close-dialog-2 fa fa-times" aria-hidden="true"></i>
            </v-btn>
        </div><br>

        <br>

        <transition name="component-fade" mode="out-in">
            <div style="position: absolute;left: 0;width: 100%;padding: 1em;">
                <v-row align="center">


                    <v-col class="d-flex" cols="12" sm="12">
                        <v-text-field color="#0989EF" v-model="job.title" filled label="Title" clearable></v-text-field>
                    </v-col>

                    <v-col class="d-flex" cols="12" sm="12">

                        <v-textarea color="#0989EF" filled label="Description" v-model="job.description" clearable>
                        </v-textarea>
                    </v-col>
                    <v-col class="d-flex" cols="6" sm="6">
                        <vuetify-money v-model="job.price_service" color="#0989EF" filled label="€ Price" clearable
                            :valueWhenIsEmpty="valueWhenIsEmpty" :options="options" />
                    </v-col>
                    <v-col class="d-flex" cols="6" sm="6">
                        <v-select color="#0989EF" :items="servicesTypes" filled v-model="job.service_type"
                            label="Price Per"></v-select>
                    </v-col>
                    <v-col class="d-flex" cols="6" sm="6">
                        <vuetify-money v-model="job.stock" color="#0989EF" filled label="Stock" clearable
                            :valueWhenIsEmpty="''" :options="options" />

                    </v-col>
                </v-row>
                <br><br><br><br><br>
            </div>
        </transition>
        <!-- <slide-unlock style="position: absolute;bottom: 5%;width: 93%;" ref="vueslideunlock" :auto-width="true" :circle="true" :disabled="false"
                    :noanimate="false" :width="400" :height="50" text="SIDER.MONEY $1500,00/lead" success-text=""
                    @completed="complete()" /> -->

        <div :key="counterW" :style="wWidth < 768 ? 'position: fixed;' : 'position: absolute;'"
            style="width: 100%;height: auto;padding: 1em;left:0;bottom:0;height: 90px;">
            <v-btn @click="saveService" fixed :style="wWidth < 768 ?'bottom: 3%;' : 'bottom: 8%;'"
                style="width: 300px;left: 0;right:0;margin: auto;color: white;" :disabled="inputsValidation"
                color="#007BFF" x-large rounded elevation="0">
                Save
            </v-btn>
        </div>
    </div>
</template>

<script>
    export default {
        async beforeMount() {
            this.job = Object.assign({}, this.editingJob);
        },
        watch: {
            editingJob() {
                this.job = Object.assign({}, this.editingJob);
            }
        },
        computed: {
            inputsValidation() {
                return !this.job.title || !this.job.description || !this.job.price_service || !this.job.service_type;
            }
        },
        mounted() {
            document.getElementById('dialogScrollView').addEventListener('scroll', this.handleScroll);

            this.wWidth = window.innerWidth;
            window.addEventListener('resize', this.wHandleResize);
        },
        beforeDestroy() {
            if (document.getElementById('dialogScrollView')) {
                document.getElementById('dialogScrollView').removeEventListener('scroll', this.handleScroll);
            }
            window.removeEventListener('resize', this.wHandleResize);
        },
        methods: {
            saveService() {
                this.$http.put('/api/v1/editService', {
                    id: this.job.id,
                    title: this.job.title,
                    description: this.job.description,
                    price_service: parseFloat(this.job.price_service),
                    price_suggestion: parseFloat(this.job.price_suggestion),
                    max_per_purchase: this.job.max_per_purchase,
                    stock: this.job.stock,
                    service_type: this.job.service_type,
                }).then(() => {
                    this.job.title = "";
                    this.job.description = "";
                    this.job.price_service = "";
                    this.job.price_suggestion = "";
                    this.$swal({
                        icon: 'success',
                        title: 'Service edited successfully',
                        timer: 2500
                    });
                    this.$emit('reload', 'getTipMenu');
                    this.$emit('close');
                })
            },
            wHandleResize() {
                this.counterW++;
                this.wWidth = window.innerWidth;
            },
            handleScroll(e) {
                this.windowY = e.target.scrollTop;
            },
            close() {
                this.$emit('close');
            },
        },
        data: () => ({
            servicesTypes: ['hour', 'day', 'week', 'month', 'service', 'download'],
            wWidth: 0,
            counterW: 0,
            job: {},
            windowY: 0,
            outlined: true,
            valueWhenIsEmpty: "",
            options: {
                locale: "pt-PT",
                prefix: " € ",
                suffix: "",
                length: 9,
                precision: 0
            },
        }),
        props: {
            editingJob: {
                type: Object,
                default: () => ({})
            },
        }
    }
</script>

<style lang="scss">
    .nav-dialog {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        align-items: center;
        background-color: white;

        &>.close-dialog {
            font-size: 20px;
            cursor: pointer;
            grid-column: 1;
        }

        &>.close-dialog-2 {
            font-size: 20px;
            cursor: pointer;
            grid-column: 12;
            text-align: right;
        }
    }
</style>