<template>
    <div class="navbar">
        <div class="logo">
            <s-logo width="200"></s-logo>
        </div>

        <div class="nav-home" :class="[{'nav-active': $route.path == '/'}]" @click="gotoHome()">
            <i class="fa-light fa-house-chimney"></i>
        </div>
        <div class="nav-search" :class="[{'nav-active': isOnSearch()}]" @click="gotoSearch">
            <i class="fa-light fa-magnifying-glass"></i>
        </div>
        <div class="nav-plus nav-active-2" :class="[{'nav-active': $store.state.openPopup == 'create-post'}]" @click="beginAPost">
            <i class="fa-light fa-plus" aria-hidden="true"></i>
        </div>
        <div class="nav-suggest" :class="[{'nav-active': $route.path == '/chat'}]"
            @click="gotoChat">
            <i class="fa-light fa-paper-plane"></i>

        </div>
        <div class="nav-profile" :class="[{'nav-active': isMyProfile()}]" @click="gotoProfile">
            <i class="fa-light fa-user"></i>

            
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            beginAPost(){
                if(this.$route.params.slug){
                    if(this.me.id){
                        this.$router.push(`/${this.me.slug}`).catch(() => {});
                        this.$store.commit('setPopup', 'create-post');
                    }else{
                        this.$router.push('/login');
                    }
                }else{
                    if(this.me.id){
                        this.$router.push(`/${this.me.slug}`)
                        .then(() => {
                            setTimeout(() => {
                                this.$store.commit('setPopup', 'create-post');
                            }, 1000);
                        })
                        .catch(() => {});
                    }else{
                        this.$router.push('/login');
                    }
                }
            },
            gotoChat(){
                this.$store.state.search = "";
                this.$store.state.category = "";
                this.$store.state.users = [];
                this.$router.push('/chat')
            },
            isOnSearch() {
                var slug = "";
                if (this.me) {
                    slug = this.me.slug;
                }
                if ((this.$store.state.search.length > 0 || this.$store.state.category.length > 0 || this.$store.state
                        .users.length > 0 || this.$route.path == '/search') && this.$route.params.slug != slug) {
                    return true;
                }
            },
            isMyProfile() {
                return this.me && this.$route.params.slug == this.me.slug || this.$route.path.startsWith('/settings');
            },
            gotoProfile() {
                this.$store.state.search = "";
                this.$store.state.category = "";
                this.$store.state.users = [];
                this.$router.push(this.myUrl()).catch(() => {});
            },
            gotoHome() {
                this.$store.state.search = "";
                this.$store.state.category = "";
                this.$store.state.users = [];
                this.$router.push('/').catch(() => {});
            },
            gotoSearch() {
                this.$store.state.search = "";
                this.$store.state.category = "";
                this.$router.push('/search').catch(() => {
                    this.$store.state.users = [];
                })
            },
            myUrl() {
                if (this.me && this.me.slug) {
                    return '/' + this.me.slug
                } else {
                    return '/login';
                }
            }
        }
    }
</script>

<style lang="scss">
    @import '~@/assets/styles/variables.scss';
    .navbar {
        width: 100%;
        position: sticky;
        display: none;
        height: 50px;
        background-color: white;
        z-index: 1;
        grid-template-columns: repeat(12, 1fr);
        column-gap: .5em;
        border-bottom: 1px solid #f1f1f1;
        padding-left: .5em;
        padding-right: .5em;

        & .nav-active {
            background: $brand-gradient;
            // width: 60px;
            height: 40px;
            margin-top: .25em;
            border-radius: 20px;
            display: grid;
            place-items: center;
            transition: .5s;
            color: white;

        }

        &>div {
            color: #4F4F4F;
            width: 70px;
            background: white;
            margin-top: .25em;
            border-radius: 20px;
            transition: .5s;
            display: grid;
            height: 40px;
            place-items: center;
            cursor: pointer;

            &.nav-active-2 {
                color:white;
            }

            &:hover:not(.nav-active):not(.logo) {
                background-color: rgba(21, 112, 216, 0.137);
                color: rgba(21, 112, 216, 0.864);
                
            }
        }

        &>.logo {
            grid-column: 1 / span 3;
            margin-left: 50px;
        }

        &>.nav-home {
            grid-column: 8;
        }

        &>.nav-search {
            grid-column: 9;
        }

        &>.nav-plus {
            grid-column: 10;
        }

        &>.nav-suggest {
            grid-column: 11;
        }

        &>.nav-profle {
            grid-column: 12;
        }
    }
</style>