<template>
  <!-- -->
  <div class="discovery-view" data-app>
    <div class="discovery-view__search-wrapper">
      <v-btn
        class="discovery-view__search-back"
        fab
        text
        style="margin-top: 16px"
        v-if="$store.state.category || showCategoriesCards"
        @click="goBackFromSearching"
      >
        <i class="fas fa-chevron-left"></i>
      </v-btn>
      <!-- :style="!$store.state.category && !showCategoriesCards ? '' : 'grid-column: 2 / span 12;'" -->
      <div
        ref="search"
        class="discovery-view__search"
        :class="{
          'discovery-view__search--active':
            $store.state.category || showCategoriesCards,
        }"
      >
        <input
          class="discovery-view__search-input"
          ref="searchInput"
          type="text"
          placeholder="Search"
          v-model="$store.state.search"
          @keypress.enter="searchUser"
          @focus="onInputFocus"
        />
        <div class="discovery-view__search-icon">
          <i class="fal fa-grid" v-if="!isGrid" @click="isGrid = !isGrid"></i>
          <i class="fal fa-bars" v-else @click="isGrid = !isGrid"></i>
        </div>
      </div>
    </div>

    <v-list
      style="overflow-y: auto"
      v-if="$store.state.users.length > 0 && $store.state.search.length > 0"
    >
      <v-list-item
        v-for="user in $store.state.users"
        :key="user.id"
        style="position: relative; padding: 0"
        @click="$router.push('/' + user.slug)"
      >
        <v-list-item-avatar
          width="75"
          height="75"
          style="border: 2px solid #007bff; margin-left: 0.5rem"
        >
          <v-img :src="user.avatar || defaultImages.avatar"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            style="font-size: 18px; padding-left: 0.5em; padding-right: 0.5rem"
          >
            {{ user.name }}

            <span
              style="float: right; font-size: 1rem"
              v-if="user.subscription_price && user.subscription_price > 0"
            >
              {{ user.subscription_price.toFixed(2) }}$/month
            </span>
            <span style="float: right; font-size: 1rem" v-else> Free </span>
          </v-list-item-title>
          <v-list-item-subtitle
            style="color: #999999; padding-left: 0.5em; padding-right: 0.5rem"
          >
            @{{ user.slug }}

            <span style="float: right; font-size: 1rem">
              <i class="fal fa-image"></i>
              {{
                user.posts
                  .map((p) => p.files.map((f) => f.imageCount))
                  .flat()
                  .filter((v) => v)
                  .reduce((p, c) => (p += c), 0)
              }}

              <span style="margin-left: 0.5rem">
                <i class="fal fa-video"></i>
                {{
                  user.posts
                    .map((p) => p.files.map((f) => f.videoCount))
                    .flat()
                    .filter((v) => v)
                    .reduce((p, c) => (p += c), 0)
                }}
              </span>

              <span style="margin-left: 0.5rem">
                <i class="fal fa-heart" aria-hidden="true"></i>
                {{ user.posts.map((p) => p.likes).flat().length }}
              </span>
            </span>
          </v-list-item-subtitle>
          <v-list-item-subtitle style="color: #999999; padding-left: 0.5em">
            <div class="grid" style="grid-template-columns: repeat(12, 1fr)">
              <span v-html="creatorCategory(user)"></span>
            </div>
          </v-list-item-subtitle>
        </v-list-item-content>
        <br />
      </v-list-item>
    </v-list>

    <transition name="component-fade" mode="out-in">
      <Categories
        class="discovery-view__categories"
        @searchUsersByCategory="handleClickCategory"
        :categories="categories"
        v-if="showCategoriesCards && $store.state.search.length <= 0"
        :isLoading="isLoading"
      >
      </Categories>
    </transition>

    <template v-if="posts.length === 0">
      <center style="margin-top: 16px">
        <v-progress-circular
          v-if="!$store.state.search"
          indeterminate
          color="#007bff"
        ></v-progress-circular>
      </center>
    </template>

    <transition name="component-fade" mode="out-in">
      <Feed
        v-if="!isGrid && !isSearching && !isInputFocus"
        :posts="posts"
        :showWelcomeCards="false"
        :showWelcomePostCard="false"
        @deletePost="deletePost"
        @newComment="onNewComment"
        @deleteComment="onDeleteComment"
      ></Feed>
    </transition>

    <transition name="component-fade" mode="out-in">
      <GridFeed v-if="isGrid && !isSearching && !isInputFocus" :posts="posts" />
    </transition>
    <template v-if="inLazy && posts.length > 0">
      <center style="margin-top: 16px">
        <v-progress-circular
          indeterminate
          color="#007bff"
        ></v-progress-circular>
      </center>
    </template>

    <center
      v-if="
        noPostsForCategory && posts.length === 0 && $route.params.categoryId
      "
    >
      <p class="color-our-white">No posts found for this category</p>
    </center>

    <br /><br /><br /><br /><br /><br />
  </div>
</template>

<script>
import Feed from "@/components/shared/Feed";
import GridFeed from "@/components/shared/GridFeed";
import Categories from "./Categories.vue";

import postMixin from "@/mixins/post";

export default {
  mixins: [postMixin],
  components: {
    Feed,
    GridFeed,
    Categories,
  },
  computed: {
    defaultImages() {
      return {
        avatar: process.env.VUE_APP_DEFAULT_USER_AVATAR,
        banner: process.env.VUE_APP_DEFAULT_USER_BANNER,
      };
    },
    isSearching() {
      return this.$store.state.search && this.$store.state.search.length > 0;
    },
    showCategories() {
      return this.$store.state.category;
    },
  },
  watch: {
    "$store.state.search": function () {
      this.searchUser();
    },
    "$store.state.category": function (val) {
      if (val.length == 0) {
        this.$store.state.users = [];
        return;
      }
    },
  },
  async beforeMount() {
    this.categories = await this.$http
      .get("/api/v1/getCategories?filter=hasUsersPosts")
      .then((res) => res.data);

    // if (this.$store.state.search) {
    //     await this.searchUser();
    //     this.$store.state.category = null;
    // }

    // if (this.$store.state.category) {
    //     await this.searchUsersByCategory(this.$store.state.category);
    // }

    // TODO BETTER WAY TO HANDLE THIS, O PORQUE ISSO [E NECESSARIO], QUANDO SE ESTA NO HOME LA EM BAIXO NA PAGINA, E VEM PRO DISCOVERY, ELE AUMENTA O PAGE++, PORQUE DETECTA QUE TAVA NO FIM E SEMPRE VAI COMECA APARTIR DO 2, E NAO DO 1
    // COM ESSE TIMEOUT ELE ESPERA UM POUCO PRO SCROLL SUBIR O +1 E DEPOIS VOLTA PRA 1
    await new Promise((resolve) => {
      setTimeout(() => {
        this.page = 1;
        resolve();
      }, 50);
    });

    let posts = await this.getPosts();

    if (this.$route.params.categoryId) {
      await this.searchUsersByCategory({ id: this.$route.params.categoryId });
      this.backupPost = posts;
      this.$store.state.category = this.$route.params.categoryId;
    } else {
      this.posts = posts;
    }

    if (
      this.$route.path == "/discover/c" ||
      this.$route.path == "/discover/c/"
    ) {
      this.onInputFocus();
      this.$store.state.category = -1;
      this.backupPost = this.posts;
    }

    this.$store.state.discoverPosts = posts;
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    getPosts() {
      this.triesGetPosts++;
      if (this.triesGetPosts > 3) {
        return [];
      }
      return this.$http
        .get("/api/v1/getAllFreePosts?page=" + this.page + "&limit=20")
        .then((res) => res.data)
        .catch((err) => {
          console.log(err);
          if (err.code == "ERR_NETWORK") {
            return this.getPosts();
          }
        });
    },
    creatorCategory(user) {
      if (user?.creator_category_id) {
        let category = this.categories.find(
          (c) => c.id == user.creator_category_id
        );
        if (category) {
          return `<i class="text-primary ${category.icon}"></i> ${category.title}`;
        }
      }
      return "";
    },
    goBackFromSearching() {
      if (this.backupPost.length == 0) {
        this.backupPost = this.posts;
      }

      if (this.$route.path == "/discover/c") {
        this.$store.state.category = "";
        this.posts = this.backupPost;
        this.page = 1; // TODO: this.backupPage;
        this.$router.replace({
          path: "/discover",
        });
        this.showCategoriesCards = false;
        this.isInputFocus = false;
        return;
      }

      this.onInputFocus();
      // this.$store.state.category = ''; this.$store.state.search = ''; this.$store.state.users = [];
      if (this.posts.length == 0) {
        this.posts = this.$store.state.discoverPosts;
      }
    },
    onInputFocus() {
      this.backupPage = this.page;
      this.isInputFocus = true;
      this.showCategoriesCards = true;
      this.$router.replace({
        path: "/discover/c",
      });
      this.stopLazy = false;
    },
    getMostValuableService(services) {
      let max = 0;
      let maxService = null;
      for (let service of services) {
        if (service.price_service > max) {
          max = service.price_service;
          maxService = service;
        }
      }
      return maxService.title;
    },
    getMaxMinPrices(services) {
      var max = Math.max.apply(
        Math,
        services.map(function (o) {
          return o.price_service;
        })
      );
      var min = Math.min.apply(
        Math,
        services.map(function (o) {
          return o.price_service;
        })
      );
      return min + "$" + " - " + max + "$";
    },
    async searchUser() {
      this.$store.state.users = await this.$http
        .get("/api/v1/discoverUsers?search=" + this.$store.state.search)
        .then((res) => res.data);
    },
    handleClickCategory(category) {
      this.backupPage = this.page;
      this.page = 1;
      this.$router.replace({
        path: "/discover/c/" + category.id,
      });
      this.searchUsersByCategory(category);
    },
    async searchUsersByCategory(category) {
      this.isLoading = true;
      this.backupPost = this.posts;
      this.posts = await this.$http
        .get(
          `/api/v1/getAllFreePosts?page=${this.page}&limit=20&categoryId=${category.id}`
        )
        .then((res) => res.data);
      this.$store.state.discoverPosts = this.posts;
      this.isInputFocus = false;
      this.$store.state.category = category.id;
      this.showCategoriesCards = false;
      this.isLoading = false;
      if (this.posts.length === 0) {
        this.noPostsForCategory = true;
      } else {
        this.noPostsForCategory = false;
      }
    },
    async handleScroll() {
      if (this.stopLazy) {
        return;
      }
      if (this.inLazy) {
        return;
      }
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 1000
      ) {
        this.page++;

        let extraParams = "";

        if (
          this.$store.state.category &&
          Number(this.$store.state.category) > 0
        ) {
          extraParams = `&categoryId=${this.$store.state.category}`;
        }
        this.inLazy = true;
        let posts = await this.$http
          .get(
            `/api/v1/getAllFreePosts?page=${this.page}&limit=20${extraParams}`
          )
          .then((res) => res.data);
        this.posts = this.posts.concat(posts);
        // this.$store.state.discoverPosts = this.posts;

        if (posts.length == 0) {
          this.stopLazy = true;
        }
        this.inLazy = false;
      }
    },
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  data: () => ({
    noPostsForCategory: false,
    inLazy: false,
    triesGetPosts: 0,
    backupPost: [],
    showCategoriesCards: false,
    isInputFocus: false,
    isGrid: true,
    posts: [],
    categories: [],
    page: 1,
    backupPage: 1,
    stopLazy: false,
    isLoading: false,
  }),
};
</script>

<style lang="scss">
@import "~@/assets/styles/variables.scss";

.discovery-view {
  &__search-wrapper {
    display: flex;
  }

  &__search {
    display: flex;
    width: calc(768px - 2rem);
    height: 50px;
    border-radius: 15px;
    align-items: center;
    padding: 0 1em;
    margin-top: 1em;
    margin-left: 1rem;
    margin-right: 1rem;

    align-items: center;
    justify-content: space-between;

    @media (prefers-color-scheme: dark) {
      background-color: #2a2a2a;
    }

    @media (prefers-color-scheme: light) {
      background-color: #ededee;
    }

    @media (max-width: 768px) {
      width: calc(100vw - 2rem);
    }

    &--active {
      margin-left: 0;
    }
  }

  &__search-input {
    width: 100%;
    background-color: transparent;
    font-size: 18px;
    color: #4f4f4f;
    border: none;
    outline: none;

    margin-right: 1rem;

    &:focus {
      outline: none;
    }
  }

  &__search-icon {
    font-size: 20px;
    font-weight: 600;
    text-align: right;
    min-width: 50px;

    @media (prefers-color-scheme: dark) {
      color: $color-our-white;
    }

    @media (prefers-color-scheme: light) {
      color: #000;
    }
  }

  &__search-back {
    margin-left: 1em;
  }

  &__categories {
    padding: 0 1em;
  }
}

.grid {
  width: 100%;
  column-gap: 1em;
  row-gap: 1em;
  display: grid;
  place-items: center;
}

.card-category {
  display: grid;
  place-content: center;
  width: 100%;
  height: 180px;
  background-color: #ededee;
  border-radius: 15px;
  text-align: center;
  cursor: pointer;
  transition: 0.5s;

  &:hover {
    transform: scale(1.05);
    transition: 0.5s;
  }

  & > .category-title {
    font-size: 12px;
    color: #4f4f4f;
    font-weight: 600;
  }
}

.v-rating > * {
  padding: 0 !important;
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
  position: absolute;
}
.component-fade-enter, .component-fade-leave-to
        /* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  position: absolute;
}
</style>
