<template>
  <div data-app class="profile-container" style="min-width: 100%">
    <!-- :style="`background-image: url('${user.banner}');transition: 0.5s;`" -->

    <v-dialog
      v-model="showPayPerViewPopup"
      max-width="768px"
      style="overflow: hidden; color: blue"
    >
      <v-card style="overflow: hidden">
        <PayPerViewPopup
          @refresh="refreshPosts(`/getPosts/${user.id}`)"
          :post="postPayPerView"
          :user="postPayPerView.owner"
          :balance="balance"
          @close="showPayPerViewPopup = false"
        ></PayPerViewPopup>
      </v-card>
    </v-dialog>

    <v-snackbar centered color="#1ED760" v-model="successAlert" :timeout="2000">
      {{ successText }}
    </v-snackbar>
    <v-dialog
      v-model="formDialog"
      max-width="768px"
      :fullscreen="
        windowWidth <= 768 && !styleDefaultPopup.includes(activePopup)
      "
      style="overflow: hidden; color: blue"
    >
      <v-card
        :style="[
          { height: !styleDefaultPopup.includes(activePopup) ? '90vh' : '' },
        ]"
        style="overflow: hidden"
      >
        <component
          :style="[
            {
              padding: !styleDefaultPopup.includes(activePopup) ? '1em' : '',
              height: !styleDefaultPopup.includes(activePopup) ? '100%' : '',
            },
          ]"
          @onSuccess="showSuccessSnack"
          @close="closeDialog"
          @reload="reload"
          :editingJob="$store.state.editingMenuItem"
          :viewingJob="$store.state.viewingMenuItem"
          :is="activePopup + '-popup'"
          :name="activePopup + '-popup'"
          :balance="balance"
          :stock="stock"
          :user="user"
        ></component>
      </v-card>
    </v-dialog>
    <div class="profile-banner vld-parent">
      <loading
        :active.sync="isLoadingBanner"
        :can-cancel="false"
        :is-full-page="false"
        color="#007BFF"
      ></loading>
      <input
        type="file"
        accept=".jpeg, .png, .gif, .jpg, .webp, !.heic, !.heif"
        ref="profileBannerInput"
        style="display: none"
        @change="onUploadBanner"
      />
      <v-btn
        v-if="isMyProfile()"
        @click="clickOnBannerInput"
        class="edit-image-button"
        small
        absolute
        right
        bottom
        style="bottom: 18px; z-index: 1"
        elevation="0"
      >
        <v-icon style="margin-right: 8px">mdi-camera</v-icon>
        Edit
      </v-btn>
      <v-img
        @click="user.banner ? (viewingBannerIndex = 0) : null"
        :src="user.banner || defaultImages.banner"
        :lazy-src="$default.banner || defaultImages.banner"
        max-height="284px"
        style="
          position: absolute;
          left: 0;
          width: 100%;
          height: calc(100vw / 2.7);
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        "
      >
      </v-img>

      <v-btn
        class="btn-fab"
        fab
        v-if="
          $store.state.search.length > 0 || $store.state.category.length > 0
        "
        small
        style="left: 3%; top: 10%"
        @click="$router.go(-1)"
      >
        <i class="fa-regular fa-chevron-left" style="font-size: 20px"></i>
      </v-btn>
    </div>

    <div class="profile-content">
      <div style="padding: 1em; padding-top: 0">
        <v-img
          @click="user.avatar ? (viewingAvatarIndex = 0) : null"
          :class="{
            ['online-border']: $store.state.userSocketIds.find(
              (u) => u.id == user.id
            ),
          }"
          :src="user.avatar || defaultImages.avatar"
          :lazy-src="user.avatar || defaultImages.avatar"
          class="profile-image"
        >
          <loading
            :active.sync="isLoadingAvatar"
            :can-cancel="false"
            :is-full-page="false"
            color="#007BFF"
          ></loading>
        </v-img>
        <!-- input file allow only images -->
        <input
          type="file"
          accept=".jpeg, .png, .gif, .jpg, .webp, !.heic, !.heif"
          ref="profileAvatarInput"
          style="display: none"
          @change="onUploadAvatar"
        />
        <v-btn
          v-if="isMyProfile()"
          @click="clickOnProfileInput"
          class="edit-image-button"
          small
          absolute
          style="margin-left: 90px; margin-top: 35px"
          fab
          elevation="1"
        >
          <v-icon>mdi-camera</v-icon>
        </v-btn>

        <div
          style="
            display: grid;
            width: 100%;
            height: 60px;
            grid-template-columns: 150px 1fr;
            margin-top: 10px;
          "
        >
          <div
            style="
              grid-column: 1;
              width: 100%;
              max-width: 165px;
              min-width: 165px;
            "
          ></div>
          <div style="grid-column: 2; position: relative">
            <span
              class="p text-gray-strong"
              style="font-size: 18px; font-weight: 600; transition: 0.5s"
              >{{ user.name }}

              <span
                style="margin-top: 2px"
                data-tooltip="Verified"
                v-if="user.verified_badge"
                data-tooltip-conf="bottom"
              >
                <svg
                  fill="#007BFF"
                  style="margin-bottom: -2px"
                  width="17"
                  viewBox="0 0 24 24"
                  aria-label="Conta verificada"
                  role="img"
                  class="r-1cvl2hr r-4qtqp9 r-yyyyoo r-1xvli5t r-f9ja8p r-og9te1 r-bnwqim r-1plcrui r-lrvibr"
                >
                  <g>
                    <path
                      d="M22.5 12.5c0-1.58-.875-2.95-2.148-3.6.154-.435.238-.905.238-1.4 0-2.21-1.71-3.998-3.818-3.998-.47 0-.92.084-1.336.25C14.818 2.415 13.51 1.5 12 1.5s-2.816.917-3.437 2.25c-.415-.165-.866-.25-1.336-.25-2.11 0-3.818 1.79-3.818 4 0 .494.083.964.237 1.4-1.272.65-2.147 2.018-2.147 3.6 0 1.495.782 2.798 1.942 3.486-.02.17-.032.34-.032.514 0 2.21 1.708 4 3.818 4 .47 0 .92-.086 1.335-.25.62 1.334 1.926 2.25 3.437 2.25 1.512 0 2.818-.916 3.437-2.25.415.163.865.248 1.336.248 2.11 0 3.818-1.79 3.818-4 0-.174-.012-.344-.033-.513 1.158-.687 1.943-1.99 1.943-3.484zm-6.616-3.334l-4.334 6.5c-.145.217-.382.334-.625.334-.143 0-.288-.04-.416-.126l-.115-.094-2.415-2.415c-.293-.293-.293-.768 0-1.06s.768-.294 1.06 0l1.77 1.767 3.825-5.74c.23-.345.696-.436 1.04-.207.346.23.44.696.21 1.04z"
                    ></path>
                  </g>
                </svg>
              </span>
              <div
                data-tooltip="Ambassador"
                v-if="user.ambassador"
                style="
                  margin-left: 4px;
                  margin-top: 2px;
                  transform: translate(0, 0.8px);
                  display: inline-block;
                "
                data-tooltip-conf="bottom"
              >
                <svg
                  width="15"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.001 11C21.001 9.42 20.126 8.05 18.853 7.4C19.007 6.965 19.091 6.495 19.091 6C19.091 3.79 17.381 2.002 15.273 2.002C14.803 2.002 14.353 2.086 13.937 2.252C13.319 0.915 12.011 0 10.501 0C8.991 0 7.685 0.917 7.064 2.25C6.649 2.085 6.198 2 5.728 2C3.618 2 1.91 3.79 1.91 6C1.91 6.494 1.993 6.964 2.147 7.4C0.875 8.05 0 9.418 0 11C0 12.495 0.782 13.798 1.942 14.486C1.922 14.656 1.91 14.826 1.91 15C1.91 17.21 3.618 19 5.728 19C6.198 19 6.648 18.914 7.063 18.75C7.683 20.084 8.989 21 10.5 21C12.012 21 13.318 20.084 13.937 18.75C14.352 18.913 14.802 18.998 15.273 18.998C17.383 18.998 19.091 17.208 19.091 14.998C19.091 14.824 19.079 14.654 19.058 14.485C20.216 13.798 21.001 12.495 21.001 11.001V11ZM14.385 7.666L10.051 14.166C9.906 14.383 9.669 14.5 9.426 14.5C9.283 14.5 9.138 14.46 9.01 14.374L8.895 14.28L6.48 11.865C6.187 11.572 6.187 11.097 6.48 10.805C6.773 10.513 7.248 10.511 7.54 10.805L9.31 12.572L13.135 6.832C13.365 6.487 13.831 6.396 14.175 6.625C14.521 6.855 14.615 7.321 14.385 7.665V7.666Z"
                    fill="#007BFF"
                  />
                  <path
                    d="M20 10.8571C20 9.72857 19.3334 8.75 18.3635 8.28571C18.4808 7.975 18.5448 7.63929 18.5448 7.28571C18.5448 5.70714 17.242 4.43 15.636 4.43C15.2779 4.43 14.9351 4.49 14.6182 4.60857C14.1473 3.65357 13.1508 3 12.0004 3C10.85 3 9.85496 3.655 9.38184 4.60714C9.06566 4.48929 8.72206 4.42857 8.36398 4.42857C6.75644 4.42857 5.45517 5.70714 5.45517 7.28571C5.45517 7.63857 5.5184 7.97429 5.63573 8.28571C4.66663 8.75 4 9.72714 4 10.8571C4 11.925 4.59578 12.8557 5.47955 13.3471C5.46431 13.4686 5.45517 13.59 5.45517 13.7143C5.45517 15.2929 6.75644 16.5714 8.36398 16.5714C8.72206 16.5714 9.0649 16.51 9.38108 16.3929C9.85344 17.3457 10.8484 18 11.9996 18C13.1516 18 14.1466 17.3457 14.6182 16.3929C14.9343 16.5093 15.2772 16.57 15.636 16.57C17.2436 16.57 18.5448 15.2914 18.5448 13.7129C18.5448 13.5886 18.5357 13.4671 18.5197 13.3464C19.4019 12.8557 20 11.925 20 10.8579V10.8571ZM14.9595 8.47571L11.6575 13.1186C11.5471 13.2736 11.3665 13.3571 11.1814 13.3571C11.0724 13.3571 10.962 13.3286 10.8644 13.2671L10.7768 13.2L8.93691 11.475C8.71368 11.2657 8.71368 10.9264 8.93691 10.7179C9.16014 10.5093 9.52202 10.5079 9.74449 10.7179L11.093 11.98L14.0071 7.88C14.1824 7.63357 14.5374 7.56857 14.7995 7.73214C15.0631 7.89643 15.1347 8.22929 14.9595 8.475V8.47571Z"
                    fill=""
                  />
                  <path
                    d="M17 9.85714C17 8.72857 16.3334 7.75 15.3635 7.28571C15.4808 6.975 15.5448 6.63929 15.5448 6.28571C15.5448 4.70714 14.242 3.43 12.636 3.43C12.2779 3.43 11.9351 3.49 11.6182 3.60857C11.1473 2.65357 10.1508 2 9.00038 2C7.84996 2 6.85496 2.655 6.38184 3.60714C6.06566 3.48929 5.72206 3.42857 5.36398 3.42857C3.75644 3.42857 2.45517 4.70714 2.45517 6.28571C2.45517 6.63857 2.5184 6.97429 2.63573 7.28571C1.66663 7.75 1 8.72714 1 9.85714C1 10.925 1.59578 11.8557 2.47955 12.3471C2.46431 12.4686 2.45517 12.59 2.45517 12.7143C2.45517 14.2929 3.75644 15.5714 5.36398 15.5714C5.72206 15.5714 6.0649 15.51 6.38108 15.3929C6.85344 16.3457 7.84844 17 8.99962 17C10.1516 17 11.1466 16.3457 11.6182 15.3929C11.9343 15.5093 12.2772 15.57 12.636 15.57C14.2436 15.57 15.5448 14.2914 15.5448 12.7129C15.5448 12.5886 15.5357 12.4671 15.5197 12.3464C16.4019 11.8557 17 10.925 17 9.85786V9.85714ZM11.9595 7.47571L8.65754 12.1186C8.54707 12.2736 8.36651 12.3571 8.18137 12.3571C8.07243 12.3571 7.96195 12.3286 7.86444 12.2671L7.77682 12.2L5.93691 10.475C5.71368 10.2657 5.71368 9.92643 5.93691 9.71786C6.16014 9.50929 6.52202 9.50786 6.74449 9.71786L8.093 10.98L11.0071 6.88C11.1824 6.63357 11.5374 6.56857 11.7995 6.73214C12.0631 6.89643 12.1347 7.22929 11.9595 7.475V7.47571Z"
                    fill="#007BFF"
                  />
                  <path
                    d="M10.939 5.029V5.77C11.5803 5.796 12.135 5.887 12.603 6.043C13.071 6.19033 13.305 6.41567 13.305 6.719C13.305 6.98767 13.214 7.343 13.032 7.785C12.8587 8.227 12.7373 8.461 12.668 8.487C11.94 8.18367 11.3637 8.01467 10.939 7.98V9.449C12.759 9.51833 13.669 10.3937 13.669 12.075C13.669 12.8637 13.4437 13.5397 12.993 14.103C12.5423 14.6663 11.8577 14.9957 10.939 15.091V15.988C10.939 16.144 10.8913 16.248 10.796 16.3C10.7093 16.352 10.6357 16.378 10.575 16.378L10.393 16.326C10.2717 16.2567 10.211 16.144 10.211 15.988V15.13C9.483 15.1127 8.83733 14.9827 8.274 14.74C7.71933 14.4973 7.442 14.1897 7.442 13.817C7.442 13.3317 7.58933 12.842 7.884 12.348C7.91 12.296 7.94467 12.2397 7.988 12.179C8.69 12.6037 9.431 12.842 10.211 12.894V11.464C8.365 11.3513 7.442 10.4067 7.442 8.63C7.442 7.85 7.689 7.20867 8.183 6.706C8.677 6.20333 9.353 5.9 10.211 5.796V5.029C10.211 4.873 10.2543 4.769 10.341 4.717C10.4363 4.665 10.5143 4.639 10.575 4.639L10.757 4.691C10.8783 4.76033 10.939 4.873 10.939 5.029ZM11.862 12.01C11.862 11.8107 11.7927 11.6763 11.654 11.607C11.524 11.529 11.2857 11.4813 10.939 11.464V12.868C11.5543 12.764 11.862 12.478 11.862 12.01ZM9.418 8.773C9.418 9.137 9.68233 9.34933 10.211 9.41V7.993C9.68233 8.07967 9.418 8.33967 9.418 8.773Z"
                    fill="white"
                  />
                </svg>
              </div>
              <p
                style="
                  font-size: 16px;
                  margin-left: 4px;
                  transform: translateY(-1px);
                  display: inline-block;
                "
                v-if="user.investor"
              >
                <i
                  style=""
                  class="fas fa-chart-pie-simple-circle-dollar primary-color"
                ></i>
              </p>
            </span>
            <br />
            <span
              @click="copySlug"
              class="text-gray"
              style="font-size: 14px; transition: 0.5s; cursor: pointer"
              >@{{ user.slug }}
              <span
                style="cursor: pointer"
                @click="$router.push('/settings/wallet')"
                v-if="me.id == user.id"
                >• {{ balance.toFixed(2) }}$</span
              ></span
            >
            <br />
            <div
              @click="gotoCategoryPage(user.category.id)"
              class="text-white"
              style="margin-top: 8px; color: #007bff; cursor: pointer"
              v-if="user.category"
            >
              <i :class="user.category.icon"></i> {{ user.category.title }}
            </div>
            <br /><br />
            <div
              v-if="!isMyProfile()"
              @click="openChat"
              :style="'top: 40px;right: 0;'"
              style="
                position: absolute;
                float: right;
                color: #cbcfd4;
                margin-top: -1px;
                margin-right: 0px;
                z-index: 0;
              "
              text
              fab
              absolute
            >
              <i
                class="fa-light fa-paper-plane"
                style="font-size: 20px; cursor: pointer"
              ></i>
            </div>

            <div
              v-if="!isMyProfile()"
              :style="'top: 5px;right: 0;'"
              style="
                float: right;
                color: #cbcfd4;
                margin-top: -1px;
                z-index: 0;
                position: absolute;
              "
              text
              fab
              absolute
              @click="openChat"
            >
              <i
                class="fa-light fa-badge-dollar"
                style="font-size: 20px; cursor: pointer"
              ></i>
            </div>
            <div
              style="position:absolute;right:2%;top: 10%;absolute;font-size: 20px;"
              v-if="isMyProfile()"
            >
              <i
                class="fa-light fa-user-gear settings-icon"
                @click="$router.push('/settings')"
              ></i>
            </div>
            <!-- TODO PUT ALL THE LINKS, AND USEFUL FEATURES LIKE COPY/SHARE ETC ETC INSIDE THE MENU -->
            <!-- <div
              style="position:absolute;right:2%;top: 18%;absolute;font-size: 20px;"
            >
            <i class="fa fa-ellipsis-h" aria-hidden="true" 
                style="cursor: pointer"></i>
            </div> -->
          </div>
        </div>
        <br /><br />
        <p style="color: #4d4d4d; transition: 0.5s; margin-top: 1em">
          <span style="margin-right: 0.5em; font-size: 18px">
            <b>{{ formatedCounts.subscribersCount }}</b>
            Subscribers
          </span>
          <span style="font-size: 18px; margin-right: 0.5em">
            <b>{{ formatedCounts.likesCount }}</b>
            Likes
          </span>
          <span style="font-size: 18px; margin-right: 0.5em">
            <b>{{ formatedCounts.postsCount }}</b>
            Posts
          </span>
          <br /><br />
          <span v-html="userBio(user.bio)"> </span>
          <br v-if="user.location" />
          <br v-if="user.location && user.bio" />
          <a v-if="user.location"
            ><i class="fa-light fa-location-dot"></i> {{ user.location }}</a
          >
          <br v-if="user.custom_link" />
          <br v-if="user.custom_link && user.location" />
          <a
            :href="formatCustomLinkURL(user.custom_link)"
            v-if="user.custom_link"
            style="color: #007bff; text-decoration: none"
            target="_blank"
            ><i class="fa-light fa-link"></i>
            {{ formatCustomLink(user.custom_link) }}</a
          >
        </p>
        <center>
          <v-btn
            @click="openPopup('subscribe')"
            v-if="!isMyProfile() && me"
            style="margin-top: 16px; font-weight: bolder; width: 100%"
            :style="{
              background:
                user.subscribedPackages && user.subscribedPackages.length > 0
                  ? 'rgba(0, 123, 255, .1)'
                  : '#007bff',
            }"
            elevation="0"
            large
            rounded
          >
            <div
              style="
                display: flex;
                justify-content: space-between;
                width: 100%;
                text-transform: none;
              "
              :style="{
                color:
                  user.subscribedPackages && user.subscribedPackages.length > 0
                    ? '#007bff'
                    : 'white',
              }"
            >
              <div
                style="color: inherit"
                v-if="
                  (user.subscribedPackages &&
                    user.subscribedPackages.length <= 0) ||
                  !user.subscribedPackages
                "
              >
                Subscribe
              </div>
              <div style="color: inherit" v-else>
                <span v-if="getHighestPaidSubscription.canceled"> Renew </span>
                <span v-else>Subscribed</span>
              </div>
              <div style="color: inherit">
                <template v-if="getHighestPaidSubscription.bundle_key">
                  <template
                    v-if="
                      getHighestPaidSubscription.bundle_key ==
                      'subscription_price_free'
                    "
                  >
                    <span style="text-transform: uppercase">free</span>
                  </template>
                  <template v-else>
                    <span style="text-transform: uppercase"> FREE</span> to
                    {{ user[getHighestPaidSubscription.bundle_key] }}$ /
                    {{ bundleNames[getHighestPaidSubscription.bundle_key] }}
                  </template>
                </template>
                <template v-else>
                  <span style="text-transform: uppercase">FREE</span>
                  <template v-if="user.subscription_price > 0"
                    >to {{ user.subscription_price }} $/month</template
                  >
                  <template
                    v-if="
                      !parseFloat(user.subscription_price) &&
                      user.subscription_price_trimester
                    "
                  >
                    to
                    {{ user.subscription_price_trimester }}
                    $/trimester</template
                  >
                  <template
                    v-if="
                      !parseFloat(user.subscription_price) &&
                      !parseFloat(user.subscription_price_trimester) &&
                      user.subscription_price_semester
                    "
                  >
                    to
                    {{ user.subscription_price_semester }}
                    $/semester</template
                  >
                </template>
              </div>
            </div>
          </v-btn>
          <v-btn
            @click="$router.push('/login')"
            v-else-if="user.subscription_price && !me"
            :text="true"
            style="
              color: #007bff;
              background-color: #007bff;
              color: white;
              margin-top: 16px;
              font-weight: bolder;
            "
            :style="windowWidth > 768 ? 'width: 100%' : 'width: 100%;'"
            elevation="0"
            large
            rounded
          >
            <div
              style="
                display: flex;
                justify-content: space-between;
                width: 100%;
                text-transform: capitalize;
              "
            >
              <div>Subscribe</div>
              <div>{{ user.subscription_price }}$/month</div>
            </div>
          </v-btn>
        </center>
      </div>
      <CoolLightBox
        :items="[`${user.banner}`]"
        :index="viewingBannerIndex"
        @close="viewingBannerIndex = null"
      >
      </CoolLightBox>
      <CoolLightBox
        :items="[`${user.avatar}`]"
        :index="viewingAvatarIndex"
        @close="viewingAvatarIndex = null"
      >
      </CoolLightBox>
      <CoolLightBox
        :items="medias('large')"
        :index="viewingMediaIndex"
        @close="viewingMediaIndex = null"
      >
      </CoolLightBox>
      <v-tabs
        v-model="tab"
        color="#007BFF"
        fixed-tabs
        :style="
          windowY >= 270 && !formDialog
            ? 'position: sticky;top:0;left:1em;' //z-index: 1000;
            : ''
        "
      >
        <v-tabs-slider color="#007BFF"></v-tabs-slider>

        <v-tab href="#tab-jobs">
          <small style="display: flex; align-items: center">
            <span style="font-size: 1.3rem">
              <i class="fa-light fa-rectangle-history"></i>
            </span>
            <span v-if="windowWidth > 768" style="margin-left: 8px">Posts</span>
          </small>
        </v-tab>

        <v-tab
          href="#tab-premium"
          @click="scroll(300), (viewingMediaFromPostIndex = null)"
          class="v-tab--custom"
        >
          <small style="display: flex; align-items: center">
            <span style="font-size: 1.3rem">
              <i class="fa-light fa-grid"></i>
            </span>
            <span v-if="windowWidth > 768" style="margin-left: 8px"
              >Medias</span
            >
          </small>
        </v-tab>

        <!-- <v-tab href="#tab-menu" @click="scroll(300)" v-if="windowWidth <= 1219">
          <small>Menu </small>
        </v-tab> -->
      </v-tabs>
      <v-tabs-items v-model="tab" touchless>
        <v-tab-item value="tab-jobs">
          <Feed
            :posts="posts"
            :user="user"
            :showWelcomeCards="showWelcomeCards"
            :showWelcomePostCard="showWelcomePostCard"
            @deletePost="deletePost"
            @newComment="onNewComment"
            @deleteComment="onDeleteComment"
            @refresh="refreshPosts(`/getPosts/${user.id}`)"
          ></Feed>
        </v-tab-item>
        <v-tab-item value="tab-premium">
          <center v-if="posts.length === 0">
            <div v-if="showWelcomePostCard" class="welcome-card-wrapper">
              <div class="welcome-card">
                <div class="welcome-card__close">
                  <v-btn icon @click="closeWelcomeCard()">
                    <i class="fa-light fa-times"></i>
                  </v-btn>
                </div>
                <div class="welcome-card__image">
                  <v-img
                    src="/assets/images/make_a_post.svg"
                    width="150px"
                  ></v-img>
                </div>
                <div class="welcome-card__title">
                  <h2>You are now a creator!</h2>
                </div>
                <div class="welcome-card__description">
                  <p>Start sharing your content.</p>
                </div>
                <v-btn
                  class="welcome-card__buttons btn-sider-money"
                  elevation="0"
                  @click="openPostPopup"
                >
                  New post
                </v-btn>
              </div>
            </div>
            <div v-if="showWelcomeCards" class="welcome-card-wrapper">
              <div class="welcome-card">
                <div class="welcome-card__close">
                  <v-btn icon @click="closeWelcomeCard()">
                    <i class="fa-light fa-times"></i>
                  </v-btn>
                </div>
                <div class="welcome-card__image">
                  <v-img
                    src="https://sidermoney.nyc3.digitaloceanspaces.com/logos/undraw_happy_news_re_tsbd.svg"
                    width="150px"
                  >
                  </v-img>
                </div>
                <div class="welcome-card__title">
                  <h2>Become a creator</h2>
                </div>
                <div class="welcome-card__description">
                  <p>
                    Create your own content and earn money with your audience.
                  </p>
                </div>
                <v-btn
                  class="welcome-card__buttons btn-sider-money"
                  elevation="0"
                  @click="$router.push('/become-a-creator')"
                >
                  Set up
                </v-btn>
              </div>
            </div>
          </center>
          <GridFeed
            :posts="posts"
            @openSubscribePopup="openPopup('subscribe')"
            @openPayperviewPopup="openPayperviewPopup"
          ></GridFeed>
          <!-- <div
            style="
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              column-gap: 1px;
              row-gap: 1px;
              position: relative;
            "
          >
            <div
              @click="viewMedia(mediaIndex)"
              style="
                cursor: pointer;
                height: calc(100vw / 3);
                background-size: cover;
                background-position: center;
                max-height: 255px;
              "
              v-for="(post, mediaIndex) in posts"
              :key="mediaIndex"
              :style="`background-image: url('${media}');`"
            >
            <s-video hide-controls maxHeight="255px" class="premium-video" style="width: 100%; height: 100%;" v-if="isVideo(media)" :src="media"></s-video>
            </div>
          </div> -->

          <!-- <v-img src="https://i.imgur.com/bqHZsvF.png" />
                    <div
                        style="display:grid;place-items: center;text-align: center;width: 100%;height: 100%;background-color: rgba(72, 72, 72, .7);position: absolute;left:0;top:0;backdrop-filter: blur(5.5px);">
                        <span>
                            <i class="fa-light fa-lock-keyhole"
                                style="color: #fff;font-size: 30px;text-shadow: 0px 2px 6px #000000;"></i>
                            <br>
                            <br>
                            <span style="color: #fff;text-shadow: 0px 2px 6px #000000;">Unlock this user
                                content,<br>with a subscription of
                                <br>
                                <b style="font-weight: 600;">12$/month</b>
                            </span>
                            <br><br>
                            <v-btn
                                style="box-shadow: 0px 2px 6px rgba(0,0,0,37%);;width: 200px;background: linear-gradient(90deg, rgba(21, 168, 216, 1) 0%, rgba(72, 79, 248, 1) 100%) !important;color:white;"
                                elevation="0">
                                Subscribe
                            </v-btn>
                        </span>

                    </div> -->
        </v-tab-item>
        <v-tab-item value="tab-menu">
          <tip-menu padding="1em"> </tip-menu>
        </v-tab-item>
      </v-tabs-items>
      <br /><br /><br /><br /><br /><br /><br />
    </div>
  </div>
</template>

<!-- BOTTÃO DE + PARA RECOMENDAÇÕES -->
<!-- <v-btn @click="openPopup('suggest')" class='btn-plus-profile'
    v-if="!isMyProfile() && tab == 'tab-recommendations'" fab :fixed="windowWidth < 768" right bottom
    style="bottom: 10%;background: linear-gradient(90deg, rgba(21, 168, 216, 1) 0%, rgba(72, 79, 248, 1) 100%) !important;color:white;"
        :style="windowWidth > 767 ? 'margin-left: 680px;position: fixed;' : ''">
    <i class="fa fa-plus" aria-hidden="true"></i>
</v-btn> -->

<!--<v-list id="minhaLista" style="overflow-y: auto;">
                            <v-list-item v-for="item in items" :key="item.title" style="position: relative;">

                                <v-list-item-content>
                                    <v-list-item-title v-text="item.title" style="font-weight: bold;">
                                    </v-list-item-title>
                                    <v-list-item-subtitle v-text="item.service" style="color: #999999;">
                                    </v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-avatar>
                                    <v-img :src="item.suggesterAvatar"></v-img>
                                </v-list-item-avatar>
                                <br>

                            </v-list-item>


                        </v-list>-->

<script>
// import SlideUnlock from "vue2-slide-unlock"
import Feed from "@/components/shared/Feed";
import InsertMenuItemPopup from "./InsertMenuItemPopup.vue";
import EditMenuItemPopup from "./EditMenuItemPopup.vue";
import SuggestLeadPopup from "./SuggestLeadPopup.vue";
import ViewMenuItemPopup from "./ViewMenuItemPopup.vue";
import SubscribePopup from "./SubscribePopup.vue";
import CreatePostPopup from "@/components/Popups/CreatePostPopup.vue";
import SetupInterestsPopup from "./SetupInterestsPopup.vue";

import TipMenu from "@/components/shared/TipMenu.vue";

import tipMenuMixin from "@/mixins/tipMenuMixin";
import utilsMixin from "@/mixins/utils";
import postMixin from "@/mixins/post";
import payperview from "@/mixins/payperview";

import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import GridFeed from "@/components/shared/GridFeed.vue";

import PayPerViewPopup from "@/components/Popups/PayPerViewPopup.vue";
export default {
  mixins: [tipMenuMixin, postMixin, utilsMixin, payperview],
  data: () => ({
    postPayPerView: {
      owner: {},
    },
    bundleNames: {
      subscription_price_free: "month",
      subscription_price: "month",
      subscription_price_trimester: "3 months",
      subscription_price_semester: "6 months",
      subscription_price_year: "1 year",
    },
    showPayPerViewPopup: false,
    styleDefaultPopup: ["subscribe", "setup-interests"],
    viewingMediaFromPostIndex: null,
    viewingMediaIndex: null,
    viewingBannerIndex: null,
    viewingAvatarIndex: null,
    posts: [],
    successText: "",
    successAlert: false,
    stock: 0,
    balance: 0,
    viewingJob: {
      category: {},
      owner: {},
    },
    editingJob: {},
    activePopup: "",
    formDialog: false,
    myJobs: [],
    mySuggestions: [],
    myLeads: [],
    user: {
      id: 0,
      name: "",
      slug: "",
      email: "",
      bio: "",
      avatar: "",
      banner: "",
      created_at: "",
    },
    windowY: 0,
    tab: null,
    showList: false,
    windowWidth: 0,
    isLoadingBanner: false,
    isLoadingAvatar: false,
  }),
  components: {
    PayPerViewPopup,
    Feed,
    CoolLightBox,
    Loading,
    GridFeed,
    "edit-menu-item-popup": EditMenuItemPopup,
    "suggest-popup": SuggestLeadPopup,
    "insert-menu-item-popup": InsertMenuItemPopup,
    "view-menu-item-popup": ViewMenuItemPopup,
    "subscribe-popup": SubscribePopup,
    "tip-menu": TipMenu,
    "create-post-popup": CreatePostPopup,
    "setup-interests-popup": SetupInterestsPopup,
  },
  mounted() {
    if (this.me.id) {
      this.getBalance();
    }

    this.windowWidth = window.innerWidth;
    this.loadtext();
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.onResize);
  },
  watch: {
    tab() {
      this.$store.commit("setTab", this.tab);
    },
    async "$store.state.openPopup"(popupName) {
      if (!this.me.id) {
        this.$store.state.openPopup = "";
        return this.$router.push("/").catch(() => {});
      }
      if (!popupName) return;
      this.activePopup = popupName;
      this.formDialog = true;
      if (popupName == "subscribe") {
        await this.getBalance();
      }
    },
    formDialog() {
      if (!this.formDialog) {
        this.$store.commit("setPopup", "");
      }
    },
  },
  beforeDestroy() {
    // I switched the example from `destroyed` to `beforeDestroy`
    // to exercise your mind a bit. This lifecycle method works too.
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    defaultImages() {
      return {
        avatar: process.env.VUE_APP_DEFAULT_USER_AVATAR,
        banner: process.env.VUE_APP_DEFAULT_USER_BANNER,
      };
    },
    formatedCounts() {
      let subscribersCount = this.user.subscribersCount;
      let likesCount = this.user.likesCount;

      return {
        subscribersCount: this.formatValueSymbol(subscribersCount),
        likesCount: this.formatValueSymbol(likesCount),
        postsCount: this.formatValueSymbol(this.posts.length),
      };
    },
    getHighestPaidSubscription() {
      if (!this.user.subscribedPackages) return {};
      if (this.user.subscribedPackages.length > 0) {
        // its not price but bundle_key
        return this.user.subscribedPackages.reduce((prev, current) =>
          prev.price > current.price ? prev : current
        );
      }
      return {};
    },
    showPerMonth() {
      return (
        this.user.subscription_price > 0 &&
        this.user.subscription_price_trimester == 0 &&
        this.user.subscription_price_semester == 0 &&
        this.user.subscription_price_year == 0
      );
    },
    lowestAndHighestSubscriptionsPrices() {
      let lowest = 0;
      let highest = 0;
      lowest = this.user.subscription_price;
      highest = this.user.subscription_price;

      if (this.user.subscription_price_trimester > 0) {
        lowest = Math.min(lowest, this.user.subscription_price_trimester);
        highest = Math.max(highest, this.user.subscription_price_trimester);
      }

      if (this.user.subscription_price_semester > 0) {
        lowest = Math.min(lowest, this.user.subscription_price_semester);
        highest = Math.max(highest, this.user.subscription_price_semester);
      }

      if (this.user.subscription_price_year > 0) {
        lowest = Math.min(lowest, this.user.subscription_price_year);
        highest = Math.max(highest, this.user.subscription_price_year);
      }
      return { lowest, highest };
    },
    countVideos() {
      return this.posts.filter(
        (post) => post.files.filter((f) => f.video).length > 0
      ).length;
    },
    countImages() {
      return this.posts.filter(
        (post) => post.files.filter((f) => f.medium).length > 0
      ).length;
    },
  },
  methods: {
    async copySlug() {
      if (!this.me.id) {
        if (this.$route.params.slug) {
          await this.$router
            .replace(`/r/${this.$route.params.slug}`)
            .catch(() => {});
          this.$router.replace("/register").catch(() => {});
          return;
        } else {
          return this.$router.replace("/register").catch(() => {});
        }
      }
      // copy with native clipboard for mac
      if (navigator.clipboard) {
        navigator.clipboard.writeText(
          `${window.location.origin}/r/${this.me.slug}`
        );
      } else {
        // fallback for safari
        const el = document.createElement("textarea");
        el.value = `${window.location.origin}/r/${this.me.slug}`;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
      }
      this.$swal({
        title: "Copied!",
        text: "Link to share copied to clipboard",
        icon: "success",
        timer: 2000,
        showCancelButton: false,
        showConfirmButton: false,
      });
    },
    formatValueSymbol(val = "") {
      if (val > 999 && val < 1000000) {
        val = (val / 1000).toFixed(1) + "K";
      } else if (val > 999999 && val < 1000000000) {
        val = (val / 1000000).toFixed(1) + "M";
      } else if (val > 999999999 && val < 1000000000000) {
        val = (val / 1000000000).toFixed(1) + "B";
      } else if (val > 999999999999 && val < 1000000000000000) {
        val = (val / 1000000000000).toFixed(1) + "T";
      }

      val = String(val);

      if (
        !val.includes("K") &&
        !val.includes("M") &&
        !val.includes("B") &&
        !val.includes("T")
      ) {
        val = Number(val);
      }

      return val;
    },
    formatCustomLinkURL(link) {
      if (!link) return;
      link = link.replace(/https:\/\/|http:\/\//g, "");
      return `https://${link}`;
    },
    formatCustomLink(link) {
      if (!link) return;
      link = link.replace(/https:\/\/|http:\/\//g, "");
      return link;
    },
    gotoCategoryPage(categoryId) {
      this.$router.push({ path: `/discover/c/${categoryId}` });
    },
    async openPayperviewPopup(post) {
      await this.getBalance();
      this.showPayPerViewPopup = true;
      this.postPayPerView = post;
    },
    viewMedia(mediaIndex) {
      // find the post index
      mediaIndex;
      // let postIndex = this.posts.findIndex(post => post.id == this.viewingMediaFromPostIndex);
      this.$router.replace({ path: `/discover/${this.user.slug}/999` });
    },
    onUploadBanner(event) {
      this.isLoadingBanner = true;
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("banner", file);
      this.$http
        .post("/api/v1/uploadBanner", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.isLoadingBanner = false;
          this.user.banner = `${process.env.VUE_APP_CDN_BASE_URL}${this.user.id}/banners/${response.data.banner}`;
          this.$refs.profileBannerInput.value = null;

          if (response.data.accessToken) {
            localStorage.setItem("sider-token", response.data.accessToken);
            this.$token = response.data.accessToken;
            this.me = this.decryptData(this.$token);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onUploadAvatar(event) {
      this.isLoadingAvatar = true;
      const file = event.target.files[0];
      const formData = new FormData();

      formData.append("avatar", file);
      this.$http
        .post("/api/v1/uploadAvatar", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.isLoadingAvatar = false;
          this.user.avatar = response.data.avatar;
          this.$refs.profileAvatarInput.value = null;

          if (response.data.accessToken) {
            localStorage.setItem("sider-token", response.data.accessToken);
            this.$token = response.data.accessToken;
            this.me = this.decryptData(this.$token);
            this.$store.state.reloadAvatarSidebar++;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clickOnProfileInput() {
      this.$refs.profileAvatarInput.click();
    },
    clickOnBannerInput() {
      this.$refs.profileBannerInput.click();
    },
    openChat() {
      this.$http
        .post("/api/v1/openChat", { id: this.user.id })
        .then((response) => {
          this.$store.state.channelOpen = response.data;
          this.$router.push("/chat?load=true");
        });
    },
    medias(size) {
      if (this.viewingMediaFromPostIndex != null) {
        if (this.viewingMediaFromPostIndex == null) return;
        var files = this.posts[this.viewingMediaFromPostIndex].files;
        return files.map((file) => {
          if (this.isImage(file.medium || "")) {
            return `${file[size]}`;
          } else {
            return `${file.medium ? file.medium : file.video}`;
          }
        });
      } else {
        files = this.posts.map((post) => {
          return post.files;
        });
        return files.flat(2).map((file) => {
          if (this.isImage(file.medium || "")) {
            return `${file[size]}`;
          } else {
            return `${file.medium ? file.medium : file.video}`;
          }
        });
      }
    },
    userBio(bio) {
      // remove all html possible
      bio = bio?.replace(/<[^>]*>?/gm, "");
      return bio;
    },
    showSuccessSnack(text) {
      this.successText = text;
      this.successAlert = true;
    },
    async getBalance() {
      this.balance = await this.$http
        .get("/api/v1/getBalance")
        .then((res) => res.data.credits)
        .catch(() => 0);
    },
    async getServiceStock(id) {
      this.stock = await this.$http
        .get("/api/v1/getStock/" + id)
        .then((res) => res.data.stock);
    },
    closeDialog() {
      this.formDialog = false;
      this.$store.commit("setPopup", "");
      this.viewingJob = {
        category: {},
        owner: {},
      };
    },
    scroll(y) {
      setTimeout(() => {
        window.scrollTo(0, y);
      }, 350);
    },
    async viewJob(job) {
      if (this.isMyProfile()) return;
      this.viewingJob = job;
      await this.getServiceStock(job.id);
      this.openPopup("view-job");
    },
    // setEditingJob(job) {
    //     this.editingJob = job;
    //     this.openPopup('edit-job');
    // },
    removeDuplicates(array) {
      return [...new Set(array)];
    },
    getMaxMinPrices(jobs) {
      var max = Math.max.apply(
        Math,
        jobs.map(function (o) {
          return o.price_service;
        })
      );
      var min = Math.min.apply(
        Math,
        jobs.map(function (o) {
          return o.price_service;
        })
      );
      return min + "$" + " - " + max + "$";
    },
    getMostValuableJob(jobs) {
      let max = 0;
      let maxJob = null;
      for (let job of jobs) {
        if (job.price_service > max) {
          max = job.price_service;
          maxJob = job;
        }
      }
      return maxJob.title;
    },
    async reloadJobs() {
      // this.myJobs = await this.$http.get('/api/v1/getServices/' + this.user.id).then(res => res.data)
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    // async openPopup(popupName) {
    //     this.activePopup = popupName;
    //     this.formDialog = true;
    //     await this.getBalance();
    // },
    async loadRequests() {
      // var {
      //     slug
      // } = this.$route.params;
      // this.user = await this.$http.get('/api/v1/getUserProfile/' + slug).then(res => res.data)
      // console.log({user: this.user});
      // if (!this.user) return this.$router.push('/not-found');
      // this.myJobs = await this.$http.get('/api/v1/getServices/' + this.user.id).then(res => res.data)
      // console.log(this.myJobs)
      // this.mySuggestions = await this.$http.get('/api/v1/getServices/' + this.user.id +
      //     '?filter=suggestions').then(res => res.data)
    },
    isMyProfile() {
      return this.me ? this.me && this.user.id == this.me?.id : false;
    },
    handleScroll() {
      this.windowY = window.pageYOffset;
    },
    // heightList() {
    //     if (document.body) {
    //         if (document.getElementById('minhaLista')) {
    //             var bodyRect = document.body.getBoundingClientRect(),
    //                 elemRect = document.getElementById('minhaLista').getBoundingClientRect(),
    //                 offset = elemRect.top - bodyRect.top;
    //             alert(offset)
    //             document.getElementById('minhaLista').setAttribute('style', `overflow-y: auto;height: calc(100vh - ${offset + 65}px);background-color:red;`)
    //         } else {
    //             return 0;
    //         }
    //     } else {
    //         return 0;
    //     }
    // },
    complete() {
      // this.$refs.vueslideunlock.$el.querySelector('.slideunlock-text').innerHTML = `  `;
      setTimeout(() => {
        this.$refs.vueslideunlock.reset();
        this.loadtext();
      }, 1000);
    },
    loadtext() {
      // this.$refs.vueslideunlock.$el.querySelector('.slideunlock-text').innerHTML = `
      // <p style='font-weight: bold;padding-top: .7em;font-size: 16px;'>SIDER.MONEY</p>
      // <span style="font-size: 11px;">e ganha 200,00€</span>
      // `
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/styles/variables.scss";

.settings-icon {
  cursor: pointer;

  &:hover {
    color: #007bff;
  }
}

.profile-content {
  // padding: 1em;
  padding-top: 0.3em;
  transition: 0.5s;
}

.profile-banner {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: calc(100vw / 2.7);
  max-height: 280px;
}

.profile-banner-info {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;

  &__card {
    background-color: var(--primary-color);
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    color: var(--color-white);

    display: flex;
    justify-content: space-between;
    align-items: center;

    & i {
      margin-right: 8px;
    }

    div {
      display: flex;
      width: 100%;
      justify-content: center;
    }

    @media (max-width: 768px) {
      padding: 0.5rem;
    }

    @media (min-width: 769px) {
      padding: 1rem;
      font-size: 1.1rem;
    }
  }
}

.profile-image {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  position: absolute !important;
  transform: translateY(-40%);
  z-index: 2 !important;

  & div {
    border-radius: 50% !important; //v-image disrespecting border-radius
  }
}

:root {
  --su-handler-bg: url("https://i.imgur.com/N0UOZJn.png");
  --su-color-progress-normal-bg: transparent;
  --su-color-progress-complete-bg: transparent;
}
</style>

<style lang="scss">
.s-tab-btn {
  width: 110px;
  // height: 40px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 20px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  transition: 0.5s;
  text-transform: uppercase;
  // color: rgba(0, 123, 255, 0.5);
  color: rgba(0, 0, 0, 0.54);

  &.active {
    background-color: rgba(0, 123, 255, 0.2);
    color: rgba(0, 123, 255, 1);
    transition: 0.5s;
    transform: scale(1.1);
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
}
</style>

<style>
.v-slide-group__prev {
  display: none !important;
}

.v-window__prev {
  left: 0;
}

.v-window__next {
  right: 0;
}
</style>

<style lang="scss">
@import "~@/assets/styles/variables.scss";

.edit-post {
  transition: 0.5s;

  &:hover {
    transition: 0.5s;
    color: #007bff !important;
  }
}

.premium-container {
  min-height: 500px;
  padding-top: 0;
  padding-bottom: 0;

  .premium-card {
    margin-top: 1em;
    margin-bottom: 8px;
    .premium-image {
      .blocked-content {
        width: 100%;
        height: 100%;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0.5) 100%
        );
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        backdrop-filter: blur(10px);
      }
    }

    .premium-footer {
      padding: 1.1em;
      padding-bottom: 6px;
      padding-top: 12px;
      font-weight: 400;

      & > .premium-text {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.54);
        margin-top: 5px;
        display: flex;

        & > span {
          margin-right: 1em;
          transition: 0.5s;

          &:hover {
            transition: 0.5s;
            cursor: pointer;
            color: #007bff;
          }
        }
      }
    }

    .premium-footer,
    .premium-header {
      color: black;
      font-size: 14px;
      letter-spacing: 1px;

      & > .premium-icons {
        font-size: 20px;

        & > span:not(:nth-child(4)) {
          transition: 0.5s;

          & > i {
            margin-right: 0.3em;
          }

          & > span {
            font-size: 17px;
          }
        }

        & > :hover {
          transition: 0.5s;
          cursor: pointer;
          color: #007bff;
        }

        & > :nth-child(1),
        & > :nth-child(2) {
          margin-right: 1.5em;
        }

        & > :nth-child(3) {
          float: right;
          margin-top: 0.1em;
        }
      }
    }
  }
}

.v-tab {
  max-width: unset !important;

  &--custom {
    padding: 0 !important;
  }
}
</style>
