<template>
  <div>
    <div class="membership-view__cards-wrapper">
      <div class="membership-view__card membership-view__card-gross-earnings">
        <div class="membership-view__card-icon">
          <i class="fal fa-dollar-sign"></i>
        </div>
        <div class="membership-view__card-info">
          <p class="membership-view__card-title">Total Gross</p>
          <p class="membership-view__card-value">$ {{ formatValue(insights.totalGross) }}</p>
          <!-- <div class="membership-view__card-comparison-line   membership-view__card-comparison-line--green">
            <i class="fal fa-chart-line-up"></i>
            43%
          </div> -->
        </div>
      </div>


      <div class="membership-view__card membership-view__card-gross-earnings">
        <div class="membership-view__card-icon">
          <i class="fal fa-dollar-sign"></i>
        </div>
        <div class="membership-view__card-info">
          <p class="membership-view__card-title">Total Net</p>
          <p class="membership-view__card-value">$ {{ formatValue(insights.totalNet) }}</p>
          <!-- <div class="membership-view__card-comparison-line   membership-view__card-comparison-line--green">
            <i class="fal fa-chart-line-up"></i>
            43%
          </div> -->
        </div>
      </div>


      <div class="membership-view__card">
        <div class="membership-view__card-icon">
          <i class="fal fa-heart" aria-hidden="true"></i>
        </div>
        <div class="membership-view__card-info">
          <p class="membership-view__card-title">Likes</p>
          <p class="membership-view__card-value">$  {{ formatValue(insights.likes) }}</p>
          <!-- <div class="membership-view__card-comparison-line  membership-view__card-comparison-line--green">
            <i class="fal fa-chart-line-down"></i>
            43%
          </div> -->
        </div>
      </div>

      <div class="membership-view__card">
        <div class="membership-view__card-icon">
          <i class="fal fa-users" aria-hidden="true"></i>
        </div>
        <div class="membership-view__card-info">
          <p class="membership-view__card-title">Affiliates</p>
          <p class="membership-view__card-value">$ {{ formatValue(insights.affiliates) }}</p>
          <!-- <div class="membership-view__card-comparison-line   membership-view__card-comparison-line--green">
            <i class="fal fa-chart-line-up"></i>
            43%
          </div> -->
        </div>
      </div>

      <div class="membership-view__card">
        <div class="membership-view__card-icon">
          <i class="fal fa-users" aria-hidden="true"></i>
        </div>
        <div class="membership-view__card-info">
          <p class="membership-view__card-title">Subscribers</p>
          <p class="membership-view__card-value"> {{ insights.subscribers }}</p>
          <!-- <div class="membership-view__card-comparison-line   membership-view__card-comparison-line--green">
            <i class="fal fa-chart-line-up"></i>
            43%
          </div> -->
        </div>
      </div>

      <div class="membership-view__card">
        <div class="membership-view__card-icon">
          <i class="fal fa-credit-card"></i>
        </div>
        <div class="membership-view__card-info">
          <p class="membership-view__card-title">Subscriptions</p>
          <p class="membership-view__card-value">$ {{ formatValue(insights.subscriptions) }}</p>
          <!-- <div class="membership-view__card-comparison-line   membership-view__card-comparison-line--green">
            <i class="fal fa-chart-line-up"></i>
            43%
          </div> -->
        </div>
      </div>

      <div class="membership-view__card">
        <div class="membership-view__card-icon">
          <i class="fa-light fa-envelope-open-dollar"></i>
        </div>
        <div class="membership-view__card-info">
          <p class="membership-view__card-title">Pay per view</p>
          <p class="membership-view__card-value">$ {{ insights.payperview }}</p>
          <!-- <div class="membership-view__card-comparison-line   membership-view__card-comparison-line--red">
            <i class="fal fa-chart-line-down"></i>
            43%
          </div> -->
        </div>
      </div>

      <div class="membership-view__card">
        <div class="membership-view__card-icon">
          <i class="fal fa-hand-holding-usd    "></i>
        </div>
        <div class="membership-view__card-info">
          <p class="membership-view__card-title">Tips</p>
          <p class="membership-view__card-value">$ {{ insights.tips }}</p>
          <!-- <div class="membership-view__card-comparison-line  membership-view__card-comparison-line--green">
            <i class="fal fa-chart-line-down"></i>
            43%
          </div> -->
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  async beforeMount(){
    this.insights = await this.$store.dispatch('getUserInsights')
  },
  data(){
    return {
      insights: {
        totalGross: 0,
        totalNet: 0,
        likes: 0,
        subscribers: 0,
        subscriptions: 0,
        payperview: 0,
        tips: 0,
        affiliates: 0,
      }
    }
  },
  methods: {
    formatValue(value){
      return value.toLocaleString('de-DE', { maximumFractionDigits: 2 })
    }
  },
}
</script>

<style lang="scss">
.membership-view {
  &__cards-wrapper {
      padding-top: 1rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 1rem;
    }

    &__card-gross-earnings {
      width: 100%;
    }

    &__card {
      border-radius: 16px;

      &  {
        width: calc(100% - 50% - .5rem);
      }

      display: flex;
      row-gap: 1rem;
      flex-direction: column;

      padding: 1rem;

      @media (prefers-color-scheme: dark) {
        background-color: var(--color-black-4);
      }

      @media (prefers-color-scheme: light) {
        box-shadow: 0 10px 10px 1px rgba(226, 226, 226, 0.463)
      }

      @media (max-width: 768px) {
        width: 100%;
      }

      &--table {
        width: 100%;
      }
    }

    &__card-title {

      font-size: 1rem;
      color: var(--color-gray-2);
    }

    &__card-value {
      font-size: 1.3rem;
      font-weight: 600;
      margin-top: 8px;
      margin-bottom: 8px;
    }

    &__card-icon {
      background-color: var(--primary-color);
      padding: 1rem;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;

      @media (prefers-color-scheme: light) {
        color: var(--color-white);
      }
    }

    &__card-comparison-line {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      font-size: .7rem;
      font-weight: 600;
      width: 80px;
      padding: .5rem 1rem;
      border-radius: 16px;

      &--green {
        color: var(--color-green);
        background-color: var(--color-green-opacity);
      }

      &--red {
        color: var(--color-red);
        background-color: var(--color-red-opacity);
      }
    }
}</style>