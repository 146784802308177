<template>
    <div>
        <video autoplay muted loop id="myVideo" class="welcome-video">
            <source src="https://cdn.discordapp.com/attachments/948574923306201108/1027255994633425036/7258584959320243965.mp4" type="video/mp4">
        </video>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss">
    .welcome-video {
        width: 300px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
</style>