module.exports = {
  exampleValue: false,
  search: "",
  category: "",
  users: [],
  openPopup: "",
  editingMenuItem: {},
  viewingMenuItem: {},
  tipMenuItems: [],
  tab: null,
  reloadTipMenu: 0,
  reloadAvatarSidebar: 0,
  channelOpen: {},
  authEmail: "",
  code: "",
  userSocketIds: [],
  verificationProcess: {
    document: null,
    consent: false,
    documentFront: null,
    documentBack: null,
    selfie: null,
  },
  isAnyVideoPlaying: false,
  isVideoMuted: true,
  videoVolume: 0.5,
  buyingPayPerView: {},
  postId: null,
  discoverPosts: [],
  subscribingTo: null,
  updateBalance: 0,
  channels: [],
};
