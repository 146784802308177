/**
 * Here is imported all views to be used at the routes.js file.
*/


import LoginView from './LoginView.vue';
import RegisterView from './RegisterView.vue';
import WaitListView from './WaitListView.vue';
import HomeView from './Home/HomeView.vue';
import DiscoverView from './Discover/DiscoverView.vue';
import ListView from './List/ListView.vue';
import ProfileView from './Profile/ProfileView.vue';
import SettingsView from './Settings/SettingsView.vue';
import PersonalDataView from './Settings/PersonalDataView';
import AffiliatesView from './Settings/AffiliatesView';
import WalletView from './Settings/Wallet/WalletView';
import MembershipView from './Settings/Membership/MembershipView';
import NotificationsView from './Settings/NotificationsView';
import ChatView from './Chat/ChatView';
import VerifyView from './VerifyView.vue';
import ForgotPasswordView from './ForgotPassword/BaseView.vue';
import BecomeACreatorView from './BecomeACreator/BecomeACreatorView.vue';
import CrashView from './Crash/Crash.vue';
import PostView from './Post/PostView.vue';
import TermsInvestView from './Terms/InvestView.vue';
import TermsView from './Terms/TermsView.vue';
import TermsDmcaView from './Terms/DmcaView.vue';
import PrivacyView from './Terms/PrivacyView.vue';
import CommunityView from './Terms/CommunityView.vue';
import FaqView from './Terms/FaqView.vue';

const views = {
    HomeView,
    LoginView,
    RegisterView,
    WaitListView,
    VerifyView,
    ListView,
    ProfileView,
    SettingsView,
    ChatView,
    PersonalDataView,
    WalletView,
    MembershipView,
    NotificationsView,
    DiscoverView,
    ForgotPasswordView,
    BecomeACreatorView,
    AffiliatesView,
    CrashView,
    PostView,
    TermsInvestView,
    TermsView,
    TermsDmcaView,
    PrivacyView,
    CommunityView, 
    FaqView
}

export default views;