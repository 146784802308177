const axios = require('axios');

const youtubeEmbed = (url) => {

    // regex to get only the id
    const regex = /v=([^&]+)/gm;

    // get the id
    const id = regex.exec(url)?.[1];

    return `<iframe width="100%" height="215" src="https://www.youtube.com/embed/${id}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe><br>`
}

const spotifyEmbed = (url) => {

    // regex to get only the id
    const regex = /\/track\/(.*)/gm;
    const regexUrl = /https:\/\/open.spotify.com\/track\/(.*)/gm;

    // get the id
    const id = regex.exec(url)[1];

    // get the rest of the text to be displayed and replace the whole url
    const text = url.replace(regexUrl, '');



    return `<iframe src="https://open.spotify.com/embed/track/${id}" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>${text}`
}

const imageEmbed = (url) => {
    return `<img src="${url}" style="width: 250px;"/>`
}

const emojiBigger = (text) => {

    // regex to check for strings
    const regex = /\w/gm;

    // if the match is a string, return it
    if (regex.test(text)) {
        return text
    }

    return text.replace(/<a?:.+?:\d{18}>|\p{Extended_Pictographic}/gu, (match) => {
        return `<span style="font-size: 2rem;">${match}</span>`
    })

}

const audioEmbed = (url) => {
    return `<audio controls><source src="${url}" type="audio/mpeg"></audio>`
}

const videoEmbed = (url) => {
    return `<video controls><source src="${url}" type="video/mp4"></video>`
}



const websiteOpenGraph = async(text) => {
    /* eslint-disable */

    // regex to check if the text is a url
    const regex = /https?:\/\/[^\s]+/gm;

    // if the match is a url, return it
    if (!regex.test(text)) {
        return text
    }

    // remove texto antes e depois da url
    var url = text.replace(/(.*)https?:\/\/[^\s]+(.*)/gm, '$1$2');

    url = text.replace(url, "");

    // get website open graph
    const getOpenGraph = async(url) => {
            // encode the url
        const response = await axios.post(`http://165.227.236.31:2000/opengraph`, {
            url: url
        })
        return response.data
    }

    const html = await getOpenGraph(url).then((data) => {
        const { url, title, image, description } = data.hybridGraph;
        if (!image && !description && !title) {
            return `<div class="open-graph"><a href="${text}" target="_blank">
      <span class="link">${text}</span>
      </a></div>`
        }
        const html = `
      <div class="open-graph">
        <a href="${url}" target="_blank">
          <span class="link">${url}</span>
          <img src="${image}" alt="${title}" />
          <div class="open-graph-content">
            <h3>${title}</h3>
            <p>${description.length >= 120 ? description.slice(0, 120) + '...' : description}</p>
          </div>
        </a>
      </div>
    `;
        return html;
    });

    return html;


}

const linkify = (text) => {
    return text.replace(/(https?:\/\/[^\s]+)/g, (match) => {
        return `<a href="${match}" target="_blank">${match}</a>`
    })
}

const embed = (url, file_type = "") => {
    const types = ["image/png", "image/jpeg", "image/gif", "audio/mpeg", "audio/wav", "video/mp4", "video/webm"]
    if (url.includes('youtube.com') || url.includes('youtu.be') || url.includes('m.youtube')) {
        return youtubeEmbed(url)
    }
    if (url.includes('spotify.com')) {
        return spotifyEmbed(url)
    }
    if (url.includes('png') || url.includes('jpg') || url.includes('jpeg') || url.includes('gif') ||
        types.includes(file_type)
    ) {

        return imageEmbed(url)
    }

    if(url.includes('pdf')){
        return `<a href="${url}" target="_blank">${url}</a>`
    }

    if (url.includes('mp3') || url.includes('wav')) {
        return audioEmbed(url)
    }

    if (url.includes('mp4') || url.includes('webm')) {
        return videoEmbed(url)
    }

    return websiteOpenGraph(url);
}

export default {
    methods: {
        embed(url, file_type) {
            return embed(url, file_type)
        },
        emojiBigger(text) {
            return emojiBigger(text)
        },
        async formatMessage(message, file_type) {
            return emojiBigger(await embed(message, file_type))
        }
    },
    watch: {
        messages: {
            async handler() {
                this.formattedMessages = this.messages.map(async(message) => {
                    if (!message.backupMessage) {
                        message.backupMessage = message.message;
                    }
                    message.message = await this.formatMessage(message.backupMessage, message.file_type)
                    return message
                })

            },
            immediate: true,
            deep: true
        }
    },
    data: () => ({
        formattedMessages: []
    })
}