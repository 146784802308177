<template>
  <div style="position: fixed" :key="reset">
    <span style="display: none">{{ viewingPost }}</span>
    <v-dialog
      v-model="viewingPost"
      fullscreen
      min-height="100dvh"
      attach="body"
    >
      <v-btn
        class="btn-fab post-dialog__close"
        fab
        @click="viewingPost = false"
        absolute
        small
      >
        <i class="close-dialog-2 fa fa-chevron-left" aria-hidden="true"></i>
      </v-btn>
      <div class="post-dialog__move-actions">
        <v-btn
          class="btn-fab"
          style="margin-bottom: 16px"
          @click="splideGo(verticalActiveIndex - 1)"
          fab
          small
        >
          <i class="fa fa-chevron-up" aria-hidden="true"></i>
        </v-btn>
        <v-btn
          class="btn-fab"
          fab
          small
          @click="splideGo(verticalActiveIndex + 1)"
        >
          <i class="fa fa-chevron-down" aria-hidden="true"></i>
        </v-btn>
      </div>
      <splide
        refs="splide"
        v-if="showSplide"
        class="splide-horizontal"
        :options="options"
        @splide:mounted="onSplideMounted"
        @splide:move="onSplideMove"
      >
        <splide-slide
          class="splide-horizontal-carousel"
          v-for="(post, index) in posts"
          :key="post.id"
        >
          <!-- <video style="height: 100dvh;width: 100%;object-fit: cover;" autoplay muted>
            <source :src="v" type="video/mp4">
          </video> -->
          <div
            class="post-dialog"
            v-if="
              verticalActiveIndex == index ||
              verticalActiveIndex + 1 == index ||
              verticalActiveIndex - 1 == index
            "
          >
            <div class="post-dialog__medias">
              <ProcessMedias
                :post="post"
                :autoPlay="verticalActiveIndex == index"
              ></ProcessMedias>

              <div class="post-dialog__comments--mobile">
                <Comments
                  :post="post"
                  mobile
                  @newComment="onNewComment"
                  @deleteComment="onDeleteComment"
                ></Comments>
              </div>
            </div>
            <div class="post-dialog__comments">
              <Comments
                :post="post"
                @newComment="onNewComment"
                @deleteComment="onDeleteComment"
              ></Comments>
            </div>
          </div>
        </splide-slide>
      </splide>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import Comments from "./Comments.vue";
import ProcessMedias from "./ProcessMedias.vue";

import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { Splide, SplideSlide } from "@splidejs/vue-splide";

export default {
  data: () => ({
    hotReloadPost: 0,
    verticalActiveIndex: 0,
    posts: [],
    reset: 0,
    viewingPost: false,
    post: {
      files: [],
      comments: [],
      likes: [],
      owner: {
        id: -1,
        slug: "",
      },
    },
    videos: [
      "https://joy1.videvo.net/videvo_files/video/free/2013-09/large_watermarked/AbstractRotatingCubesVidevo_preview.mp4",
      "https://happy.videvo.net/videvo_files/video/premium/partners0886/large_watermarked/BB_4ed02c3b-bbfa-4de4-97bf-49e0c16dffd6_preview.mp4",
    ],
    showSplide: true,
    options: {
      lazyLoad: false,
      direction: "ttb",
      height: "100dvh",
      perPage: 1,
      pagination: false,
      speed: 300,
    },
    splideObject: {},
  }),
  methods: {
    splideGo(n) {
      this.splideObject.go(n);
    },
    async onSplideMounted(splide) {
      this.splideObject = splide;
    },
    async onSplideMove(_, next, last) {
      let lastPost = this.posts[this.posts.length - 1];
      let currentPost = this.posts[next];
      if (currentPost.id == lastPost.id) {
        setTimeout(() => {
          this.splideObject.refresh();
        }, 300);
        return;
      }

      let categoryId = this.$route.params.categoryId
        ? `&categoryId=${this.$route.params.categoryId}`
        : "";

      this.verticalActiveIndex = next;

      if (next == this.posts.length - 3) {
        const isFree =
          !this.$route.params.slug && this.$route.path != "/chat"
            ? "?free=true"
            : "";
        const ownerProfile = this.$route.params.slug
          ? isFree
            ? "&slug=" + this.$route.params.slug
            : "?slug=" + this.$route.params.slug
          : "";
        this.post = await this.$http
          .get(
            "/api/v1/getPostById/" +
              this.posts[this.posts.length - 1].id +
              isFree +
              ownerProfile +
              categoryId
          )
          .then((res) => res.data);
        this.showSplide = true;

        this.posts = [
          ...this.posts.filter((p) => p.id != this.post.id),
          ...this.post.nextPosts,
        ];

        setTimeout(() => {
          this.splideObject.refresh();
        }, 300);
      }

      // add this class: overflow-y-hidden to the html element
      document.documentElement.style.overflowY = "hidden";
    },
    onNewComment(comment) {
      // console.log('PostView.vue -> onNewComment()')
      // find post
      const postIndex = this.posts.findIndex((p) => p.id == comment.post_id);
      if (postIndex == -1) return;
      this.posts[postIndex].comments?.push(comment);
      // this.hotReloadPost++;
    },
    onDeleteComment(comment) {
      const postIndex = this.posts.findIndex((p) => p.id == comment.post_id);
      if (postIndex == -1) return;

      this.posts[postIndex].comments = this.posts[postIndex].comments.filter(
        (c) => c.id != comment.id
      );
    },
    deletePost(postId) {
      postId;
      // TODO: delete post
      // this.posts = this.posts.filter((post) => post.id != postId);
    },
    keyDown(e) {
      if (e.keyCode == 38) {
        this.$emit("goBackPost", this.post.id);
      } else if (e.keyCode == 40) {
        this.$emit("goFowardPost", this.post.id);
      }
    },
    copyPostShareLink(post) {
      // copy with native clipboard for mac
      if (navigator.clipboard) {
        navigator.clipboard.writeText(`${window.location.origin}/p/${post.id}`);
      } else {
        // fallback for safari
        const el = document.createElement("textarea");
        el.value = `${window.location.origin}/p/${post.id}`;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
      }
      this.$swal({
        title: "Copied!",
        text: "Link to share copied to clipboard",
        icon: "success",
        timer: 2000,
        showCancelButton: false,
        showConfirmButton: false,
      });
    },
  },
  async mounted() {
    // let postId = this.$route.params.postId||this.$store.state.postId;
    // if (postId) {
    //   this.post = await this.$http
    //     .get("/api/v1/getFreePostById/" + postId)
    //     .then((res) => res.data);
    //   this.viewingPost = true;
    // }
    // document.addEventListener("keydown", this.keyDown);
  },
  beforeDestroy() {
    // document.removeEventListener("keydown", this.keyDown);
  },
  watch: {
    viewingPost() {
      if (!this.viewingPost) {
        this.post = {
          files: [],
          comments: [],
          likes: [],
        };
        this.$store.commit("setPostId", -1);
        if (this.$store.state.postId && this.$route.path.includes("/p/")) {
          return this.$router.push("/");
        }

        let categoryId = "";

        if (this.$route.params.categoryId) {
          categoryId = `/c/${this.$route.params.categoryId}`;
        }

        this.$router
          .replace({
            path: `/` + this.$route.path.split("/").slice(1)[0] + categoryId,
          })
          .catch(() => {});

        document.documentElement.style.overflowY = "auto";

        this.posts = [];
        this.verticalActiveIndex = 0;
      }

      if (this.viewingPost) {
        document.documentElement.style.overflowY = "hidden";
      }
    },
    async "$store.state.postId"() {
      if (this.$store.state.postId == -1) {
        this.viewingPost = false;
        return;
      }
      this.showSplide = false;

      let categoryId = this.$route.params.categoryId
        ? `&categoryId=${this.$route.params.categoryId}`
        : "";

      this.posts = [];
      const isFree =
        !this.$route.params.slug &&
        this.$route.path != "/chat" &&
        !this.$route.params.postId
          ? "?free=true"
          : "";

      const ownerProfile = this.$route.params.slug
        ? isFree
          ? "&slug=" + this.$route.params.slug
          : "?slug=" + this.$route.params.slug
        : "";
      this.post = await this.$http
        .get(
          "/api/v1/getPostById/" +
            this.$store.state.postId +
            isFree +
            ownerProfile +
            categoryId
        )
        .then((res) => res.data);
      this.showSplide = true;

      this.posts = this.post.nextPosts;

      // setTimeout(() => {
      //   this.splideObject.refresh();
      // }, 300)

      if (!this.viewingPost) {
        this.viewingPost = true;
        // disable body scroll
        // document.getElementsByTagName('html')[0].style.overflow = 'hidden';
      }
    },
    "$route.path"() {
      this.reset++;
      this.viewingPost = false;
    },
    // async "$route.params.postId"() {
    //   if (this.$route.params.postId) {
    //     this.post = await this.$http
    //       .get("/api/v1/getFreePostById/" + this.$route.params.postId)
    //       .then((res) => res.data);
    //       this.viewingPost = true;
    //   } else {
    //     this.viewingPost = false;
    //     this.$router
    //       .replace({
    //         path: `/` + this.$route.path.split("/").slice(1)[0],
    //       })
    //       .catch(() => {});
    //   }
    //   console.log(this.$route.params.postId, this.viewingPost, this.post.id)
    // },
  },
  components: {
    ProcessMedias,
    Comments,
    Splide,
    SplideSlide,
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/variables.scss";

.post-dialog {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: minmax(60%, 1fr) minmax(20%, 540px);

  @media (prefers-color-scheme: dark) {
    background-color: $bg-gray-color;
  }

  @media (prefers-color-scheme: light) {
    background-color: $bg-card-light;
  }

  @media (max-width: 1200px) {
    grid-template-columns: 60% 40%;
  }

  @media (max-width: 1000px) {
    grid-template-columns: 100%;
  }

  &__move-actions {
    position: absolute;
    top: 0;
    left: 10px;
    height: calc(100% - 40px);
    padding-top: 40px;
    width: 40px !important;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-right: 10px;

    @media screen and (max-width: 1000px) {
      display: none;
    }
  }

  &__medias {
    width: 100%;
    height: 100dvh;
    position: relative;
  }

  &__comments {
    width: 100%;
    height: 100%;
    overflow: hidden;

    @media (prefers-color-scheme: dark) {
      border-left: 1px solid #424242;
    }

    @media (prefers-color-scheme: light) {
      border-left: 1px solid #e0e0e0;
    }

    @media (min-width: 1001px) {
      &--mobile {
        display: none;
      }
    }

    @media (max-width: 1000px) {
      display: none;

      &--mobile {
        display: block;
      }
    }
  }

  &__close {
    position: fixed;
    top: 10px;
    left: 10px;
    color: #fff;
    cursor: pointer;
    z-index: 100;

    i {
      font-size: 20px;
    }
  }
}
</style>

<style lang="scss">
.splide-horizontal {
  .splide__track {
    height: 100dvh;
  }
  .splide__list {
    width: auto !important;
  }
}
.splide-horizontal-carousel {
  height: 100dvh;
}
</style>
