<template>
    <div>
        <br><br><br>
         <div class="settings-container">
            <center>
                <v-list class="settings-list">
                    <template v-for="(option, optionIndex) in options" >
                    <v-list-item 
                    :key="optionIndex"
                        @click="viewOption(option)" style="height: 52px;"
                        v-if="option.if ? option.if() : true"
                        >
                        <v-list-item-icon>
                            <i :class="option.icon" aria-hidden="true"></i>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{option.label}}
                                <i class="fa-light fa-chevron-right" style="float:right;" aria-hidden="true"></i>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    </template>
                </v-list>
            </center>
        </div>
    </div>
</template>

<script>
    export default {
        async beforeMount() {
            this.user = await this.$http.get('/api/v1/getUserProfile/' + this.me?.slug).then(res => res.data);
        },
        methods: {
            viewOption(option) {

                if(option.click){
                    this[option.click]()
                    return;
                }

                this.$router.push(option.route)
            },
            logout(){
                this.$store.commit('removeToken');                
                this.$cookies.delete('g_state');
                window.location.href = "/";
            },
            askBecomeCreator(){
                window.location.href = '/become-a-creator';
                // ask to become a creator with swal
                // this.$swal({
                //     title: 'Become a creator',
                //     text: 'You are going to be redirected to the authentication service?',
                //     icon: 'warning',
                //     confirmButtonText: 'Confirm',
                //     showCancelButton: true,
                //     dangerMode: true,
                // }).then(({isConfirmed}) => {
                    
                //     if (isConfirmed) {
                        
                //     }
                // })
            }
        },
        data()  {
            return {
                user: {},
                options: [{
                        label: "Personal Data",
                        route: "/settings/personal-data",
                        icon: "fa-light fa-user"
                    },
                    {
                        label: "Wallet",
                        route: "/settings/wallet",
                        icon: "fa-light fa-wallet"
                    },
                    {
                        label: "Become a Creator",
                        icon: "fa-light fa-video",
                        click: "askBecomeCreator",
                        if: () => {
                            if(this.me ){
                                if(this.me.creator_category_id){
                                    return false;
                                }
                            }
                            return true;
                        }
                        // route: "/settings/become-a-creator",
                    },
                    {
                        label: "Membership",
                        icon: "fa-light fa-video",
                        if: () => {
                            if(this.me ){
                                if(this.me.creator_category_id){
                                    return true;
                                }
                            }
                            return false;
                        },
                        route: "/settings/membership",
                    },
                    {
                        label: "Affiliates",
                        icon: "fa-light fa-screen-users",
                        route: "/settings/affiliates",
                    },
                    {
                        label: "Notifications",
                        icon: "fa-light fa-bell",
                        route: "/settings/notifications",
                    },
                    {
                        label: "Logout",
                        route: "/logout",
                        icon: "fa-light fa-sign-out",
                        click: "logout"
                    }
                ]
            }
        }
    }
</script>

<style lang="scss">
    .settings-container {
        padding-top: 0 !important;
        padding: 1em;
        height: 100vh;
        background: white;
        z-index: 1;

        & > center > .v-list > .v-list-item > .v-list-item__icon{
            margin: 0 !important;
        }
    }

    .settings-list>* {
        text-align: left;
        color: #4F4F4F;
        border-radius: 5px;
        padding: 1em;
        width: 100%;
        max-width: 768px;
        min-width: 300px;
        border: 1px solid rgb(245, 245, 245);
        margin-bottom: 10px;
        background-color: #F2F2F6;
    }
</style>