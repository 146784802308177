/**
 * Here is defined all layouts of the application.
*/

import Vue from 'vue'

import NotAuthenticatedLayout from './NotAuthenticatedLayout';
Vue.component('not-authenticated-layout', NotAuthenticatedLayout);

import AuthenticatedLayout from './AuthenticatedLayout';
Vue.component('authenticated-layout', AuthenticatedLayout);

import SettingsLayout from './SettingsLayout';
Vue.component('settings-layout', SettingsLayout);

import AuthLayout from './AuthLayout';
Vue.component('auth-layout', AuthLayout);

import BecomeACreatorLayout from './BecomeACreatorLayout';
Vue.component('become-a-creator-layout', BecomeACreatorLayout);