<template>
    <div class="crash_layout">
        <div class="header">
            <img class="logo" src="https://aviator-demo.spribegaming.com/aviator-logo.cafbd29233306bf7.svg">
            <div class="saldo">Balance: 3000,34$</div>
        </div>
        <div class="history">
            <v-icon size="16px">mdi-history</v-icon>
            <div class="dent"> 3.42x</div>
            <div class="dent"> 4.23x</div>
            <div class="dent"> 3.41x</div>
            <div class="dent"> 2.42x</div>
            <div class="dent"> 3.14x</div>
            
        </div>
        <div class="stage-board">
            <div class="stage">Playing</div>
            <div class="board-container">
                <div class="firstcolumn">
                    <div>.</div>
                <div>.</div>
                <div>.</div>
                <div>.</div>
                <div>.</div>
                <div>.</div>
                <div>.</div>
                <div>.</div>
                </div>
                <div ref="board" class="board">
                        <img class="fundo" ref="fundo" src="https://sidermoney.nyc3.digitaloceanspaces.com/logos/fundo%20terra.png" alt="">
                        <div v-if="flyingAway" style="text-align: center;font-size: x-large;">FLEW AWAY</div>
                        <div v-else style="text-align: center;font-size: x-large;"></div>

                        <div v-if="start" style="text-align: center;font-weight: 600;font-size: 40px;z-index: 1;">{{ counter }}x</div>
                        <div v-else style="display: flex;
                                           flex-direction: column;
                                           justify-content: center;
                                           align-items: center;     ">
                            <div  style="text-align: center;font-size: x-large; margin-bottom: 20px;z-index: 1;"> COUNTDOWN TO LAUNCH</div>
                            <img  ref="helice" class="helice" src="https://sidermoney.nyc3.digitaloceanspaces.com/logos/helice.svg">
                            
                            <progress style="margin-top:20px; height: 8px; width: 300px;z-index: 1;" max="10" :value="countdown"></progress>
                        </div>
                        

                            <img ref="plane" class="plane" :style="planeStyle" src="https://cdn.discordapp.com/attachments/948574923306201108/1111447469042176040/image.png">
                            <svg ref="svg" style="position: absolute;height: 100%; width: 100%;transform: scaleY(-1); z-index: 1;">
                                <path ref="pathLine" :d="pathString" stroke="orange" fill="transparent"/>
                            </svg>

                        




                </div>    
            </div>


            <div class="stage-footer">
                <div>.</div>
                <div>.</div>
                <div>.</div>
                <div>.</div>
                <div>.</div>
                <div>.</div>
                <div>.</div>
                <div>.</div>
                <div>.</div>
                <div>.</div>
                <div>.</div>
            </div>

        </div>

        <div class="bet-controls">

            <v-btn class="bet-btn" @click="placeBet()" 
            color="#007BFF"
            x-large>
            <span v-if="this.counter==1.00" style="font-size: 16px; text-transform: none; font-weight: 600;">BET (Next Round)</span> 
            <span v-else style="color:red; font-size: 16px; text-transform: none; font-weight: 600;">CASH OUT {{cashOutValue}}$</span>
        </v-btn>

            <div class="inputs">
                <div class="amounts">
                    <v-chip 
                    style="height: 56px; color: #0989EF;background-color: rgba(0,123,255,0.1); text-align: center; margin: auto;"
                    @click="reduceHalfBet()"> 1/2
                    </v-chip>
                        <v-text-field v-model="bet" style="padding-left: 10px; padding-right: 10px;" placeholder="Amount to BET" hide-details filled>sapo</v-text-field>
                        <v-chip 
                    style="height: 56px;  color: #0989EF;background-color: rgba(0,123,255,0.1);"
                    @click="doubleBet()">2x
                    </v-chip>
                </div>
                <div class="chips">
                    <v-chip 
                    style="height: 36px; margin: 10px; color: #0989EF;background-color: rgba(0,123,255,0.1);"
                    @click="setBet(1)">1$
                    </v-chip>
                    <v-chip 
                    style="height: 36px; margin: 10px;  color: #0989EF;background-color: rgba(0,123,255,0.1);"
                    @click="setBet(5)">5$
                    </v-chip>
                    <v-chip 
                    style="height: 36px; margin: 10px;  color: #0989EF;background-color: rgba(0,123,255,0.1);"
                    @click="setBet(10)">10$
                    </v-chip>
                    <v-chip 
                    style="height: 36px; margin: 10px; color: #0989EF;background-color: rgba(0,123,255,0.1);"
                    @click="setBet(100)">100$
                    </v-chip>
                </div>
            </div>
           
          
        </div>

        <div class="bets">
            <!-- create a list of bets -->
            <div class="bets_header"> <div>123 Apostadores</div> 4500$</div>
            <div class="bet"> <div>apostador1</div><div style="font-weight: bold;">2.34x</div>350$</div>
            <div class="bet"> <div>apostador2</div><div style="font-weight: bold;">3.12x</div>373$</div>
            <div class="bet"> <div>apostador3</div><div style="font-weight: bold;">5.23x</div>1235$</div>
            <div class="bet"> <div>apostador4</div><div style="font-weight: bold;">2.35x</div>332325$</div>
            

        </div>
    
    </div>
</template>


<script>


export default {
    data(){
        return{
            countdown: 10,
            counter: 1.00,
            increment: 0.01,
            bet: 0,
            planeStyle: {
                transform: 'translate(0px, 0px)'
            },
            start: null,
            duration: 5000,
            flyingAway: false,
            fluttering: false,
            lastPosition: {
                x: 0,
                y: 0,
            },
            path:[],
            pathLine:[],
            
       
          
        }
    },
    computed:{
        cashOutValue(){
            return (this.counter * this.bet).toFixed(2);
        },
     
    pathString() {
        return `M ${this.pathLine.join(' ')}`;
    }


      
    },
    methods:{
        setBet(value){
            this.bet = value;
            
        },
        reduceHalfBet(){
            this.bet = this.bet/2;
        },
        doubleBet(){
            this.bet = this.bet*2;
        },
        placeBet(){
            requestAnimationFrame(this.animatePlane);
            this.$refs.fundo.classList.toggle('fundoplay');
            this.startCounter();
                },
                animatePlane(timestamp) {
                    // Set up the initial timestamp if it's not already set.
                    if (!this.start) this.start = timestamp;
                    // Calculate the progress of the animation.
                    let progress = timestamp - this.start;
                    // Get the board elements
                    let board = this.$refs.board;

                    if (!this.flyingAway && !this.fluttering) {
                        // Use the progress to calculate the new position of the plane.
                        let newX = ((progress / this.duration) * board.offsetWidth * 0.8).toFixed(0);
                         // Calculate the new Y exponentially and scale it down.
                        let newY = (Math.pow((progress / this.duration), 2) * board.offsetHeight * 0.8).toFixed(0);
                        // Add the new position to the path
                        this.path.push(`${newX},-${newY}`);
                        this.pathLine.push(`${newX},${newY}`);
                        // Save the last position
                        if(newY > 0){
                            this.lastPosition.y = newY;
                        }
                        //console.log(newY);
                        // Set the plane's new position.
                        this.planeStyle.transform = `translate(${newX}px, -${newY}px)`;
                        // If the animation is still going, request another animation frame.
                        if (progress < this.duration) {
                            requestAnimationFrame(this.animatePlane);
                        } else {
                            // Start the "fluttering" animation
                            this.fluttering = true;
                            this.start = null;
                            this.lastPosition.x = newX;
                            
                            requestAnimationFrame(this.animatePlane);
                        }
                    } else if (this.fluttering) {
                        // Fluttering animation.
                        let flutterDistanceY = 20; // Change this to adjust the vertical distance the plane moves.
                        let flutterDistanceX = 10; // Change this to adjust the horizontal distance the plane moves.
                        let newY = this.lastPosition.y - flutterDistanceY * Math.sin(progress / 500);
                        // Invert the horizontal flutter by negating the sine function.
                        let newX = this.lastPosition.x + flutterDistanceX * Math.sin(progress / 500);
                        // Add the new position to the path
                        this.path.push(`${newX},-${newY}`);
                        this.pathLine.push(`${(newX).toFixed(0)},${(newY).toFixed(0)}`);
                        // Save the last position
                        this.planeStyle.transform = `translate(${newX}px, -${newY}px)`;
                        requestAnimationFrame(this.animatePlane);
                    } else {
                        // Fly away animation, horizontally.
                        let newX = this.lastPosition.x + (progress / this.duration) * board.offsetWidth;
                        // Use the same vertical position as where the first part of the animation ended.
                        let newY = this.lastPosition.y;
                        // Add the new position to the path
                        this.path.push(`${newX},-${newY}`);
                        this.pathLine.push(`${newX},${newY}`);
                        this.planeStyle.transform = `translate(${newX}px, -${newY}px)`;
                        if (progress < this.duration) {
                            requestAnimationFrame(this.animatePlane);
                        }
                    }
            },

        startCounter(){
                        const increaseIncrementIntervalId = setInterval(() => {
                        this.increment += 0.01;  // increase increment by 0.01 every second
                        }, 1000);

                        const updateCounterIntervalId = setInterval(() => {
                        if (this.counter < 10.00) {
                            this.counter += this.increment;
                            // Round to 2 decimal places
                            this.counter = Math.round(this.counter * 100) / 100;
                        } else {
                            // If counter has reached 3.45, stop the intervals
                            clearInterval(increaseIncrementIntervalId);
                            clearInterval(updateCounterIntervalId);
                        }
                        }, 100);

        },
        startCountdown() {
        let interval = setInterval(() => {
            if (this.countdown > 0) {
                this.countdown -= 1;
            } else {
                clearInterval(interval);
                // reset countdown and start the game
                this.countdown = 10;
                //this.start = true;
            }
        }, 1000);
    }

    },
    mounted(){
            //requestAnimationFrame(this.animatePlane);
            // setTimeout(() => {
            //     this.flyingAway = true;
            // }, 5000);
            this.startCountdown();

            
  
       

    },
    
    
}

</script>

<style lang="scss" scoped >

.plane {
    margin-right: auto;
    z-index: 1;
  width: 80px;
  
  justify-self: start;

}
.parked {
  position: relative;
  width: 80px;
  height: 50px;
}

.flying {
  
  width: 80px;
  height: 50px;
  animation: moveImage 5s linear forwards;
    animation-fill-mode: forwards

}

.flew {
  width: 80px;
  height: 50px;
  animation: flewPlane 1s linear forwards;
  
}


@keyframes moveImage {
  0% {
    transform: translate(0, 0);
  }
    70% {
    transform: translate(500px, -400px);
  }
  100% {
    transform: translate(calc(500px - 100%), calc(-400px + 100%));
  }
}


.crash_layout{
    
    height: 600px;
    padding: 10px;
    display: flex;
    flex-direction: column;
}


.history{
    background-color: #2a2a2a;
    height: 44px;
    padding-left: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    border-radius: 10px;

    .dent{
        background-color: #222;
        border-radius: 10px;
        padding: 0px 10px 0px 10px;
        margin-left: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
        display: inline-block;
        color: var(--color-our-white);
        font-size: 14px;
    }
    
}
.header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 44px;
    .logo{
        padding: 6px;
    
    }
    .saldo{
        padding: 8px;
        color: #fff;
        font-size: 12px;
        font-weight: bold;

    }
    
}
.stage-board{
    background-color: #2a2a2a;
    border-radius: 10px;
 
    
    .stage {
        background-color: var(--primary-color);
        border-radius: 10px 10px 0px 0px;
        padding: 0px 10px 0px 10px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

        .board-container{
            display: grid;
            grid-template-columns: 40px 1fr;
            .board{
                overflow: hidden;
                border-radius: 0px 0px 0px 10px;
                padding: 0px 10px 0px 10px;
                height: 400px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                color: var(--color-our-white);
                font-size: 120px;
                border-left: 1px solid #333;
                border-bottom: 1px solid #333;
                position: relative;
                background-color: black;
                .helice{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    width: 100px;
                    height: 100px;
                    z-index: 1;
                    animation: rotate 1.5s linear infinite;
                    @keyframes rotate {
                        0% {
                            transform: rotate(0deg);
                        }
                        100% {
                            transform: rotate(360deg);
                        }
                    }
                }
                .fundo{
                    position: absolute;
                    left: 0;
                    height: 100%;
                    background-color: #2a2a2a;
                    border-radius: 0px 0px 0px 10px;
                    z-index: 0;
                }
                .fundoplay{
                    
                    animation: leavePlanet 20s linear forwards;
                
                 @keyframes leavePlanet {
                    0% {
                        transform: translate(0, 0);
                    }
                    100% {
                        transform: translate(-100vw, 100vh);
                    }
                    }
                }

            }
            .firstcolumn{
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                font-weight: 800;
                background-color: black;
            }
    }
    
    .stage-footer{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        height: 30px;
        border-radius: 0px 0px 10px 10px;
        padding: 20px;
        font-weight: 800;
        background-color: black;
    }
}
.bet-controls{
    background-color: #2a2a2a;
    border-radius: 10px;
    padding: 10px;
    margin-top:10px;
    
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
    }
        
    .bet-btn{
        border-radius:10px;
        width: 100%;
        margin: auto;
    }

    .inputs{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;   
        .amounts{
            display: flex;
            flex-direction: row;
            
        }
        .chips{
            display: flex;
            flex-direction: row;
            
        }
    }
}


.bets{
    background-color: #2a2a2a;
    height: 100px;
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
    .bets_header{
        color: var(--color-our-white);
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: var(--primary-color);
    }
    .bet{
        color: var(--color-our-white);
        font-size: 12px;
        font-weight: normal;
        text-align: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

</style>