<template>
  <!-- <div>
    <iframe src="https://voluble-daifuku-6686ac.netlify.app/" frameborder="0" style="width: 100vw;height: calc(100% - 130px);position: fixed;left:0;top: 50px;"></iframe>
  </div> -->
  <div class="chat">
    <transition name="component-fade" mode="out-in">
      <contacts
        :subscribersCount="subscribersCount"
        :style="{ position: windowWidth <= 768 ? 'absolute' : '' }"
        v-if="!$store.state.channelOpen.id && windowWidth <= 768"
        :channels="channels"
        @openChannel="openChannel"
        @sendNewMessage="setupNewMessage"
      ></contacts>
    </transition>
    <transition name="component-fade" mode="out-in">
      <contacts
        :subscribersCount="subscribersCount"
        :style="{ position: windowWidth <= 768 ? 'absolute' : '' }"
        v-if="windowWidth >= 769"
        :channels="channels"
        @openChannel="openChannel"
        @sendNewMessage="setupNewMessage"
      ></contacts>
    </transition>
    <chat
      ref="chat"
      :title="chatTitle"
      :description="chatDescription"
      :messages="messages"
      @deleteMessage="deleteMessage"
      @sendMessage="sendMessage"
      @closeChat="closeChat"
    ></chat>
  </div>
</template>

<script>
import Contacts from "./Contacts.vue";
import Chat from "./Chat.vue";
import utils from "@/mixins/utils";
export default {
  name: "ChatComponent",
  async beforeMount() {
    await this.$http.get("/api/v1/getChannels").then((response) => {
      this.$store.state.channels = response.data;
    });

    if (this.$route.query.load) {
      this.$router.push("/chat").catch(() => {});

      var index = this.$store.state.channels.findIndex((channel) => {
        return channel.id == this.$store.state.channelOpen.id;
      });

      this.openChannel(this.$store.state.channelOpen, index);
    }
  },
  components: {
    Contacts,
    Chat,
  },
  computed: {
    // orderChannels(){
    //   // channels
    //   // older channels by last messages
    //   // new channels by created at
    //   return this.$store.state.channels.sort((a, b) => {
    //     if (a.lastMessage && b.lastMessage) {
    //       return new Date(b.lastMessage.createdAt) - new Date(a.lastMessage.createdAt);
    //     } else if (a.lastMessage && !b.lastMessage) {
    //       return new Date(b.createdAt) - new Date(a.lastMessage.createdAt);
    //     } else if (!a.lastMessage && b.lastMessage) {
    //       return new Date(b.lastMessage.createdAt) - new Date(a.createdAt);
    //     } else {
    //       return new Date(b.createdAt) - new Date(a.createdAt);
    //     }
    //   });
    // },
  },
  data: () => ({
    chatTitle: "",
    chatDescription: "",
    audio: null,
    windowWidth: 0,
    updateLastMessage: 0,
    message: "",
    messages: [],
    search: "",
    channels: [],
    subscribersCount: 0,
  }),
  async mounted() {
    this.audio = new Audio("./assets/sounds/notification.mp3");
    this.audio.volume = 0.1;

    if (this.me && this.me.id) {
      window.socket.emit("register-connected-user", this.me);
      if (window.socket && window.socket.id) {
        this.me.socketId = window.socket.id;
      }

      this.subscribersCount = await this.$http
        .get("/api/v1/subscribersCount")
        .then((response) => {
          return response.data.subscribersCount;
        });
    }

    // get socket id
    window.socket.on("online-users", (userSocketIds) => {
      this.$store.state.userSocketIds = userSocketIds;
    });

    // play notification sound

    window.addEventListener("resize", this.onResize);
    this.windowWidth = window.innerWidth;

    window.socket.on("new-message", this.onNewMessage);

    // setInterval(() => {
    //   // fetch messages
    //   if (this.$store.state.channelOpen.id) {
    //     this.openChannel(this.$store.state.channelOpen, this.$store.state.channelOpen.index);
    //   }
    // }, 1000);

    // emit event to server

    // this.socket on connection
    // window.socket.on(this.me.id + "-" + 2, ({message, sender, receiver}) => {

    //   console.log(receiver);

    //   if(receiver != this.me.id && sender == this.me.id) return;

    //   this.messages.push({
    //     id: this.messages.length + 1,
    //     text: message,
    //     time: new Date().toLocaleTimeString()
    //   })

    // });
  },
  methods: {
    setupNewMessage() {
      this.chatTitle = "Send a new message";
      this.chatDescription = "To all your fans";
      this.$store.state.channelOpen = {};
      this.messages = [];
    },
    deleteMessage(message) {
      this.$http
        .post("/api/v1/deleteMessage", {
          message_id: message.id,
        })
        .then(() => {
          this.messages = this.messages.filter((msg) => {
            return msg.id != message.id;
          });
        });
    },
    onNewMessage(message) {
      const { channel_id } = message;

      if (message.donationAmount) {
        message.transaction = {
          amount: message.donationAmount,
        };
      }

      // Check if the current channel is the same as the channel_id
      if (channel_id == this.$store.state.channelOpen.id) {
        this.messages.push(message);
        this.$store.state.channelOpen.lastMessage = message;
        this.$store.state.channelOpen.lastMessage.read = false;
        this.scrollChat();
      } else {
        // If not find the channel and update the last message
        this.$store.state.channels.forEach((channel) => {
          if (channel.id == channel_id) {
            channel.lastMessage = message;
            channel.lastMessage.read = false;
          }
        });
      }
      this.scrollChat();
    },
    scrollChat() {
      this.$nextTick(() => {
        this.$el.querySelector(".chat__messages").scrollTop =
          this.$el.querySelector(".chat__messages").scrollHeight;
      });
    },
    closeChat() {
      this.$store.state.channelOpen = {};
      this.messages = [];
      this.chatTitle = "";
      this.chatDescription = "";
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    calcPostedAt: utils.methods.calcPostedAt,
    openChannel(channel, channelIndex) {
      this.$refs.chat.focusInput();
      this.$http
        .post("/api/v1/openChannel/", {
          channel_id: channel.id,
          users: channel.users,
        })
        .then(async (response) => {
          // this.messages = response.data.messages;
          if (!this.$store.state.channels[channelIndex].id) {
            this.$store.state.channels[channelIndex].id =
              response.data.channel.id;
            channel.id = response.data.channel.id;
          }

          // if last message .read false set to true
          if (this.$store.state.channels[channelIndex].lastMessage) {
            this.$store.state.channels[channelIndex].lastMessage.read = true;
          }

          this.$store.state.channels.forEach((channel) => {
            if (channel.id == response.data.channel.id) {
              if (channel.lastMessage) {
                channel.lastMessage.read = true;
              }
            }
          });

          if (channel.id != this.$store.state.channelOpen.id) {
            this.messages = [];
          }

          this.$store.state.channelOpen = response.data.channel;
          this.$store.state.channelOpen.index = channelIndex;

          response.data.messages.forEach((message) => {
            // check if message doenst exists, if not pushu
            if (!this.messages.find((m) => m.id == message.id)) {
              this.messages.push(message);
              this.scrollChat();
            }
          });

          this.updateLastMessage++;
        });
    },
    sendMessage(message, donationAmount) {
      let payload = {};

      var createdAt = new Date();
      if (message.postId) {
        payload = {
          id: this.messages.length + 1,
          post: message.post,
          payperview: {
            ...message.post,
            preview: message.preview,
          },
          postId: message.postId,
          preview: message.preview,
          message: "",
          channel_id: message.chatId || this.$store.state.channelOpen.id,
          sender_id: this.me.id,
          sender: {
            avatar: this.me.avatar,
            id: this.me.id,
            name: this.me.name,
            slug: this.me.slug,
          },
          createdAt,
          receiver: this.$store.state.channelOpen.users
            ? this.$store.state.channelOpen.users.filter(
                (user) => user.user_id != this.me.id
              )
            : [],
          type: this.chatTitle ? "FANS" : "",
          new_post: message.new_post,
          donationAmount,
        };
        // back faz isso
      } else {
        payload = {
          id: this.messages.length + 1,
          message: message,
          backupMessage: message,
          channel_id: message.chatId || this.$store.state.channelOpen.id,
          sender_id: this.me.id,
          sender: {
            avatar: this.me.avatar,
            id: this.me.id,
            name: this.me.name,
            slug: this.me.slug,
          },
          createdAt,
          receiver: this.$store.state.channelOpen.users
            ? this.$store.state.channelOpen.users.filter(
                (user) => user.user_id != this.me.id
              )
            : [],
          type: this.chatTitle ? "FANS" : "",
          donationAmount,
        };
      }

      window.socket.emit("message", payload);

      // scroll to bottom the container
      this.scrollChat();

      this.$http.post("/api/v1/sendMessage/", payload).then((response) => {
        this.$store.state.updateBalance++;
        if (this.messages && this.messages.length > 0) {
          const message = response.data;
          this.messages[this.messages.length - 1].id = message.id;
        }

        if (this.chatTitle) {
          this.chatTitle = "";
          this.chatDescription = "";

          this.$swal({
            title: "Message sent!",
            text: "Your message was sent successfully",
            icon: "success",
            timer: 2000,
            showConfirmButton: false,
            showCancelButton: false,
          });
        }
      });
    },
  },
};
</script>

<style></style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/styles/variables.scss";

.chat {
  display: grid;
  grid-template-columns: 300px 468px;
  grid-template-rows: 1fr;
}

// @media (min-width: 878px) {
//   .chat {
//     height: 100vh;
//   }
// }

// @media (max-width: 877px) {
//   .chat {
//     height: calc(100vh - 60px);

//     .chat__channels {
//       height: calc(100vh - 60px);
//     }

//     .chat__messages {

//       height: calc(100vh - 140px);
//     }

//   }
// }

@media (max-width: 768px) {
  .chat .chat__messages {
    width: 100vw;
    z-index: -1;
    padding-top: 100px;
    padding-bottom: 10px;
  }

  .chat__channels {
    z-index: 1;
    width: 100vw !important;
  }

  .chat__header {
    display: flex !important;
    left: 0;
    top: 0;
    width: 100vw;

    .back {
      margin-right: 20px;
      cursor: pointer;
    }
  }

  .chat .chat__actions {
    width: 100vw;
    transition: 0.5s;
  }
}

@media (min-width: 769px) {
  .chat__header {
    position: static !important;
    display: flex !important;
    left: 0;
    top: 0;
  }
}

.chat__header {
  display: none;
  height: 65px;
  align-items: center;
  padding: 1em;
  color: $color-our-white;
  transition: 0s;
}

.chat__channels {
  height: 100vh;
  width: 300px;
  // height: 100vh;
  display: flex;
  flex-direction: column;
  grid-column: 1;
  grid-row: 1 / 3;
}

.chat__channels__header {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat__channels__header__search {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  input {
    width: 100%;
    border: none;
    outline: none;
    padding: 0.8em;
    border-radius: 5px;
    border-radius: 25px;
  }

  i {
    position: absolute;
    right: 20px;
  }
}
.chat__channels__list {
  flex: 1;
  overflow-y: auto;
  padding: 10px 0;
}

.chat__channels__list__item {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;

  &.active {
    background: #007bff25;
    border-left: 3px solid #007bff;
  }

  // &:hover {
  //   background: #eee;
  // }

  .chat__channels__list__item__avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .chat__channels__list__item__info {
    width: 100%;
    .chat__channels__list__item__info__name {
      font-weight: 600;
    }

    .chat__channels__list__item__info__message {
      color: #999;
      font-size: 0.8rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }
}

.chat__messages {
  grid-column: 2;
  overflow-y: auto;
  padding: 10px 20px;
  width: calc(100%);

  @media (max-width: 768px) {
    height: calc(100vh - 60px);
  }

  @media (min-width: 769px) {
    height: calc(100vh - 125px);
  }

  grid-row: 1 / 3;

  &::-webkit-scrollbar {
    width: 5px;
  }

  // max-height: 450px;
}

.chat__message {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 10px;

  &.sender {
    width: 100%;
    justify-content: flex-start !important;
    flex-direction: row-reverse;

    & .chat__message__content {
      word-break: break-word;
      border-radius: 5px;
      padding: 10px;
      max-width: 80%;
    }
  }

  .chat__message__avatar {
    align-self: flex-end;
    margin-right: 4px;

    &--sender {
      margin-left: 4px;
      justify-self: flex-end;
      align-self: flex-end;
    }
  }

  .chat__message__content {
    word-break: break-word;
    max-width: 80%;
    border-radius: 5px;
    padding: 10px;
    position: relative;

    &:hover {
      .chat__message__content__message-options {
        display: block;
      }
    }

    .chat__message__content__text {
      font-size: 14px;

      &__preview :before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        background-color: rgba(0, 0, 0, 0.2);
      }
    }

    .chat__message__content__time {
      font-size: 12px;
      color: #999;
      margin-top: 5px;
      display: flex;
      justify-content: space-between;
      padding: 0 0.5rem;

      &--no-padding-left {
        padding-left: 0;
      }
    }

    &__message-options {
      display: none;
      position: absolute;
      right: -10px;
      top: -10px;
      z-index: 1;
      font-size: 10px;
      background-color: white;
      border-radius: 25px;
      i {
        color: white;
        font-size: 18px;
      }

      &--sender {
        right: auto;
        left: -10px;

        i {
          color: #007bff;
        }
      }
    }
  }
}

.chat__input {
  grid-column: 1 / 3;
  padding: 0;
  height: 30px;

  input {
    width: 100%;
    border: none;
    outline: none;
    // padding: 5px 15px;
    padding-left: 20px !important;
    padding-right: 10px !important;
    border-radius: 5px;
  }
}

.chat__actions {
  grid-column: 1 / 3;
  display: flex;
  align-items: center;
  height: 60px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  bottom: 0;
  left: 0;

  @media (max-width: 768px) {
    position: fixed;
  }

  & .spacer {
    margin-top: -20px;
    margin-left: 10px;

    .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
      > .v-input__control
      > .v-input__slot {
      box-shadow: unset;
    }
  }

  &__item {
    & i {
      cursor: pointer;
      font-size: 25px;
      color: $color-gray;
      transition: 0.5s;

      &.send-icon {
        &.active {
          color: $primary-color;
        }

        // color: white;

        &:hover {
          // color: white;
        }
      }

      &:hover {
        transition: 0.5s;
        color: #007bff;
      }
    }
  }
}
</style>
