<template>
  <div></div>
</template>

<script>
export default {
  async beforeMount(){
    const { token } = this.$route.params;

    if(!token){
      this.$router.push('/login');
    }

    this.$http.get(`/api/v1/validateEmail/?token=${token}`).then((res) => {
      if (res.status == 200) {
        this.$swal({
          title: 'Success!',
          text: 'Your account has been verified!',
          icon: 'success',
          confirmButtonText: 'Ok'
        })
        setTimeout(() => {
          if (res.data.accessToken) {
              this.$store.commit('setToken', res.data.accessToken);
              if(!this.me){
                  this.me = this.decryptData(res.data.accessToken);
              }
              window.location.href = '/' + this.me.slug;
          }
        }, 1500);
      }else{
        this.$swal({
          title: 'Error!',
          text: 'Something went wrong!',
          icon: 'error',
          confirmButtonText: 'Ok'
        }).then(() => {
          this.$router.push('/login');
        })
      }
    })
  }
}
</script>