import Vue from 'vue'



export default  {
    getExample: state => {
        return state.exampleValue
    },
    getCurrentVerificationProcess: state => {
        let $cookies = Vue.prototype.$cookies;

        if($cookies.get('sider-verification-process-consent')) {
            state.verificationProcess.consent = Boolean($cookies.get('sider-verification-process-consent'));
        }
        
        if($cookies.get('sider-verification-process-document')){
            state.verificationProcess.document = Boolean($cookies.get('sider-verification-process-document'));
        }

        if($cookies.get('sider-verification-process-document-front')){
            state.verificationProcess.documentFront = Boolean($cookies.get('sider-verification-process-document-front'));
        }

        if($cookies.get('sider-verification-process-document-back')){
            state.verificationProcess.documentBack = Boolean($cookies.get('sider-verification-process-document-back'));
        }

        if($cookies.get('sider-verification-process-document-selfie')){
            state.verificationProcess.selfie = Boolean($cookies.get('sider-verification-process-document-selfie'));
        }

        return state.verificationProcess
    }
}