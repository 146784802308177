<template>
  <div style="position: relative; overflow: auto" id="dialogScrollView">
    <v-overlay :opacity="0.85" v-if="showExistingPayperviews">
      <v-dialog
        v-model="showExistingPayperviews"
        attach="#dialogScrollView"
        max-width="700px"
        persistent
        hide-overlay
      >
        <v-card style="padding: 1rem">
          <center>
            <h3 class="text-white">Select a existing post</h3>
          </center>
          <GridFeed
            @onClick="setSelectedPost"
            :posts="postsPayperview"
            from-chat
          ></GridFeed>
        </v-card>
      </v-dialog>
    </v-overlay>

    <div
      class="nav-dialog"
      :style="
        windowY >= 60
          ? 'position: sticky;top:-1.1em;left: 0;z-index: 1000;padding: 1em;padding-right: 0;'
          : ''
      "
    >
      <i
        class="close-dialog fa fa-chevron-left"
        @click="goBack"
        aria-hidden="true"
        v-if="setSelectedCategory.id"
      ></i>
      <span class="header-dialog">
        <span
          style="font-weight: bold"
          class="text-white"
          v-if="!selectedExistingPost.id"
          >Create a new Post</span
        >
        <span style="font-weight: bold" class="text-white" v-else>
          Send Payperview
        </span>
        <br />
        <small v-if="setSelectedCategory.id">{{
          setSelectedCategory.title
        }}</small>
      </span>
      <v-btn fab elevation="0" @click="close" small class="btn-fab">
        <i class="close-dialog-2 fa fa-times" aria-hidden="true"></i>
      </v-btn>
    </div>
    <br />

    <v-list style="background-color: transparent !important; padding: 0">
      <v-list-item
        style="
          background-color: transparent !important;
          padding: 0.5em;
          padding-top: 0em;
        "
      >
        <v-avatar size="42px" class="feed-avatar">
          <img :src="`${me.avatar}` || $default.avatar" />
        </v-avatar>
        <v-list-item-content style="padding: 0">
          <v-list-item-title
            class="headline mb-1 text-white"
            style="
              gap: 1rem;
              margin-left: 0.5em;
              display: flex;
              place-items: center;
              justify-content: space-between;
            "
          >
            <span
              style="font-size: 14px; text-transform: none; font-weight: 600"
              class="text-gray-strong"
              >{{ me.name }}
              <svg
                v-if="me.verified_badge"
                fill="#007BFF"
                style="margin-bottom: -2px"
                width="17"
                viewBox="0 0 24 24"
                aria-label="Conta verificada"
                role="img"
                class="r-1cvl2hr r-4qtqp9 r-yyyyoo r-1xvli5t r-f9ja8p r-og9te1 r-bnwqim r-1plcrui r-lrvibr"
              >
                <g>
                  <path
                    d="M22.5 12.5c0-1.58-.875-2.95-2.148-3.6.154-.435.238-.905.238-1.4 0-2.21-1.71-3.998-3.818-3.998-.47 0-.92.084-1.336.25C14.818 2.415 13.51 1.5 12 1.5s-2.816.917-3.437 2.25c-.415-.165-.866-.25-1.336-.25-2.11 0-3.818 1.79-3.818 4 0 .494.083.964.237 1.4-1.272.65-2.147 2.018-2.147 3.6 0 1.495.782 2.798 1.942 3.486-.02.17-.032.34-.032.514 0 2.21 1.708 4 3.818 4 .47 0 .92-.086 1.335-.25.62 1.334 1.926 2.25 3.437 2.25 1.512 0 2.818-.916 3.437-2.25.415.163.865.248 1.336.248 2.11 0 3.818-1.79 3.818-4 0-.174-.012-.344-.033-.513 1.158-.687 1.943-1.99 1.943-3.484zm-6.616-3.334l-4.334 6.5c-.145.217-.382.334-.625.334-.143 0-.288-.04-.416-.126l-.115-.094-2.415-2.415c-.293-.293-.293-.768 0-1.06s.768-.294 1.06 0l1.77 1.767 3.825-5.74c.23-.345.696-.436 1.04-.207.346.23.44.696.21 1.04z"
                  ></path>
                </g>
              </svg>
              <svg
                v-if="me.ambassador"
                width="15"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style="margin-left: 4px; transform: translate(0, 1px)"
              >
                <path
                  d="M21.001 11C21.001 9.42 20.126 8.05 18.853 7.4C19.007 6.965 19.091 6.495 19.091 6C19.091 3.79 17.381 2.002 15.273 2.002C14.803 2.002 14.353 2.086 13.937 2.252C13.319 0.915 12.011 0 10.501 0C8.991 0 7.685 0.917 7.064 2.25C6.649 2.085 6.198 2 5.728 2C3.618 2 1.91 3.79 1.91 6C1.91 6.494 1.993 6.964 2.147 7.4C0.875 8.05 0 9.418 0 11C0 12.495 0.782 13.798 1.942 14.486C1.922 14.656 1.91 14.826 1.91 15C1.91 17.21 3.618 19 5.728 19C6.198 19 6.648 18.914 7.063 18.75C7.683 20.084 8.989 21 10.5 21C12.012 21 13.318 20.084 13.937 18.75C14.352 18.913 14.802 18.998 15.273 18.998C17.383 18.998 19.091 17.208 19.091 14.998C19.091 14.824 19.079 14.654 19.058 14.485C20.216 13.798 21.001 12.495 21.001 11.001V11ZM14.385 7.666L10.051 14.166C9.906 14.383 9.669 14.5 9.426 14.5C9.283 14.5 9.138 14.46 9.01 14.374L8.895 14.28L6.48 11.865C6.187 11.572 6.187 11.097 6.48 10.805C6.773 10.513 7.248 10.511 7.54 10.805L9.31 12.572L13.135 6.832C13.365 6.487 13.831 6.396 14.175 6.625C14.521 6.855 14.615 7.321 14.385 7.665V7.666Z"
                  fill="#007BFF"
                />
                <path
                  d="M20 10.8571C20 9.72857 19.3334 8.75 18.3635 8.28571C18.4808 7.975 18.5448 7.63929 18.5448 7.28571C18.5448 5.70714 17.242 4.43 15.636 4.43C15.2779 4.43 14.9351 4.49 14.6182 4.60857C14.1473 3.65357 13.1508 3 12.0004 3C10.85 3 9.85496 3.655 9.38184 4.60714C9.06566 4.48929 8.72206 4.42857 8.36398 4.42857C6.75644 4.42857 5.45517 5.70714 5.45517 7.28571C5.45517 7.63857 5.5184 7.97429 5.63573 8.28571C4.66663 8.75 4 9.72714 4 10.8571C4 11.925 4.59578 12.8557 5.47955 13.3471C5.46431 13.4686 5.45517 13.59 5.45517 13.7143C5.45517 15.2929 6.75644 16.5714 8.36398 16.5714C8.72206 16.5714 9.0649 16.51 9.38108 16.3929C9.85344 17.3457 10.8484 18 11.9996 18C13.1516 18 14.1466 17.3457 14.6182 16.3929C14.9343 16.5093 15.2772 16.57 15.636 16.57C17.2436 16.57 18.5448 15.2914 18.5448 13.7129C18.5448 13.5886 18.5357 13.4671 18.5197 13.3464C19.4019 12.8557 20 11.925 20 10.8579V10.8571ZM14.9595 8.47571L11.6575 13.1186C11.5471 13.2736 11.3665 13.3571 11.1814 13.3571C11.0724 13.3571 10.962 13.3286 10.8644 13.2671L10.7768 13.2L8.93691 11.475C8.71368 11.2657 8.71368 10.9264 8.93691 10.7179C9.16014 10.5093 9.52202 10.5079 9.74449 10.7179L11.093 11.98L14.0071 7.88C14.1824 7.63357 14.5374 7.56857 14.7995 7.73214C15.0631 7.89643 15.1347 8.22929 14.9595 8.475V8.47571Z"
                  fill="#007BFF"
                />
                <path
                  d="M17 9.85714C17 8.72857 16.3334 7.75 15.3635 7.28571C15.4808 6.975 15.5448 6.63929 15.5448 6.28571C15.5448 4.70714 14.242 3.43 12.636 3.43C12.2779 3.43 11.9351 3.49 11.6182 3.60857C11.1473 2.65357 10.1508 2 9.00038 2C7.84996 2 6.85496 2.655 6.38184 3.60714C6.06566 3.48929 5.72206 3.42857 5.36398 3.42857C3.75644 3.42857 2.45517 4.70714 2.45517 6.28571C2.45517 6.63857 2.5184 6.97429 2.63573 7.28571C1.66663 7.75 1 8.72714 1 9.85714C1 10.925 1.59578 11.8557 2.47955 12.3471C2.46431 12.4686 2.45517 12.59 2.45517 12.7143C2.45517 14.2929 3.75644 15.5714 5.36398 15.5714C5.72206 15.5714 6.0649 15.51 6.38108 15.3929C6.85344 16.3457 7.84844 17 8.99962 17C10.1516 17 11.1466 16.3457 11.6182 15.3929C11.9343 15.5093 12.2772 15.57 12.636 15.57C14.2436 15.57 15.5448 14.2914 15.5448 12.7129C15.5448 12.5886 15.5357 12.4671 15.5197 12.3464C16.4019 11.8557 17 10.925 17 9.85786V9.85714ZM11.9595 7.47571L8.65754 12.1186C8.54707 12.2736 8.36651 12.3571 8.18137 12.3571C8.07243 12.3571 7.96195 12.3286 7.86444 12.2671L7.77682 12.2L5.93691 10.475C5.71368 10.2657 5.71368 9.92643 5.93691 9.71786C6.16014 9.50929 6.52202 9.50786 6.74449 9.71786L8.093 10.98L11.0071 6.88C11.1824 6.63357 11.5374 6.56857 11.7995 6.73214C12.0631 6.89643 12.1347 7.22929 11.9595 7.475V7.47571Z"
                  fill="#007BFF"
                />
                <path
                  d="M10.939 5.029V5.77C11.5803 5.796 12.135 5.887 12.603 6.043C13.071 6.19033 13.305 6.41567 13.305 6.719C13.305 6.98767 13.214 7.343 13.032 7.785C12.8587 8.227 12.7373 8.461 12.668 8.487C11.94 8.18367 11.3637 8.01467 10.939 7.98V9.449C12.759 9.51833 13.669 10.3937 13.669 12.075C13.669 12.8637 13.4437 13.5397 12.993 14.103C12.5423 14.6663 11.8577 14.9957 10.939 15.091V15.988C10.939 16.144 10.8913 16.248 10.796 16.3C10.7093 16.352 10.6357 16.378 10.575 16.378L10.393 16.326C10.2717 16.2567 10.211 16.144 10.211 15.988V15.13C9.483 15.1127 8.83733 14.9827 8.274 14.74C7.71933 14.4973 7.442 14.1897 7.442 13.817C7.442 13.3317 7.58933 12.842 7.884 12.348C7.91 12.296 7.94467 12.2397 7.988 12.179C8.69 12.6037 9.431 12.842 10.211 12.894V11.464C8.365 11.3513 7.442 10.4067 7.442 8.63C7.442 7.85 7.689 7.20867 8.183 6.706C8.677 6.20333 9.353 5.9 10.211 5.796V5.029C10.211 4.873 10.2543 4.769 10.341 4.717C10.4363 4.665 10.5143 4.639 10.575 4.639L10.757 4.691C10.8783 4.76033 10.939 4.873 10.939 5.029ZM11.862 12.01C11.862 11.8107 11.7927 11.6763 11.654 11.607C11.524 11.529 11.2857 11.4813 10.939 11.464V12.868C11.5543 12.764 11.862 12.478 11.862 12.01ZM9.418 8.773C9.418 9.137 9.68233 9.34933 10.211 9.41V7.993C9.68233 8.07967 9.418 8.33967 9.418 8.773Z"
                  fill="white"
                />
              </svg>

              <p
                style="
                  font-size: 15px;
                  margin-left: 4px;
                  transform: translateY(-1.5px);
                  display: inline-block;
                "
                v-if="me.investor"
              >
                <i
                  style=""
                  class="fas fa-chart-pie-simple-circle-dollar primary-color"
                ></i>
              </p>

              <br />

              <span
                style="font-size: 12px; text-transform: none"
                class="text-gray"
                >@{{ me.slug }}
              </span>
            </span>

            <div style="display: flex; gap: 1rem">
              <v-select
                :disabled="Boolean(selectedExistingPost.id)"
                style="padding: 0; max-width: 200px"
                hide-details
                :items="postViewOptions"
                item-text="name"
                v-model="isSubscribersOnly"
                filled
              >
                <template #item="{ item }">
                  <span class="custom-selector-option">
                    <i :class="item.icon"></i> {{ item.name }}
                  </span>
                </template>
                <template #selection="{ item }">
                  <span class="custom-selector-option">
                    <i :class="item.icon"></i> {{ item.name }}
                  </span>
                </template>
              </v-select>
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <div
      class="drop-files-here"
      :style="{
        border: urls.length > 0 ? 'none' : '',
        display: urls.length > 0 ? 'block' : 'flex',
        cursor: urls.length > 0 ? 'default' : 'pointer',
      }"
    >
      <div v-if="urls.length > 0" style="width: 100%">
        <div
          v-for="(url, urlIndex) in urls"
          :key="urlIndex"
          style="width: 100%; position: relative"
        >
          <div v-if="urls.length == 1">
            <v-btn
              v-if="!selectedExistingPost.id"
              class="creating-post-popup"
              @click="handleClickOnDropArea"
              absolute
              left
              top
              style="z-index: 2"
              elevation="0"
            >
              <i class="fas fa-image" style="margin-right: 8px"></i>
              Add File
            </v-btn>
            <v-btn
              v-if="!selectedExistingPost.id"
              class="creating-post-popup"
              absolute
              right
              top
              style="z-index: 2"
              elevation="0"
              @click="removeFile(url.file)"
            >
              <i class="fas fa-times"></i>
            </v-btn>
            <div
              v-if="isImage(url.type || '')"
              class="blurred"
              :style="`background-image: url('${url.url}');width: 100%;top: 0;`"
            ></div>

            <img
              v-if="isImage(url.type || '')"
              :src="url.url"
              class="preview-image-creating-post"
            />

            <video
              playsinline
              loop
              class="preview-video-creating-post"
              style="width: 100%"
              v-if="isVideo(url.type || '')"
              controls
            >
              <source :src="url.url" type="video/mp4" />
            </video>
            <div
              v-if="
                isAudio(url.type) ||
                isPDF(url.type) ||
                isDoc(url.type) ||
                isExcel(url.type)
              "
              class="other-files-post"
            >
              <span v-if="isPDF(url.type)">
                <i
                  class="fa fa-file-pdf-o"
                  style="font-size: 3em"
                  aria-hidden="true"
                ></i>
                <br /><br />
                {{ url.name }}
              </span>
              <span v-if="isDoc(url.type)" style="max-width: 400px">
                <i
                  class="fa fa-file-word-o"
                  style="font-size: 3em"
                  aria-hidden="true"
                ></i>
                <br /><br />
                {{ url.name }}
              </span>
              <span v-if="isExcel(url.type)" style="max-width: 400px">
                <i
                  class="fa fa-file-excel-o"
                  style="font-size: 3em"
                  aria-hidden="true"
                ></i>
                <br /><br />
                {{ url.name }}
              </span>
              <span v-if="isAudio(url.type)" style="max-width: 400px">
                <!-- audio html -->
                <audio controls style="width: 100%; height: 70px">
                  <source :src="url.url" type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
                <br /><br />
                {{ url.name }}
              </span>
            </div>
          </div>
        </div>

        <splide
          class="feed-carousel-create-post"
          :options="optionsSplide"
          @splide:moved="onMoveSplide"
          @splide:mounted="onSplideMounted"
          v-if="showSlide && urls.length > 1"
        >
          <splide-slide
            class="feed-carousel-create-post__slide video-wrapper"
            v-for="(url, urlIndex) in urls"
            :key="urlIndex"
          >
            <v-btn
              v-if="!Boolean(selectedExistingPost.id)"
              class="creating-post-popup"
              @click="handleClickOnDropArea"
              absolute
              left
              top
              style="z-index: 2"
              elevation="0"
            >
              <i class="fas fa-image" style="margin-right: 8px"></i>
              Add File
            </v-btn>
            <v-btn
              v-if="!Boolean(selectedExistingPost.id)"
              class="creating-post-popup"
              absolute
              right
              top
              style="z-index: 2"
              elevation="0"
              @click="removeFile(url.file)"
            >
              <i class="fas fa-times"></i>
            </v-btn>
            <v-btn
              :color="filePreviewIndex == urlIndex ? '#007bff' : ''"
              :style="
                filePreviewIndex == urlIndex
                  ? 'color: white;background-color: #007bff !important;'
                  : ''
              "
              v-if="payPerView > 0"
              class="creating-post-popup"
              @click="filePreviewIndex = urlIndex"
              absolute
              left
              bottom
              style="z-index: 2"
              elevation="0"
            >
              <i class="fas fa-image" style="margin-right: 8px"></i>
              <span v-if="filePreviewIndex == urlIndex"> Current preview </span>
              <span v-else> Set as preview </span>
            </v-btn>
            <!-- BLUR BACKGROUND -->
            <div
              v-if="isImage(url.type)"
              class="blurred"
              :style="`background-image: url('${url.url}');width: 100%;top: 0;z-index: 0;`"
            ></div>
            <!-- POST IMAGE -->
            <img
              v-if="isImage(url.type)"
              :src="url.url"
              style="object-fit: contain; width: 100%; z-index: 1"
              class="preview-image-creating-post"
            />
            <!-- VIDEO -->
            <video
              class="preview-video-creating-post"
              playsinline
              loop
              style="width: 100%; position: absolute"
              v-if="isVideo(url.type || '')"
              controls
            >
              <source :src="url.url" type="video/mp4" />
            </video>

            <div
              class="other-files-post"
              v-if="
                isAudio(url.type) ||
                isPDF(url.type) ||
                isDoc(url.type) ||
                isExcel(url.type)
              "
            >
              <span v-if="isPDF(url.type)">
                <i
                  class="fa fa-file-pdf-o"
                  style="font-size: 3em"
                  aria-hidden="true"
                ></i>
                <br /><br />
                {{ url.name }}
              </span>
              <span v-if="isDoc(url.type)" style="max-width: 400px">
                <i
                  class="fa fa-file-word-o"
                  style="font-size: 3em"
                  aria-hidden="true"
                ></i>
                <br /><br />
                {{ url.name }}
              </span>
              <span v-if="isExcel(url.type)" style="max-width: 400px">
                <i
                  class="fa fa-file-excel-o"
                  style="font-size: 3em"
                  aria-hidden="true"
                ></i>
                <br /><br />
                {{ url.name }}
              </span>
              <span v-if="isAudio(url.type)" style="max-width: 400px">
                <!-- audio html -->
                <audio controls style="width: 100%; height: 70px">
                  <source :src="url.url" type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
                <br /><br />
                {{ url.name }}
              </span>
            </div>
          </splide-slide>
        </splide>
      </div>
      <div
        v-else
        @click="handleClickOnDropArea"
        style="
          width: 100%;
          height: 100%;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
        "
      >
        <i class="fa fa-image" style="font-size: 3em" aria-hidden="true"></i>
        <br />
        <span style="font-size: 1.2em">Click to select your files</span>
      </div>
    </div>

    <v-file-input
      style="display: none"
      id="createPostMultiple"
      @change="previewImage"
      v-model="files"
      color="#007BFF"
      counter
      multiple
      placeholder="Select your files"
      prepend-icon="mdi-paperclip"
      filled
      :show-size="1000"
    >
      <template v-slot:selection="{ index, text }">
        <v-chip v-if="index < 2" color="#007BFF" dark label small>
          {{ text }}
        </v-chip>

        <span v-else-if="index === 2" class="text-white">
          +{{ files.length - 2 }} File(s)
        </span>
      </template>
    </v-file-input>

    <div class="upload-post-icons">
      <span>
        <i class="fa-light fa-heart" aria-hidden="true"></i>
        <span style="margin-left: 8px">0</span>
      </span>
      <span style="margin-left: 16px"
        ><i class="fa-light fa-comment"></i> <span>0</span></span
      >
      <div style="flex-grow: 1"></div>
      <span>
        <i class="fa-light fa-badge-dollar" aria-hidden="true"></i>
        0
      </span>
    </div>

    <v-textarea
      no-resize
      rows="3"
      :disabled="Boolean(selectedExistingPost.id)"
      v-model="description"
      color="#0989EF"
      filled
      placeholder="Honestly, write anything - your subscribers will love it 😄"
      clearable
      persistent-hint
      :hint="
        Boolean(selectedExistingPost.id) ? 'Description (not editable)' : ''
      "
    >
      <template slot="append">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
              style="transform: translate(-18px, 50.5px); cursor: pointer"
            >
              <i class="fa-light fa-face-smile-wink"></i>
            </div>
          </template>

          <v-card style="height: 300px; overflow-y: auto">
            <v-divider></v-divider>

            <div
              class="text-white"
              style="padding: 1em; width: 200px"
              v-for="(emojis, category) in emojis"
              :key="category"
            >
              {{ category }}
              <br />
              <center>
                <v-btn
                  @click="description += emoji"
                  v-for="emoji in emojis"
                  :key="emoji"
                  style="font-size: 25px"
                  fab
                  icon
                  small
                >
                  {{ emoji }}
                </v-btn>
              </center>
            </div>
          </v-card>
        </v-menu>
      </template>
    </v-textarea>

    <vuetify-money
      v-model="payPerView"
      :disabled="Boolean(selectedExistingPost.id)"
      :valueWhenIsEmpty="valueWhenIsEmpty"
      :options="optionsMoney"
      color="#0989EF"
      filled
      placeholder="Pay-per-view (Optional)"
      persistent-hint
      :hint="
        Boolean(selectedExistingPost.id)
          ? 'Pay-per-view price (not editable)'
          : 'If you enter any amount, this post will become a pay-per-view post.'
      "
    ></vuetify-money>

    <br /><br /><br /><br />

    <!-- <v-row align="center">


            <v-col class="d-flex" cols="12" sm="12">

                <v-textarea no-resize v-model="description" color="#0989EF" filled
                    placeholder="Honestly, write anything - your subscribers will love it 😄" clearable>

                    <template slot="append">
                        <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x>
                            <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on" style="transform: translate(-18px, 100.5px);cursor: pointer;">
                                    
                                        <i class="fa-light fa-face-smile-wink"></i>
                                    </div>
                            </template>

                            <v-card style="height: 300px;overflow-y: auto;">

                                <v-divider></v-divider>

                                <div class="text-white" style="padding: 1em;width: 200px;"
                                    v-for="(emojis, category) in emojis" :key="category">
                                    {{category}}
                                    <br>
                                    <center>
                                        <v-btn @click="description+=emoji" v-for="emoji in emojis" :key="emoji" style="font-size: 25px;" fab icon small>
                                            {{emoji}}
                                        </v-btn>
                                    </center>
                                </div>

                            </v-card>
                        </v-menu>
                    </template>
                </v-textarea> -->

    <!-- <v-file-input @change="previewImage" v-model="files" color="#007BFF" counter multiple
                    placeholder="Select your files" prepend-icon="mdi-paperclip" filled :show-size="1000">
                    <template v-slot:selection="{ index, text }">
                        <v-chip v-if="index < 2" color="#007BFF" dark label small>
                             {{ text }}
                        </v-chip>

                        <span v-else-if="index === 2" class="text-white">
                            +{{ files.length - 2 }} File(s)
                        </span>
                    </template>
                </v-file-input> -->
    <!-- IF FILES .LENGTH KEEPS INCREASIN MORE THAN 3 CHANGE THE GRID COLUMNS TO MORE -->
    <!-- <div v-if="urls.length > 0" style="display:grid;grid-template-columns: repeat(3, 1fr);column-gap: 1px;row-gap: 1px;">
                    
                    <v-img v-for="(file, urlIndex) in urls" :key="urlIndex" :src="file.url" style="height: calc(100vw/3);max-width: 243px;max-height: 243px;" fit>
                    <i @click="removeFile(file)" style="z-index: 100;margin-right:5px;color:red;cursor: pointer;left:3%;top: 2%;font-size: 20px;position:absolute;" class="fa fa-times" aria-hidden="true"></i>
                    <div v-if="!isImageOrVideo(file)" style="position: absolute;width: 100%;height: 100%;display: grid;place-items: center;left:0;top:0;" class="text-white bg-white">
                        <small style="font-size: 10px;">
                            <i class="fa-light fa-paperclip" aria-hidden="true"></i>
                            {{file.name}}
                        </small>
                    </div>
                    <video ref="video" controls :src="file.url" v-if="file.type.includes('video')" style="height: calc(100vw/3);max-width: 243px;max-height: 243px;">
                        <source :src="file.url" :type="file.type">
                    </video>
                    </v-img>
                </div>

                
                <v-radio-group v-model="isSubscribersOnly">
                    <v-radio color="#007BFF" label="Public" :value="false"></v-radio>
                    <v-radio color="#007BFF" label="Subscribers Only" :value="true"></v-radio>
                </v-radio-group> -->
    <!--                 
            </v-col>

        </v-row> -->
    <center>
      <v-btn
        @click="
          () => {
            Boolean(selectedExistingPost.id) || chatId
              ? sendPayperview()
              : canCreatePostDisabled
              ? null
              : createPost();
          }
        "
        :disabled="canCreatePost"
        :style="{
          bottom: wWidth < 768 ? '3%' : '8%',

          background: canCreatePost
            ? '#f5f5f5 !important'
            : `linear-gradient(90deg, #007BFF ${percentageCompleted.allPercent}%, #6c757d ${percentageCompleted.allPercent}%) !important`,
          transition: 'all 0.5s ease-in-out',
        }"
        style="
          width: 100%;
          max-width: 700px;
          left: 0;
          right: 0;
          margin: auto;
          color: white;
          text-transform: none;
        "
        color="#007BFF"
        x-large
        rounded
        elevation="0"
      >
        <span v-if="Boolean(selectedExistingPost.id) || chatId">SEND</span>
        <div
          v-else
          style="
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            gap: 1rem;
          "
        >
          <p style="font-size: 12px">
            {{
              percentageCompleted.allPercent > 0
                ? percentageCompleted.allMbs + "MB/s"
                : ""
            }}
          </p>
          <p style="font-size: 18px">
            {{
              percentageCompleted.allPercent > 0
                ? percentageCompleted.allPercent + "%"
                : "POST"
            }}
          </p>
          <p style="font-size: 12px">
            {{
              percentageCompleted.allPercent > 0
                ? "Time Left " + percentageCompleted.allTimeRemaining + "s"
                : ""
            }}
          </p>
        </div>
      </v-btn>
    </center>
    <!-- <div :key="counterW" :style="wWidth < 768 ? 'position: fixed;' : 'position: absolute;'"
            style="width: 100%;height: auto;padding: 1em;left:0;bottom:0;height: 90px;">
            <v-btn :loading="canCreatePostDisabled" @click="() => {canCreatePostDisabled ? null : createPost()}" fixed :style="wWidth < 768 ?'bottom: 3%;' : 'bottom: 8%;'"
                style="width: 300px;left: 0;right:0;margin: auto;color: white;" color="#007BFF" x-large rounded
                elevation="0">
                POST
            </v-btn>
        </div> -->
  </div>
</template>

<script>
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import GridFeed from "@/components/shared/GridFeed";

import utilsMixins from "@/mixins/utils";
// TODO CLEAR ALL INPUTS WHEN CLOSING THE DIALOG
import axios from "axios";
export default {
  components: {
    Splide,
    SplideSlide,
    GridFeed,
  },
  props: {
    chatId: {
      type: String,
      default: () => null,
      required: false,
    },
  },
  mixins: [utilsMixins],
  async beforeMount() {
    this.categories = await this.$http
      .get("/api/v1/getCategories")
      .then((res) => res.data);
  },
  computed: {
    percentageCompleted() {
      const allTotals = this.percentCompleted.reduce((a, b) => {
        return (a += b.total);
      }, 0);
      const allLoaded = this.percentCompleted.reduce((a, b) => {
        return (a += b.loaded);
      }, 0);
      const allPercent = Math.round((allLoaded * 100) / allTotals);
      const allMbs = this.percentCompleted.reduce((a, b) => {
        return (a += b.mbs);
      }, 0);
      const allTimeRemaining = Math.max(
        ...this.percentCompleted.map((p) => p.timeRemaining)
      );
      return {
        allPercent: allPercent.toFixed(0),
        allMbs: allMbs.toFixed(2),
        allTimeRemaining: allTimeRemaining.toFixed(0),
      };
    },
    canCreatePost() {
      if (this.chatId) {
        return this.payPerView <= 0 && this.files.length <= 0;
      }
      return this.files.length <= 0 && this.description.length <= 0;
    },
  },
  mounted() {
    document
      .getElementById("dialogScrollView")
      .addEventListener("scroll", this.handleScroll);
    this.wWidth = window.innerWidth;
    window.addEventListener("resize", this.wHandleResize);
  },
  beforeDestroy() {
    if (document.getElementById("dialogScrollView")) {
      document
        .getElementById("dialogScrollView")
        .removeEventListener("scroll", this.handleScroll);
    }
    window.removeEventListener("resize", this.wHandleResize);
  },
  methods: {
    async sendPayperview() {
      if (this.selectedExistingPost.id) {
        this.$emit("sendMessage", {
          chatId: this.chatId,
          postId: this.selectedExistingPost.id,
          post: this.selectedExistingPost,
          preview:
            this.filePreviewIndex > -1
              ? this.selectedExistingPost.files[this.filePreviewIndex]
              : null,
        });
        this.$emit("close");
      } else {
        this.canCreatePostDisabled = true;
        const post = await this.createPost(false, true);
        this.$emit("sendMessage", {
          chatId: this.chatId,
          postId: post.id,
          post: post,
          preview:
            this.filePreviewIndex > -1
              ? post.files[this.filePreviewIndex]
              : null,
          new_post: true,
        });
        setTimeout(() => {
          this.canCreatePostDisabled = false;
          this.$emit("close");
        }, 5000);
      }
    },
    setSelectedPost(post) {
      if (post.new) {
        this.showExistingPayperviews = false;
        return;
      }
      this.selectedExistingPost = post;
      this.showExistingPayperviews = false;
      this.description = post.description;
      this.payPerView = post.pay_per_view;
      this.urls = post.files.map((file) => {
        return {
          url: file.medium || file.video || file.file,
          name: file.medium || file.video || file.file,
          type: file.medium || file.video || file.file,
        };
      });
      if (this.urls.length > 1) {
        this.showSlide = true;
      }
    },
    onSplideMounted(splide) {
      this.splideObject = splide;
    },
    onMoveSplide() {
      // params: splide, newIndex, oldIndex;
      // TODO PAUSE VIDEO ON SLIDE
    },
    swipeTo(index) {
      this.splideObject.go(index);
    },
    handleClickOnDropArea() {
      document.getElementById("createPostMultiple").click();
    },
    isImageOrVideo(file) {
      return file.type.includes("image") || file.type.includes("video");
    },
    removeFile(f) {
      this.showSlide = false;
      const index = this.files.findIndex((file) => file.name == f.name);
      const index2 = this.urls.findIndex((file) => file.name == f.name);
      this.urls.splice(index2, 1);
      this.files.splice(index, 1);
      setTimeout(() => {
        this.showSlide = true;
      }, 250);
    },
    async previewImage() {
      this.showSlide = false;

      this.files.forEach((file, i) => {
        // add to being of array
        this.urls.unshift({
          url: URL.createObjectURL(file),
          name: file.name,
          type: file.type,
          file,
        });

        // if end of array check all that is video and load
        if (i === this.files.length - 1) {
          if (this.files.length > 0) {
            if (this.urls.length > 0) {
              // check if file already exists
              this.urls.forEach((url) => {
                this.files.includes(url.file)
                  ? null
                  : this.files.unshift(url.file);
              });
            }
          }
          setTimeout(() => {
            this.showSlide = true;
          }, 500);
        }
      });
    },
    wHandleResize() {
      this.counterW++;
      this.wWidth = window.innerWidth;
    },
    handleScroll(e) {
      this.windowY = e.target.scrollTop;
    },
    async createPost(emits = true, new_post = false) {
      this.percentCompleted = [];
      this.canCreatePostDisabled = true;
      const formData = new FormData();

      if (this.files.length > 0) {
        this.files.forEach(async (file) => {
          const { signedRequest, finalFileName } = await this.$http
            .get(
              `/api/v1/getS3Signature?file-name=${file.name}&file-type=${file.type}`
            )
            .then((res) => res.data);
          const startedUploadAt = new Date().getTime();
          formData.append(
            "files[]",
            JSON.stringify({
              name: finalFileName,
              originalName: file.name,
              type: file.type,
              size: file.size,
            })
          );

          // use axios and create a progressbar with the % of upload
          await axios
            .put(signedRequest, file, {
              headers: {
                "Content-Type": file.type,
                "x-amz-acl": "public-read",
              },
              onUploadProgress: (progressEvent) => {
                if (!this.percentCompleted.find((p) => p.name == file.name)) {
                  this.percentCompleted.push({
                    name: file.name,
                    percent: 0,
                    total: progressEvent.total,
                    loaded: progressEvent.loaded,
                    mbs: 0,
                    timelapsed: 0,
                    velocity: 0,
                    timeRemaining: 0,
                  });
                } else {
                  this.percentCompleted.find(
                    (p) => p.name == file.name
                  ).percent = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  this.percentCompleted.find(
                    (p) => p.name == file.name
                  ).loaded = progressEvent.loaded;
                  this.percentCompleted.find(
                    (p) => p.name == file.name
                  ).timelapsed =
                    (new Date().getTime() - startedUploadAt) / 1000;
                  this.percentCompleted.find(
                    (p) => p.name == file.name
                  ).velocity =
                    progressEvent.loaded /
                    this.percentCompleted.find((p) => p.name == file.name)
                      .timelapsed;
                  this.percentCompleted.find((p) => p.name == file.name).mbs =
                    this.percentCompleted.find((p) => p.name == file.name)
                      .velocity /
                    1024 /
                    1024;
                  this.percentCompleted.find(
                    (p) => p.name == file.name
                  ).timeRemaining =
                    (progressEvent.total - progressEvent.loaded) /
                    this.percentCompleted.find((p) => p.name == file.name)
                      .velocity;
                }
              },
            })
            .catch((err) => {
              this.percentCompleted.find(
                (p) => p.name == file.name
              ).error = true;
              return { error: true, err };
            });
        });
      }

      if (this.percentCompleted.find((p) => p.error)) {
        return this.$swal({
          icon: "error",
          title: "Error uploading file",
          text: "Please try again",
          timer: 3000,
          showConfirmButton: false,
        });
      }

      formData.append("description", this.description);
      formData.append("subscribers_only", this.isSubscribersOnly);
      formData.append("payPerView", this.payPerView);
      if (this.chatId) {
        formData.append("chatId", this.chatId);
        formData.append("new_post", new_post);
      }

      if (this.files.length > 0) {
        // wait before going check if this.percentageCompleted.allPercent is 100 if not keep waiting
        const interval = setInterval(() => {
          if (this.percentageCompleted.allPercent == 100) {
            clearInterval(interval);
            this.$http
              .post("/api/v1/createPost", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((res) => {
                this.$swal({
                  icon: "success",
                  title: "Post created successfully",
                  timer: 3000,
                  showConfirmButton: false,
                });
                this.description = "";
                this.isSubscribersOnly = true;
                this.files = [];

                if (emits) {
                  this.canCreatePostDisabled = false;
                  this.percentCompleted = [];
                  this.$emit("reload", "getPosts");
                  this.$emit("close");

                  setTimeout(() => {
                    this.$emit("reload", "getPosts");
                    setTimeout(() => {
                      this.$emit("reload", "getPosts");
                      setTimeout(() => {
                        this.$emit("reload", "getPosts");
                        setTimeout(() => {
                          this.$emit("reload", "getPosts");
                          setTimeout(() => {
                            this.$emit("reload", "getPosts");
                            setTimeout(() => {
                              this.$emit("reload", "getPosts");
                            }, 10000);
                          }, 10000);
                        }, 10000);
                      }, 10000);
                    }, 5000);
                  }, 2500);
                }
                return res.data;
              });
          }
        }, 1000);
      } else {
        this.$http
          .post("/api/v1/createPost", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            this.$swal({
              icon: "success",
              title: "Post created successfully",
              timer: 3000,
              showConfirmButton: false,
            });
            this.description = "";
            this.isSubscribersOnly = true;
            this.files = [];

            if (emits) {
              this.canCreatePostDisabled = false;
              this.percentCompleted = [];
              this.$emit("reload", "getPosts");
              this.$emit("close");
            }
            return res.data;
          });
      }
    },
    close() {
      this.setSelectedCategory = {};
      this.$emit("close");
    },
    goBack() {
      if (!this.setSelectedCategory.id) {
        this.$emit("close");
      } else {
        this.setSelectedCategory = {};
      }
    },
    complete() {},
  },
  data: () => ({
    percentCompleted: [],
    filePreviewIndex: 0,
    selectedExistingPost: {},
    postsPayperview: [],
    showExistingPayperviews: false,
    payPerView: "",
    optionsMoney: {
      locale: "pt-PT",
      prefix: " € ",
      suffix: "",
      length: 9,
      precision: 0,
    },
    showSlide: false,
    optionsSplide: {
      rewind: true,
      width: 800,
      perPage: 1,
      lazyLoad: "nearby",
    },
    splideObject: null,
    postViewOptions: [
      {
        name: "Public",
        value: false,
        icon: "fas fa-globe-americas",
      },
      {
        name: "Paid Subs Only",
        value: true,
        icon: "fas fa-lock",
      },
    ],
    selectExistingPostsOptions: [
      {
        name: "Create new post",
        value: true,
        icon: "fas fa-plus",
      },
      {
        name: "Select from existing posts",
        value: false,
        icon: "fas fa-images",
      },
    ],
    canCreatePostDisabled: false,
    newPost: {
      id: null,
    },
    urls: [],
    files: [],
    isSubscribersOnly: false,
    text: "",
    menu: false,
    // an array of emojis organized like whatsapp
    emojis: {
      People: {
        smile: "😄",
        smiley: "😃",
        grinning: "😀",
        blush: "😊",
        wink: "😉",
        heart_eyes: "😍",
        kissing_heart: "😘",
        kissing_closed_eyes: "😚",
        kissing: "😗",
        kissing_smiling_eyes: "😙",
        stuck_out_tongue_winking_eye: "😜",
        stuck_out_tongue_closed_eyes: "😝",
        stuck_out_tongue: "😛",
        flushed: "😳",
        grin: "😁",
        pensive: "😔",
        relieved: "😌",
        unamused: "😒",
        disappointed: "😞",
        persevere: "😣",
        cry: "😢",
        joy: "😂",
        sob: "😭",
        sleepy: "😪",
        disappointed_relieved: "😥",
        cold_sweat: "😰",
        sweat_smile: "😅",
        sweat: "😓",
        weary: "😩",
        tired_face: "😫",
        fearful: "😨",
        scream: "😱",
        angry: "😠",
        rage: "😡",
        triumph: "😤",
        confounded: "😖",
        laughing: "😆",
        yum: "😋",
        mask: "😷",
        sunglasses: "😎",
        sleeping: "😴",
        dizzy_face: "😵",
        astonished: "😲",
        worried: "😟",
        frowning: "😦",
        anguished: "😧",
        imp: "👿",
        open_mouth: "😮",
        grimacing: "😬",
        neutral_face: "😐",
        confused: "😕",
        hushed: "😯",
        smirk: "😏",
        expressionless: "😑",
        man_with_gua_pi_mao: "👲",
        man_with_turban: "👳",
        cop: "👮",
        construction_worker: "👷",
        guardsman: "💂",
        baby: "👶",
        boy: "👦",
        girl: "👧",
        man: "👨",
        woman: "👩",
        older_man: "👴",
        older_woman: "👵",
        person_with_blond_hair: "👱",
        angel: "👼",
        princess: "👸",
        smiley_cat: "😺",
        smile_cat: "😸",
        heart_eyes_cat: "😻",
        kissing_cat: "😽",
        smirk_cat: "😼",
        scream_cat: "🙀",
        crying_cat_face: "😿",
        joy_cat: "😹",
        pouting_cat: "😾",
        japanese_ogre: "👹",
        japanese_goblin: "👺",
        see_no_evil: "🙈",
        hear_no_evil: "🙉",
        speak_no_evil: "🙊",
        skull: "💀",
        alien: "👽",
        hankey: "💩",
        fire: "🔥",
        sparkles: "✨",
        star2: "🌟",
        dizzy: "💫",
        boom: "💥",
        anger: "💢",
        sweat_drops: "💦",
        droplet: "💧",
        zzz: "💤",
        dash: "💨",
        ear: "👂",
        eyes: "👀",
        nose: "👃",
        tongue: "👅",
        lips: "👄",
        thumbs_up: "👍",
        "-1": "👎",
        ok_hand: "👌",
        facepunch: "👊",
        fist: "✊",
        wave: "👋",
        hand: "✋",
        open_hands: "👐",
        point_up_2: "👆",
        point_down: "👇",
        point_right: "👉",
        point_left: "👈",
        raised_hands: "🙌",
        pray: "🙏",
        clap: "👏",
        muscle: "💪",
        walking: "🚶",
        runner: "🏃",
        dancer: "💃",
        couple: "👫",
        family: "👪",
        couplekiss: "💏",
        couple_with_heart: "💑",
        dancers: "👯",
        ok_woman: "🙆",
        no_good: "🙅",
        information_desk_person: "💁",
        raising_hand: "🙋",
        massage: "💆",
        haircut: "💇",
        nail_care: "💅",
        bride_with_veil: "👰",
        person_with_pouting_face: "🙎",
        person_frowning: "🙍",
        bow: "🙇",
        tophat: "🎩",
        crown: "👑",
        womans_hat: "👒",
        athletic_shoe: "👟",
        mans_shoe: "👞",
        sandal: "👡",
        high_heel: "👠",
        boot: "👢",
        shirt: "👕",
        necktie: "👔",
        womans_clothes: "👚",
        dress: "👗",
        running_shirt_with_sash: "🎽",
        jeans: "👖",
        kimono: "👘",
        bikini: "👙",
        briefcase: "💼",
        handbag: "👜",
        pouch: "👝",
        purse: "👛",
        eyeglasses: "👓",
        ribbon: "🎀",
        closed_umbrella: "🌂",
        lipstick: "💄",
        yellow_heart: "💛",
        blue_heart: "💙",
        purple_heart: "💜",
        green_heart: "💚",
        broken_heart: "💔",
        heartpulse: "💗",
        heartbeat: "💓",
        two_hearts: "💕",
        sparkling_heart: "💖",
        revolving_hearts: "💞",
        cupid: "💘",
        love_letter: "💌",
        kiss: "💋",
        ring: "💍",
        gem: "💎",
        bust_in_silhouette: "👤",
        speech_balloon: "💬",
        footprints: "👣",
      },
      Nature: {
        dog: "🐶",
        wolf: "🐺",
        cat: "🐱",
        mouse: "🐭",
        hamster: "🐹",
        rabbit: "🐰",
        frog: "🐸",
        tiger: "🐯",
        koala: "🐨",
        bear: "🐻",
        pig: "🐷",
        pig_nose: "🐽",
        cow: "🐮",
        boar: "🐗",
        monkey_face: "🐵",
        monkey: "🐒",
        horse: "🐴",
        sheep: "🐑",
        elephant: "🐘",
        panda_face: "🐼",
        penguin: "🐧",
        bird: "🐦",
        baby_chick: "🐤",
        hatched_chick: "🐥",
        hatching_chick: "🐣",
        chicken: "🐔",
        snake: "🐍",
        turtle: "🐢",
        bug: "🐛",
        bee: "🐝",
        ant: "🐜",
        beetle: "🐞",
        snail: "🐌",
        octopus: "🐙",
        shell: "🐚",
        tropical_fish: "🐠",
        fish: "🐟",
        dolphin: "🐬",
        whale: "🐳",
        racehorse: "🐎",
        dragon_face: "🐲",
        blowfish: "🐡",
        camel: "🐫",
        poodle: "🐩",
        feet: "🐾",
        bouquet: "💐",
        cherry_blossom: "🌸",
        tulip: "🌷",
        four_leaf_clover: "🍀",
        rose: "🌹",
        sunflower: "🌻",
        hibiscus: "🌺",
        maple_leaf: "🍁",
        leaves: "🍃",
        fallen_leaf: "🍂",
        herb: "🌿",
        ear_of_rice: "🌾",
        mushroom: "🍄",
        cactus: "🌵",
        palm_tree: "🌴",
        chestnut: "🌰",
        seedling: "🌱",
        blossom: "🌼",
        new_moon: "🌑",
        first_quarter_moon: "🌓",
        moon: "🌔",
        full_moon: "🌕",
        first_quarter_moon_with_face: "🌛",
        crescent_moon: "🌙",
        earth_asia: "🌏",
        volcano: "🌋",
        milky_way: "🌌",
        stars: "🌠",
        partly_sunny: "⛅",
        snowman: "⛄",
        cyclone: "🌀",
        foggy: "🌁",
        rainbow: "🌈",
        ocean: "🌊",
      },
      Objects: {
        bamboo: "🎍",
        gift_heart: "💝",
        dolls: "🎎",
        school_satchel: "🎒",
        mortar_board: "🎓",
        flags: "🎏",
        fireworks: "🎆",
        sparkler: "🎇",
        wind_chime: "🎐",
        rice_scene: "🎑",
        jack_o_lantern: "🎃",
        ghost: "👻",
        santa: "🎅",
        christmas_tree: "🎄",
        gift: "🎁",
        tanabata_tree: "🎋",
        tada: "🎉",
        confetti_ball: "🎊",
        balloon: "🎈",
        crossed_flags: "🎌",
        crystal_ball: "🔮",
        movie_camera: "🎥",
        camera: "📷",
        video_camera: "📹",
        vhs: "📼",
        cd: "💿",
        dvd: "📀",
        minidisc: "💽",
        floppy_disk: "💾",
        computer: "💻",
        iphone: "📱",
        telephone_receiver: "📞",
        pager: "📟",
        fax: "📠",
        satellite: "📡",
        tv: "📺",
        radio: "📻",
        loud_sound: "🔊",
        bell: "🔔",
        loudspeaker: "📢",
        mega: "📣",
        hourglass_flowing_sand: "⏳",
        hourglass: "⌛",
        alarm_clock: "⏰",
        watch: "⌚",
        unlock: "🔓",
        lock: "🔒",
        lock_with_ink_pen: "🔏",
        closed_lock_with_key: "🔐",
        key: "🔑",
        mag_right: "🔎",
        bulb: "💡",
        flashlight: "🔦",
        electric_plug: "🔌",
        battery: "🔋",
        mag: "🔍",
        bath: "🛀",
        toilet: "🚽",
        wrench: "🔧",
        nut_and_bolt: "🔩",
        hammer: "🔨",
        door: "🚪",
        smoking: "🚬",
        bomb: "💣",
        gun: "🔫",
        hocho: "🔪",
        pill: "💊",
        syringe: "💉",
        moneybag: "💰",
        yen: "💴",
        dollar: "💵",
        credit_card: "💳",
        money_with_wings: "💸",
        calling: "📲",
        "e-mail": "📧",
        inbox_tray: "📥",
        outbox_tray: "📤",
        envelope_with_arrow: "📩",
        incoming_envelope: "📨",
        mailbox: "📫",
        mailbox_closed: "📪",
        postbox: "📮",
        package: "📦",
        memo: "📝",
        page_facing_up: "📄",
        page_with_curl: "📃",
        bookmark_tabs: "📑",
        bar_chart: "📊",
        chart_with_upwards_trend: "📈",
        chart_with_downwards_trend: "📉",
        scroll: "📜",
        clipboard: "📋",
        date: "📅",
        calendar: "📆",
        card_index: "📇",
        file_folder: "📁",
        open_file_folder: "📂",
        pushpin: "📌",
        paperclip: "📎",
        straight_ruler: "📏",
        triangular_ruler: "📐",
        closed_book: "📕",
        green_book: "📗",
        blue_book: "📘",
        orange_book: "📙",
        notebook: "📓",
        notebook_with_decorative_cover: "📔",
        ledger: "📒",
        books: "📚",
        book: "📖",
        bookmark: "🔖",
        name_badge: "📛",
        newspaper: "📰",
        art: "🎨",
        clapper: "🎬",
        microphone: "🎤",
        headphones: "🎧",
        musical_score: "🎼",
        musical_note: "🎵",
        notes: "🎶",
        musical_keyboard: "🎹",
        violin: "🎻",
        trumpet: "🎺",
        saxophone: "🎷",
        guitar: "🎸",
        space_invader: "👾",
        video_game: "🎮",
        black_joker: "🃏",
        flower_playing_cards: "🎴",
        mahjong: "🀄",
        game_die: "🎲",
        dart: "🎯",
        football: "🏈",
        basketball: "🏀",
        soccer: "⚽",
        baseball: "⚾",
        tennis: "🎾",
        "8ball": "🎱",
        bowling: "🎳",
        golf: "⛳",
        checkered_flag: "🏁",
        trophy: "🏆",
        ski: "🎿",
        snowboarder: "🏂",
        swimmer: "🏊",
        surfer: "🏄",
        fishing_pole_and_fish: "🎣",
        tea: "🍵",
        sake: "🍶",
        beer: "🍺",
        beers: "🍻",
        cocktail: "🍸",
        tropical_drink: "🍹",
        wine_glass: "🍷",
        fork_and_knife: "🍴",
        pizza: "🍕",
        hamburger: "🍔",
        fries: "🍟",
        poultry_leg: "🍗",
        meat_on_bone: "🍖",
        spaghetti: "🍝",
        curry: "🍛",
        fried_shrimp: "🍤",
        bento: "🍱",
        sushi: "🍣",
        fish_cake: "🍥",
        rice_ball: "🍙",
        rice_cracker: "🍘",
        rice: "🍚",
        ramen: "🍜",
        stew: "🍲",
        oden: "🍢",
        dango: "🍡",
        egg: "🍳",
        bread: "🍞",
        doughnut: "🍩",
        custard: "🍮",
        icecream: "🍦",
        ice_cream: "🍨",
        shaved_ice: "🍧",
        birthday: "🎂",
        cake: "🍰",
        cookie: "🍪",
        chocolate_bar: "🍫",
        candy: "🍬",
        lollipop: "🍭",
        honey_pot: "🍯",
        apple: "🍎",
        green_apple: "🍏",
        tangerine: "🍊",
        cherries: "🍒",
        grapes: "🍇",
        watermelon: "🍉",
        strawberry: "🍓",
        peach: "🍑",
        melon: "🍈",
        banana: "🍌",
        pineapple: "🍍",
        sweet_potato: "🍠",
        eggplant: "🍆",
        tomato: "🍅",
        corn: "🌽",
      },
      Places: {
        house: "🏠",
        house_with_garden: "🏡",
        school: "🏫",
        office: "🏢",
        post_office: "🏣",
        hospital: "🏥",
        bank: "🏦",
        convenience_store: "🏪",
        love_hotel: "🏩",
        hotel: "🏨",
        wedding: "💒",
        church: "⛪",
        department_store: "🏬",
        city_sunrise: "🌇",
        city_sunset: "🌆",
        japanese_castle: "🏯",
        european_castle: "🏰",
        tent: "⛺",
        factory: "🏭",
        tokyo_tower: "🗼",
        japan: "🗾",
        mount_fuji: "🗻",
        sunrise_over_mountains: "🌄",
        sunrise: "🌅",
        night_with_stars: "🌃",
        statue_of_liberty: "🗽",
        bridge_at_night: "🌉",
        carousel_horse: "🎠",
        ferris_wheel: "🎡",
        fountain: "⛲",
        roller_coaster: "🎢",
        ship: "🚢",
        boat: "⛵",
        speedboat: "🚤",
        rocket: "🚀",
        seat: "💺",
        station: "🚉",
        bullettrain_side: "🚄",
        bullettrain_front: "🚅",
        metro: "🚇",
        railway_car: "🚃",
        bus: "🚌",
        blue_car: "🚙",
        car: "🚗",
        taxi: "🚕",
        truck: "🚚",
        rotating_light: "🚨",
        police_car: "🚓",
        fire_engine: "🚒",
        ambulance: "🚑",
        bike: "🚲",
        barber: "💈",
        busstop: "🚏",
        ticket: "🎫",
        traffic_light: "🚥",
        construction: "🚧",
        beginner: "🔰",
        fuelpump: "⛽",
        izakaya_lantern: "🏮",
        slot_machine: "🎰",
        moyai: "🗿",
        circus_tent: "🎪",
        performing_arts: "🎭",
        round_pushpin: "📍",
        triangular_flag_on_post: "🚩",
      },
      Symbols: {
        100: "💯",
        1234: "🔢",
        keycap_ten: "🔟",
        symbols: "🔣",
        capital_abcd: "🔠",
        abcd: "🔡",
        abc: "🔤",
        arrow_up_small: "🔼",
        arrow_down_small: "🔽",
        rewind: "⏪",
        fast_forward: "⏩",
        arrow_double_up: "⏫",
        arrow_double_down: "⏬",
        ok: "🆗",
        new: "🆕",
        up: "🆙",
        cool: "🆒",
        free: "🆓",
        ng: "🆖",
        signal_strength: "📶",
        cinema: "🎦",
        koko: "🈁",
        u6307: "🈯",
        u7a7a: "🈳",
        u6e80: "🈵",
        u5408: "🈴",
        u7981: "🈲",
        ideograph_advantage: "🉐",
        u5272: "🈹",
        u55b6: "🈺",
        u6709: "🈶",
        u7121: "🈚",
        restroom: "🚻",
        mens: "🚹",
        womens: "🚺",
        baby_symbol: "🚼",
        wc: "🚾",
        no_smoking: "🚭",
        u7533: "🈸",
        accept: "🉑",
        cl: "🆑",
        sos: "🆘",
        id: "🆔",
        no_entry_sign: "🚫",
        underage: "🔞",
        no_entry: "⛔",
        negative_squared_cross_mark: "❎",
        white_check_mark: "✅",
        heart_decoration: "💟",
        vs: "🆚",
        vibration_mode: "📳",
        mobile_phone_off: "📴",
        ab: "🆎",
        diamond_shape_with_a_dot_inside: "💠",
        ophiuchus: "⛎",
        six_pointed_star: "🔯",
        atm: "🏧",
        chart: "💹",
        heavy_dollar_sign: "💲",
        currency_exchange: "💱",
        x: "❌",
        exclamation: "❗",
        question: "❓",
        grey_exclamation: "❕",
        grey_question: "❔",
        o: "⭕",
        top: "🔝",
        end: "🔚",
        back: "🔙",
        on: "🔛",
        soon: "🔜",
        arrows_clockwise: "🔃",
        clock12: "🕛",
        clock1: "🕐",
        clock2: "🕑",
        clock3: "🕒",
        clock4: "🕓",
        clock5: "🕔",
        clock6: "🕕",
        clock7: "🕖",
        clock8: "🕗",
        clock9: "🕘",
        clock10: "🕙",
        clock11: "🕚",
        heavy_plus_sign: "➕",
        heavy_minus_sign: "➖",
        heavy_division_sign: "➗",
        white_flower: "💮",
        radio_button: "🔘",
        link: "🔗",
        curly_loop: "➰",
        trident: "🔱",
        small_red_triangle: "🔺",
        black_square_button: "🔲",
        white_square_button: "🔳",
        red_circle: "🔴",
        large_blue_circle: "🔵",
        small_red_triangle_down: "🔻",
        white_large_square: "⬜",
        black_large_square: "⬛",
        large_orange_diamond: "🔶",
        large_blue_diamond: "🔷",
        small_orange_diamond: "🔸",
        small_blue_diamond: "🔹",
      },
    },
    wWidth: 0,
    servicesTypes: ["hour", "day", "week", "month", "service", "download"],
    counterW: 0,
    stock: "",
    maxPerPurchase: "",
    selectedType: "",
    windowY: 0,
    outlined: true,
    valueWhenIsEmpty: "",
    options: {
      locale: "pt-PT",
      prefix: " € ",
      suffix: "",
      length: 9,
      precision: 0,
    },
    priceJob: "",
    priceSuggestion: "",
    title: "",
    description: "",
    categories: [],
    setSelectedCategory: {},
    showAllPostsModal: false,
  }),
  watch: {
    chatId: {
      async handler() {
        if (!this.chatId) return;

        this.postsPayperview = [];

        this.postsPayperview = await this.$http
          .get("/api/v1/getPostsPayperview/")
          .then((res) => res.data);
        this.postsPayperview.unshift({
          id: -1,
          new: true,
          files: [
            {
              file: "Create new payperview",
              type: "image",
            },
          ],
        });
        // console.log(this.postsPayperview)
        this.showExistingPayperviews = true;
      },
      immediate: true,
    },
    files() {
      if (this.files.length == 0) {
        this.urls = [];
      }
    },
  },
};
</script>

<style lang="scss">
.small-font-input .v-label {
  font-size: 10px;
}

.nav-dialog {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
  background-color: white;

  & > .close-dialog {
    font-size: 20px;
    cursor: pointer;
    grid-column: 1;
  }

  & > .close-dialog-2 {
    font-size: 20px;
    cursor: pointer;
    grid-column: 12;
    text-align: right;
  }

  & > .header-dialog {
    text-align: center;
    grid-column: 2 / span 10;

    & > small {
      font-size: 12px;
    }
  }
}

.v-radio .v-input--selection-controls__input {
  margin-right: 1em;
}

.upload-post-icons {
  display: flex;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 20px;
  padding-left: 8px;
  padding-right: 8px;
}

.drop-files-here {
  width: 100%;
  height: 100%;
  max-height: 300px;
  border: 2px dashed #007bff;
  display: flex;
  align-items: center;
  cursor: pointer;
  @media (min-width: 768px) {
    max-height: 550px;
  }
}

.blurred::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  /* apply the blur */
  pointer-events: none;
  /* make the overlay click-through */
}

.blurred {
  position: absolute;
  z-index: 0;
  background: no-repeat center center;
  background-size: cover;
  height: 300px;
  max-height: 300px;

  @media (min-width: 768px) {
    height: 550px;
    max-height: 550px;
  }
}

.other-files-post {
  display: flex;
  width: 100%;
  height: 300px;
  text-align: center;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    height: 550px;
    max-height: 550px;
  }
}

.preview-image-creating-post {
  width: 100%;
  max-height: 300px;
  object-fit: contain;
  z-index: 1;
  position: absolute;
  top: 0;
  @media (min-width: 768px) {
    max-height: 550px;
  }
}

.preview-video-creating-post {
  max-height: 300px;
  @media (min-width: 768px) {
    height: 550px;
    max-height: 550px;
  }
}

.feed-carousel-create-post {
  height: 300px;
  @media (min-width: 768px) {
    height: 550px;
  }
}

.feed-carousel-create-post__slide {
  height: 300px;
  @media (min-width: 768px) {
    height: 550px;
  }
}
</style>
