<!-- GRID SYSTEM LIKE INSTAGRAM EXPLORE -->
<template>
  <div class="grid-feed">
    <div v-for="(row,rowIndex) in rows" :key="row" class="grid-feed__content">
      <div v-for="(file, fileIndex) in getFilesPerRow(row)" :key="fileIndex " class="grid-feed__content__item" @click="onlyBlurFile(file) ? null : viewFileOnLightbox(file.postId)" :class="{'horizontal-left': (rowIndex%2!=0 && (fileIndex == 0)), 'horizontal-right': (rowIndex%2==0 && (fileIndex == 2))}">
        <!-- <template v-if="loadedImages.hasOwnProperty(rowIndex)">
          <VueSkeletonLoader
            :color="skeletonColors.backgroundColor"
            :wave-color="skeletonColors.waveColor"
            type="rect"
            width="100%"
            height="100%"
            animation="fade"
            style="position: absolute;"
            v-if="!loadedImages[rowIndex][fileIndex]"
          />
          <div :key="hotReload" >
              {{ loadedImages[rowIndex][fileIndex] }}
          </div>
        </template> -->
        <!-- @load="onImageLoad(rowIndex, fileIndex)" -->
        <img loading="lazy" :src="`${file.medium}`" v-if="isImage(file.medium||'')" @error="onImageLoadError" >
        <div v-if="onlyBlurFile(file)">
          <div class="subscriber-only-medias" :style="{
            background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${file.blur})`,
          }">
            <i class="fa-solid fa-lock"></i>
            <v-btn class="btn-sider-money" style="color: white !important;" @click="openPayperview(file)" v-if="file.pay_per_view && !file.paid">
              Pay {{ file.pay_per_view }}$
            </v-btn>
            <v-btn class="btn-sider-money" style="color: white !important;" @click="$emit('openSubscribePopup')" v-else-if="!file.pay_per_view">
              Subscribe
            </v-btn>
            <small>To see this user post</small>
          </div>
        </div>
        <div class="grid-feed__video" v-else-if="isVideo(file.video||'')" :style="`background-image: url('${file.thumbnail}')`">
          <!-- <button class="grid-feed__video__icon">
            <i class="fas fa-play"></i>
          </button> -->
        </div>
        <span v-else-if="file.file" class="grid-feed__file text-white" :alt="JSON.stringify(file) ">
          {{file.file}}
        </span>
        <i class="grid-feed__content__item__icon-notation fa-sharp fa-solid"
        :class="isImage(file.medium||'') ? file.filesLenght > 1 ? 'fa-images' : '' : (isVideo(file.video||'') ? 'fa-video' : '')"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>

import utilsMixin from '@/mixins/utils';



// import VueSkeletonLoader from 'skeleton-loader-vue';


export default {
  // components: {
  //   VueSkeletonLoader
  // },
  computed: {
    syncLoadingImages(){
      return this.loadedImages;
    },
    skeletonColors() {
      let waveColor = 'rgba(255, 255, 255, 0.5)';
      let backgroundColor = 'rgba(0,0,0,0.12)';
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        waveColor = '#ff7f00';
        backgroundColor = 'rgba(255, 255, 255, 0.12)';
      } else {
        waveColor = 'rgba(255, 255, 255, 0.5)';
        backgroundColor = 'rgba(0,0,0,0.12)';
      }
      return {
        waveColor,
        backgroundColor,
      };
    }
  },
  mixins: [utilsMixin],
  mounted(){
    this.rows = Math.ceil(this.postFiles().length / 5);
  },
  methods: {
    onImageLoad(rowIndex, fileIndex){
      this.loadedImages[rowIndex][fileIndex] = true;
      this.hotReload++;
    },
    openPayperview(file){
      if(!this.me.id) return this.$router.push('/');
      this.$emit('openPayperviewPopup', this.posts.find(post => post.id == file.postId));
    },
    onlyBlurFile(file){
      return file.blur && !file.medium && !file.video;
    },
    viewFileOnLightbox(postId){

      if(this.fromChat){
        const post = this.posts.find(post => post.id == postId);
        return this.$emit('onClick', post);
      }

      this.$store.commit('setPostId', postId);
      // this.$router.push({
      //   path: `/discover/${postId}`
      // }).catch(() => {})
    },
    getFilesPerRow(row){
      let files = this.postFiles();
      return files.slice((row - 1) * 5, row * 5);
    },
    onImageLoadError(e){

      let div = document.createElement('div');
      div.classList.add('grid-feed__not-loaded');

      div.innerHTML = `
        <div class="grid-feed__not-loaded__content">
          <div class="grid-feed__not-loaded__content__icon">
            <i class="fas fa-image"></i>
          </div>
          <div class="grid-feed__not-loaded__content__text">
            <span>Image not found</span>
          </div>
        </div>
      `;
      
      e.target.parentNode.replaceChild(div, e.target);
    },
    onVideoLoadError(e){
      console.log('ERROR HANDLER S-VIDEO', e)
      console.log('error loading file');
      let div = document.createElement('div');
      div.classList.add('grid-feed__not-loaded');

      div.innerHTML = `
        <div class="grid-feed__not-loaded__content">
          <div class="grid-feed__not-loaded__content__icon">
            <i class="fas fa-video"></i>
          </div>
          <div class="grid-feed__not-loaded__content__text">
            <span>Video not found</span>
          </div>
        </div>
      `;
      
      e.target.parentElement.parentElement.parentNode.replaceChild(div, e.target.parentElement.parentElement);
    },
    postFiles(){
      let files = this.posts.map(post => {
        let f = post.files.map(file => {
          return {
            ...file,
            owner: post.owner,
            postId: post.id,
            filesLenght: post.files.length,
            pay_per_view: post.pay_per_view,
            paid: post.paid,
          }
        })
        f.sort((a,b) => a.id - b.id)
        return f;
      }).map((v) => {
        if(v) return v[0];
      }).filter((f) => f)
      .filter((f) => !this.ignoredFiles.includes(f.folder));
      return files;
    },
    medias(){
    },
  },
  data: () => ({
    hotReload: 0,
    loadedImages: {},
    rows: 0,
    ignoredFiles: ['documents', 'audios', 'others'],
    viewPostPopup: false,
  }),
  props: {
    posts: {
      type: Array,
      default: () => []
    },
    fromChat: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    posts:{
      handler(){
        this.rows = Math.ceil(this.postFiles().length / 5);
        // for(let row = 0; row < this.rows; row++){
        //   if(!this.loadedImages[row]){
        //     this.loadedImages[row] = {};
        //   }
        //   const files = this.getFilesPerRow(row);
        //   for(let x = 0; x < files.length; x++) {
        //     if(typeof this.loadedImages[row][x] == 'undefined' && this.loadedImages[row][x] !== true && this.loadedImages[row][x] !== false){
        //       if(this.isVideo(files[x].video || '')){
        //         this.loadedImages[row][x] = true;
        //       }else {
        //         this.loadedImages[row][x] = false;
        //       }
        //     }
        //   }
        // }
        // console.log(this.loadedImages)
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.grid-feed{
  display: grid;
  row-gap: 1px;
  padding-top: 1rem;

  &__content{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    
    &__item{
      width: 100%;
      height: calc(100vw / 3);
      max-height: 250px;
      position: relative;
      cursor: pointer;

      .video-container video {
        object-fit: cover;
      }

      img, video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }

      &.horizontal-right, &.horizontal-left{
        height: 100%;
        max-height: 501px;

        img {
          height: 100%;
        }
      }
      
      &.horizontal-left{
        grid-row: 1 / 3;
      }

      &.horizontal-right{
        grid-column: 3;
        grid-row: 1 / 3;
      }

      

      &__icon-notation{
        position: absolute;
        z-index: 1;
        top: .5rem;
        right: .5rem;
        padding: 0.5rem;
        text-shadow: 0 0 5px black;
        color: white;
        font-size: 1.2rem;
      }
    }

  }

  &__file {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border: 2px dashed #007bff;
    padding: .5rem;
  }

  &__video{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    background-size: cover;

    &__icon{
      width: 64px;
      height: 64px;
      font-size: 2rem;
      color: white;
      background-color: rgba(0,0,0,0.5);
      border-radius: 50%;
      cursor: pointer;
      
      transition: background-color 0.2s ease-in-out;

      &:hover{
        background-color: #007bff;
      }
    }

  }

  .grid-feed__not-loaded{
    height: 100%;
    width: 100%;
    background-size: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 20px;

    @media (prefers-color-scheme: dark) {
      background-image: url("/assets/images/processing-post-dark.png");
      color: var(--color-our-white);
    }

    @media (prefers-color-scheme: light) {
      background-image: url("/assets/images/processing-post-light.png");
      color: black;
    }

  }
}



.subscriber-only-medias {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .5);
    background-size: cover !important;
    width: 100%;
    height: 100%;

    i {
      font-size: 50px;
      color: #fff;
    }

    .btn-sider-money {
      margin-top: 16px;
      box-shadow: none;
      font-weight: bolder;
    }

    small {
      color: var(--color-our-white);
      font-size: 16px;
      margin-top: 16px;
    }

    @media (max-width: 550px) {
      .btn-sider-money {
        transform: scale(1);
        margin-top: 8px;
      }

      i {
        font-size: 30px;
      }

      small {
        display: none;
      }
    }

    @media (max-width: 400px) {


      .btn-sider-money {
        transform: scale(.8);
        margin-top: 4px;
      }

      i {
        font-size: 20px;
      }

      small {
        display: none;
      }
    }
  }
</style>