<template>
  <div style="padding: 35px; max-width: 512px; width: 100vw">
    <!-- <div
      id="g_id_onload"
      data-client_id="910653381438-aoifduc0ndbmhsr9lrtqhdmp6tvcrkem.apps.googleusercontent.com"
      response_type="code"
      scope="openid profile email"
      :data-login_uri="`${getBaseURL}/api/v1/auth/google`"
      :data-godFatherCode="godFatherCode"
    ></div> -->
    <center style="padding-top: 36px">
      <s-logo />
      <br />
      <v-text-field
        class="inputs-login"
        filled
        style="margin-top: 5em; font-size: 14px; color: #007bff"
        placeholder="E-mail"
        prepend-inner-icon="mdi-email-outline"
        v-model="$store.state.authEmail"
      >
      </v-text-field>
      <v-text-field
        class="inputs-login"
        type="password"
        filled
        prepend-inner-icon="mdi-key"
        style="font-size: 14px; color: #007bff"
        placeholder="Password"
        v-model="password"
        @keypress.enter="complete"
      >
      </v-text-field>

      <v-btn
        @click="complete"
        :loading="loading"
        style="width: 100%; margin: auto; color: white; text-transform: none"
        color="#007BFF"
        x-large
        elevation="0"
      >
        Login
      </v-btn>

      <div
        style="
          display: grid;
          grid-template-columns: 1fr;
          margin-bottom: calc(100px - 41.59px);
        "
      >
        <div style="display: grid; place-items: center; padding: 0.8em">
          <span
            style="font-size: 13px; color: #999999; cursor: pointer"
            @click="$router.push('/forgot-password')"
            >Forgot password</span
          >
        </div>
      </div>
      <span style="font-size: 14px; color: #999">Don't have an account?</span>
      <br />
      <h3
        class="text-gradient"
        style="margin-top: 0.5em; font-size: 14px; cursor: pointer"
        @click="$router.push('/register')"
      >
        Sign Up Sider.Money
      </h3>
      <br /><br /><br />
    </center>
  </div>
</template>

<script>
import godFatherMixin from "@/mixins/godFather";
import googleMixin from "@/mixins/google";
export default {
  mixins: [godFatherMixin, googleMixin],
  data: () => ({
    email: "",
    password: "",
    rememberMe: false,
    loading: false,
  }),
  mounted() {
    if (this.$route.query.error) {
      this.$swal({
        title: "Error",
        text: this.$route.query.error,
        icon: "error",
        showConfirmButton: false,
        showCancelButton: true,
        timer: 2000,
      });

      this.$router.push("/login").catch(() => {});
    }
  },
  components: {
    // SlideUnlock
  },
  computed: {
    getBaseURL() {
      // return // process.env.VUE_APP_API_BASE_URL;
      return "http://localhost";
    },
    verifyInputs() {
      // use a regex to verify if email is valid
      if (
        /* eslint-disable-next-line */
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          this.$store.state.authEmail.trim()
        )
      ) {
        if (this.password) {
          return false;
        }
      }

      return true;
    },
  },
  methods: {
    complete() {
      this.loading = true;
      this.$http
        .post("/api/v1/auth", {
          email: this.$store.state.authEmail.toLowerCase().trim(),
          password: this.password,
          ip: 0,
        })
        .then((res) => {
          if (res.data.accessToken) {
            this.$store.commit("setToken", res.data.accessToken);
            this.me = this.decryptData(res.data.accessToken);
            window.location.href = "/" + this.me.slug;
          } else {
            this.$swal({
              title: "Error",
              text: "Invalid credentials",
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
            });
          }
          this.loading = false;
        })
        .catch((err) => {
          if (err && err.response && err.response.status == 403) {
            this.$swal({
              title: "Error",
              text: "Invalid credentials",
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
            });
          }
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.footer-out {
  background-color: white;
  width: 100%;
  bottom: 0%;
  left: 0;
  right: 0;
  margin: auto;
}

.text-gradient {
  background-color: #f3ec78;
  background-image: linear-gradient(
    45deg,
    rgba(21, 168, 216, 1),
    rgba(72, 79, 248, 1)
  );
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.slideunlock-text {
  font-size: 14px !important;
}

.slideunlock {
  background: linear-gradient(
    90deg,
    rgba(21, 168, 216, 1) 0%,
    rgba(72, 79, 248, 1) 100%
  ) !important;
}

.slideunlock .slideunlock-handler {
  background-color: rgba(255, 255, 255, 0.329) !important;
  color: white !important;
}

:root {
  --su-handler-bg: url("https://i.imgur.com/N0UOZJn.png");
  --su-color-progress-normal-bg: transparent;
  --su-color-progress-complete-bg: transparent;
}

.slideunlock .slideunlock-text {
  color: white !important;
}

.slideunlock .slideunlock-text:before {
  display: unset;
  padding-top: 1em;
}

.inputs-login input {
  padding-left: 10px !important;
}
</style>

<style scoped>
.v-label {
  font-size: 14px;
}
</style>
