<template>
  <div class="chat__channels">
    <div class="chat__channels__header">
      <div class="chat__channels__header__search">
        <input type="text" placeholder="Search" v-model="search" />
        <i class="fa-light fa-search"></i>
      </div>
      <div
        @click="sendNewMessage"
        style="
          padding-left: 8px;
          width: 50px;
          cursor: pointer;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          gap: 0.25rem;
        "
        :style="{
          opacity: subscribersCount > 0 ? 1 : 0.3,
          cursor: subscribersCount > 0 ? 'pointer' : 'no-drop',
        }"
      >
        <i class="fal fa-circle-plus" aria-hidden="true"></i>
        <small style="font-size: 8px">All fans</small>
      </div>
    </div>
    <div class="chat__channels__list">
      <div
        class="chat__channels__list__item"
        v-for="(channel, channelIndex) in channelsFiltred"
        :key="'channel-' + channel.id + '-' + channelIndex"
        @click="$emit('openChannel', channel, channelIndex)"
        :class="{
          active:
            $store.state.channelOpen.id == channel.id &&
            $store.state.channelOpen.id != null,
        }"
      >
        <div>
          <!-- class="chat__channels__list__item__avatar"  -->
          <div
            class="contact-avatar"
            :class="{
              ['online-border']:
                channel.users.length > 0
                  ? $store.state.userSocketIds.find(
                      (user) => user.id == channel.users[0].id
                    )
                  : 0,
            }"
            :style="`background-image: url('${
              channel.users.length > 0
                ? channel.users[0].avatar || $default.avatar
                : $default.avatar
            }');`"
          ></div>
          <!-- <v-img :src="channel.avatar||$default.avatar" alt="" v-if="channel.users.length > 1" :class="{['online-border']: userSocketIds.find(id => id == channel.users[0].id)}" style="border-radius: 50%;border: 2px solid transparent;"/>
          <v-img :src="channel.users[0].avatar||$default.avatar" alt="" v-else :class="{['online-border']: userSocketIds.find(id => id == channel.users[0].id)}" style="border-radius: 50%;border: 2px solid transparent;"/> -->
        </div>
        <div class="chat__channels__list__item__info">
          <div class="chat__channels__list__item__info__name">
            <span v-if="channel.users.length > 1">
              {{ channel.name }}
            </span>
            <span v-else>
              {{ channel.users[0].name }}
              <svg
                v-if="channel.users[0].verified_badge"
                fill="#007BFF"
                style="margin-bottom: -2px"
                width="17"
                viewBox="0 0 24 24"
                aria-label="Conta verificada"
                role="img"
                class="r-1cvl2hr r-4qtqp9 r-yyyyoo r-1xvli5t r-f9ja8p r-og9te1 r-bnwqim r-1plcrui r-lrvibr"
              >
                <g>
                  <path
                    d="M22.5 12.5c0-1.58-.875-2.95-2.148-3.6.154-.435.238-.905.238-1.4 0-2.21-1.71-3.998-3.818-3.998-.47 0-.92.084-1.336.25C14.818 2.415 13.51 1.5 12 1.5s-2.816.917-3.437 2.25c-.415-.165-.866-.25-1.336-.25-2.11 0-3.818 1.79-3.818 4 0 .494.083.964.237 1.4-1.272.65-2.147 2.018-2.147 3.6 0 1.495.782 2.798 1.942 3.486-.02.17-.032.34-.032.514 0 2.21 1.708 4 3.818 4 .47 0 .92-.086 1.335-.25.62 1.334 1.926 2.25 3.437 2.25 1.512 0 2.818-.916 3.437-2.25.415.163.865.248 1.336.248 2.11 0 3.818-1.79 3.818-4 0-.174-.012-.344-.033-.513 1.158-.687 1.943-1.99 1.943-3.484zm-6.616-3.334l-4.334 6.5c-.145.217-.382.334-.625.334-.143 0-.288-.04-.416-.126l-.115-.094-2.415-2.415c-.293-.293-.293-.768 0-1.06s.768-.294 1.06 0l1.77 1.767 3.825-5.74c.23-.345.696-.436 1.04-.207.346.23.44.696.21 1.04z"
                  ></path>
                </g>
              </svg>
              <svg
                v-if="channel.users[0].ambassador"
                width="15"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style="transform: translate(0, 1px); margin-left: 4px"
              >
                <path
                  d="M21.001 11C21.001 9.42 20.126 8.05 18.853 7.4C19.007 6.965 19.091 6.495 19.091 6C19.091 3.79 17.381 2.002 15.273 2.002C14.803 2.002 14.353 2.086 13.937 2.252C13.319 0.915 12.011 0 10.501 0C8.991 0 7.685 0.917 7.064 2.25C6.649 2.085 6.198 2 5.728 2C3.618 2 1.91 3.79 1.91 6C1.91 6.494 1.993 6.964 2.147 7.4C0.875 8.05 0 9.418 0 11C0 12.495 0.782 13.798 1.942 14.486C1.922 14.656 1.91 14.826 1.91 15C1.91 17.21 3.618 19 5.728 19C6.198 19 6.648 18.914 7.063 18.75C7.683 20.084 8.989 21 10.5 21C12.012 21 13.318 20.084 13.937 18.75C14.352 18.913 14.802 18.998 15.273 18.998C17.383 18.998 19.091 17.208 19.091 14.998C19.091 14.824 19.079 14.654 19.058 14.485C20.216 13.798 21.001 12.495 21.001 11.001V11ZM14.385 7.666L10.051 14.166C9.906 14.383 9.669 14.5 9.426 14.5C9.283 14.5 9.138 14.46 9.01 14.374L8.895 14.28L6.48 11.865C6.187 11.572 6.187 11.097 6.48 10.805C6.773 10.513 7.248 10.511 7.54 10.805L9.31 12.572L13.135 6.832C13.365 6.487 13.831 6.396 14.175 6.625C14.521 6.855 14.615 7.321 14.385 7.665V7.666Z"
                  fill="#007BFF"
                />
                <path
                  d="M20 10.8571C20 9.72857 19.3334 8.75 18.3635 8.28571C18.4808 7.975 18.5448 7.63929 18.5448 7.28571C18.5448 5.70714 17.242 4.43 15.636 4.43C15.2779 4.43 14.9351 4.49 14.6182 4.60857C14.1473 3.65357 13.1508 3 12.0004 3C10.85 3 9.85496 3.655 9.38184 4.60714C9.06566 4.48929 8.72206 4.42857 8.36398 4.42857C6.75644 4.42857 5.45517 5.70714 5.45517 7.28571C5.45517 7.63857 5.5184 7.97429 5.63573 8.28571C4.66663 8.75 4 9.72714 4 10.8571C4 11.925 4.59578 12.8557 5.47955 13.3471C5.46431 13.4686 5.45517 13.59 5.45517 13.7143C5.45517 15.2929 6.75644 16.5714 8.36398 16.5714C8.72206 16.5714 9.0649 16.51 9.38108 16.3929C9.85344 17.3457 10.8484 18 11.9996 18C13.1516 18 14.1466 17.3457 14.6182 16.3929C14.9343 16.5093 15.2772 16.57 15.636 16.57C17.2436 16.57 18.5448 15.2914 18.5448 13.7129C18.5448 13.5886 18.5357 13.4671 18.5197 13.3464C19.4019 12.8557 20 11.925 20 10.8579V10.8571ZM14.9595 8.47571L11.6575 13.1186C11.5471 13.2736 11.3665 13.3571 11.1814 13.3571C11.0724 13.3571 10.962 13.3286 10.8644 13.2671L10.7768 13.2L8.93691 11.475C8.71368 11.2657 8.71368 10.9264 8.93691 10.7179C9.16014 10.5093 9.52202 10.5079 9.74449 10.7179L11.093 11.98L14.0071 7.88C14.1824 7.63357 14.5374 7.56857 14.7995 7.73214C15.0631 7.89643 15.1347 8.22929 14.9595 8.475V8.47571Z"
                  fill="#007BFF"
                />
                <path
                  d="M17 9.85714C17 8.72857 16.3334 7.75 15.3635 7.28571C15.4808 6.975 15.5448 6.63929 15.5448 6.28571C15.5448 4.70714 14.242 3.43 12.636 3.43C12.2779 3.43 11.9351 3.49 11.6182 3.60857C11.1473 2.65357 10.1508 2 9.00038 2C7.84996 2 6.85496 2.655 6.38184 3.60714C6.06566 3.48929 5.72206 3.42857 5.36398 3.42857C3.75644 3.42857 2.45517 4.70714 2.45517 6.28571C2.45517 6.63857 2.5184 6.97429 2.63573 7.28571C1.66663 7.75 1 8.72714 1 9.85714C1 10.925 1.59578 11.8557 2.47955 12.3471C2.46431 12.4686 2.45517 12.59 2.45517 12.7143C2.45517 14.2929 3.75644 15.5714 5.36398 15.5714C5.72206 15.5714 6.0649 15.51 6.38108 15.3929C6.85344 16.3457 7.84844 17 8.99962 17C10.1516 17 11.1466 16.3457 11.6182 15.3929C11.9343 15.5093 12.2772 15.57 12.636 15.57C14.2436 15.57 15.5448 14.2914 15.5448 12.7129C15.5448 12.5886 15.5357 12.4671 15.5197 12.3464C16.4019 11.8557 17 10.925 17 9.85786V9.85714ZM11.9595 7.47571L8.65754 12.1186C8.54707 12.2736 8.36651 12.3571 8.18137 12.3571C8.07243 12.3571 7.96195 12.3286 7.86444 12.2671L7.77682 12.2L5.93691 10.475C5.71368 10.2657 5.71368 9.92643 5.93691 9.71786C6.16014 9.50929 6.52202 9.50786 6.74449 9.71786L8.093 10.98L11.0071 6.88C11.1824 6.63357 11.5374 6.56857 11.7995 6.73214C12.0631 6.89643 12.1347 7.22929 11.9595 7.475V7.47571Z"
                  fill="#007BFF"
                />
                <path
                  d="M10.939 5.029V5.77C11.5803 5.796 12.135 5.887 12.603 6.043C13.071 6.19033 13.305 6.41567 13.305 6.719C13.305 6.98767 13.214 7.343 13.032 7.785C12.8587 8.227 12.7373 8.461 12.668 8.487C11.94 8.18367 11.3637 8.01467 10.939 7.98V9.449C12.759 9.51833 13.669 10.3937 13.669 12.075C13.669 12.8637 13.4437 13.5397 12.993 14.103C12.5423 14.6663 11.8577 14.9957 10.939 15.091V15.988C10.939 16.144 10.8913 16.248 10.796 16.3C10.7093 16.352 10.6357 16.378 10.575 16.378L10.393 16.326C10.2717 16.2567 10.211 16.144 10.211 15.988V15.13C9.483 15.1127 8.83733 14.9827 8.274 14.74C7.71933 14.4973 7.442 14.1897 7.442 13.817C7.442 13.3317 7.58933 12.842 7.884 12.348C7.91 12.296 7.94467 12.2397 7.988 12.179C8.69 12.6037 9.431 12.842 10.211 12.894V11.464C8.365 11.3513 7.442 10.4067 7.442 8.63C7.442 7.85 7.689 7.20867 8.183 6.706C8.677 6.20333 9.353 5.9 10.211 5.796V5.029C10.211 4.873 10.2543 4.769 10.341 4.717C10.4363 4.665 10.5143 4.639 10.575 4.639L10.757 4.691C10.8783 4.76033 10.939 4.873 10.939 5.029ZM11.862 12.01C11.862 11.8107 11.7927 11.6763 11.654 11.607C11.524 11.529 11.2857 11.4813 10.939 11.464V12.868C11.5543 12.764 11.862 12.478 11.862 12.01ZM9.418 8.773C9.418 9.137 9.68233 9.34933 10.211 9.41V7.993C9.68233 8.07967 9.418 8.33967 9.418 8.773Z"
                  fill="white"
                />
              </svg>

              <p
                style="
                  font-size: 14px;
                  margin-left: 4px;
                  transform: translateY(-2px);
                  display: inline-block;
                "
                v-if="channel.users[0].investor"
              >
                <i
                  style=""
                  class="fas fa-chart-pie-simple-circle-dollar primary-color"
                ></i>
              </p>
            </span>
          </div>
          <div
            class="chat__channels__list__item__info__message"
            :key="'auto-update-' + updateLastMessage + '-' + channelIndex"
          >
            {{
              channel.lastMessage
                ? channel.lastMessage.message.length > 14
                  ? channel.lastMessage.message.substring(0, 14) + "..."
                  : channel.lastMessage.message
                : ""
            }}
            <span style="font-size: 12px" v-if="channel.lastMessage">
              {{ calculateTime(channel.lastMessage.createdAt) }}
              <div
                v-if="
                  channel.lastMessage.hasOwnProperty('read') &&
                  channel.lastMessage.read == false
                "
                class="new-message-blink-effect"
              ></div>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data: () => ({
    updateLastMessage: 0,
    search: "",
  }),
  props: {
    channels: {
      type: Array,
      default: () => [],
    },
    subscribersCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    channelsFiltred() {
      let channels = [
        ...this.$store.state.channels
          .filter((channel) => {
            if (channel.users.length > 1) {
              return channel.name
                .toLowerCase()
                .includes(this.search.toLowerCase());
            } else {
              return channel.users[0].name
                .toLowerCase()
                .includes(this.search.toLowerCase());
            }
          })
          .sort((a, b) => {
            if (!a.lastMessage || !b.lastMessage) return 1;
            let date =
              new Date(b.lastMessage.createdAt) -
              new Date(a.lastMessage.createdAt);
            return date;
          }),
      ];

      return channels;
    },
  },
  methods: {
    calculateTime(date) {
      return moment(date).fromNow();
    },
    sendNewMessage() {
      if (this.subscribersCount == 0) return;
      this.$emit("sendNewMessage");
    },
  },
};
</script>

<style>
.contact-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: cover;
  margin-right: 10px;
}

.online-border {
  border-color: #1ed760 !important;
}

.new-message-blink-effect {
  width: 10px;
  height: 10px;
  background-color: #007bff;
  border-radius: 50%;
  display: inline-block;

  animation: blink 1s infinite;

  @keyframes blink {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
}
</style>
