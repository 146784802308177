<template>
  <!-- style="height: 100vh;" -->
  <splide v-if="showSplide" :options="options" @splide:mounted="onSplideMounted">
    <splide-slide v-for="(file, fileIndex) in post.files" :key="fileIndex" class="multi-media"
      style="position: relative;">
      <!-- BLUR BACKGROUND -->
      <img class="multi-media__backdrop" v-if="isImage(file.medium||'')" :src="`${
        file.blur
      }`" />

      <PostImage v-if="isImage(file.medium||'')" :post="post" :file="file"></PostImage>

      <!-- VIDEO -->
      <!-- hide-controls keep-pause -->
      <s-video :autoPlay="autoPlay" class="multi-media__video" isSlider playsinline loop :windowWidth="windowWidth" style="width: 100%;
        height: 100%;
      " v-if="isVideo(file.video || '')" :blur="`${
          file.blur
        }`" :thumbnail="`${
                file.thumbnail
              }`" :src="`${
          file.video
        }`"></s-video>


    </splide-slide>
  </splide>
</template>

<script>
  import utilsMixin from '@/mixins/utils'
  import PostImage from './FileTypes/PostImage.vue';


  import "@splidejs/splide/dist/css/themes/splide-default.min.css";
  import {
    Splide,
    SplideSlide
  } from "@splidejs/vue-splide";

  export default {
    mixins: [utilsMixin],
    props: {
      post: {
        type: Object,
        default: () => {}
      },
      autoPlay: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      showSplide: false,
      commentsBottomSheet: false,
      options: {
        rewind: true,
        perPage: 1,
        lazyLoad: "nearby",

      },
      splideObject: {},
      windowWidth: window.innerWidth,
    }),
    components: {
      PostImage,
      Splide,
      SplideSlide
    },
    mounted() {
      setTimeout(() => {
        this.showSplide = true;
      }, 10);
    },
    methods: {

      onSplideMounted(splide) {
        this.splideObject = splide;
      },
    }
  }
</script>

<style lang="scss">
  .multi-media {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__backdrop {
      z-index: 0;
      width: 100%;
      position: absolute;
      height: 100%;
    }

    &__video {
      height: 100%;

      video {
        max-height: 100vh !important;
      }
    }
  }
</style>