<template>
    <div style="position: relative;overflow: auto;" id="dialogScrollView">
        <div class="nav-dialog"
            :style="windowY >= 60 ? 'position: sticky;top:-1.1em;left: 0;z-index: 1000;padding: 1em;padding-right: 0;' : ''">
            <span class="header-dialog">
                <span style="font-weight: bold;" class="text-white">
                    {{viewingJob.title}}
                </span>
                <br>
                <small>{{viewingJob.category.title}}</small>
            </span>
            <v-btn class="btn-fab" fab elevation="0" @click="close" small>
                <i class="close-dialog-2 fa fa-times" aria-hidden="true"></i>
            </v-btn>
        </div><br>
        <!-- <div style="position: absolute;width: 100%;border-bottom:1px solid #BBDDFF;left: 0;"></div> -->
        <center>
            <i :class="viewingJob.category.icon" style="font-size: 50px;color: #007BFF;"></i>
        </center>
        <transition name="component-fade" mode="out-in">
            <div style="position: absolute;left: 0;width: 100%;padding: 1em;">
                <v-row align="center">

                    <v-col cols="12" sm="12">
                        <h4>Sobre o Serviço</h4>
                        <br>
                        <span style="font-size: 14px;">{{viewingJob.description}}</span>
                        <br>
                    </v-col>

                    <br>

                </v-row>

                <br><br>
                <h4>Como funciona</h4>
                <v-card class="how-it-works-card" style="margin-top: 20px;background-color: rgba(74,74,74, .02);">

                    <v-timeline dense clipped>
                        <v-timeline-item style="align-items: center;font-size: 12px;">
                            <template v-slot:icon>
                                <span>1</span>
                            </template>
                            Você paga o serviço, e automaticamente a <span style="color: #007BFF;">SIDER.MONEY</span>
                            partilha as suas informações e do serviço com <span
                                style="color: #007BFF;">{{viewingJob.owner.name}}.</span>
                        </v-timeline-item>
                        <v-timeline-item style="align-items: center;font-size: 12px;">
                            <template v-slot:icon>
                                <span>2</span>
                            </template>
                            A <span style="color: #007BFF;">SIDER.MONEY</span> retem o seu dinheiro até <span
                                style="color: #007BFF;">{{viewingJob.owner.name}}</span> realizar o serviço de <span
                                style="color: #007BFF;">{{viewingJob.title}}</span>.
                        </v-timeline-item>
                        <v-timeline-item style="align-items: center;font-size: 12px;">
                            <template v-slot:icon>
                                <span>3</span>
                            </template>
                            Após a sua confirmação da realização do serviço, tranferimos o dinheiro para <span
                                style="color: #007BFF;">{{viewingJob.owner.name}}</span>.
                        </v-timeline-item>
                    </v-timeline>
                </v-card>

                <br><br><br><br><br>
            </div>
        </transition>

        <div class="bg-white" style="width: 100%;height: auto;padding: 1em;left:0;bottom:0;height: 90px;"
            :style="wWidth < 768 ? 'position:fixed;' : 'position:absolute;'">

            <v-btn fab elevation="0" @click="decreaseQuantity" :disabled="quantity <= 1">
                <i class="fa fa-minus" aria-hidden="true"></i>
            </v-btn>
            <v-btn fab elevation="0" style="background-color: transparent !important;color: black !important;" disabled>
                <div style="display:grid;" v-if="stock == null || stock > 0">
                    {{quantity}}
                    <small style="font-size: 8px;">
                        {{viewingJob.service_type}}{{quantity > 1 ? 's' : ''}}
                    </small>
                    <small style="font-size: 10px;">{{(viewingJob.price_service * quantity).toFixed(2)}}€</small>
                </div>
                <span v-else>
                    <small style="font-size: 8px;">
                        NO
                        <br>
                        STOCK
                    </small>
                </span>
            </v-btn>
            <v-btn fab elevation="0" @click="increaseQuantity" :disabled="cannotIncrease">
                <i class="fa fa-plus" aria-hidden="true"></i>
            </v-btn>
            <v-btn @click="pay" style="float:right;bottom: 3%;width: 170px;right:0;color: white;margin-top: 5px;"
                :disabled="cannotPurchase" color="#007BFF" x-large rounded elevation="0">
                <div style="display:grid;">
                    Pay
                    <br>
                    <small style="font-size: 10px;">Balance: {{balance.toFixed(2)}}</small>
                </div>

            </v-btn>
        </div>

    </div>
</template>

<script>
    export default {
        computed: {
            cannotPurchase() {
                return (this.balance < (this.viewingJob.price_service * this.quantity)) || this.stock == 0
            },
            cannotIncrease() {
                if (this.stock == null) return false;
                if (this.quantity < this.stock) return false;
                return true;
            }
        },
        props: {
            balance: {
                type: Number,
                default: () => (0)
            },
            stock: {
                type: Number,
                default: () => (0)
            },
            viewingJob: {
                type: Object,
                default: () => ({
                    category: {},
                    owner: {}
                })
            },
        },
        async beforeMount() {
            this.job = Object.assign({}, this.viewingJob);
            this.wWidth = window.innerWidth;
        },
        mounted() {
            document.getElementById('dialogScrollView').addEventListener('scroll', this.handleScroll);
            window.addEventListener('resize', this.wHandleResize);
        },
        beforeDestroy() {
            if (document.getElementById('dialogScrollView')) {
                document.getElementById('dialogScrollView').removeEventListener('scroll', this.handleScroll);
            }
            window.removeEventListener('resize', this.wHandleResize);
        },
        methods: {
            pay() {
                if (this.cannotPurchase) return;

                this.$http({
                    url: 'api/v1/pay',
                    method: 'POST',
                    data: {
                        quantity: this.quantity,
                        foreign_key: this.viewingJob.id,
                        sub_type: 'service'
                    }
                }).then((res) => {
                    if (res.status == 201) {
                        this.$swal({
                            icon: 'success',
                            title: 'Payment Successful',
                            showConfirmButton: false,
                            timer: 3000
                        });
                    } else {
                        this.$swal({
                            icon: 'error',
                            title: 'Payment Failed',
                            timer: 3000
                        });
                    }
                    this.quantity = 1;
                    this.$emit('close');
                }).catch((err) => {
                    this.quantity = 1;
                    console.log(err.response);
                })
            },
            decreaseQuantity() {
                if (this.quantity > 1) {
                    this.quantity--;
                }
            },
            increaseQuantity() {
                if (this.stock == null) return this.quantity++;
                if (this.quantity < this.stock) {
                    this.quantity++;
                }
            },
            wHandleResize() {
                this.wWidth = window.innerWidth;
            },
            handleScroll(e) {
                this.windowY = e.target.scrollTop;
            },
            close() {
                this.$emit('close');
            },
        },
        data: () => ({
            quantity: 1,
            windowY: 0,
            wWidth: 0,
        })
    }
</script>

<style lang="scss">
    .theme--light.v-timeline:before {
        background-color: transparent !important;
    }

    .how-it-works-card {
        box-shadow: 0px 0px 0px 0px !important;

        & .v-timeline-item__divider {
            display: grid;
            align-items: center;
            justify-content: left;
            padding-left: 1.5em;
            padding-right: 4em;
            min-width: 50px;
        }

        & .v-timeline-item__body {
            transform: translateX(-20px);
        }
    }
</style>