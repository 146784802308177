<template>

  <v-btn 
    style="width: 100%;margin: auto;color: #007BFF;text-transform: none;" outlined x-large elevation="0">
    <v-img style="width: 30px;max-width:30px;margin-right: 20px;"
      src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/1200px-Google_%22G%22_Logo.svg.png"
      lazy-src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/1200px-Google_%22G%22_Logo.svg.png" />
    <slot></slot>
  </v-btn>
</template>

<script>
  export default {
    methods: {
      loginWithGoogle() {
        this.$gAuth
          .signIn()
          .then(GoogleUser => {
            // on success do something
            var profile = GoogleUser.getBasicProfile();
            this.$request.authWallet(profile.getEmail(), 'Social Media')
              .then((res) => {
                this.dataUser = res;
                this.paycriticalUser = res.user;
                this.loginMenu();
              })
              .catch((err) => {
                if (err.response.data.error == "Invalid credentials") {
                  this.$session.set('registerUser', {
                    name: profile.getName(),
                    email: profile.getEmail()
                  });
                  this.$router.push('/register')
                }
              })
          })
          .catch(() => {
            this.$swal({
              title: 'Whoops!',
              icon: 'error',
              html: "User cancelled login or did not fully authorize."
            })
          })
      }
    }
  }
</script>