<template>
  <div
    style="position: relative; overflow: auto; height: 500px"
    class="interest-popup"
  >
    <br />
    <center>
      <h1>Interests</h1>
      <p>Pick 3 or more categories you'd like to see in discovery.</p>
      <br />
      <v-chip
        class="interest-chip"
        @click="selectCategory(category.id)"
        :class="{
          'interest-chip--selected': selectedCategories.includes(category.id),
        }"
        v-for="(category, categoryIndex) in categories"
        :key="categoryIndex"
        style="
          cursor: pointer;
          font-size: 16px;
          margin-right: 10px;
          margin-bottom: 10px;
        "
      >
        <i
          :class="[
            category.icon,
            'text-primary',
            {
              'interest-chip--selected': selectedCategories.includes(
                category.id
              ),
            },
          ]"
          style="margin-right: 5px"
        />{{ category.title }}
      </v-chip>
      <br />
      <br />
      <v-btn
        :disabled="selectedCategories.length < 3"
        class="welcome-card__buttons btn-sider-money"
        style="width: 90%; border-radius: 25px"
        elevation="0"
        @click="saveInterests"
      >
        Save
      </v-btn>
    </center>
    <br />
  </div>
</template>

<script>
export default {
  async beforeMount() {
    this.categories = await this.$http
      .get("/api/v1/getCategories")
      .then((res) => res.data);
  },
  methods: {
    saveInterests() {
      this.$http
        .post("/api/v1/defineInterests", {
          interests: this.selectedCategories,
        })
        .then(() => {
          localStorage.setItem("sider-asked-interests", true);
          if (this.$route.path == "/" + this.me.slug) {
            window.location.reload();
          }
          this.$router.push("/" + this.me.slug).catch(() => {});
        });
    },
    selectCategory(id) {
      if (this.selectedCategories.includes(id)) {
        this.selectedCategories = this.selectedCategories.filter(
          (item) => item != id
        );
      } else {
        this.selectedCategories.push(id);
      }
    },
  },
  data: () => ({
    selectedCategories: [],
    categories: [],
  }),
};
</script>

<style lang="scss">
button:disabled {
  opacity: 0.3;
}
</style>
