var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"interest-popup",staticStyle:{"position":"relative","overflow":"auto","height":"500px"}},[_c('br'),_c('center',[_c('h1',[_vm._v("Interests")]),_c('p',[_vm._v("Pick 3 or more categories you'd like to see in discovery.")]),_c('br'),_vm._l((_vm.categories),function(category,categoryIndex){return _c('v-chip',{key:categoryIndex,staticClass:"interest-chip",class:{
        'interest-chip--selected': _vm.selectedCategories.includes(category.id),
      },staticStyle:{"cursor":"pointer","font-size":"16px","margin-right":"10px","margin-bottom":"10px"},on:{"click":function($event){return _vm.selectCategory(category.id)}}},[_c('i',{class:[
          category.icon,
          'text-primary',
          {
            'interest-chip--selected': _vm.selectedCategories.includes(
              category.id
            ),
          } ],staticStyle:{"margin-right":"5px"}}),_vm._v(_vm._s(category.title)+" ")])}),_c('br'),_c('br'),_c('v-btn',{staticClass:"welcome-card__buttons btn-sider-money",staticStyle:{"width":"90%","border-radius":"25px"},attrs:{"disabled":_vm.selectedCategories.length < 3,"elevation":"0"},on:{"click":_vm.saveInterests}},[_vm._v(" Save ")])],2),_c('br')],1)}
var staticRenderFns = []

export { render, staticRenderFns }