<template>
  <div style="position: relative;overflow: auto;" :style="{
    height: thumbnail.url ? '500px' : '300px'
  }">
      <div class="profile-banner" :style="{
        height: thumbnail.url ? '400px' : '60px'
      }" >
          <v-img v-if="thumbnail.url" :src="`${thumbnail.url}`"  :lazy-src="`${user.banner}`" max-height="284px"
              style="position: absolute;left:0;width: 100%;height: 400px;
      border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
          </v-img>
          
          <v-btn class="btn-fab" fab small
          absolute
          right
              style="top:10%;" @click="$emit('close')">
              <i class="fa fa-times" style="font-size: 20px;" aria-hidden="true"></i>
          </v-btn>
      </div>

      <div style="padding-left: 3em;padding-top: 1em;">

          <v-list-item style="padding: 0em;" v-if="post.files && post.files.filter((f) => f.folder == 'images').length > 0">
              <v-list-item-content >
                  <v-list-item-title  class="text-white">
                      <i class="fal fa-image text-primary"></i>
                      {{ post.files.filter((f) => f.folder == 'images').length }} images
                  </v-list-item-title>
              </v-list-item-content>
          </v-list-item>
          <v-list-item style="padding: 0em;" v-if="post.files && post.files.filter((f) => f.folder == 'videos').length > 0">
              <v-list-item-content >
                  <v-list-item-title  class="text-white">
                      <i class="fal fa-image text-primary"></i>
                      {{ post.files.filter((f) => f.folder == 'videos').length }} videos
                  </v-list-item-title>
              </v-list-item-content>
          </v-list-item>
          <v-list-item style="padding: 0em;" v-if="post.files && post.files.filter((f) => f.folder == 'audios').length > 0">
              <v-list-item-content >
                  <v-list-item-title  class="text-white">
                      <i class="fal fa-image text-primary"></i>
                      {{ post.files.filter((f) => f.folder == 'audios').length }} audios
                  </v-list-item-title>
              </v-list-item-content>
          </v-list-item>
          <v-list-item style="padding: 0em;" v-if="post.files && post.files.filter((f) => f.folder == 'documents').length > 0">
              <v-list-item-content >
                  <v-list-item-title  class="text-white">
                      <i class="fal fa-image text-primary"></i>
                      {{ post.files.filter((f) => f.folder == 'documents').length }} audios
                  </v-list-item-title>
              </v-list-item-content>
          </v-list-item>
          <v-list-item style="padding: 0em;">
              
              <v-list-item-content>
                  
                  <v-list-item-title class="text-white">
                      <i class="fa fa-check text-primary" aria-hidden="true"></i>
                      Pay one time, and get access to all files
                  </v-list-item-title>
              </v-list-item-content>
          </v-list-item>
      </div>
      <br>
      <center>
          
      <v-btn @click="buyPost" style="color:#007BFF;background-color:#007BFF;color: white;width: 90%;" elevation="0" rounded >
          <div style="display:grid;">
              Unlock Post
              <small style="font-size: 10px;">
                  {{post.pay_per_view}}$
              </small>
          </div>
      </v-btn>
      <br>
      <p  style="margin-top: 8px;"><i class="text-gray">Balance {{ balance.toFixed(2) }}$</i></p>
      </center>
      <br>
  </div>
</template>

<script>
  import utilsMixin from '@/mixins/utils';
  export default {
      mixins: [utilsMixin],
      props: {
          user: {
              type: Object,
              default: () => ({})
          },
          balance: {
              type: Number,
              default: 0
          },
            post: {
                type: Object,
                default: () => ({})
            },
      },
      computed: {
        thumbnail(){
          if(!this.post.files) return {};
          let file = this.post.files.find((file) => (file.thumbnail || file.blur));
          return {
            ...file,
            url: file?.thumbnail || file?.blur || ""
          }
        }
      },
      methods: {
        buyPost() {
              if(!this.me.id) return;
              if (this.balance < this.post.pay_per_view) {

                this.$emit('close');
                this.$swal({
                    title: 'Insufficient Balance',
                    text: 'You do not have enough balance to buy this payperview.',
                    icon: 'error',
                    confirmButtonColor: '#888',
                    cancelButtonColor: '#007BFF',
                    confirmButtonText: 'Close',
                    showCancelButton: true,
                    cancelButtonText: '<i class="fa-light fa-credit-card"></i> Topup',
                }).then((res) => {
                    if (res.value) {
                        this.$emit('close');
                    } else {
                        this.$router.push('/settings/wallet');
                    }
                })
                  return;
              }
              this.$http.post('/api/v1/post/buy', {
                post_id: this.post.id,
              }).then(() => {
                  this.$swal({
                      title: 'Success',
                      text: 'You have successfully bought this post',
                      icon: 'success',
                      showConfirmButton: false,
                  })
                  setTimeout(() => {
                    this.$emit('close');
                    this.$emit('refresh');
                  }, 1500);
              }).catch(() => {
                  this.$swal({
                      title: 'Error',
                      text: 'Something went wrong',
                      icon: 'error',
                      showConfirmButton: false,
                  })
              })
          }
      }
  }
</script>

<style lang="scss">
  
</style>