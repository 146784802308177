<template>
  <div data-app>
    <TabComponent title="Personal Information">
      <div class="settings-personal-data-edit-card">
        Informations
        <br /><br />
        <i
          class="fa-light fa-link"
          aria-hidden="true"
          style="color: #007bff; margin-bottom: 8px"
        ></i>
        Slug
        <v-text-field
          style="margin-top: 8px"
          color="#0989EF"
          v-model="user.slug"
          filled
          placeholder="Slug"
          clearable
        ></v-text-field>

        <i
          class="fa-light fa-user"
          aria-hidden="true"
          style="color: #007bff"
        ></i>
        Name
        <v-text-field
          style="margin-top: 10px"
          color="#0989EF"
          v-model="user.name"
          filled
          placeholder="Name"
          clearable
        ></v-text-field>
        <i
          class="fa-light fa-envelope"
          aria-hidden="true"
          style="color: #007bff"
        ></i>
        E-mail

        <v-text-field
          style="margin-top: 10px"
          color="#0989EF"
          disabled
          v-model="user.email"
          filled
          placeholder="E-mail"
          clearable
        ></v-text-field>
        <i class="fa-light fa-book-user" style="color: #007bff"></i> Biography

        <v-textarea
          style="margin-top: 10px"
          color="#0989EF"
          v-model="user.bio"
          filled
          placeholder="bio"
          clearable
        >
        </v-textarea>



      <i
        class="fa-light fa-envelope"
        aria-hidden="true"
        style="color: #007bff"
      ></i>
      Additional Link

      <v-text-field
        style="margin-top: 10px"
        color="#0989EF"
        v-model="user.custom_link"
        filled
        placeholder="www.example.com"
        clearable
      ></v-text-field>

        <i class="fa-light fa-calendar" style="color: #007bff"></i>
        Date of Birth
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
          dark
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              style="margin-top: 8px"
              v-model="user.date_of_birth"
              placeholder="Birthday date"
              readonly
              filled
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="user.date_of_birth"
            dark
            :active-picker.sync="activePicker"
            :max="
              new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10)
            "
            min="1950-01-01"
            @change="menu = false"
          ></v-date-picker>
        </v-menu>

        <i class="fa-light fa-location-dot" style="color: #007bff"></i> Location
        <v-text-field
          style="margin-top: 10px; margin-bottom: 8px"
          color="#0989EF"
          v-model="user.location"
          filled
          placeholder="Location"
          clearable
        ></v-text-field>
      </div>

    <v-btn
      @click="save"
      class="welcome-card__buttons btn-sider-money"
      block
      large
      elevation="0"
    >
      Save
    </v-btn>
    <br /><br /><br />
    </TabComponent>
  </div>
</template>

<script>
import TabComponent from "./TabComponent.vue";
export default {
  components: {
    TabComponent,
  },
  async beforeMount() {
    this.user = await this.$http
      .get("/api/v1/getUserProfile/" + this.me?.slug)
      .then((res) => res.data);

    // if(!this.user.custom_link) {
    //   this.user.custom_link = "https://";
    // }

    if (this.user.date_of_birth) {
      this.user.date_of_birth = new Date(this.user.date_of_birth)
        .toLocaleDateString("en-Gb", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$2-$1");
    }
  },
  methods: {
    save() {
      //? Check if user changed e-mail
      if (this.user.email != this.me.email) {
        this.user.changeEmail = true;
      }

      this.user.location = this.user.location?.replace(/ /g, " ");

      this.$http
        .post("/api/v1/updateUserProfile", this.user)
        .then(({ data: { accessToken = null, message } }) => {
          if (accessToken) {
            localStorage.setItem("sider-token", accessToken);
            this.$token = accessToken;
            this.me = this.decryptData(this.$token);

            setTimeout(() => {
              window.location.reload();
            }, 2500);
          }

          this.$swal({
            title: "Success",
            text: message,
            icon: "success",
            timer: 2000,
            showConfirmButton: false,
            showCancelButton: false,
          });
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            this.$swal({
              title: "Error",
              text: err.response.data.error,
              icon: "error",
              timer: 2000,
              showConfirmButton: false,
              showCancelButton: false,
            });
          }
        });
    },
    userBio(bio) {
      return bio?.replace(/\n/gm, "<br>");
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  data: () => ({
    user: {},
    editing: true,
    menu: false,
    activePicker: null,
  }),
};
</script>

<style lang="scss">
.settings-personal-data-container {
  padding: 1em;
}

.settings-personal-data-edit-card {
  text-align: left;
  color: #4f4f4f;
  background-color: #f2f2f6;
  padding: 1em;
  border-radius: 5px;
  width: 100%;
  max-width: 768px;
  min-width: 300px;
  border: 1px solid rgb(245, 245, 245);
  margin-bottom: 10px;
}

/* breakpoint min-width 768px */
@media screen and (min-width: 768px) {
  .header-settings {
    // margin-top: 50px;
  }
}
</style>
