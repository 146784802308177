<template>
  <img v-on="$listeners" ref="image" :src="src" :class="{ 'pinch-zoom-transition': enableTransition }" class="picture" lazy>
</template>

<style>
.pinch-zoom-transition {
  transition: transform 0.5s ease;
}

.picture {
  width: 100%;
  height: 100%;
  object-fit: contain;
  user-select: none;
  touch-action: none;
}
</style>

<script>
import Hammer from 'hammerjs';

export default {
  props: {
      src: {
          type: String,
          required: true
      }
  },
  data() {
      return {
          enableTransition: true,
          scale: 1,
          isdragging: false
      }
  },
  mounted() {

      const image = this.$refs.image;
      const hammer = new Hammer(image);

      hammer.get('pinch').set({ enable: true });
      hammer.get('pan').set({ enable: true });

      image.style.touchAction = 'pan-x pan-y';


      hammer.on('pinch', (e) => {
        image.style.touchAction = 'none';
        if(e.pointers.length > 1){
          this.scale = e.scale;
          image.style.transform = `scale(${this.scale}) translate(${e.deltaX/2}px, ${e.deltaY/2}px)`;
          image.style.zIndex = 999;
          this.enableTransition = false;
        }
      });

      hammer.on('pinchend', () => {
          this.enableTransition = true;
          image.style.transform = `scale(1) translate(0px, 0px)`;


          image.style.touchAction = 'pan-x pan-y';
          image.style.zIndex = 0;
      });



  },
  beforeDestroy() {
      const image = this.$refs.image;
      const hammer = new Hammer(image);
      hammer.destroy();
  }
}
</script>
