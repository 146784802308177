var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.$route.path != '/login' &&
    _vm.$route.path != '/register' &&
    _vm.$route.path != '/forgot-password' &&
    _vm.$route.path != '/waitlist'
  )?_c('div',{staticStyle:{"display":"grid","width":"100%"},style:({
    gridTemplateColumns:
      _vm.windowWidth >= 1013
        ? 'minmax(245px, 1fr) 768px 1fr'
        : _vm.windowWidth <= 870 && _vm.windowWidth > 769
        ? '1fr 1fr 1fr'
        : _vm.windowWidth < 769
        ? '1fr'
        : 'minmax(100px, 1fr) 10fr',
    display: _vm.$route.path.includes('become-a-creator') ? 'block' : 'grid',
  })},[_c('post-view',{on:{"goBackPost":_vm.goBackPost,"goFowardPost":_vm.goFowardPost}}),(!_vm.$route.path.includes('become-a-creator'))?_c('div',{staticStyle:{"width":"100%","display":"grid","justify-content":"flex-end","justify-items":"flex-end"}},[_c('sidebar')],1):_vm._e(),_c('div',{staticStyle:{"width":"100%","display":"grid"},style:({
      gridTemplateColumns: _vm.windowWidth <= 768 ? 'auto' : '768px 1fr',
      paddingBottom: _vm.paddingBottom,
    })},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c(_vm.layout,{tag:"component",staticClass:"view-container",staticStyle:{"max-width":"768px","min-width":"320px"},style:({
          maxWidth:
            _vm.windowWidth > 877 && !_vm.$route.path.includes('become-a-creator')
              ? '768px'
              : '',
        })})],1),(_vm.windowWidth > 1215 && !_vm.$route.path.includes('become-a-creator'))?_c('AdvertiseComponent'):_vm._e()],1),(
      !_vm.$store.state.channelOpen.id &&
      !_vm.$route.path.includes('become-a-creator')
    )?_c('s-footer'):_vm._e()],1):_c('div',[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c(_vm.layout,{tag:"component",staticClass:"view-container",attrs:{"windowWidth":_vm.windowWidth}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }