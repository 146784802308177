<template>
  <div style="padding: 35px; max-width: 800px">
    <!-- <div
      id="g_id_onload"
      data-client_id="716402199538-s2u4717u8lh2461riv8752uscccvlich.apps.googleusercontent.com"
      :data-login_uri="`${getBaseURL}/api/v1/register/google`"
      :data-godFatherCode="godFatherCode"
      data-context="signup"
    ></div> -->
    <center style="padding-top: 36px">
      <s-logo />
      <div v-if="godFatherCode">
        <br />
        <v-avatar size="100px" style="border: 1px solid rgba(0, 0, 0, 0.1)">
          <v-img :src="godFather.avatar"></v-img>
        </v-avatar>
        <br />
        <br />
        <span style="font-size: 14px; color: #999"
          >Invited by <b>{{ godFather.name }}</b></span
        >
      </div>
      <br />
      <v-text-field
        class="inputs-login"
        filled
        style="margin-top: 1em; font-size: 14px; color: #007bff"
        placeholder="E-mail"
        prepend-inner-icon="mdi-email-outline"
        v-model="email"
      ></v-text-field>
      <v-text-field
        class="inputs-login"
        filled
        type="password"
        prepend-inner-icon="mdi-key"
        style="font-size: 14px; color: #007bff"
        placeholder="Password"
        v-model="password"
        @keypress.enter="complete"
      >
      </v-text-field>
      <v-btn
        @click="complete"
        :loading="loading"
        style="
          margin-bottom: 100px;
          text-transform: none;
          width: 100%;
          left: 0;
          right: 0;
          color: white;
        "
        color="#007BFF"
        x-large
        elevation="0"
      >
        Sign Up
      </v-btn>
      <span style="font-size: 14px; color: #999; cursor: pointer"
        >Already have an account?</span
      >
      <br />
      <h3
        class="text-gradient"
        style="margin-top: 0.5em; font-size: 14px; cursor: pointer"
        @click="$router.push('/login')"
      >
        Login into your account
      </h3>
      <br /><br /><br />
    </center>
  </div>
</template>

<script>
import godFatherMixin from "@/mixins/godFather";
import googleMixin from "@/mixins/google";
export default {
  mixins: [godFatherMixin, googleMixin],
  data: () => ({
    email: "",
    password: "",
    rememberMe: false,
    loading: false,
    godFather: {},
  }),
  components: {
    // SlideUnlock
  },
  async beforeMount() {
    if (this.godFatherCode) {
      this.godFather = await this.$http
        .get(`/api/v1/getGodFatherInfo/?slug=${this.godFatherCode}`)
        .then((res) => res.data);
    }
  },
  computed: {
    getBaseURL() {
      return process.env.VUE_APP_API_BASE_URL;
    },
    verifyInputs() {
      // use a regex to verify if email is valid
      /* eslint-disable-next-line */
      if (
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email.trim())
      ) {
        if (this.password) {
          return false;
        }
      }

      return true;
    },
  },
  methods: {
    async complete() {
      if (this.loading) return;
      this.loading = true;
      var { ip } = await fetch("https://api.ipify.org?format=json").then((x) =>
        x.json()
      );

      var extra = {};

      // check if a cookie with name sider-godFatherCode exists

      if (
        document.cookie
          .split(";")
          .filter((item) => item.includes("sider-godFatherCode")).length
      ) {
        extra.godFatherCode = document.cookie
          .split(";")
          .filter((item) => item.includes("sider-godFatherCode"))[0]
          .split("=")[1];
      }

      this.$http
        .post("/api/v1/users", {
          email: this.email.toLowerCase().trim(),
          password: this.password,
          ip: ip,
          ...extra,
        })
        .then((res) => {
          if (res.data.accessToken) {
            this.$store.commit("setToken", res.data.accessToken);
            this.me = this.decryptData(res.data.accessToken);
            window.location.href = "/" + this.me.slug;
          } else {
            this.$swal({
              title: "Error",
              text: "Could not register user, please try again.",
              icon: "error",
              confirmButtonText: "Ok",
            });
          }
          this.loading = false;
        })
        .catch((err) => {
          if (err && err.response) {
            this.$swal({
              title: "Error",
              text:
                err.response.data?.error ||
                "Could not register user, please try again.",
              icon: "error",
              confirmButtonText: "Ok",
            });
          }
          this.loading = false;
        });
    },
  },
};
</script>
