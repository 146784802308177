<template>
    <img src="/assets/images/logo.svg" :width="width">
</template>

<script>
    export default {
        props: {
            width: {
                type: String,
                default: '251'
            }
        }
    }
</script>