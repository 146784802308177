export default {
    async 'affiliates/getAffiliates' () {
        return await this._vm.$http.get("/api/v1/getMyAffiliates/")
            .then((res) => res.data);
    },
    async 'subscriptionsPrices/get' () {
        return await this._vm.$http.get("/api/v1/getUserSubscriptionPrices/")
            .then((res) => res.data);
    },
    async 'subscriptionsPrices/update' (_, payload) {
        return await this._vm.$http.post("/api/v1/updateUserSubscriptionPrices/", payload)
            .then((res) => res.data);
    },
    async 'withdraw' (_, payload) {
        return await this._vm.$http.post("/api/v1/withdraw/", payload)
            .then((res) => res.data);
    },
    async 'getUserInsights' () {
        return await this._vm.$http.get("/api/v1/getUserInsights/")
            .then((res) => res.data);
    },
    async 'chat/sendFile' ({ state }, file) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('channel', JSON.stringify(state.channelOpen));
        const response = await this._vm.$http.post("/api/v1/channel/file", formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        const message = response.data;

        window.socket.emit('message', message);
    },
}