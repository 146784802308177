<template>
  <MultiMedia v-if="post.files.length > 1" :post="post" :autoPlay="autoPlay"></MultiMedia>
  <SingleMedia v-else-if="post.files.length > 0" :post="post" :autoPlay="autoPlay"></SingleMedia>
</template>

<script>
import MultiMedia from './MultiMedia.vue';
import SingleMedia from './SingleMedia.vue';


export default {
    props: {
        post: {
            type: Object,
            default: () => { }
        },
        autoPlay: {
            type: Boolean,
            default: false
        }
    },
    components: { SingleMedia, MultiMedia },
}
</script>