export default {
  methods: {
    onNewComment(comment) {
      // TODO: Filter posts not in the right order (createdAt)
      this.posts.forEach(post => {
        if (post.id == comment.post_id) {
          if (!post.comments) post.comments = []
          post.comments.unshift(comment)
        }
      })
      this.posts = this.posts.map((post) => {
        if (post.id == comment.post_id) {
          return post;
        }
        return post;
      });
    },
    onDeleteComment(comment) {
      this.posts.forEach(post => {
        if (post.id == comment.post_id) {
          if (!post.comments) post.comments = []
          post.comments = post.comments.filter(c => c.id != comment.id)
        }
      })
    },
    deletePost(postId) {
      this.posts = this.posts.filter((post) => post.id != postId);
    },
  }
}