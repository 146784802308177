<template>
  <div class="auth-layout">
    <div class="sidecard" v-if="windowWidth > 768">
      <script
        type="module"
        src="https://unpkg.com/@splinetool/viewer@0.9.489/build/spline-viewer.js"
      ></script>
      <spline-viewer
        hint
        loading-anim
        url="https://prod.spline.design/I6c1uzbrspv3LplU/scene.splinecode"
      ></spline-viewer>
    </div>
    <div class="sidecard--right">
      <center class="center">
        <router-view class="_padding-top769w"></router-view>
      </center>

      <div class="auth-layout__footer">
        <!-- dmca, terms & privacy, faq, pricing, contact links -->
        <div class="auth-layout__footer-links">
          <router-link to="/terms">Terms</router-link>
          <router-link to="/privacy">Privacy</router-link>
          <router-link to="/dmca">DMCA</router-link>
          <router-link to="/community">Guidelines</router-link>
          <router-link to="/faq">FAQ</router-link>
        </div>
        <div class="auth-layout__footer-links">
          <a>© 2023 Sider Money</a>
          <router-link
            to="/contact"
            style="display: flex; align-items: center; gap: 8px"
            >We accept
            <img width="38px" src="/assets/images/visa-logo.png" />
            <img width="38px" src="/assets/images/mastercard-logo.png"
          /></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    windowWidth: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss">
.auth-layout {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  & .sidecard--right {
    position: relative;
    background-size: 100%;
    background-repeat: repeat;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (prefers-color-scheme: dark) {
      background: var(--color-black-4);
    }
  }

  & .sidecard {
    width: 100%;
    height: 100vh;
    display: grid;
    align-items: center;
    justify-items: center;
    color: white;
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.802),
        rgba(0, 123, 255, 0.637)
      ),
      rgba(0, 0, 0, 0.8);
    background-size: cover;

    @media (max-width: 768px) {
      display: none;
    }

    & {
      span .question {
        font-size: 1.5em;
        font-weight: 600;
      }

      span {
        width: 80%;
        font-size: 1.5rem;
        font-weight: 500;
        text-align: left;
      }
    }
  }

  &__footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 1em;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 16px;
  }

  &__footer-links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;

    & a {
      text-decoration: none;
      color: #999;
      font-size: 12px;

      &:hover {
        color: #007bff;
      }
    }
  }
}
</style>
