<template>
  <v-app class="v-app-card">
    <TabComponent title="Notifications">
      <div class="settings-personal-data-edit-card">
        <div style="display: flex;justify-content: space-between;align-items: center;">
          <div><i
            class="fa-light fa-envelope"
            aria-hidden="true"
            style="color: #007bff; margin-bottom: 8px"
          ></i>
          E-mail notifications</div>
          <div><v-switch v-model="emailNotification" inset></v-switch></div>
        </div>


<div style="display: flex;justify-content: space-between;align-items: center;">
  <div><i
    class="fab  fa-telegram"
    aria-hidden="true"
    style="color: #007bff; margin-bottom: 8px"
  ></i>
  Telegram notifications <small><i>(coming soon)</i></small></div>
  <div><v-switch inset disabled></v-switch></div>
</div>


<div style="display: flex;justify-content: space-between;align-items: center;">
  <div><i
    class="fal  fa-bell"
    aria-hidden="true"
    style="color: #007bff; margin-bottom: 8px"
  ></i>
  Push notification <small><i>(coming soon)</i></small></div>
  <div><v-switch inset disabled></v-switch></div>
</div>
      </div>

    <v-btn
      @click="save"
      class="welcome-card__buttons btn-sider-money"
      block
      large
      elevation="0"
    >
      Save
    </v-btn>
    <br /><br /><br />
    </TabComponent>
  </v-app>
</template>

<script>
import TabComponent from "./TabComponent.vue";
export default {
  components: {
    TabComponent,
  },
  async beforeMount() {
    let { emailNotification } = await this.$http
      .get("/api/v1/getNotifications/")
      .then((res) => res.data);
    this.emailNotification = emailNotification;
  },
  methods: {
    async save() {
      await this.$http
      .post("/api/v1/updateNotifications/", {
        emailNotification: this.emailNotification,
      })

      this.$swal({
        title: "Success",
        text: "Your settings have been saved",
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  },
  data() {
    return {
      emailNotification: true,
    }
  }
};
</script>

<style lang="scss">
.v-app-card {
  font-family: 'Montserrat', sans-serif !important;
}

.theme--light.v-application{
  background: unset !important;
  color: unset !important;

  @media (preferred-color-scheme: dark) {
    background: #1e1e1e !important;
  }

  @media (preferred-color-scheme: light) {
    background: #fff !important;
  }
}
</style>
