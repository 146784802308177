<template>
  <div style="position: fixed">
    <div
      class="sidebar"
      v-if="
        me ||
        (!me &&
          $route.path != '/' &&
          $route.path != '/login' &&
          $route.path != '/register')
      "
    >
      <div class="sidebar__content">
        <div
          v-if="me && me.id"
          class="sidebar__content__link"
          :class="[{ active: isMyProfile() }]"
          @click="gotoProfile"
        >
          <a>
            <div class="sidebar__content__link__icon" :key="localReloadAvatar">
              <v-avatar size="30px">
                <v-img
                  :src="me && me.avatar ? me.avatar : defaultImages.avatar"
                  :lazy-src="me && me.avatar ? me.avatar : defaultImages.avatar"
                ></v-img>
              </v-avatar>
            </div>
            <div class="sidebar__content__link__text">
              <span>{{ me.name }}</span>
              <small>{{ balance.toFixed(2) }}$</small>
            </div>
          </a>
        </div>
        <div
          class="sidebar__content__link"
          :class="[{ active: $route.path == '/' }]"
          @click="gotoHome"
        >
          <a>
            <div class="sidebar__content__link__icon">
              <i class="fa-light fa-home"></i>
            </div>
            <div class="sidebar__content__link__text">
              <span>Home</span>
            </div>
          </a>
        </div>
        <div
          class="sidebar__content__link"
          :class="[{ active: $route.path == '/discover' }]"
          @click="gotoSearch"
        >
          <a>
            <div class="sidebar__content__link__icon">
              <i class="fa-light fa-search"></i>
            </div>
            <div class="sidebar__content__link__text">
              <span>Discover</span>
            </div>
          </a>
        </div>
        <div
          class="sidebar__content__link"
          :class="[{ active: $route.path == '/chat' }]"
          @click="gotoChat"
        >
          <a>
            <div class="sidebar__content__link__icon">
              <i class="fa-light fa-paper-plane"></i>
            </div>
            <div class="sidebar__content__link__text">
              <span>Chat</span>
            </div>
          </a>
        </div>
        <div
          class="sidebar__content__link"
          :class="[{ active: $route.path == '/settings' }]"
          @click="$router.push('/settings').catch(() => {})"
        >
          <a>
            <div class="sidebar__content__link__icon">
              <i class="fa-light fa-cog"></i>
            </div>
            <div class="sidebar__content__link__text">
              <span>Settings</span>
            </div>
          </a>
        </div>
        <div class="sidebar__content__link__button" @click="beginAPost">
          <a>
            <div class="sidebar__content__link__button__icon">
              <i class="fa-light fa-plus"></i>
            </div>
            <div class="sidebar__content__link__button__text">
              <span>New Post</span>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilsMixins from "@/mixins/utils";
export default {
  mixins: [utilsMixins],
  computed: {
    defaultImages() {
      return {
        avatar: process.env.VUE_APP_DEFAULT_USER_AVATAR,
      };
    },
  },
  watch: {
    "$store.state.reloadAvatarSidebar"() {
      this.$token = localStorage.getItem("sider-token");
      this.me = this.decryptData(this.$token);
      this.localReloadAvatar++;
    },
    "$route.path": {
      handler() {
        if (this.me && this.me.id) this.getBalance();
      },
      immediate: true,
    },
    "$store.state.updateBalance"() {
      if (this.me && this.me.id) this.getBalance();
    },
  },
  data: () => ({ localReloadAvatar: 0, balance: 0 }),
  methods: {
    scrollTop() {
      window.scrollTo(0, 0);
    },
    async getBalance() {
      this.balance = await this.$http
        .get("/api/v1/getBalance")
        .then((res) => res.data.credits)
        .catch(() => 0);
    },
    beginAPost() {
      if (this.$route.params.slug) {
        if (this.me.id) {
          if (this.me.creator_category_id) {
            this.$router.push(`/${this.me.slug}`).catch(() => {});
            this.$store.commit("setPopup", "create-post");
          } else {
            this.$swal({
              title: "Oops!",
              text: "You need to be a creator to create a post, would you like to become a creator?",
              icon: "warning",
              showCancelButton: true,
              cancelButtonText: "No",
              confirmButtonText: "Yes",
            }).then(({ isConfirmed }) => {
              if (isConfirmed) {
                this.$router.push("/become-a-creator").catch(() => {});
              }
            });
          }
        } else {
          this.$router.push("/login");
        }
      } else {
        if (this.me.id) {
          this.$router
            .push(`/${this.me.slug}`)
            .then(() => {
              setTimeout(() => {
                this.$store.commit("setPopup", "create-post");
              }, 1000);
            })
            .catch(() => {});
        } else {
          this.$router.push("/login");
        }
      }
    },
    gotoChat() {
      if (!this.me) {
        this.$router.push("/login");
      } else {
        this.$store.state.search = "";
        this.$store.state.category = "";
        this.$store.state.users = [];
        this.$router.push("/chat");
      }
    },
    isOnSearch() {
      var slug = "";
      if (this.me) {
        slug = this.me.slug;
      }
      if (
        (this.$store.state.search.length > 0 ||
          this.$store.state.category.length > 0 ||
          this.$store.state.users.length > 0 ||
          this.$route.path == "/discover") &&
        this.$route.params.slug != slug
      ) {
        return true;
      }
    },
    isMyProfile() {
      return (
        (this.me && this.$route.params.slug == this.me.slug) ||
        this.$route.path.startsWith("/settings")
      );
    },
    gotoProfile() {
      if (!this.$route.path.startsWith("/settings") && this.isMyProfile()) {
        return this.scrollTop();
      }
      this.$store.state.search = "";
      this.$store.state.category = "";
      this.$store.state.users = [];
      this.$router.push(this.myUrl()).catch(() => {});
    },
    gotoHome() {
      if (this.$route.path == "/") {
        return this.scrollTop();
      }
      this.$store.state.search = "";
      this.$store.state.category = "";
      this.$store.state.users = [];
      this.$router.push("/").catch(() => {});
    },
    gotoSearch() {
      if (this.$route.path.startsWith("/discover")) {
        return this.scrollTop();
      }
      this.$store.state.search = "";
      this.$store.state.category = "";
      this.$router.push("/discover").catch(() => {
        this.$store.state.users = [];
      });
    },
    myUrl() {
      if (this.me && this.me.slug) {
        return "/" + this.me.slug;
      } else {
        return "/login";
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/styles/variables.scss";
// @media (max-width: 313px) {
//   .sidebar {
//     transition: .5s;
//     display: none;
//   }
// }

.sidebar {
  transition: 0.5s;
  margin-right: 1.5rem;
  margin-left: 1.5rem;

  @media (max-width: 870px) {
    display: none;
  }

  @media (max-width: 1013px) {
    & {
      width: 60px;
    }
  }

  &__content {
    height: 100%;

    &__link__button {
      margin-top: 30px;
      padding: 1em;
      border-radius: 50px;
      color: $color-gray;
      transition: 0.5s;
      background-color: #007bff;
      cursor: pointer;

      &:hover {
        background-color: #004fa3;
      }

      & a {
        display: grid;
        grid-template-columns: 1fr 4fr;
        text-decoration: none;
      }

      & &__icon {
        & i {
          transition: 0.5s;
          font-size: 30px;
          color: #fff;
        }
      }

      & &__text {
        display: grid;
        align-items: center;
        justify-content: flex-start;

        @media (max-width: 1013px) {
          & {
            display: none;
          }
        }

        & span {
          transition: 0.5s;
          font-size: 17px;
          font-weight: 600;
          color: #fff;
          margin-left: 10px;
        }

        & small {
          transition: 0.5s;
          margin-left: 10px;
        }
      }
    }

    &__link {
      margin-top: 30px;
      padding: 0.8em;
      border-radius: 20px;
      color: $color-gray;
      transition: 0.5s;
      cursor: pointer;

      @media (max-width: 1013px) {
        &:first-child {
          padding: 0.35em;
        }
      }

      &.active a div i,
      &.active a div span {
        color: black;
      }

      & a {
        display: grid;
        grid-template-columns: 1fr 4fr;
        text-decoration: none;

        @media (max-width: 1013px) {
          & {
            display: flex;
            justify-content: center;
          }
        }
      }

      &:hover {
        background-color: #007bff12;

        & a {
          color: #007bff;
        }

        & .sidebar__content__link__icon i {
          color: #007bff;
        }

        & .sidebar__content__link__text span {
          color: #007bff;
        }
      }

      & &__icon {
        display: flex;
        align-items: center;

        & i {
          transition: 0.5s;
          font-size: 30px;
          color: $color-gray;
        }
      }

      & &__text {
        display: grid;
        align-items: center;

        @media (max-width: 1013px) {
          & {
            display: none;
          }
        }

        & span {
          transition: 0.5s;
          font-size: 17px;
          font-weight: 600;
          color: $color-gray;
        }

        & small {
          transition: 0.5s;
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
