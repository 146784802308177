import Vue from "vue";
export default {
  async beforeMount() {
    var { slug } = this.$route.params;
    this.slug = slug;
  },
  mounted() {
    this.windowWidth = window.innerWidth;
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("scroll", this.handleScroll);
  },
  data: () => ({
    slug: "",
    user: {},
    posts: [],
    showWelcomeCards: false,
    showWelcomePostCard: false,
  }),
  watch: {
    async "$route.path"() {
      var { slug } = this.$route.params;
      this.slug = slug;
    },
  },
  methods: {
    reload($event) {
      this[$event]();
    },
    async getUser() {
      // this.user.banner = this.$default.banner;
      this.user.banner = null;
      this.user = await this.$http
        .get("/api/v1/getUserProfile/" + this.slug)
        .then((res) => res.data)
        .catch((err) => {
          if (err.response.data.error) {
            this.$router.push({ path: "/" });
          }
        });

      await this.getPosts();

      if (this.me) {
        if (this.posts.length == 0) {
          if (this.$route.params.slug) {
            if (this.$route.params.slug == this.me.slug) {
              if (!this.user.creator_category_id) {
                this.showWelcomeCards = true;
              }

              if (this.user.creator_category_id) {
                this.showWelcomePostCard = true;
              }
            }
          }
        }

        if (this.me.id && this.me.id == this.user.id) {
          if (!this.me.creator_category_id && this.user.creator_category_id) {
            Vue.prototype.$http
              .post("/api/v1/updateTokenCreatorCategory")
              .then(({ data }) => {
                let accessToken = data.accessToken;

                this.$store.commit("setToken", accessToken);
                this.me = this.decryptData(accessToken);
                window.location.reload();
              });
          }
        }
      }
    },
    async getPosts() {
      if (this.user) {
        this.posts = await this.$http
          .get("/api/v1/getPosts/" + this.user.id)
          .then((res) => res.data);

        // this is applied so the lightbox discover can be used
        this.$store.state.discoverPosts = this.posts;
      }
    },
    isMyProfile() {
      if (!this.user) return false;
      return this.me && this.user.id == this.me.id;
    },
    async openPopup(popupName) {
      this.$store.commit("setPopup", popupName);
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    handleScroll() {
      this.windowY = window.pageYOffset;
    },
  },
};
