<template>
  <div  class="single-media" style="position: relative;">
    <!-- BLUR BACKGROUND -->
    <img class="single-media__backdrop" v-if="isImage(post.files[0].medium||'')" :src="`${
            post.files[0].blur
          }`" />
    
    <PostImage v-if="isImage(post.files[0].medium||'')"  :post="post"></PostImage>

    <!-- VIDEO -->
    <!-- hide-controls keep-paused -->
    <!-- :is-mobile="windowWidth <= 1000" -->
    <!-- :isMobile="windowWidth <= 768" -->
    <!-- :key="hotReload" -->
    <s-video :autoPlay="autoPlay" class="single-media__video" isSlider  playsinline loop muted :windowWidth="windowWidth" style="
              width: 100%;
              height: 100%;
            " v-if="isVideo(post.files[0].video || '')" :blur="`${
                post.files[0].blur
              }`" :thumbnail="`${
                post.files[0].thumbnail
              }`" :src="`${
                post.files[0].video
              }`"></s-video>


    <div
      v-if="isAudio(post.files[0].file || '') || isPDF(post.files[0].file || '') || isDoc(post.files[0].file || '') || isExcel(post.files[0].file || '')"
      class="other-files-post">
      <span v-if="isPDF(post.files[0].file || '')" style="max-width: 400px;word-wrap: break-word;">
        <i class="fa fa-file-pdf-o" style="font-size: 3em;" aria-hidden="true"></i>
        <br><br>
        {{ post.files[0].file.split('/').pop() }}
        <br><br>
        <a :href="`${
                post.files[0].file
              }`" download style="text-decoration: none;">
          <v-btn elevation="0">
            <span style="color: black;">Download</span>
          </v-btn>
        </a>
      </span>
      <span v-if="isDoc(post.files[0].file || '')" style="max-width: 400px;word-wrap: break-word;">
        <i class="fa fa-file-word-o" style="font-size: 3em;" aria-hidden="true"></i>
        <br><br>
        {{ file.file.split('/').pop() }}
        <br><br>
        <a :href="`${
                post.files[0].file
              }`" download style="text-decoration: none;">
          <v-btn elevation="0">
            <span style="color: black;">Download</span>
          </v-btn>
        </a>
      </span>
      <span v-if="isExcel(post.files[0].file || '')" style="max-width: 400px;word-wrap: break-word;">
        <i class="fa fa-file-excel-o" style="font-size: 3em;" aria-hidden="true"></i>
        <br><br>
        {{ post.files[0].file.split('/').pop() }} 
        <br><br>
        <a :href="`${
                post.files[0].file
              }`" download style="text-decoration: none;">
          <v-btn elevation="0">
            <span style="color: black;">Download</span>
          </v-btn>
        </a>
      </span>
      <span v-if="isAudio(post.files[0].file || '')" style="max-width: 400px;word-wrap: break-word;">
        <audio controls style="height: 70px;">
          <source :src="`${
                post.files[0].file
              }`" type="audio/mpeg">
          Your browser does not support the audio element.
        </audio>
        <br><br>
        {{ post.files[0].file }}
      </span>
    </div>

    <!-- IF VIDEO IS PAYPER VIEW AND NOT UNLOCKED -->
    <div class="subscriber-only" v-if="Number(post.pay_per_view > 0) && !post.paid && post.owner.id != me.id">
      <i class="fa-solid fa-lock"></i>
      <v-btn class="btn-sider-money" @click="openPayPerView(post)">
        Pay {{ post.pay_per_view }}$ to see this post
      </v-btn>
      <small>To see this user post</small>
    </div>
  </div>
</template>

<script>
  import utilsMixin from '@/mixins/utils'
import PostImage from './FileTypes/PostImage.vue';
  export default {
    mixins: [utilsMixin],
    props: {
        post: {
            type: Object,
            default: () => { }
        },
        autoPlay: {
            type: Boolean,
            default: false
        }
    },
    watch: {
      // SPIKE: Still neded?
        // post: {
        //     handler: function () {
        //         this.hotReload++;
        //     },
        //     deep: true,
        //     immediate: true
        // }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
    },
    data: () => ({
        hotReload: 0,
        commentsBottomSheet: false,
        windowWidth: window.innerWidth
    }),
    components: { PostImage },
}
</script>

<style lang="scss">
.single-media{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // user-select: none;
  // pointer-events: none;
  z-index: 1;
  // disable drag image
  // -webkit-user-drag: none;
  // -khtml-user-drag: none;

  &__image{
    user-select: none;
    pointer-events: none;
    // disable drag image
    -webkit-user-drag: none;
    -khtml-user-drag: none;
  }

  &__backdrop{
    z-index: 0;
    width: 100%;
    position: absolute;
    height: 100%;
  }

  &__video{
    height: 100%;

    video {
      max-height: 100vh !important;
    }
  }
}
</style>