<template>
  <div>
    <v-card flat>
      <!-- default table from html -->

      <v-text-field
        v-model="searchTransactions"
        append-icon="search"
        label="Search"
        filled
        hide-details
      ></v-text-field>

      <table class="s-table-historic">
        <tr>
          <th>#</th>
          <th>Date</th>
          <th>Type</th>
          <th>Amount</th>
        </tr>

        <tr v-for="transaction in transactionsFilter" :key="transaction.id">
          <td>
            <!-- convert id to hash -->
            {{ encryptData(`${transaction.id}`) }}
          </td>
          <td>
            {{
              new Date(transaction.createdAt).toLocaleDateString("pt-PT", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
              })
            }}
          </td>
          <td>
            <span>
              {{
                (
                  transaction.sub_type.charAt(0).toUpperCase() +
                  transaction.sub_type.slice(1)
                ).replace(/\_/g, " ")
              }}
              <template v-if="transaction.sub_type == 'withdraw'">
                <small>
                  <i>({{ transaction.status }})</i></small
                >
              </template>
              <template v-if="transaction.sub_type == 'comission'">
                <template v-if="transaction.user_id == 1"> from </template>
                <template v-else> to </template>
                <router-link
                  class="text-primary"
                  :to="`/${
                    showTransactionSubscriptionCreator(transaction).parent.user
                      .slug ||
                    showTransactionSubscriptionCreator(transaction)
                      .child.map((c) => c.user.slug)
                      .join(', ')
                  }`"
                >
                  {{
                    showTransactionSubscriptionCreator(transaction).parent.user
                      .name ||
                    showTransactionSubscriptionCreator(transaction)
                      .child.map((c) => c.user.name)
                      .join(", ")
                  }}</router-link
                >
              </template>
              <template v-else-if="transaction.sub_type == 'investment'">
                {{ transaction.user_id == 1 ? "from" : "to" }}
                <router-link
                  class="text-primary"
                  :to="`/${
                    showTransactionSubscriptionCreator(transaction).parent.user
                      .slug ||
                    showTransactionSubscriptionCreator(transaction)
                      .child.map((c) => c.user.slug)
                      .join(', ')
                  }`"
                >
                  {{
                    showTransactionSubscriptionCreator(transaction).parent.user
                      .name ||
                    showTransactionSubscriptionCreator(transaction)
                      .child.map((c) => c.user.name)
                      .join(", ")
                  }}</router-link
                >
              </template>
              <template v-else-if="transaction.sub_type == 'subscription'">
                from
                <router-link
                  class="text-primary"
                  :to="`/${
                    showTransactionSubscriptionCreator(transaction).subscriber
                      .slug
                  }`"
                >
                  {{
                    showTransactionSubscriptionCreator(transaction).subscriber
                      .id == me.id
                      ? "Me"
                      : showTransactionSubscriptionCreator(transaction)
                          .subscriber.name
                  }}
                </router-link>
                to
                <router-link
                  class="text-primary"
                  :to="`/${
                    showTransactionSubscriptionCreator(transaction).creator.slug
                  }`"
                >
                  {{
                    showTransactionSubscriptionCreator(transaction).creator
                      .id == me.id
                      ? "Me"
                      : showTransactionSubscriptionCreator(transaction).creator
                          .name
                  }}
                </router-link>
              </template>
              <template v-if="transaction.sub_type == 'godfather_comission'">
                <template v-if="transaction.user_id == 1">
                  to
                  <router-link
                    class="text-primary"
                    :to="`/${
                      showTransactionSubscriptionCreator(transaction).parent
                        .user.slug
                    }`"
                  >
                    {{
                      showTransactionSubscriptionCreator(transaction).parent
                        .user.name
                    }}</router-link
                  >
                </template>
                <template v-else>
                  from
                  <router-link
                    class="text-primary"
                    :to="`/${
                      showTransactionSubscriptionCreator(transaction).creator
                        .slug ||
                      showTransactionSubscriptionCreator(transaction).parent
                        .user.slug ||
                      showTransactionSubscriptionCreator(transaction)
                        .child.map((c) => c.user.slug)
                        .join(', ') ||
                      showTransactionSubscriptionCreator(transaction).post.owner
                        .slug
                    }`"
                  >
                    {{
                      showTransactionSubscriptionCreator(transaction).creator
                        .name ||
                      showTransactionSubscriptionCreator(transaction).parent
                        .user.name ||
                      showTransactionSubscriptionCreator(transaction)
                        .child.map((c) => c.user.name)
                        .join(", ") ||
                      showTransactionSubscriptionCreator(transaction).post.owner
                        .name
                    }}</router-link
                  >
                </template>
              </template>
              <template v-if="transaction.sub_type == 'donation_payer'">
                to
                <router-link
                  class="text-primary"
                  :to="`/${showTransactionSubscriptionCreator(transaction)
                    .child.map((c) => c.user.slug)
                    .join(', ')}`"
                >
                  {{
                    showTransactionSubscriptionCreator(transaction)
                      .child.map((c) => c.user.name)
                      .join(", ")
                  }}</router-link
                >
              </template>
              <template v-if="transaction.sub_type == 'donation_receiver'">
                from
                <router-link
                  class="text-primary"
                  :to="`/${
                    showTransactionSubscriptionCreator(transaction).parent.user
                      .slug
                  }`"
                >
                  {{
                    showTransactionSubscriptionCreator(transaction).parent.user
                      .name
                  }}</router-link
                >
              </template>
              <template v-if="transaction.sub_type == 'payperview_receiver'">
                from
                <router-link
                  class="text-primary"
                  :to="`/${
                    showTransactionSubscriptionCreator(transaction).parent.user
                      .slug
                  }`"
                >
                  {{
                    showTransactionSubscriptionCreator(transaction).parent.user
                      .name
                  }}</router-link
                >
              </template>
              <template v-if="transaction.sub_type == 'payperview_payer'">
                to
                <router-link
                  class="text-primary"
                  :to="`/${showTransactionSubscriptionCreator(transaction)
                    .child.map((c) => c.user.slug)
                    .join(', ')}`"
                >
                  {{
                    showTransactionSubscriptionCreator(transaction)
                      .child.map((c) => c.user.name)
                      .join(", ")
                  }}</router-link
                >
              </template>
            </span>
          </td>
          <td
            style="text-align: right"
            :style="transaction.type == 'credit' ? 'color: #0989EF;' : ''"
          >
            {{ transaction.type != "credit" ? "-" : ""
            }}{{ transaction.amount.toFixed(2) }}$
          </td>
        </tr>
      </table>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    transactionsFilter: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    searchTransactions() {
      this.$emit("transactionsSearch", this.searchTransactions);
    },
  },
  data() {
    return {
      searchTransactions: "",
    };
  },
  methods: {
    showTransactionSubscriptionCreator(transaction) {
      if (
        (transaction &&
          transaction.subscription &&
          transaction.subscription.creator &&
          transaction.subscription.subscriber) ||
        transaction.parent_transaction ||
        transaction.user ||
        transaction.child_transaction ||
        transaction.post
      ) {
        return {
          creator: transaction.subscription?.creator || {
            slug: "",
            name: "",
          },
          subscriber: transaction.subscription?.subscriber || {
            slug: "",
            name: "",
          },
          parent: transaction.parent_transaction || {
            user: {
              slug: "",
              name: "",
            },
          },
          child: transaction.child_transaction || [],
          user: transaction.user || {
            slug: "",
            name: "",
          },
          post: transaction.post || {
            owner: {
              slug: "",
              name: "",
            },
          },
        };
      }
      return {
        creator: {
          slug: "",
          name: "",
        },
        subscriber: {
          slug: "",
          name: "",
        },
        parent: {
          user: {
            slug: "",
            name: "",
          },
        },
        child: [],
        user: {
          slug: "",
          name: "",
        },
      };
    },
  },
};
</script>
