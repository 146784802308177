/**
 * Here is defined all routes of the application with their respective layouts & views.
 * And exported to be used at the router.js file.
 * Example:
 *  {
 *     path: '/',
 *     name: 'home',
 *     component: Views.Home,
 *     meta: {
 *        layout: 'blank-layout'
 *     }
 *  }
 */

const formatterNumber = (val = "") => {
  if (val > 999 && val < 1000000) {
    val = (val / 1000).toFixed(1) + "K";
  } else if (val > 999999 && val < 1000000000) {
    val = (val / 1000000).toFixed(1) + "M";
  } else if (val > 999999999 && val < 1000000000000) {
    val = (val / 1000000000).toFixed(1) + "B";
  } else if (val > 999999999999 && val < 1000000000000000) {
    val = (val / 1000000000000).toFixed(1) + "T";
  }

  val = String(val);

  if (
    !val.includes("K") &&
    !val.includes("M") &&
    !val.includes("B") &&
    !val.includes("T")
  ) {
    val = Number(val);
  }

  return val;
}

import Views from "@/views/";

export default [
  {
    path: "/login",
    component: Views.LoginView,
    meta: {
      layout: "auth-layout",
      title: "Login - Sider.Money",
      image: "https://media.discordapp.net/attachments/994181274531024969/1179583537603694603/facebook-post_low.jpg",
    },
  },
  {
    path: "/register",
    component: Views.RegisterView,
    meta: {
      layout: "auth-layout",
      title: "Register - Sider.Money",
      image: "https://media.discordapp.net/attachments/994181274531024969/1179583537603694603/facebook-post_low.jpg"
    },
  },
  // {
  //   path: "/waitlist",
  //   component: Views.WaitListView,
  //   meta: {
  //     layout: "auth-layout",
  //     title: "Waitlist - Sider.Money",
  //     notSitemap: true,
  //   },
  // },
  {
    path: "/forgot-password",
    component: Views.ForgotPasswordView,
    meta: {
      layout: "auth-layout",
      title: "Forgot Password - Sider.Money",
      image: "https://media.discordapp.net/attachments/994181274531024969/1179583537603694603/facebook-post_low.jpg",
      notSitemap: true,
    },
  },
  {
    path: "/verify/:token",
    component: Views.VerifyView,
    meta: {
      layout: "authenticated-layout",
      title: "Verify - Sider.Money",
      image: "https://media.discordapp.net/attachments/994181274531024969/1179583537603694603/facebook-post_low.jpg",
      notSitemap: true,
    },
  },
  {
    path: "/discover",
    component: Views.DiscoverView,
    meta: {
      layout: "authenticated-layout",
      title: "Discover - Sider.Money",
      image: "https://media.discordapp.net/attachments/994181274531024969/1179583537603694603/facebook-post_low.jpg"
    },
  },
  {
    path: "/discover/c/:categoryId?",
    component: Views.DiscoverView,
    meta: {
      layout: "authenticated-layout",
      title: "Discover - Sider.Money",
      notSitemap: true,
    },
  },
  {
    path: "/p/:postId?",
    component: Views.PostView,
    meta: {
      layout: "authenticated-layout",
      api: {
        url: 'https://api.sider.money/api/v1/getPostById/$0',
        param: ['postId'],
        bodyImage: '.files[0].medium',
        bodyVideo: '.files[0].video',
        bodyThumbnail: '.files[0].thumbnail',
        bodyTitle: [
          {
            selector: '.owner.name',
            formatter: (title) => title
          }, 
          {
            selector:  '.owner.slug',
            formatter: (slug) => ` @${slug}`         
          }
        ],
        bodyDescription: [
          {
            selector: '.likes.length + requestDescription.instagram_likes',
            formatter: (likes) => `${formatterNumber(likes)} likes, `
          }, 
          {
            selector: '.comments.length',
            formatter: (name) => `${formatterNumber(name)} comments, `
          },
          {
            selector: '.description',
            formatter: (description) => description
          }
        ],
      },
      notSitemap: true,
    },
  },
  {
    path: "/",
    component: Views.HomeView,
    meta: {
      layout: "authenticated-layout",
      title: "Home - Sider.Money",
    },
  },
  {
    path: "/settings",
    component: Views.SettingsView,
    meta: {
      layout: "settings-layout",
      title: "Settings - Sider.Money",
      image: "https://media.discordapp.net/attachments/994181274531024969/1179583537603694603/facebook-post_low.jpg",
      notSitemap: true,
    },
  },
  {
    path: "/settings/personal-data",
    component: Views.PersonalDataView,
    meta: {
      layout: "settings-layout",
      title: "Personal Data - Sider.Money",
      image: "https://media.discordapp.net/attachments/994181274531024969/1179583537603694603/facebook-post_low.jpg",
      notSitemap: true,
    },
  },
  {
    path: "/settings/wallet",
    component: Views.WalletView,
    meta: {
      layout: "settings-layout",
      title: "Wallet - Sider.Money",
      image: "https://media.discordapp.net/attachments/994181274531024969/1179583537603694603/facebook-post_low.jpg",
      notSitemap: true,
    },
  },
  {
    path: "/settings/membership",
    component: Views.MembershipView,
    meta: {
      layout: "settings-layout",
      title: "Membership - Sider.Money",
      image: "https://media.discordapp.net/attachments/994181274531024969/1179583537603694603/facebook-post_low.jpg",
      notSitemap: true,
    },
  },
  {
    path: "/settings/affiliates",
    component: Views.AffiliatesView,
    meta: {
      layout: "settings-layout",
      title: "Affiliates - Sider.Money",
      image: "https://media.discordapp.net/attachments/994181274531024969/1179583537603694603/facebook-post_low.jpg",
      notSitemap: true,
    },
  },
  {
    path: "/settings/notifications",
    component: Views.NotificationsView,
    meta: {
      layout: "settings-layout",
      title: "Notifications - Sider.Money",
      image: "https://media.discordapp.net/attachments/994181274531024969/1179583537603694603/facebook-post_low.jpg",
      notSitemap: true,
    },
  },
  {
    path: "/chat",
    component: Views.ChatView,
    meta: {
      layout: "not-authenticated-layout",
      title: "Chat - Sider.Money",
      image: "https://media.discordapp.net/attachments/994181274531024969/1179583537603694603/facebook-post_low.jpg",
    },
  },
  {
    path: "/become-a-creator",
    component: Views.BecomeACreatorView,
    meta: {
      layout: "become-a-creator-layout",
      title: "Become a Creator - Sider.Money",
      image: "https://media.discordapp.net/attachments/994181274531024969/1179583537603694603/facebook-post_low.jpg",
    },
  },
  // {
  //   path: "/crash",
  //   component: Views.CrashView,
  //   meta: {
  //     layout: "authenticated-layout",
  //     notSitemap: true,
  //   },
  // },
  {
    path: "/terms-invest",
    component: Views.TermsInvestView,
    meta: {
      layout: "not-authenticated-layout",
      title: "Terms Invest - Sider.Money", 
      image: "https://media.discordapp.net/attachments/994181274531024969/1179583537603694603/facebook-post_low.jpg",   
      notSitemap: true,
    },
  },
  {
    path: "/dmca",
    component: Views.TermsDmcaView,
    meta: {
      layout: "not-authenticated-layout",
      title: "Terms DMCA - Sider.Money",
      image: "https://media.discordapp.net/attachments/994181274531024969/1179583537603694603/facebook-post_low.jpg",
      notSitemap: true,
    },
  },
  {
    path: "/terms",
    component: Views.TermsView,
    meta: {
      layout: "not-authenticated-layout",
      title: "Terms - Sider.Money",
      image: "https://media.discordapp.net/attachments/994181274531024969/1179583537603694603/facebook-post_low.jpg",
      notSitemap: true,
    },
  },
  {
    path: "/privacy",
    component: Views.PrivacyView,
    meta: {
      layout: "not-authenticated-layout",
      title: "Privacy - Sider.Money",
      image: "https://media.discordapp.net/attachments/994181274531024969/1179583537603694603/facebook-post_low.jpg",
      notSitemap: true,
    },
  },
  {
    path: "/community",
    component: Views.CommunityView,
    meta: {
      layout: "not-authenticated-layout",
      title: "Community - Sider.Money",
      image: "https://media.discordapp.net/attachments/994181274531024969/1179583537603694603/facebook-post_low.jpg",
      notSitemap: true,
    },
  },
  {
    path: "/faq",
    component: Views.FaqView,
    meta: {
      layout: "not-authenticated-layout",
      title: "FAQ - Sider.Money",
      image: "https://media.discordapp.net/attachments/994181274531024969/1179583537603694603/facebook-post_low.jpg",
      notSitemap: true,
    },
  },
  {
    path: "/:slug/:postId?",
    component: Views.ProfileView,
    meta: {
      layout: "authenticated-layout",
      title: 'Profile - Sider.Money',
      notSitemap: true,
      api: {
        url: "https://api.sider.money/api/v1/getUserProfile/$0",
        param: ["slug"],
        bodyImage: ".avatar"
      }
    },
  },
];
