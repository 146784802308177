import LogoComponent from './shared/Logo.vue';
import FooterComponent from './shared/Footer.vue';
import NavbarComponent from './shared/Navbar.vue';
import CategoriesCards from './shared/CategoriesCards.vue';
import LoginWithGoogleComponent from './buttons/LoginWithGoogleComponent';
import VideoComponent from './Video/VideoComponent';


export default (Vue) => {
    Vue.component('s-logo', LogoComponent);
    Vue.component('s-footer', FooterComponent);
    Vue.component('s-navbar', NavbarComponent);
    Vue.component('s-categories-card', CategoriesCards)
    Vue.component("s-video", VideoComponent);
    Vue.component("s-login-with-google", LoginWithGoogleComponent);

}