<template>
    <div style="margin:2rem">
        <v-btn
        class="btn-fab"
        fab
        small
        @click="$router.go(-1)"
      >
        <i class="fa-regular fa-chevron-left" style="font-size: 20px; "></i>
      </v-btn>
      <div>
        <h1 class="title" style="text-align: center; margin: 2rem;">Investor terms</h1>

      </div>
     Lorem ipsum dolor, sit amet consectetur adipisicing elit. Deserunt, non eaque sint nemo sequi facilis quam, maiores perferendis ullam voluptatibus animi suscipit. Assumenda blanditiis impedit totam, eos reiciendis repudiandae recusandae!
      
    </div>
</template>

<script> 

 export default {
     name: 'InvestView',
    
 }


</script>