<template>
  <img :class="`${file?'multi':'single'}-media__image`"
    :style="!post.iMSubscriber && post.subscribers_only && me.id != user.id || (Number(post.pay_per_view > 0) && !post.paid)? 'z-index: 0;' : 'z-index: 1;'"
    :src="src">
</template>

<script>
  import utilsMixin from '@/mixins/utils'
  export default {
    mixins: [utilsMixin],
    computed: {
      src() {
        let src = "";
        if(this.file){
          src = `${
            this.file.medium||''
          }`
        }else{
          src = `${
            this.post.files[0].medium||''
          }`
        }
        return src;
      }
    },
    props: {
      file: {
        type: Object,
        default: () => {}
      },
      post: {
        type: Object,
        default: () => {}
      }
    }
  }
</script>

<style lang="scss">
  .single-media{
    &__image-wrapper {
      max-height: 100vh;
    }

    &__image {

      position: absolute;
      max-height: 100%;
      max-width: 100%;

      cursor: pointer;
      z-index: 1;
    }
  }

  .multi-media{
    &__image {
      min-width: 100%;
      object-fit: contain;
      height: 100vh;
    }
  }
</style>