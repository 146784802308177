<template>
  <transition name="component-fade" mode="out-in">
        <div style="width: 100%;display: flex;justify-content: center;">
            <s-categories-card class="categories-parent" style="margin-top: 16px;" :isLoading="isLoading" :categories="categories" @onClick="$emit('searchUsersByCategory', $event)"></s-categories-card>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        categories: {
            type: Array,
            required: true
        },
        isLoading: {
            type: Boolean,
            required: true
        },
    }
}
</script>



<style>
.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<style lang="scss">
.categories-parent {
    @media (max-width: 768px) {
        width: 95%;
    }
}
</style>