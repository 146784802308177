<template>
  <div style="padding: 35px;max-width: 800px;">
    <center style="padding-top: 36px;">
      <s-logo />
      <br><br>
      Enter your email to receive a reset password code
      <v-text-field class="inputs-login" filled style="margin-top: 3em;font-size: 14px;color: #007BFF;"
        placeholder="E-mail" prepend-inner-icon="mdi-email-outline" v-model="$store.state.authEmail" @keypress.enter="sendEmail"></v-text-field>

      <v-btn @click="sendEmail" :loading="loading" style="width: 100%;margin: auto;color: white;text-transform: none;"
        color="#007BFF" x-large elevation="0">
        Send Email
      </v-btn>
      <div style="display:grid;grid-template-columns: 1fr;margin-bottom: 100px;">
        <div style="display: grid;place-items: center;padding: .8em;">
          <span style="font-size: 13px;color: #999999;cursor: pointer;" @click="$router.push('/login')">Go Back</span>
        </div>
      </div>

      <span style="font-sizxe: 14px;color: #999;">Don't have an account?</span>
      <br>
      <h3 class="text-gradient" style="margin-top: .5em;font-size: 14px;cursor: pointer;"
        @click="$router.push('/register')">Sign Up Sider.Money</h3>
      <br><br><br>

    </center>
  </div>
</template>

<script>
  // import SlideUnlock from "vue2-slide-unlock"
  export default {
    data: () => ({
      email: "",
      password: "",
      rememberMe: false,
      loading: false
    }),
    mounted(){
      this.$el.querySelector('input').focus();
    },
    computed: {
      verifyInputs() {
        // use a regex to verify if email is valid
        /* eslint-disable-next-line */
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.$store.state.authEmail.trim())) {
          if (this.password) {
            return false;
          }
        }

        return true;
      }
    },
    methods: {
      sendEmail() {

        this.loading = true;
        this.$http.post('/api/v1/requestChangePassword', {
          email: this.$store.state.authEmail.toLowerCase().trim(),
        }).then(() => {

          this.$emit('nextStep');
          this.loading = false;
        }).catch(err => {
          if (err && err.response) {
            this.$swal({
              title: 'Error',
              text: err.response.data.error,
              icon: 'error',
              showConfirmButton: false,
              timer: 2000
            })
          }
          this.loading = false;
        })
      },
    }
  }
</script>

<style>
  .footer-out {
    background-color: white;
    width: 100%;
    bottom: 0%;
    left: 0;
    right: 0;
    margin: auto;
  }

  .text-gradient {
    background-color: #f3ec78;
    background-image: linear-gradient(45deg, rgba(21, 168, 216, 1), rgba(72, 79, 248, 1));
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }


  .slideunlock-text {
    font-size: 14px !important;
  }

  .slideunlock {
    background: linear-gradient(90deg, rgba(21, 168, 216, 1) 0%, rgba(72, 79, 248, 1) 100%) !important;
  }

  .slideunlock .slideunlock-handler {
    background-color: rgba(255, 255, 255, 0.329) !important;
    color: white !important;
  }

  :root {
    --su-handler-bg: url("https://i.imgur.com/N0UOZJn.png");
    --su-color-progress-normal-bg: transparent;
    --su-color-progress-complete-bg: transparent;
  }


  .slideunlock .slideunlock-text {
    color: white !important;
  }

  .slideunlock .slideunlock-text:before {
    display: unset;
    padding-top: 1em;
  }

  .inputs-login input {
    padding-left: 10px !important;
  }
</style>

<style scoped>
  .v-label {
    font-size: 14px;
  }
</style>