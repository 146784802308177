<template>
    <div>
        <v-tabs v-model="tab" color="#007BFF"
            style="position: sticky;top:0;left:1em;z-index: 1000;">
            <v-tabs-slider color="#007BFF"></v-tabs-slider>

            <v-tab href="#tab-services">
                <small>SERVICES
                    
                        <v-chip small style="margin-left: 5px;color: #0089FF;background-color: rgb(0,137,255,.1);">
                            1
                        </v-chip>
                </small>
            </v-tab>

            <v-tab href="#tab-premium">
                <small>PREMIUM
                    
                        <v-chip small style="margin-left: 5px;color: #0089FF;background-color: rgb(0,137,255,.1);">
                           3
                        </v-chip>
                </small>
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
                <v-tab-item value="tab-services">
                    <v-card flat>
                        
                        waiting
                        <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
                    </v-card>
                </v-tab-item>
                <v-tab-item value="tab-premium">
                    <v-card flat>
                        inprogress
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
    </div>
</template>

<script>
    export default {
        data: () => ({
            tab: null,
        })
    }
</script>