<template>
    <div style="padding: 35px;max-width: 800px;">
        <center style="padding-top: 36px;">
            <s-logo />
            <br>
            <br>
            Join the waitlist to reserve your Slug,
            <br>
            and be the first to know when we launch.
            <v-text-field class="inputs-login" filled style="margin-top: 2em;font-size: 14px;color: #007BFF;"
                placeholder="E-mail" prepend-inner-icon="mdi-email-outline" v-model="email">
            </v-text-field>
            <v-text-field class="inputs-login" filled style="font-size: 14px;color: #007BFF;" placeholder="Slug"
                prepend-inner-icon="mdi-at" v-model="slug">
            </v-text-field>

            <v-btn style="width: 100%;margin: auto;color: white;text-transform: none;" color="#007BFF" x-large
                elevation="0" @click="registerWaitlist" :disabled="verifyInputs" :loading="loading">
                Reserve your Slug
            </v-btn>
            <br>
            <div class="hide-desktop" style="width: 300px;text-align: left;font-size: 12px;">
                <br><br><br><br><br><br>
                <b>💸 Sider Money</b> is the best place for creators to offer subscriptions,<br>💼 ensuring exclusive
                access to their work <br>👥 and a more meaningful connection with their communities .
                <br><br>
                💖 Get paid by receiving likes from your community.
                <br><br>
                <i class="fa-brands fa-bitcoin"></i> Payments support in crypto currencies with 0% withdraw fees
            </div>
        </center>
    </div>
</template>

<script>
    import axios from 'axios';
    export default {
        data: () => ({
            email: "",
            slug: "",
            rememberMe: false,
            loading: false
        }),
        computed: {
            getBaseURL() {
                return process.env.VUE_APP_API_BASE_URL;
            },
            verifyInputs() {
                // use a regex to verify if email is valid
                /* eslint-disable-next-line */
                if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email.trim())) {
                    if (this.slug.trim().length > 3) {
                        return false;
                    }
                }

                return true;
            }
        },
        methods: {
            async registerWaitlist() {

                let ip = await axios.get('https://api.ipify.org/?format=json').then((res) => {
                    return res.data.ip;
                }).catch(err => {
                    console.log(err);
                })

                this.loading = true;

                this.$http.post('/api/v1/registerWaitlist', {
                    email: this.email.toLowerCase().trim(),
                    slug: this.slug.toLowerCase().trim(),
                    ip: ip,
                }).then(() => {
                    this.loading = false;

                    this.$swal({
                        title: 'Success',
                        text: 'You have been added to the waitlist',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 2000
                    })

                    this.email = "";
                    this.slug = "";
                }).catch(err => {
                    this.loading = false;

                    this.$swal({
                        title: 'Error',
                        text: err.response.data,
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 2000
                    })
                })
            },
        }
    }
</script>

<style>
    .footer-out {
        background-color: white;
        width: 100%;
        bottom: 0%;
        left: 0;
        right: 0;
        margin: auto;
    }

    .text-gradient {
        background-color: #f3ec78;
        background-image: linear-gradient(45deg, rgba(21, 168, 216, 1), rgba(72, 79, 248, 1));
        background-size: 100%;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
    }


    .slideunlock-text {
        font-size: 14px !important;
    }

    .slideunlock {
        background: linear-gradient(90deg, rgba(21, 168, 216, 1) 0%, rgba(72, 79, 248, 1) 100%) !important;
    }

    .slideunlock .slideunlock-handler {
        background-color: rgba(255, 255, 255, 0.329) !important;
        color: white !important;
    }

    :root {
        --su-handler-bg: url("https://i.imgur.com/N0UOZJn.png");
        --su-color-progress-normal-bg: transparent;
        --su-color-progress-complete-bg: transparent;
    }


    .slideunlock .slideunlock-text {
        color: white !important;
    }

    .slideunlock .slideunlock-text:before {
        display: unset;
        padding-top: 1em;
    }

    .inputs-login input {
        padding-left: 10px !important;
    }

    @media (min-width: 769px) {
        .hide-desktop {
            display: none;
        }
    }
</style>

<style scoped>
    .v-label {
        font-size: 14px;
    }
</style>