var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"position":"relative","overflow":"auto","height":"500px"}},[_c('div',{staticClass:"profile-banner",staticStyle:{"height":"100px"}},[_c('v-img',{staticStyle:{"position":"absolute","left":"0","width":"100%","height":"100px","border-bottom-left-radius":"10px","border-bottom-right-radius":"10px"},attrs:{"src":("" + (_vm.user.banner)),"lazy-src":("" + (_vm.user.banner)),"max-height":"284px"}}),_c('v-btn',{staticClass:"btn-fab",staticStyle:{"top":"10%"},attrs:{"fab":"","small":"","absolute":"","right":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('i',{staticClass:"fa fa-times",staticStyle:{"font-size":"20px"},attrs:{"aria-hidden":"true"}})])],1),_c('v-img',{staticClass:"profile-image",staticStyle:{"position":"absolute","margin-left":"2em","width":"100px !important","height":"100px !important"},attrs:{"src":("" + (_vm.user.avatar)),"lazy-src":("" + (_vm.user.avatar))}}),(
      _vm.user.subscribedPackages.length > 0 &&
      !_vm.getHighestPaidSubscription.canceled &&
      _vm.user.subscribedPackages
        .filter(function (p) { return p.bundle_key == 'subscription_price_free'; })
        .filter(function (v) { return v.canceled == false; })
        .flat().length > 0
    )?_c('div',{staticStyle:{"padding-left":"3em","padding-top":"5em"}},[_c('span',{staticClass:"text-white"},[(_vm.renewPrice > 0)?_c('span',[_vm._v("Renews at "+_vm._s(_vm.calcSubscriptionsExpires)+" for "+_vm._s(_vm.renewPrice)+"$ "),_c('br'),_c('br'),_c('br')]):_vm._e(),_vm._v(" If you unsubscribe you lose: ")]),_c('br'),_c('v-list-item',{staticStyle:{"padding":"0em"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-white",staticStyle:{"word-wrap":"wrap"}},[_c('i',{staticClass:"fa fa-times text-primary",attrs:{"aria-hidden":"true"}}),_vm._v(" Access to all content "),_c('small',[_vm._v("(Free subscription gives access only to free posts)")])])],1)],1),_c('v-list-item',{staticStyle:{"padding":"0em"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-white"},[_c('i',{staticClass:"fa fa-times text-primary",attrs:{"aria-hidden":"true"}}),_vm._v(" Direct message "),_c('small',[_vm._v("(Free & paid subscription)")])])],1)],1)],1):_vm._e(),(
      _vm.user.subscribedPackages.length == 0 ||
      _vm.user.subscribedPackages
        .filter(function (p) { return p.bundle_key == 'subscription_price_free'; })
        .filter(function (v) { return v.canceled == false; })
        .flat().length == 0
    )?_c('div',{staticStyle:{"padding-left":"3em","padding-top":"2em"}},[_c('br'),_c('br'),(_vm.getHighestPaidSubscription.canceled)?_c('span',{staticStyle:{"color":"#cbcfd4"}},[_vm._v(" Your "+_vm._s(_vm.bundleNames[_vm.getHighestPaidSubscription.bundle_key])+" subscription "),_c('br'),_vm._v(" expires at "+_vm._s(_vm.calcSubscriptionsExpires)+" "),_c('br'),_c('br')]):_vm._e(),_c('span',{staticStyle:{"color":"#cbcfd4","font-weight":"bolder"}},[_vm._v(" Subscribe and get the following benefits: ")]),_c('br'),_c('br'),_c('v-list-item',{staticStyle:{"padding":"0em"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"color":"#cbcfd4"}},[_c('i',{staticClass:"fa fa-check text-primary",attrs:{"aria-hidden":"true"}}),_vm._v(" Access to all content "),_c('small',[_vm._v("(Free subscription gives access only to free posts)")])])],1)],1),_c('v-list-item',{staticStyle:{"padding":"0em"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"color":"#cbcfd4"}},[_c('i',{staticClass:"fa fa-check text-primary",attrs:{"aria-hidden":"true"}}),_vm._v(" Direct message "),_c('small',[_vm._v("(Free & paid subscription)")])])],1)],1),_c('v-list-item',{staticStyle:{"padding":"0em"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"color":"#cbcfd4"}},[_c('i',{staticClass:"fa fa-check text-primary",attrs:{"aria-hidden":"true"}}),_vm._v(" Cancel anytime "),_c('small',[_vm._v("(You will still have access until it expires)")])])],1)],1)],1):_vm._e(),_c('br'),_c('center',[(_vm.hideButtonsBasedOnSubscription('subscription_price_free'))?_c('v-btn',{class:{
        'btn-subscribe-active':
          !_vm.user.subscribedPackages.find(
            function (p) { return p.bundle_key == 'subscription_price_free'; }
          ) || _vm.getHighestPaidSubscription.canceled,
        'btn-subscribe-not-active':
          _vm.user.subscribedPackages.find(
            function (p) { return p.bundle_key == 'subscription_price_free'; }
          ) && !_vm.getHighestPaidSubscription.canceled,
      },staticStyle:{"background-color":"rgba(0, 123, 255, 0.2) !important","font-weight":"bolder","color":"white","width":"90%"},attrs:{"elevation":"0","rounded":"","large":""},on:{"click":function($event){return _vm.subscribe('subscription_price_free')}}},[(
          !_vm.user.subscribedPackages.find(
            function (p) { return p.bundle_key == 'subscription_price_free'; }
          ) ||
          _vm.user.subscribedPackages
            .filter(function (p) { return p.bundle_key == 'subscription_price_free'; })
            .filter(function (v) { return v.canceled == false; })
            .flat().length == 0
        )?_c('div',{staticStyle:{"display":"flex","width":"100%","justify-content":"space-between","text-transform":"capitalize"}},[(
            !_vm.user.subscribedPackages.find(
              function (p) { return p.bundle_key == 'subscription_price_free'; }
            ) ||
            _vm.user.subscribedPackages
              .filter(function (p) { return p.bundle_key == 'subscription_price_free'; })
              .filter(function (v) { return v.canceled == false; })
              .flat().length == 0
          )?_c('div',[(
              _vm.user.subscribedPackages
                .filter(function (p) { return p.bundle_key == 'subscription_price_free'; })
                .filter(function (v) { return v.canceled == true; })
                .flat().length > 0
            )?_c('span',[_vm._v(" Renew ")]):_c('span',[_vm._v(" Subscribe ")])]):_vm._e(),(
            !_vm.user.subscribedPackages.find(
              function (p) { return p.bundle_key == 'subscription_price_free'; }
            ) ||
            _vm.user.subscribedPackages
              .filter(function (p) { return p.bundle_key == 'subscription_price_free'; })
              .filter(function (v) { return v.canceled == false; })
              .flat().length == 0
          )?_c('div',[_vm._v(" FREE ")]):_vm._e()]):_vm._e(),(
          _vm.user.subscribedPackages.find(
            function (p) { return p.bundle_key == 'subscription_price_free'; }
          ) &&
          _vm.user.subscribedPackages
            .filter(function (p) { return p.bundle_key == 'subscription_price_free'; })
            .filter(function (v) { return v.canceled == false; })
            .flat().length > 0
        )?_c('div',{staticStyle:{"display":"flex","width":"100%","justify-content":"center","text-transform":"capitalize"}},[_c('span',[_vm._v("Unsubscribe free")])]):_vm._e()]):_vm._e(),_c('br'),_c('br'),(
        _vm.user.subscription_price > 0 &&
        _vm.hideButtonsBasedOnSubscription('subscription_price')
      )?_c('v-btn',{class:{
        'btn-subscribe-active':
          !_vm.user.subscribedPackages.find(
            function (p) { return p.bundle_key == 'subscription_price'; }
          ) || _vm.getHighestPaidSubscription.canceled,
        'btn-subscribe-not-active':
          _vm.user.subscribedPackages.find(
            function (p) { return p.bundle_key == 'subscription_price'; }
          ) && !_vm.getHighestPaidSubscription.canceled,
      },staticStyle:{"background-color":"rgba(0, 123, 255, 0.4) !important","font-weight":"bolder","color":"white","width":"90%"},attrs:{"elevation":"0","rounded":"","large":""},on:{"click":function($event){return _vm.subscribe('subscription_price')}}},[(
          !_vm.user.subscribedPackages.find(
            function (p) { return p.bundle_key == 'subscription_price'; }
          ) ||
          (_vm.getHighestPaidSubscription && _vm.getHighestPaidSubscription.canceled)
        )?_c('div',{staticStyle:{"display":"flex","width":"100%","justify-content":"space-between","text-transform":"capitalize"}},[(
            !_vm.user.subscribedPackages.find(
              function (p) { return p.bundle_key == 'subscription_price'; }
            ) ||
            (_vm.getHighestPaidSubscription &&
              _vm.getHighestPaidSubscription.canceled)
          )?_c('div',[(
              _vm.user.subscribedPackages.find(
                function (p) { return p.bundle_key == 'subscription_price'; }
              ) &&
              _vm.user.subscribedPackages.find(
                function (p) { return p.bundle_key == 'subscription_price'; }
              ).canceled
            )?[_vm._v(" Renew ")]:_vm._e(),_vm._v(" Monthly ")],2):_vm._e(),(
            !_vm.user.subscribedPackages.find(
              function (p) { return p.bundle_key == 'subscription_price'; }
            ) ||
            (_vm.getHighestPaidSubscription &&
              _vm.getHighestPaidSubscription.canceled)
          )?_c('div',[_vm._v(" "+_vm._s(_vm.user.subscription_price)+"$ ")]):_vm._e()]):_vm._e(),(
          _vm.user.subscribedPackages.find(
            function (p) { return p.bundle_key == 'subscription_price'; }
          ) &&
          !_vm.user.subscribedPackages.find(
            function (p) { return p.bundle_key == 'subscription_price'; }
          ).canceled
        )?_c('div',{staticStyle:{"display":"flex","width":"100%","justify-content":"center","text-transform":"capitalize"}},[_c('span',[_vm._v("Unsubscribe Monthly")])]):_vm._e()]):_vm._e(),(_vm.hideButtonsBasedOnSubscription('subscription_price'))?_c('span',[_c('br'),_c('br')]):_vm._e(),(
        _vm.user.subscription_price_trimester &&
        _vm.hideButtonsBasedOnSubscription('subscription_price_trimester')
      )?_c('v-btn',{class:{
        'btn-subscribe-active':
          !_vm.user.subscribedPackages.find(
            function (p) { return p.bundle_key == 'subscription_price_trimester'; }
          ) || _vm.getHighestPaidSubscription.canceled,
        'btn-subscribe-not-active':
          _vm.user.subscribedPackages.find(
            function (p) { return p.bundle_key == 'subscription_price_trimester'; }
          ) && !_vm.getHighestPaidSubscription.canceled,
      },staticStyle:{"background-color":"rgba(0, 123, 255, 0.6) !important","font-weight":"bolder","color":"white","width":"90%"},attrs:{"elevation":"0","rounded":"","large":""},on:{"click":function($event){return _vm.subscribe('subscription_price_trimester')}}},[(
          !_vm.user.subscribedPackages.find(
            function (p) { return p.bundle_key == 'subscription_price_trimester'; }
          ) ||
          (_vm.getHighestPaidSubscription && _vm.getHighestPaidSubscription.canceled)
        )?_c('div',{staticStyle:{"display":"flex","width":"100%","justify-content":"space-between","text-transform":"capitalize"}},[(
            !_vm.user.subscribedPackages.find(
              function (p) { return p.bundle_key == 'subscription_price_trimester'; }
            ) ||
            (_vm.getHighestPaidSubscription &&
              _vm.getHighestPaidSubscription.canceled)
          )?_c('div',[(
              _vm.user.subscribedPackages.find(
                function (p) { return p.bundle_key == 'subscription_price_trimester'; }
              ) &&
              _vm.user.subscribedPackages.find(
                function (p) { return p.bundle_key == 'subscription_price_trimester'; }
              ).canceled
            )?[_vm._v("Renew")]:_vm._e(),_vm._v(" 3 Months "),(_vm.calcDiscount(_vm.user.subscription_price_trimester, 3))?[_vm._v("("+_vm._s(_vm.calcDiscount(_vm.user.subscription_price_trimester, 3).toFixed(2))+"% off)")]:_vm._e()],2):_vm._e(),(
            !_vm.user.subscribedPackages.find(
              function (p) { return p.bundle_key == 'subscription_price_trimester'; }
            ) ||
            (_vm.getHighestPaidSubscription &&
              _vm.getHighestPaidSubscription.canceled)
          )?_c('div',[_vm._v(" "+_vm._s(_vm.user.subscription_price_trimester)+"$ ")]):_vm._e()]):_vm._e(),(
          _vm.user.subscribedPackages.find(
            function (p) { return p.bundle_key == 'subscription_price_trimester'; }
          ) &&
          _vm.getHighestPaidSubscription &&
          !_vm.getHighestPaidSubscription.canceled
        )?_c('div',{staticStyle:{"display":"flex","width":"100%","justify-content":"center","text-transform":"capitalize"}},[(
            _vm.user.subscribedPackages.find(
              function (p) { return p.bundle_key == 'subscription_price_trimester'; }
            )
          )?_c('div',[(
              !_vm.user.subscribedPackages.find(
                function (p) { return p.bundle_key == 'subscription_price_trimester'; }
              ).canceled
            )?_c('span',[_vm._v("Unsubscribe 3 months")]):_vm._e()]):_vm._e()]):_vm._e()]):_vm._e(),(_vm.hideButtonsBasedOnSubscription('subscription_price_trimester'))?_c('span',[_c('br'),_c('br')]):_vm._e(),(
        _vm.user.subscription_price_semester &&
        _vm.hideButtonsBasedOnSubscription('subscription_price_semester')
      )?_c('v-btn',{class:{
        'btn-subscribe-active':
          !_vm.user.subscribedPackages.find(
            function (p) { return p.bundle_key == 'subscription_price_semester'; }
          ) || _vm.getHighestPaidSubscription.canceled,
        'btn-subscribe-not-active':
          _vm.user.subscribedPackages.find(
            function (p) { return p.bundle_key == 'subscription_price_semester'; }
          ) && !_vm.getHighestPaidSubscription.canceled,
      },staticStyle:{"background-color":"rgba(0, 123, 255, 0.8) !important","font-weight":"bolder","color":"white","width":"90%"},attrs:{"elevation":"0","rounded":"","large":""},on:{"click":function($event){return _vm.subscribe('subscription_price_semester')}}},[(
          !_vm.user.subscribedPackages.find(
            function (p) { return p.bundle_key == 'subscription_price_semester'; }
          ) ||
          (_vm.getHighestPaidSubscription && _vm.getHighestPaidSubscription.canceled)
        )?_c('div',{staticStyle:{"display":"flex","width":"100%","justify-content":"space-between","text-transform":"capitalize"}},[(
            !_vm.user.subscribedPackages.find(
              function (p) { return p.bundle_key == 'subscription_price_semester'; }
            ) ||
            (_vm.getHighestPaidSubscription &&
              _vm.getHighestPaidSubscription.canceled)
          )?_c('div',[(
              _vm.user.subscribedPackages.find(
                function (p) { return p.bundle_key == 'subscription_price_semester'; }
              ) &&
              _vm.user.subscribedPackages.find(
                function (p) { return p.bundle_key == 'subscription_price_semester'; }
              ).canceled
            )?[_vm._v("Renew")]:_vm._e(),_vm._v(" 6 months "),(_vm.calcDiscount(_vm.user.subscription_price_semester, 6))?[_vm._v("("+_vm._s(_vm.calcDiscount(_vm.user.subscription_price_semester, 6).toFixed(2))+"% off)")]:_vm._e()],2):_vm._e(),(
            !_vm.user.subscribedPackages.find(
              function (p) { return p.bundle_key == 'subscription_price_semester'; }
            ) ||
            (_vm.getHighestPaidSubscription &&
              _vm.getHighestPaidSubscription.canceled)
          )?_c('div',[_vm._v(" "+_vm._s(_vm.user.subscription_price_semester)+"$ ")]):_vm._e()]):_vm._e(),(
          _vm.user.subscribedPackages.find(
            function (p) { return p.bundle_key == 'subscription_price_semester'; }
          ) &&
          _vm.getHighestPaidSubscription &&
          !_vm.getHighestPaidSubscription.canceled
        )?_c('div',{staticStyle:{"display":"flex","width":"100%","justify-content":"center","text-transform":"capitalize"}},[(
            _vm.user.subscribedPackages.find(
              function (p) { return p.bundle_key == 'subscription_price_semester'; }
            )
          )?_c('div',[(
              !_vm.user.subscribedPackages.find(
                function (p) { return p.bundle_key == 'subscription_price_semester'; }
              ).canceled
            )?_c('span',[_vm._v("Unsubscribe 6 Months")]):_vm._e()]):_vm._e()]):_vm._e()]):_vm._e(),(_vm.hideButtonsBasedOnSubscription('subscription_price_semester'))?_c('span',[_c('br'),_c('br')]):_vm._e(),(_vm.user.subscription_price_year)?_c('v-btn',{class:{
        'btn-subscribe-active':
          !_vm.user.subscribedPackages.find(
            function (p) { return p.bundle_key == 'subscription_price_year'; }
          ) || _vm.getHighestPaidSubscription.canceled,
        'btn-subscribe-not-active':
          _vm.user.subscribedPackages.find(
            function (p) { return p.bundle_key == 'subscription_price_year'; }
          ) && !_vm.getHighestPaidSubscription.canceled,
      },staticStyle:{"background-color":"rgba(0, 123, 255, 1) !important","font-weight":"bolder","color":"white","width":"90%"},attrs:{"elevation":"0","rounded":"","large":""},on:{"click":function($event){return _vm.subscribe('subscription_price_year')}}},[(
          !_vm.user.subscribedPackages.find(
            function (p) { return p.bundle_key == 'subscription_price_year'; }
          ) ||
          (_vm.getHighestPaidSubscription && _vm.getHighestPaidSubscription.canceled)
        )?_c('div',{staticStyle:{"display":"flex","width":"100%","justify-content":"space-between","text-transform":"capitalize"}},[(
            !_vm.user.subscribedPackages.find(
              function (p) { return p.bundle_key == 'subscription_price_year'; }
            ) ||
            (_vm.getHighestPaidSubscription &&
              _vm.getHighestPaidSubscription.canceled)
          )?_c('div',[(
              _vm.user.subscribedPackages.find(
                function (p) { return p.bundle_key == 'subscription_price_year'; }
              ) &&
              _vm.user.subscribedPackages.find(
                function (p) { return p.bundle_key == 'subscription_price_year'; }
              ).canceled
            )?[_vm._v("Renew")]:_vm._e(),_vm._v(" 1 year "),(_vm.calcDiscount(_vm.user.subscription_price_year, 12))?[_vm._v("("+_vm._s(_vm.calcDiscount(_vm.user.subscription_price_year, 12).toFixed(2))+"% off) ")]:_vm._e()],2):_vm._e(),(
            !_vm.user.subscribedPackages.find(
              function (p) { return p.bundle_key == 'subscription_price_year'; }
            ) ||
            (_vm.getHighestPaidSubscription &&
              _vm.getHighestPaidSubscription.canceled)
          )?_c('div',[_vm._v(" "+_vm._s(_vm.user.subscription_price_year)+"$ ")]):_vm._e()]):_vm._e(),(
          _vm.user.subscribedPackages.find(
            function (p) { return p.bundle_key == 'subscription_price_year'; }
          ) &&
          _vm.getHighestPaidSubscription &&
          !_vm.getHighestPaidSubscription.canceled
        )?_c('div',{staticStyle:{"display":"flex","width":"100%","justify-content":"center","text-transform":"capitalize"}},[(
            _vm.user.subscribedPackages.find(
              function (p) { return p.bundle_key == 'subscription_price_year'; }
            )
          )?_c('div',[(
              !_vm.user.subscribedPackages.find(
                function (p) { return p.bundle_key == 'subscription_price_year'; }
              ).canceled
            )?_c('span',[_vm._v("Unsubscribe 1 year")]):_vm._e()]):_vm._e()]):_vm._e()]):_vm._e()],1),_c('br'),_c('br')],1)}
var staticRenderFns = []

export { render, staticRenderFns }