<template>
    <div style="margin-left: 1.5rem;margin-right: 1.5rem;">
        <v-card flat style="padding-top: 0;" :style=[{width:width,padding:padding}]>

            <template v-if="appendTop" tag="div">

                <h3 style="margin-bottom: .5em;margin-top:.5em;font-weight: normal;text-transform: uppercase;"
                    class="text-primary">
                    Menu

                    <v-btn v-if="this.isMyProfile()" @click="openPopup('insert-menu-item')" class='btn-plus-profile'
                        style="background: linear-gradient(90deg, rgba(21, 168, 216, 1) 0%, rgba(72, 79, 248, 1) 100%) !important;color:white;float:right;"
                        small>
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </h3>
                <div style="border-bottom: 2px solid #007bff;width:100%;"></div>
            </template>
            <v-list id="minhaLista" class="services-list" style="overflow-y: auto;margin: 0;">
                <v-list-item v-for="(item, itemIndex) in $store.state.tipMenuItems" :key="item.id"
                    @click="() => { if(isMyProfile()) { setEditingMenuItem(item) }else{viewMenuItem(item)} }"
                    style="position: relative;padding: 0;cursor: pointer;"
                    :style="itemIndex != $store.state.tipMenuItems.length - 1 ? 'border-bottom: 1px solid #f1f1f1;' : ''">

                    <v-list-item-content>
                        <v-list-item-title>
                            {{item.title}}
                            <span style="float:right;" :style="!isMyProfile() ? 'color: #007BFF;' : ''">

                                {{item.price_service.toFixed(2)}}$

                                <br>

                            </span>
                            <br>

                        </v-list-item-title>
                        <v-list-item-subtitle style="color: #888;" @click="viewMenuItem(item)">
                            <i style="color: #007BFF;" :class="[`${item.category.icon}`]"></i>
                            {{item.category.title}}

                            <small style="font-size: 10px;float:right;">{{item.service_type}}</small>
                        </v-list-item-subtitle>
                        <div style="color: #888;max-width: 85%;" @click="viewMenuItem(item)">
                            {{item.description.substring(0, 100).trim()}}...</div>
                        <div>
                        </div>



                    </v-list-item-content>

                    <br>

                </v-list-item>


            </v-list>
            <v-btn @click="openPopup('insert-menu-item')" class='btn-plus-profile' v-if="showInsertButton()" fab
                :fixed="windowWidth < 1220"  right bottom
                style="background: linear-gradient(90deg, rgba(21, 168, 216, 1) 0%, rgba(72, 79, 248, 1) 100%) !important;color:white;"
                :style="windowWidth > 1219 ? 'right: 50%;transform:translate(50%,100%);' : 'bottom: 12%;'">
                <i class="fa fa-plus" aria-hidden="true"></i>
            </v-btn>

        </v-card>
    </div>
</template>

<script>
    import tipMenuMixin from '@/mixins/tipMenuMixin'
    export default {
        mixins: [tipMenuMixin],
        mounted() {},
        methods: {
            showInsertButton() {
                return this.isMyProfile() && this.$store.state.tab == 'tab-menu'
            },
            test() {
                console.log(1);
            }
        },
        props: {
            width: {
                type: String,
                default: ''
            },
            padding: {
                type: String,
                default: '0'
            },
            appendTop: {
                type: Boolean,
                default: false
            }
        }
    }
</script>