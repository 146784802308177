<template>
  <div>
    <transition name="component-fade" mode="out-in">
      <component :is="`step${step}`" :name="`step${step}`" @nextStep="step++" @backStep="step--"></component>
    </transition>
  </div>
</template>

<script>
import steps from './Steps/';
export default {
  components: {
    ...steps
  },
  data: () => ({
    step: 1
  })
}
</script>