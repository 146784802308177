<template>
    <div style="position: relative;">
        <!-- <transition name="component-fade" mode="out-in"> -->
            <router-view :class="{'_padding-top769w': $route.path != '/discover'}"></router-view>
        <!-- </transition> -->
        
        
    </div>
</template>

<script>
export default {
    name: 'AuthenticatedLayout',
}
</script>



<style>
.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* media query max width */
@media only screen and (min-width: 769px) {
    ._padding-top769w {
      /* margin-top: 50px; */
    }
}
</style>