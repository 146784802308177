<template>
    <div class="grid" style="grid-template-columns: 1fr 1fr;">
        <div class="card card-category" v-for="category in categories" :key="category.id"
            @click="onClick(category)">
            <i v-if="isLoading && isLoadingCategory != category.id" class="primary-color" :class="category.icon" style="font-size: 50px;"></i>
            <i v-if="!isLoading && isLoadingCategory != category.id" class="primary-color" :class="category.icon" style="font-size: 50px;"></i>
            <!-- fontawesome loading -->
            <template v-if="isLoading && isLoadingCategory == category.id" class="vld-overlay">
                <div style="font-size: 50px;">
                    <i class="fa-duotone fa-spin fa-spinner-third primary-color"></i>
                </div>
            </template>
            <br>
            <span class="category-title">
                {{category.title.split(' ')[0]}}<br>
                {{category.title.split(' ')[1]}}<br>
                {{category.title.split(' ')[2]}}<br v-if="category.title.split(' ')[2]">
                {{category.title.split(' ')[3]}}<br v-if="category.title.split(' ')[3]">
                {{category.title.split(' ')[4]}}
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            categories: {
                default: () => ([]),
                type: Array
            },
            isLoading: {
                default: false,
                type: Boolean
            }
        },
        data() {
            return {
                isLoadingCategory: -1
            }
        },
        methods: {
            onClick(category) {
                this.isLoadingCategory = category.id;
                this.$emit('onClick', category);
            }
        },
    }
</script>