<template>
    <div style="position: relative;overflow: auto;" id="dialogScrollView">
        <div class="nav-dialog"
            :style="windowY >= 60 ? 'position: sticky;top:-1.1em;left: 0;z-index: 1000;padding: 1em;padding-right: 0;' : ''">
            <i class="close-dialog fa fa-chevron-left" @click="goBack" aria-hidden="true"
                v-if="setSelectedCategory.id"></i>
            <span class="header-dialog">
                <span style="font-weight: bold;" class="text-white">Add a Item to your Menu</span>
                <br>
                <small v-if="setSelectedCategory.id">{{setSelectedCategory.title}}</small>
            </span>
            <v-btn fab elevation="0" @click="close" small class="btn-fab">
                <i class="close-dialog-2 fa fa-times" aria-hidden="true" ></i>
            </v-btn>
        </div><br>
        <br>
        <transition name="component-fade" mode="out-in">
            <s-categories-card class="dialog-grid-container" style="position: absolute;left: 0;width: 100%;padding: 1em;"
                v-if="!setSelectedCategory.id" :categories="categories" @onClick="setSelectedCategory = $event">
            </s-categories-card>
        </transition>

        <transition name="component-fade" mode="out-in">
            <div v-if="setSelectedCategory.id" style="position: absolute;left: 0;width: 100%;padding: 1em;">
                <v-row align="center">

                
                    <v-col class="d-flex" cols="12" sm="12">
                <v-text-field color="#0989EF" v-model="title" filled label="Title" clearable></v-text-field>
                    </v-col>
                
                    <v-col class="d-flex" cols="12" sm="12">

                <v-textarea color="#0989EF" filled label="Description" v-model="description" clearable></v-textarea>
                    </v-col>
                    <v-col class="d-flex" cols="6" sm="6">
                        <vuetify-money v-model="priceJob" color="#0989EF" filled label="€ Price" clearable
                            :valueWhenIsEmpty="valueWhenIsEmpty" :options="options" />
                    </v-col>
                    <v-col class="d-flex" cols="6" sm="6">
                        <v-select color="#0989EF" :items="servicesTypes" filled
                            v-model="selectedType" label="Price Per"></v-select>
                    </v-col>
                    <v-col class="d-flex" cols="6" sm="6">
                        
                        <vuetify-money v-model="stock" color="#0989EF" filled
                            label="Stock" clearable :valueWhenIsEmpty="''"
                            :options="options" />

                    </v-col>
                </v-row>
                <br><br><br><br><br>
            </div>
        </transition>
        <!-- <slide-unlock style="position: absolute;bottom: 5%;width: 93%;" ref="vueslideunlock" :auto-width="true" :circle="true" :disabled="false"
                    :noanimate="false" :width="400" :height="50" text="SIDER.MONEY $1500,00/lead" success-text=""
                    @completed="complete()" /> -->

        <div class="dialog-grid-container" v-if="setSelectedCategory.id" :key="counterW" :style="wWidth < 768 ? 'position: fixed;' : 'position: absolute;'" style="width: 100%;height: auto;padding: 1em;left:0;bottom:0;height: 90px;">
            <v-btn @click="createService" fixed
            :style="wWidth < 768 ?'bottom: 3%;' : 'bottom: 8%;'"
            style="width: 300px;left: 0;right:0;margin: auto;color: white;" :disabled="inputsValidation"
            color="#007BFF" x-large rounded elevation="0">
            Create
            </v-btn>
        </div>
        
    </div>
</template>

<script>
    export default {
        async beforeMount() {
            this.categories = await this.$http.get('/api/v1/getCategories').then((res) => res.data);
        },
        computed: {
            inputsValidation() {
                return !this.title || !this.description || !this.priceJob || (this.selectedType?false:true);
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.loadtext();
            })
            document.getElementById('dialogScrollView').addEventListener('scroll', this.handleScroll);
            this.wWidth = window.innerWidth;
            window.addEventListener('resize', this.wHandleResize);
        },
        beforeDestroy() {
            if (document.getElementById('dialogScrollView')) {
                document.getElementById('dialogScrollView').removeEventListener('scroll', this.handleScroll);
            
            }
            window.removeEventListener('resize', this.wHandleResize);
        },
        methods: {
            wHandleResize(){
                this.counterW++;
                this.wWidth = window.innerWidth;
            },
            handleScroll(e) {
                this.windowY = e.target.scrollTop;
            },
            createService() {
                this.$http.post('/api/v1/createService', {
                    title: this.title,
                    description: this.description,
                    price_service: parseFloat(this.priceJob),
                    price_suggestion: parseFloat(this.priceSuggestion),
                    categoryId: this.setSelectedCategory.id,
                    max_per_purchase: this.maxPerPurchase || null,
                    stock: this.stock || null,
                    service_type: this.selectedType,
                }).then(() => {
                    this.setSelectedCategory = {};
                    this.title = "";
                    this.description = "";
                    this.price = "";
                    this.$swal({
                        icon: 'success',
                        title: 'Service created successfully',
                        timer: 3000
                    });
                    this.$emit('reload', 'getTipMenu');
                    this.$emit('close');
                })
            },
            close() {
                this.setSelectedCategory = {};
                this.$emit('close');
            },
            goBack() {
                if (!this.setSelectedCategory.id) {
                    this.$emit('close');
                } else {
                    this.setSelectedCategory = {};
                }
            },
            complete() {

            },
            loadtext() {
                // this.$refs.vueslideunlock.$el.querySelector('.slideunlock-text').innerHTML = `
                // <p style='font-weight: bold;padding-top: .4em;font-size: 16px;'>SIDER.MONEY</p>
                // <span style="font-size: 11px;">e ganha 200,00€</span>
                // `
            }
        },
        data: () => ({
            servicesTypes: ['hour', 'day', 'week', 'month', 'service', 'download'],
            counterW: 0,
            stock: "",
            maxPerPurchase: "",
            selectedType: "",
            windowY: 0,
            outlined: true,
            valueWhenIsEmpty: "",
            options: {
                locale: "pt-PT",
                prefix: " € ",
                suffix: "",
                length: 9,
                precision: 0
            },
            priceJob: "",
            priceSuggestion: "",
            title: "",
            description: "",
            categories: [],
            setSelectedCategory: {}
        })
    }
</script>

<style lang="scss">
    .small-font-input .v-label {
        font-size: 10px;
    }
    .nav-dialog {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        align-items: center;
        background-color: white;

        &>.close-dialog {
            font-size: 20px;
            cursor: pointer;
            grid-column: 1;
        }

        &>.close-dialog-2 {
            font-size: 20px;
            cursor: pointer;
            grid-column: 12;
            text-align: right;
        }

        &>.header-dialog {
            text-align: center;
            grid-column: 2 / span 10;

            &>small {
                font-size: 12px;
            }
        }
    }
</style>