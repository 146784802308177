<template>
  <div class="become-a-creator-view" data-app>
    <v-btn icon class="goBack" v-if="step > 0 && step < 5">
      <i class="fa fa-chevron-left" @click="goBack" aria-hidden="true"></i>
    </v-btn>

    <img
      src="/assets/images/favicon.svg"
      width="50px"
      style="position: fixed; top: 2.5%; left: 2%"
    />
    <v-btn icon class="leave" @click="cancelVerification">
      <i class="fa fa-times" aria-hidden="true"></i>
    </v-btn>

    <div v-if="step == -1" class="text-center">
      <h1>Tell us about your content</h1>
      <br />
      <p>
        Every creator gets their own page, and your page name is how<br />siders
        will know you and search for you.<br />You can change it at anytime.
      </p>
      <br />
      <v-text-field
        v-model="slug"
        :rules="slugRules"
        placeholder="your-slug"
        prefix="https://sider.money/"
        required
        filled
        style="
          width: 100%;
          min-width: 300px;
          max-width: 500px;
          padding-left: 1em;
          padding-right: 1em;
        "
      >
      </v-text-field>
      <v-select
        :items="categories"
        v-model="selectedCategory"
        style="
          padding-left: 1em;
          padding-right: 1em;
          width: 100%;
          min-width: 300px;
          max-width: 500px;
        "
        filled
        placeholder="Select a category for your content"
        item-text="title"
        item-value="id"
      >
        <template #item="{ item }">
          <i
            :class="[item.icon, 'text-primary']"
            style="margin-right: 15px"
          ></i>
          {{ item.title }}
        </template>
      </v-select>
      <div style="padding: 1em">
        <div
          v-if="selectedCategory == ADULT_CATEGORY_ID"
          style="
            min-width: 300px;
            max-width: 468px;
            width: auto;
            background-color: rgba(255, 139, 0, 0.05);
            padding: 1em;
            color: #ff8b00;
            font-size: 15px;
          "
        >
          To create explicit adult content, you will need to go through the
          identity verification process.
        </div>
      </div>
      <br />
      <v-btn
        :disabled="!selectedCategory"
        class="welcome-card__buttons btn-sider-money"
        style="transform: scale(1.3)"
        elevation="0"
        @click="setupContent"
      >
        {{ selectedCategory == ADULT_CATEGORY_ID ? "Continue" : "Save" }}
      </v-btn>
    </div>

    <template v-if="selectedCategory === ADULT_CATEGORY_ID">
      <div class="consent-panel" v-if="step == 0">
        <h1>Consent for personal data processing</h1>
        <br />
        <h2>In simple words:</h2>
        <li>
          In order to use the services you need to perform an identity
          verification process, as part of this process biometric data of your
          face and personal data from your government identity document document
          will be captured through camera and processed to authenticate.
        </li>
        <li>
          If you have any questions about how Ondato or Sider.Money please
          contact support@sider.money or check Sider.Money Privacy Policy.
        </li>
        <br />
        <h2>Legal version:</h2>
        <br />
        <h3>Required data</h3>
        <p>
          Please note we use Ondato’s facial recognition identity verification
          services to authenticate you and your government identity document. In
          order to obtain the service from Sider.Money, by clicking “[I agree]”
          I agree that Ondato will receive and manage my personal data,
          including my biometric data, gained from remote identity verification
          of my personal identity by taking and/or filming live image of my
          face, my person’s identity or the document used to identify it and/or
          their image and the personal data contained in them and/or check in
          the population registry: image of my face, name, surname, nationality,
          gender, personal code, date of birth, numbers of the document that is
          being used, date of issue and validity, my signature and the transfer
          of such data to Sider.Money.
        </p>
        <br />
        <h3>Data processing</h3>
        <p>
          I am aware that Ondato is acting as a data processor on behalf of
          Sider.Money and my personal data, including my biometric data, will be
          processed by Ondato until the remote identity verification procedure
          is complete and the results will be transferred to Sider.Money.
          Sider.Money does not receive any facial biometric information
          generated from the images, and our third-party service providers are
          contractually limited to processing this information on our behalf and
          are required to destroy the images and any biometric information
          promptly in accordance with a data retention schedule and Sider.Money
          instructions as set forth in the “Retention of Personal Data” section
          above.
        </p>
        <br />
        <h3>Data handling</h3>
        <p>
          I am aware that I can submit request for information regarding my
          personal data by contacting at support@sider.money. I declare and
          confirm, that all the personal data I will provide for remote identity
          verification, is correct and accurate, and that any government
          documents which I will use for this purpose is authentic, valid and is
          provided to authenticate my identity.
        </p>
        <div class="spacer-mobile"></div>
        <br />
        <center>
          <button class="btn-disagree" @click="disagree">Disagree</button>
          <button class="btn-agree" @click="agree">I Agree</button>
        </center>
      </div>
      <div v-if="step == 1">
        <center>
          <h2>Choose a document and start verification</h2>
        </center>
        <br />
        <div class="card-options-verification-wrapper">
          <div
            class="card-option-verification"
            @click="setDocument('passport')"
          >
            <img src="/assets/images/passport.png" style="width: 90px" />
            <br />
            <div class="label">Passport</div>
          </div>
          <div
            class="card-option-verification"
            @click="setDocument('identity-card')"
          >
            <img src="/assets/images/identity-card.png" style="width: 180px" />
            <br />
            <div class="label">Identity Card</div>
          </div>
          <div
            class="card-option-verification"
            @click="setDocument('driving-license')"
          >
            <img
              src="/assets/images/driving-license.png"
              style="width: 180px"
            />
            <br />
            <div class="label">Driver Licence</div>
          </div>
        </div>
        <br /><br /><br />
        <div
          style="display: flex; align-items: center; justify-content: center"
        >
          <img
            src="https://images-ext-1.discordapp.net/external/78L7K7QYJR1grIRRdp9RXFafusEBpT3_zyQ7rE6iHKA/https/kyc.ondato.com/public/images/certificates/iso-27001-02.png"
            style="margin-right: 15px; width: 50px"
          />
          <img
            src="https://images-ext-1.discordapp.net/external/3lZ_L3jCb4jHKR9nCg8MprSSIiSCGnDGaO-HP1_YPhk/https/kyc.ondato.com/public/images/certificates/gdpr.png"
            style="margin-right: 15px; width: 35px"
          />
          <img
            src="https://images-ext-1.discordapp.net/external/T9His8O078Pk3ef5Iub3F4ayCCpuR5A1FmFLRlLDbCc/https/kyc.ondato.com/public/images/certificates/iso-30107-3-1.png"
            style="margin-right: 15px; width: 100px"
          />
          <img
            src="https://images-ext-1.discordapp.net/external/DNwbsrEfieeMfe83nHA56D7ECPGXQDA2vuRMIVfgAoA/https/kyc.ondato.com/public/images/certificates/iso-30107-3-2.png"
            style="width: 100px"
          />
        </div>
      </div>
      <div v-if="step == 2 || step == 3">
        <center>
          <h2>Please send a picture of your document.</h2>
          <br />
          <p v-if="step == 2">
            Please show the front side of your ID card in the marked <br />area.
          </p>
          <p v-if="step == 3">
            Please show the back side of your ID card in the marked <br />area.
          </p>
          <br />
          <br />
          <img
            v-if="step == 2"
            src="/assets/images/identity-card-front.png"
            class="document-image"
          />
          <img
            v-if="step == 3"
            src="/assets/images/identity-card-back.png"
            class="document-image"
          />
          <br />
          <br />
          <br />
          <br />
          <input
            v-if="step == 2"
            type="file"
            style="width: 0px; height: 0px; position: fixed"
            ref="frontDocument"
            @change="fileHandler"
          />
          <button
            v-if="step == 2"
            class="btn-image"
            @click="clickOnFrontDocument"
            :disabled="fileUploading"
          >
            START <i class="fa fa-camera" aria-hidden="true"></i>
          </button>
          <input
            v-if="step == 3"
            type="file"
            style="width: 0px; height: 0px; position: fixed"
            ref="backDocument"
            @change="fileHandler2"
          />
          <button
            v-if="step == 3"
            class="btn-image"
            @click="clickOnBackDocument"
            :disabled="fileUploading"
          >
            START <i class="fa fa-camera" aria-hidden="true"></i>
          </button>
        </center>
      </div>
      <div v-if="step == 4">
        <center>
          <h2>Take a selfie</h2>
          <br />
          <p>
            Position your face in the middle of the frame and follow the
            <br />instructions. Photo should be not blurry and evenly lit.
          </p>
          <br />
          <br />
          <img src="/assets/images/selfie.png" class="document-image" />
          <br /><br /><br />
          <input
            type="file"
            style="width: 0px; height: 0px; position: fixed"
            ref="selfie"
            @change="fileHandler3"
          />
          <button class="btn-image" @click="clickOnSelfie">
            START
            <i
              class="fa fa-camera"
              aria-hidden="true"
              :disabled="fileUploading"
            ></i>
          </button>
        </center>
      </div>
      <div v-if="step == 5">
        <center>
          <h2>Please wait while we are processing your documents.</h2>
          <br />
          <p>This process can take up to 24 hours.</p>
          <br />
          <br />
          <h1>
            <i
              class="fa-duotone fa-clock"
              style="color: #007bff; font-size: 70px"
            ></i>
          </h1>
          <br />
          <br />
          <button class="btn-image" @click="backToSider" style="width: 200px">
            Back to Sider
          </button>
        </center>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  async beforeMount() {
    this.categories = await this.$http
      .get("/api/v1/getCategories")
      .then((res) => res.data);
    this.slug = this.me.slug;
  },
  async created() {
    let processBack = await this.$http
      .get("/api/v1/getVerificationProcess")
      .then((res) => res.data);
    if (processBack.creator_category_id) {
      this.$router.push(`/${this.me.slug}`);
    }

    this.$store.commit("setCurrentVerificationProcess", processBack);

    let process = this.$store.getters.getCurrentVerificationProcess;

    if (process.consent) {
      this.selectedCategory = this.ADULT_CATEGORY_ID;
      this.step = 1;

      if (process.document) {
        this.step = 2;
      }

      if (process.documentFront) {
        this.step = 3;
      }

      if (process.documentBack) {
        this.step = 4;
      }

      if (process.selfie) {
        this.step = 5;
      }
    }
  },
  methods: {
    async setupContent() {
      // Adult
      var response = await this.$http
        .post("/api/v1/setupContent", {
          slug: this.slug,
          category_id:
            this.selectedCategory != this.ADULT_CATEGORY_ID
              ? this.selectedCategory
              : null,
        })
        .then((res) => res.data)
        .catch((err) => {
          var text = err.response.data.toLowerCase();
          this.$swal({
            title: "Error",
            text: text.charAt(0).toUpperCase() + text.slice(1),
            icon: "error",
          });
        });

      if (response) {
        if (this.selectedCategory == this.ADULT_CATEGORY_ID) {
          this.step++;

          if (response.accessToken) {
            localStorage.setItem("sider-token", response.accessToken);
            this.$token = response.accessToken;
            this.me = this.decryptData(this.$token);
          }
          return;
        }
        var text = response.message.toLowerCase();
        this.$swal({
          title: "Success",
          // capital first letters of response
          text: text.charAt(0).toUpperCase() + text.slice(1),
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });

        if (response.accessToken) {
          localStorage.setItem("sider-token", response.accessToken);
          this.$token = response.accessToken;
          this.me = this.decryptData(this.$token);
        }

        setTimeout(() => {
          window.location.href = `/settings/membership`;
        }, 2000);
      }
    },
    goBack() {
      if (this.step == 1) {
        this.$cookies.delete("sider-verification-process-consent");
      }
      if (this.step == 2) {
        this.$cookies.delete("sider-verification-process-document");
      }
      if (this.step == 3) {
        this.$cookies.delete("sider-verification-process-document-front");
      }
      if (this.step == 4) {
        this.$cookies.delete("sider-verification-process-document-back");
      }
      if (this.step == 5) {
        this.$cookies.delete("sider-verification-process-document-selfie");
      }
      this.step--;
    },
    cancelVerification() {
      // really want to leave the process?
      this.$swal({
        title: this.step == 5 ? "You can leave!" : "Are you sure?",
        text:
          this.step == 5
            ? "You verification process still under verification."
            : "You will lose all your progress!",
        icon: "info",
        showCancelButton: this.step == 5 ? false : true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.step == 5 ? "Ok!" : "Yes, cancel it!",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("cancelVerificationProcess");
          this.$router.push(`/${this.me.slug}`);
        }
      });
    },
    backToSider() {
      window.location.href = "/";
    },
    fileHandler(e) {
      const file = e.target.files[0];

      if (file) {
        this.$cookies.set(
          "sider-verification-process-document-front",
          true,
          "7d"
        );
        this.$refs.frontDocument.value = null;

        const formData = new FormData();
        formData.append("front", file);
        this.fileUploading = true;
        this.$http
          .post("/api/v1/uploadDocument", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            this.fileUploading = false;
            this.step++;
          })
          .catch(() => {
            this.fileUploading = false;
            this.$swal({
              title: "Error",
              text: "Something went wrong",
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
            });
            this.step--;
            // reset input
            e.target.value = null;
            e.target.files = null;
          });
      }
    },
    fileHandler2(e) {
      const file = e.target.files[0];

      if (file) {
        this.$cookies.set(
          "sider-verification-process-document-back",
          true,
          "7d"
        );

        this.$refs.backDocument.value = null;

        this.fileUploading = true;
        const formData = new FormData();
        formData.append("back", file);
        this.$http
          .post("/api/v1/uploadDocument", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            this.fileUploading = false;
            this.step++;
          })
          .catch(() => {
            this.fileUploading = false;
            this.$swal({
              title: "Error",
              text: "Something went wrong",
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
            });
            this.step--;
            e.target.value = null;
            e.target.files = null;
          });
      }
    },
    fileHandler3(e) {
      const file = e.target.files[0];

      if (file) {
        this.fileUploading = true;
        this.$cookies.set(
          "sider-verification-process-document-selfie",
          true,
          "7d"
        );
        this.$refs.selfie.value = null;
        const formData = new FormData();
        formData.append("selfie", file);
        this.$http
          .post("/api/v1/uploadDocument", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            this.fileUploading = false;
            this.step++;
          })
          .catch(() => {
            this.fileUploading = false;
            this.$swal({
              title: "Error",
              text: "Something went wrong",
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
            });
            this.step--;
            e.target.value = null;
            e.target.files = null;
          });
      }
    },
    clickOnSelfie() {
      this.$refs.selfie.click();
    },
    clickOnFrontDocument() {
      this.$refs.frontDocument.click();
    },
    clickOnBackDocument() {
      this.$refs.backDocument.click();
    },
    agree() {
      console.log("agree");
      this.$cookies.set("sider-verification-process-consent", true, "7d");
      this.step++;
    },
    disagree() {
      window.location.href = "/settings";
    },
    setDocument(document) {
      // save document on cookie
      this.$store.commit("setDocument", document);
      this.$cookies.set("sider-verification-process-document", document, "7d");
      this.step++;

      const formData = new FormData();
      formData.append("document_type", document);
      this.$http
        .post("/api/v1/uploadDocument", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(() => {
          this.$swal({
            title: "Error",
            text: "Something went wrong",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
          this.step--;
        });
    },
  },
  data: () => ({
    ADULT_CATEGORY_ID: 99,
    fileUploading: false,
    selectedCategory: null,
    categories: [],
    step: -1,
    // slugRules
    slugRules: [
      (v) => !!v || "Slug is required",
      (v) => (v && v.length <= 20) || "Slug must be less than 20 characters",
      (v) => (v && v.length >= 2) || "Slug must be more than 3 characters",
      (v) => (v && /^[a-zA-Z0-9-]*$/.test(v)) || "Slug must be alphanumeric",
    ],
    slug: "",
  }),
};
</script>

<style lang="scss" scoped>
.become-a-creator-view {
  display: grid;
  place-items: center;
  justify-items: center;
  width: 100vw;
  height: 100dvh;

  .goBack,
  .leave {
    position: fixed;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    top: 2.5%;
    font-size: 20px;

    &:hover {
      transform: scale(1.5);
      transition: transform 0.3s ease-in-out;
    }
  }

  .goBack {
    left: 7%;
    top: 3.2%;
  }

  .leave {
    right: 2%;
  }
}

.btn-image {
  background: #007bff;
  border: 1px solid #007bff;
  box-sizing: border-box;
  border-radius: 25px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 20px;
  cursor: pointer;
  box-shadow: 0px 4px 4px #007bff3b;
  transition: 0.5s;
  width: 150px;
  text-transform: uppercase;

  &:hover {
    box-shadow: none;
    transition: 0.5s;
    transform: translateY(3px);
  }
}

.card-options-verification-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
    row-gap: 1rem;
  }
}

.card-option-verification {
  border-radius: 10px;
  border: 2px solid #eee;
  width: 256px;
  height: 249px;
  display: grid;
  place-content: center;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  .label {
    position: absolute;
    bottom: 25px;
    left: 50%;
    width: 200px;
    text-align: center;
    transform: translate(-50%);
    font-size: 24px;
    font-family: "Work Sans", sans-serif;
  }

  @media (max-width: 768px) {
    height: 200px;

    & .label {
      bottom: 10px;
    }
  }

  &:hover {
    border: 2px solid #007bff;
  }
}

.spacer-mobile {
  height: 80px;

  @media (min-width: 769px) {
    display: none;
  }
}

.consent-panel {
  width: 768px;
  max-width: 768px;
  min-width: 300px;
  min-height: 400px;
  max-height: 922px;
  border-radius: 10px;
  padding: 3rem;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: 100%;
    box-shadow: none;

    & center {
      position: fixed;
      bottom: 0;
      width: 100%;
      left: 0;
    }

    & .btn-disagree {
      margin-right: 10px !important;
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }

  h1 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    font-family: "Metropolis", sans-serif;
  }

  h2 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    font-family: "Metropolis", sans-serif;
  }

  h3 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    font-family: "Metropolis", sans-serif;
  }

  p,
  li {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
    font-family: "Work Sans", sans-serif;
  }

  list-style: none;
  /* Remove default bullets */

  li::before {
    content: "\2022";
    /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: #007bff;
    /* Change the color */
    font-weight: bold;
    /* If you want it to be bold */
    display: inline-block;
    /* Needed to add space between the bullet and the text */
    width: 1em;
    /* Also needed for space (tweak if needed) */
    transform: scale(1.5);
    /* (tweak if needed) */
  }

  .btn-disagree {
    background: transparent;
    border: 1px solid #007bff;
    box-sizing: border-box;
    border-radius: 25px;
    color: #007bff;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 20px;
    margin-right: 100px;
    width: 150px;
    cursor: pointer;
    box-shadow: 0px 4px 4px #007bff3b;
    transition: 0.5s;
    text-transform: uppercase;

    &:hover {
      box-shadow: none;
      transition: 0.5s;
      transform: translateY(3px);
    }
  }

  .btn-agree {
    background: #007bff;
    border: 1px solid #007bff;
    box-sizing: border-box;
    border-radius: 25px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 20px;
    cursor: pointer;
    box-shadow: 0px 4px 4px #007bff3b;
    transition: 0.5s;
    width: 150px;
    text-transform: uppercase;

    &:hover {
      box-shadow: none;
      transition: 0.5s;
      transform: translateY(3px);
    }
  }
}

.document-image {
  width: 500px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 1em;
  }
}
</style>

<!-- <style>
.id-start-icon-fullcolor-cls-1 {
    isolation: isolate;
}
.id-start-icon-fullcolor-cls-2, .id-start-icon-fullcolor-cls-5{
  fill: #f2f2f2;
}
.id-start-icon-fullcolor-cls-3{
    fill: #b6b7ba;
}
.id-start-icon-fullcolor-cls-18, .id-start-icon-fullcolor-cls-14, .id-start-icon-fullcolor-cls-13{
  fill: #D8D0C8;
}
.id-start-icon-fullcolor-cls-6 {
    fill: #fff;
}
.id-start-icon-fullcolor-cls-15{
  fill: #AFA6A1;
}
.id-start-icon-fullcolor-cls-20 {
    opacity: 0;
}
.id-start-icon-fullcolor-cls-10{
  fill: #007bff;
}
.id-start-icon-fullcolor-cls-21 {
    fill: #007bff;
}
.id-start-icon-fullcolor-cls-7 {
    fill: url(#id-start-icon-linear-gradient-2);
}
.id-start-icon-fullcolor-cls-11 {
    opacity: 0.58;
    fill: url(#id-start-icon-linear-gradient-4);
}
.id-start-icon-fullcolor-cls-16 {
    fill: #333;
}
.id-start-icon-fullcolor-cls-3 {
    fill: #b6b7ba;
}
.id-start-icon-fullcolor-cls-4 {
    opacity: 1;
    fill: red;
}
.id-start-icon-fullcolor-cls-1 {
    isolation: isolate;
}
</style> -->
