<template>
  <div>
    <v-card flat>
      <br />
      <center>
        <div class="settings-personal-data-edit-card">
          <div style="display: flex; justify-content: space-between">
            <h4>Balance:</h4>
            <span style="font-weight: bolder"
              >{{ this.balance.toFixed(2) }}$</span
            >
          </div>
          <br />
          <vuetify-money
            type="number"
            style="margin-top: -10px"
            v-model="amountWithdraw"
            color="#0989EF"
            :label="`$ (min: 50$) (available ${availableBalance.toFixed(2)}$)`"
            clearable
            :valueWhenIsEmpty="valueWhenIsEmpty"
            filled
            :options="options"
          >
            <template slot="append">
              <div
                @click="amountWithdraw = availableBalance"
                style="transform: translateY(3.5px); cursor: pointer"
              >
                <small>MAX</small>
              </div>
            </template>
          </vuetify-money>
          <div
            style="
              display: flex;
              justify-content: space-between;
              margin-top: 5px;
            "
          >
            <h4 style="font-weight: normal">Balance after withdraw :</h4>
            <!-- balance -->
            <h4 style="font-weight: normal">
              {{ (balance - parseFloat(amountWithdraw || 0)).toFixed(2) }}$
            </h4>
          </div>
        </div>
        <div class="settings-personal-data-edit-card">
          <h4>
            Addresses
            <br />
            <small style="font-size: 10px; font-weight: normal; color: #888"
              >(to withdraw the balance from the wallet)</small
            >
          </h4>
          <br />
          <div style="cursor: pointer">
            <div v-if="!addingWithdrawMethod" @click="addWithdrawMethod">
              <i
                class="fa-light fa-plus-circle"
                style="color: #0989ef"
                aria-hidden="true"
              ></i>
              Add address
            </div>
            <div v-else @click="addingWithdrawMethod = false">
              <i
                class="fa-light fa-times"
                style="color: #0989ef"
                aria-hidden="true"
              ></i>
              Cancel
            </div>
          </div>
          <br />
          <p style="margin-bottom: 8px" v-if="sources.length > 0">
            Select your address:
          </p>
          <div v-if="addingWithdrawMethod">
            <br />
            <v-select
              color="#0989EF"
              filled
              :items="withdrawMethods"
              item-text="label"
              item-value="value"
              v-model="selectedWithdrawMethod"
              placeholder="Select method"
            >
            </v-select>
            <v-text-field
              color="#0989EF"
              filled
              placeholder="Address"
              v-model="withdrawMethodValue"
              :style="!selectedWithdrawMethod ? '' : ''"
            ></v-text-field>
          </div>
          <div
            v-for="(method, key) in userWithdrawMethods"
            :key="key"
            @click="selectedWithdrawMethodKey = { method, key }"
          >
            <div
              :style="{
                border:
                  selectedWithdrawMethodKey.key == key
                    ? '2px dotted rgba(0,123,255,0.7)'
                    : '',
              }"
              v-if="method"
              style="
                color: #0989ef;
                background-color: rgba(0, 123, 255, 0.1);
                margin-right: 4px;
                padding: 1em;
                border-radius: 5px;
                margin-bottom: 1em;
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <span>{{ getWithdrawType(key) }}</span>
              <span style="float: right; font-size: 12px">
                {{ parseMethod(key, method) }}
              </span>
            </div>
          </div>
        </div>

        <v-btn
          :disabled="!selectedWithdrawMethod || !withdrawMethodValue"
          v-if="addingWithdrawMethod"
          @click="saveWithdrawMethod"
          style="
            letter-spacing: 0.5px;
            font-weight: normal;
            font-family: 'Montserrat';
            height: 51px;
            font-size: 16px;
            text-transform: none;
            margin-top: 1em;
            width: 100%;
            padding: 1em;
            background-color: rgb(0, 123, 255);
            color: white;
            border-radius: 5px;
          "
        >
          Add Address
        </v-btn>
        <!-- !/[0-9]/g.test(String(amountWithdraw)) -->
        <v-btn
          v-else
          @click="withdraw"
          class="btn-topup"
          :disabled="disableWithdrawButton"
          style="
            letter-spacing: 0.5px;
            font-weight: normal;
            font-family: 'Montserrat';
            height: 51px;
            font-size: 16px;
            text-transform: none;
            margin-top: 1em;
            width: 100%;
            padding: 1em;
            background-color: rgb(0, 123, 255);
            color: white;
            border-radius: 5px;
          "
        >
          Withdraw
        </v-btn>
      </center>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    balance: {
      type: Number,
      default: 0,
    },
    availableBalance: {
      type: Number,
      default: 0
    },
    sources: {
      type: Array,
      default: () => [],
    },
    userWithdrawMethods: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    disableWithdrawButton() {
      let isDisabled = false;
      if (!this.amountWithdraw) {
        isDisabled = true;
      } else if (parseFloat(this.amountWithdraw) < 50) {
        isDisabled = true;
      } else if (parseFloat(this.amountWithdraw) > this.availableBalance) {
        isDisabled = true;
      } else if (!this.selectedWithdrawMethodKey.method) {
        isDisabled = true;
      }

      return isDisabled;
    },
  },
  methods: {
    getWithdrawType(key) {
      var filterFrom = this.withdrawMethods;
      var result = filterFrom.find(function (el) {
        return el.value == key;
      });
      return result.label;
    },

    async withdraw() {
      await this.$store.dispatch("withdraw", {
        amount: this.amountWithdraw,
        method: this.selectedWithdrawMethodKey.key,
      });

      this.$swal({
        title: "Withdraw request sent!",
        text: "Your withdraw request was sent successfully. We will process it as soon as possible.",
        icon: "success",
        showConfirmButton: false,
        timer: 3000,
      });

      this.amountWithdraw = "";

      this.$emit("getTransactions");
      this.$emit("getBalance");
    },

    parseMethod(key, method) {
      if (key == "iban") return method;

      // show a little bit of the start and the end
      return method.slice(0, 12) + "****" + method.slice(-12);
    },

    saveWithdrawMethod() {
      this.$http
        .post("/api/v1/addWithdrawAddress", {
          address: this.withdrawMethodValue,
          type: this.selectedWithdrawMethod,
        })
        .then(async (res) => {
          this.withdrawMethodValue = "";
          this.selectedWithdrawMethod = "";
          if (res.data == "UPDATED") {
            this.$swal({
              icon: "success",
              title: "Withdraw method updated successfully!",
              showConfirmButton: false,
              timer: 3000,
            });
          } else {
            this.$swal({
              icon: "success",
              title: "Withdraw method added successfully!",
              showConfirmButton: false,
              timer: 3000,
            });
          }
          this.$emit("getWithdrawMethods");
          this.addingWithdrawMethod = false;
        });
    },
    addWithdrawMethod() {
      this.addingWithdrawMethod = true;
    },
  },
  watch: {
    selectedWithdrawMethod() {
      this.withdrawMethodValue =
        this.userWithdrawMethods[this.selectedWithdrawMethod];
    },
  },
  data() {
    return {
      isLoading: false,
      options: {
        locale: "pt-PT",
        prefix: " $ ",
        suffix: "",
        length: 9,
        precision: 2,
      },
      withdrawMethodValue: "",
      amountWithdraw: "",
      addingWithdrawMethod: false,
      valueWhenIsEmpty: "",
      selectedWithdrawMethodKey: {},
      selectedWithdrawMethod: "",
      withdrawMethods: [
        {
          label: "EU IBAN",
          value: "iban",
        },
        {
          label: "US Bank",
          value: "usdc_address",
        },
        {
          label: "BTC",
          value: "btc_address",
        },
        {
          label: "ETH",
          value: "eth_address",
        },
        {
          label: "USDT",
          value: "usdt_address",
        },
        {
          label: "USDC",
          value: "usdc_address",
        },
      ],
    };
  },
};
</script>
