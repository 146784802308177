import moment from 'moment';
export default {
    methods: {
        isVideo(fileName){
            var ext = fileName.split('.').pop().toLowerCase();
            return ext == 'mp4' || ext == 'webm' || ext == 'ogg' || ext == 'mov' || fileName.endsWith('mp4') || fileName.endsWith('webm') || fileName.endsWith('ogg') || fileName.endsWith('mov') || fileName.endsWith('quicktime');
        },
        isImage(fileName){
            var ext = fileName.split('.').pop().toLowerCase();
            return ext == 'jpg' || ext == 'jpeg' || ext == 'png' || ext == 'gif' || ext == 'heic' || fileName.endsWith('jpg') || fileName.endsWith('jpeg') || fileName.endsWith('png') || fileName.endsWith('gif') || fileName.endsWith('heic') || fileName.endsWith('webp') || fileName.endsWith('svg') || fileName.endsWith('bmp') || fileName.endsWith('tif') || fileName.endsWith('tiff') || fileName.endsWith('jfif');
        },
        isAudio(fileName){
            return fileName.startsWith('audio') || fileName.endsWith('mp3') || fileName.endsWith('wav') || fileName.endsWith('ogg') || fileName.endsWith('aac') || fileName.endsWith('flac') || fileName.endsWith('m4a') || fileName.endsWith('wma') || fileName.endsWith('alac') || fileName.endsWith("mpeg");
        },
        isPDF(fileName){
            return fileName.endsWith('pdf');
        },
        isDoc(fileName){
            return fileName.endsWith('doc') || fileName.endsWith('docx') || fileName.endsWith('odt') || fileName.endsWith('rtf') || fileName.endsWith('txt') || fileName.endsWith('tex') || fileName.endsWith('wks') || fileName.endsWith('wps') || fileName.endsWith('wpd') || fileName.endsWith('.document');
        },
        isExcel(fileName){
            return fileName.endsWith('xls') || fileName.endsWith('xlsx') || fileName.endsWith('ods') || fileName.endsWith('csv');
        },
        calcPostedAt(date){
            return moment(date).fromNow();
        },
        cdnURL(){
            return process.env.VUE_APP_CDN_BASE_URL;
        }
    }
}