<template>
  <div class="advertise-component-wrapper">
    <div class="advertise-component">
      <div class="advertise-component__content">
        <h2 class="advertise-component__title">Start a paid Subscription</h2>
        <br />
        <div>
          Offer subscribers the opportunity to pay monthly for a premium
          experience.
        </div>
        <br />
        <button
          class="advertise-component__button"
          @click="
            $router
              .replace('/settings/membership?settings=true')
              .catch(() => {})
          "
        >
          <b>Set value now</b>
          <i class="fa-regular fa-euro-sign"></i></button
        ><br />

        <small style="font-size: 12px">Set the value you want</small>
      </div>
    </div>

    <div class="advertise-component">
      <div class="advertise-component__content">
        <h2 class="advertise-component__title">Sell digital products</h2>
        <br />
        <div>
          Like videos and photos, directly on Sider to anyone <small> </small>
        </div>
        <br />
        <button class="advertise-component__button" @click="beginAPost">
          <b>Sell now</b>
          <i class="fa-regular fa-cart-shopping"></i></button
        ><br />

        <small style="font-size: 12px"
          >Buyer don't need to be a subscriber</small
        >
      </div>
    </div>

    <div class="advertise-component advertise-component--invites">
      <div class="advertise-component__content">
        <h2 class="advertise-component__title">Invite your friends</h2>
        <h3 class="advertise-component__subtitle">
          and get 25% <br />from their comissions
        </h3>
        <button
          class="advertise-component__button"
          :class="{
            'advertise-component__button--out': !me.id,
          }"
          @click="copySlug"
        >
          <template v-if="me && me.id">
            <span>
              <span class="advertise-component__highlight">@</span>{{ me.slug }}
            </span>
            <i class="fal fa-copy" aria-hidden="true"></i>
          </template>
          <template v-else> Sign up now </template>
        </button>
      </div>
    </div>

    <div
      class="advertise-component advertise-component--likes"
      @click="$router.replace('/settings/membership').catch(() => {})"
    >
      <div class="advertise-component__content">
        <h2 class="advertise-component__title">
          Earn money from your likes
          <i class="fa fa-heart" aria-hidden="true"></i>
        </h2>
        <h3 class="advertise-component__subtitle">
          100 <i class="fa fa-heart" aria-hidden="true"></i> = 1 $ider
        </h3>
      </div>
    </div>

    <!-- <div
      class="advertise-component advertise-component--invest"
      @click="$router.replace('/settings/wallet?invest=true').catch(() => {})"
    >
      <div class="advertise-component__content">
        <h2 class="advertise-component__title">Own a share of Sider today</h2>
        <br />
        <i
          style="font-size: 32px"
          class="far fa-chart-pie-simple-circle-dollar"
        ></i>
        <br />
        <button
          class="advertise-component__button"
          :class="{
            'advertise-component__button--out': !me.id,
          }"
        >
          <b>Invest now</b>
        </button>
        <br />
        <small style="font-size: 12px">Starting from just 1 $ider</small>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  methods: {
    async copySlug() {
      if (!this.me.id) {
        if (this.$route.params.slug) {
          await this.$router
            .replace(`/r/${this.$route.params.slug}`)
            .catch(() => {});
          this.$router.replace("/register").catch(() => {});
          return;
        } else {
          return this.$router.replace("/register").catch(() => {});
        }
      }
      // copy with native clipboard for mac
      if (navigator.clipboard) {
        navigator.clipboard.writeText(
          `${window.location.origin}/r/${this.me.slug}`
        );
      } else {
        // fallback for safari
        const el = document.createElement("textarea");
        el.value = `${window.location.origin}/r/${this.me.slug}`;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
      }
      this.$swal({
        title: "Copied!",
        text: "Link to share copied to clipboard",
        icon: "success",
        timer: 2000,
        showCancelButton: false,
        showConfirmButton: false,
      });
    },
    beginAPost() {
      if (this.$route.params.slug) {
        if (this.me.id) {
          if (this.me.creator_category_id) {
            this.$router.push(`/${this.me.slug}`).catch(() => {});
            this.$store.commit("setPopup", "create-post");
          } else {
            this.$swal({
              title: "Oops!",
              text: "You need to be a creator to create a post, would you like to become a creator?",
              icon: "warning",
              showCancelButton: true,
              cancelButtonText: "No",
              confirmButtonText: "Yes",
            }).then(({ isConfirmed }) => {
              if (isConfirmed) {
                this.$router.push("/become-a-creator").catch(() => {});
              }
            });
          }
        } else {
          this.$router.push("/login");
        }
      } else {
        if (this.me.id) {
          this.$router
            .push(`/${this.me.slug}`)
            .then(() => {
              setTimeout(() => {
                this.$store.commit("setPopup", "create-post");
              }, 1000);
            })
            .catch(() => {});
        } else {
          this.$router.push("/login");
        }
      }
    },
  },
};
</script>

<style lang="scss">
.advertise-component-wrapper {
  position: relative;
}
.advertise-component {
  width: calc(300px - 1.5rem);
  border-radius: 24px;

  margin: 30px 0 0 1.5rem;

  background-color: rgba(0, 123, 255, 0.07);

  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;

  cursor: pointer;

  &:hover {
    border: 1px solid #007bff;
    transform: scale(1.05);
    .advertise-component__button {
      transform: scale(1.05);
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    }
  }

  &--likes {
    height: 150px;
  }

  &--invites {
    top: calc(150px + 1.5rem);
    height: 210px;
  }

  &--invest {
    top: calc(385px + 1.5rem);
    height: 256px;

    .advertise-component__button {
      flex-direction: column;
      justify-content: center;
      background-color: #007bff;
      color: white;
    }

    .advertise-component__content {
      justify-content: unset;
    }

    &:hover {
      border: 1px solid #007bff;
      .advertise-component__button {
        transform: scale(1.05);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      }
    }
  }

  &__content {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 16px;

    text-align: center;
  }

  &__title {
    font-size: 24px;
    line-height: 32px;

    & i {
      color: #ff4d4d;
      font-size: 32px;
    }
  }

  &__subtitle {
    font-size: 18px;
    line-height: 32px;
    & i {
      color: #ff4d4d;
      font-size: 18px;
    }
  }

  &__button {
    width: 100%;
    height: 50px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    &--out {
      justify-content: center;
    }

    color: black;

    padding: 8px 24px;

    border-radius: 24px;

    background-color: white;

    font-size: 18px;
    font-weight: 500;
    line-height: 24px;

    border: none;
    outline: none;

    cursor: pointer;

    transition: all 0.2s ease-in-out;

    & i {
      margin-left: 8px;
    }
  }
}
</style>
