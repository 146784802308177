<template>
    <div style="position: relative;">
        
        <transition :name="$route.path == '/settings' ? 'slide-fade' : 'slide-fade2'">
            <router-view></router-view>
        </transition>
        
    </div>
</template>

<script>
export default {
    name: 'SettingsLayout',
}
</script>



<style>
/* Enter and leave animations can use different */
/* durations and timing functions.              */

/* breakpoint max-width 768px */

@media screen and (max-width: 768px) {


.slide-fade-enter-active {
  transition: all .5s ease-out;
}
.slide-fade-leave-active {
  transition: all .5s ease-out;
}
.slide-fade-leave-to{
    position: absolute;
    width: 1000px;
}
.slide-fade-enter{
    transform: translateX(-600px);
}
.slide-fade-leave-to{
    transform: translateX(600px);
}


.slide-fade2-enter-active {
  transition: all .5s ease-out;
}
.slide-fade2-leave-active {
  transition: all .5s ease-out;
}
.slide-fade2-leave-to{
    position: absolute;
}
.slide-fade2-enter{
    transform: translateX(600px);
    opacity: 0;
}
.slide-fade2-leave-to{
    transform: translateX(-600px);
    opacity: 0;
}
}

/* breakpoint min-width 768px */

@media screen and (min-width: 768px) {

.slide-fade-enter-active {
  transition: all .5s ease-out;
}
.slide-fade-leave-active {
  transition: all .5s ease-out;
}
.slide-fade-leave-to{
    position: absolute;
    width: 1000px;
}
.slide-fade-enter{
    /* transform: translateY(-600px); */
    opacity: 0;
}
.slide-fade-leave-to{
    /* transform: translateY(600px); */
    opacity: 0;
}


.slide-fade2-enter-active {
  transition: all .5s ease-out;
}
.slide-fade2-leave-active {
  transition: all .5s ease-out;
}
.slide-fade2-leave-to{
    position: absolute;
}
.slide-fade2-enter{
    /* transform: translateY(600px); */
    opacity: 0;
}
.slide-fade2-leave-to{
    /* transform: translateY(-600px); */
    opacity: 0;
}
}



</style>