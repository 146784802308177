<template>
    <div style="margin:2rem">
        <v-btn
        class="btn-fab"
        fab
        small
        @click="$router.go(-1)"
      >
        <i class="fa-regular fa-chevron-left" style="font-size: 20px; "></i>
      </v-btn>
      <div>
        <h1 class="title" style="text-align: center; margin: 2rem;">Community Guidelines</h1>

      </div>


- Sider.Money strives to be a community in which all Users can express themselves openly and in which they can engage in free, open communications about whatever subjects they choose. The Platform is provided to showcase content and creativity of all kinds so that Users can enjoy diverse and wide-ranging material. However, in order to make that possible, it is necessary for all Users to conduct themselves in a way that is respectful, honest and fair.
<br><br>- These Community Guidelines set out Shift Holdings Ltd's standards of conduct for using any of our Services. These rules apply to using any Service offered on or via the Platform. By using the Platform, you are agreeing and undertaking to respect both the letter and spirit of these rules.
<br><br>- We may change these Community Guidelines at any time without notice. Any such changes shall take effect on the next occasion you use the Platform. Any such new rules may be displayed on-screen when you next use the Platform, and you may be required to read and accept them to continue using the Platform. However, you are responsible for checking these Community Guidelines before using the Platform and making yourself aware of any changes. For ease of reference, the top of these guidelines indicates the date they were last updated.

<br><br>## Content Guidelines<br><br>

Users must have the legal right to post any User Content they upload to the Platform, either by being the owner of the relevant rights or possessing a valid licence which entitles them to upload that content to the Platform.

<br><br>### User Content must not:<br><br>

- infringe or violate the Intellectual Property Rights of any third party, including other Users;
- violate the local laws of the jurisdiction from which the relevant User is accessing the Platform;
- incite or encourage other Users (or anyone else) to engage in activity which would be unlawful in your or their jurisdictions;
- be a reposting or a replication of any other User Content which we have declared to be in breach of the Community Guidelines (or any other part of our terms).

<br><br>### **Hate Speech**<br><br>

Members of the Sider.Money community must communicate with each other respectfully and politely. Any form of harassment, hate speech, or discrimination is strictly prohibited. We encourage open and honest communication, but it must be conducted respectfully that does not infringe on the rights of others. If you disagree with someone, please do so respectfully.

- We encourage free speech and recognise that some opinions and viewpoints may cause upset or offend others. However, we will not in any circumstances tolerate speech which incites, encourages or expresses hatred to or against any ethnic, religious, gender, or other groups which share an identity characteristic.
- You must not post or upload hateful User Content. There are no exceptions to this rule.
- Your User Content is likely to be considered hateful if it encourages or wishes for violence/physical harm to a particular group of people, encourages for exclusion or segregation of a particular group of people, or if it contains slurs that are intended to degrade or dehumanize a particular group of people.

<br><br>### **Terrorism and Extremism**<br><br>

- The Platform expressly prohibits any content which glorifies, encourages, or seeks to recruit individuals to participate in terrorism or other extremist activity. There are no exceptions to this rule.

<br><br>### **Non-Consensual and Unsolicited Sexual Material**<br><br>

- Any sexual material which depicts images of sexual activity in which one or more participants are unwilling or unconsenting is prohibited. You must not upload it to the Platform.
- This includes sexual material in which participants are unwillingly involved, including intimate photography/video of individuals who were unaware they were being photographed/filmed or unwilling to participate.
- You must not send User Content which is sexual in nature to other Users who have not indicated that they wish to receive it from you by, for example, subscribing to your User Content or through direct communications. This includes intimate photography of yourself.

<br><br>### AI-Generated Media<br><br>

Sider.Money is open to and supportive of creative users who wish to utilise AI-generated media. However, to ensure a respectful and safe community, Sider.Money has specific guidelines for uploading, posting, and sharing AI-generated media. This includes but is not limited to deep fakes, generated images, and other types of synthetic media.
<br><br>
<br><br>- ************************Disclosure:************************ If you are uploading, posting or sharing AI-generated media on Sider.Money, you must disclose that it is not real and was generated using AI. This disclosure must be clear and prominent so that other community members know the media is not authentic. This may be displayed on the profile bio, content caption, or as a watermark on the media itself. Failure to properly disclose may result in your content being removed and/or your account being suspended.
<br><br>- ****************Nature:**************** AI-generated media must not be used to create harmful or misleading content. Any content that is intended to deceive or harm others is strictly prohibited.
<br><br>- ************************Moderation:************************ All content must pass Sider.Money's "Reasonable Person's Test," conducted by three or more members of Sider.Money's moderation team. The "Reasonable Person's Test" is designed to assess if a piece of AI content complies with Sider.Money's rules and policies. A failed test will result in the content being removed, multiple failures may result in your account being permanently suspended.
<br><br>- ********************Age and Appearance:******************** AI-generated media uploaded to Sider.Money must not be of a real person other than the account owner or a consenting and verified co-creator. AI-generated media uploaded to Sider.Money must not be a person who resembles or intends to resemble (visually or contextually) an individual under the age of 18. Prompt text cannot be used to 'prove' the age of AI-generated content due to multiple variables such as the model used, training data, prompt weight, and delta.
<br><br>- ************************Compliance:************************ All AI-generated media must be respectful and not violate any of the community guidelines. This includes but is not limited to avoiding harassment, hate speech, or discrimination.
<br><br>- ********************Copyright:******************** AI-generated media must not infringe on any copyright or ownership rights. Uploading such content may result in legal consequences, and the uploader is responsible for ensuring they have the right to use and share the media.
<br><br>- ******Consequences:****** Violating these guidelines will result in appropriate consequences, determined based on the severity of the violation, and may include a warning, removal, suspension, or permanent ban from the community.
<br><br>- We retain the right to remove any content without notice if it breaks the community guidelines or Sider.Money policies. This includes but is not limited to AI-generated media that violates any of the guidelines outlined above.

<br><br>### **Explicit Content**<br><br>

- Explicitly sexual and/or violent content is permitted on the Platform. Still, as a wide audience of people uses the Platform, you must flag explicitly sexual and/or violent content as “18+ Content”. In addition, you must not make explicit User Content available to Users, as a Paid for Service or otherwise, if you are aware, or ought reasonably to be aware, such Users are under the age of 18. Explicit User Content without the appropriate 18+ Content marking is prohibited, and you must not upload it to the Platform.
- Explicit User Content depicting or otherwise involving anyone under 18, including yourself, is prohibited. It is your responsibility to ensure that the subjects of your explicit User Content are over the age of 18 and to maintain appropriate documentation sufficient to evidence the same.
- User Content is likely to be considered explicit if it could be considered inappropriate for people under the age of 18; it contains material which is sexual or pornographic; or it depicts injury or violence in a way that is graphic, gratuitous, obscene or excessive.
- Context can be important in making a determination. In particular, content representing factual reporting on current events is less likely to be considered explicit when it consists of newsworthy images that help individuals understand the events being reported on properly. Likewise, images representing artistic or stylised representations of these subjects are less likely to be considered explicit by us.

<br><br>### **Private Information**<br><br>

- You must not disclose or publish private information about others who have not consented to that publication. In particular, this includes publishing the name, address, or occupation of other Users who have not chosen to make that information about themselves public.

<br><br>### **Impersonation**:<br><br>

- You must not use the Platform to impersonate other individuals in a way that is designed to mislead other Users. In particular, you must not use the Platform to impersonate other Users or public figures.
- 'Parody' or similar comedic use of the Platform is unlikely to infringe this rule. However, this kind of use must be clearly labelled and easily recognisable by its intended and likely audience.

<br><br>### **Unlawful Activity**

<br><br>- You must not use the Platform to upload any unlawful content in your jurisdiction. For the avoidance of doubt, this includes content that solicits sales of goods/services which are unlawful in the jurisdiction of the buyer/seller, it includes content which solicits sexual activity with individuals below the age of consent, it includes content to designed to bully, harass, threaten or intimidate other individuals, and it includes content which promotes or encourages suicide or self-harm (including eating disorders).

Shift Holdings Ltd reserves the right to determine whether User Content has infringed any of the above restrictions. While we will consider information about User Content provided by Users, our judgement is final.

While we reserve the right to remove, delete, suspend or edit any Content on the Platform for any reason and at any time, individual Users are responsible for all User Content on the Platform. Shift Holdings Ltd accepts no responsibility or liability for anything you or any other User may post or upload to the Platform. You must be willing to accept the legal consequences of your own speech and actions if you infringe on any applicable laws.

<br><br>## **User Behaviour Guidelines**<br><br>

As a condition of being granted access to the Platform and the Services, you agree.

- not to submit or transmit any material, including User Content which is in breach of these Community Guidelines;
- not to use the Platform or Service in any way to offer, solicit, arrange, promote, facilitate or engage in human trafficking, sex trafficking, child exploitation, physical abuse, violence, brutality, rape or any other non-consensual sexual behaviour and you agree that any active membership or participation in anti-human trafficking and / or anti-child exploitation groups is forbidden;
- not to use the Platform or Service in an unlawful manner, for any unlawful purpose, in any manner inconsistent with these Community Guidelines, or act fraudulently or maliciously, for example, by attempting to gain unauthorised access to the Platform or the server on which the Platform is stored, by hacking into the Platform or by inserting malicious code, including viruses, or harmful data, into the Platform, any Service or any operating system;
- not to use the Platform or Service to offer, solicit, arrange, or engage in any kind of activity or arrangement which is, or which would be unlawful, including, but not limited to, the promotion, distribution or advertisement of escort services, illegal drugs (or illegal drug paraphernalia), drug manufacturing techniques, illegal firearms or other illegal weapons;
- not to infringe our rights or those of any third party in relation to your use of the Platform or any Service, including, without a limitation, a person’s Intellectual Property Rights, contractual rights, confidentiality rights, human rights, rights to privacy and other rights under applicable law;

In conclusion, community guidelines are essential in creating a safe and inclusive environment where members can learn, grow, and collaborate. Every community member must familiarize themselves with the guidelines and work together to ensure they are followed.

By following these guidelines, we can ensure that Sider.Money remains a safe and respectful community for all members, regardless of the type of media being shared.      
    </div>
</template>

<script> 

 export default {
     name: 'TermsView',
    
 }


</script>