import Vue from 'vue'
import App from './App.vue'

import router from './routes/router';
import store from '@/store';

import './globals';

import '@/layouts/'

import '@/assets/styles/theme.scss'
import '@/assets/styles/tooltip.scss'

import '@/assets/styles/vuetify.scss'

import vuetify from '@/plugins/vuetify' // path to vuetify export
import "@/plugins/vuetify-money";

Vue.config.productionTip = false

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import axios from 'axios';

import VueSweetalert2 from 'vue-sweetalert2';
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);

Vue.prototype.$http = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
})

import registerComponents from '@/components/';

registerComponents(Vue);

Vue.prototype.$version = "1.0.0";

Vue.prototype.$default = {
    avatar: process.env.VUE_APP_DEFAULT_USER_AVATAR,
    banner: process.env.VUE_APP_DEFAULT_USER_BANNER
}

import Vue2TouchEvents from 'vue2-touch-events'

Vue.use(Vue2TouchEvents)

import VueToast from 'vue-toast-notification';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueToast);

window.stripe = window.S(process.env.VUE_APP_STRIPE_KEY);



export default new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
}).$mount('#app')