<template>
  <div>
    <v-card flat>
      <br />
      <center>
        <div class="settings-personal-data-edit-card">
          <div style="display: flex; justify-content: space-between">
            <h4>Balance:</h4>
            <span style="font-weight: bolder">{{ balance.toFixed(2) }}$</span>
          </div>
          <br />
          <center>
            <vuetify-money
              v-model="amountDeposit"
              color="#0989EF"
              label="$ Amount to add to your wallet (min. 30$)"
              clearable
              :valueWhenIsEmpty="valueWhenIsEmpty"
              filled
              :options="options"
            />
            <v-chip
              :style="{
                border:
                  amountDeposit == 10 ? '2px dotted rgba(0,123,255,0.7)' : '',
              }"
              style="
                color: #0989ef;
                background-color: rgba(0, 123, 255, 0.1);
                margin-right: 4px;
              "
              @click="setSelectedTopupAmount(30)"
              >30$
            </v-chip>
            <v-chip
              :style="{
                border:
                  amountDeposit == 50 ? '2px dotted rgba(0,123,255,0.7)' : '',
              }"
              style="
                color: #0989ef;
                background-color: rgba(0, 123, 255, 0.1);
                margin-right: 4px;
              "
              @click="setSelectedTopupAmount(50)"
              >50$
            </v-chip>
            <v-chip
              :style="{
                border:
                  amountDeposit == 100 ? '2px dotted rgba(0,123,255,0.7)' : '',
              }"
              style="
                color: #0989ef;
                background-color: rgba(0, 123, 255, 0.1);
                margin-right: 4px;
              "
              @click="setSelectedTopupAmount(100)"
              >100$
            </v-chip>
            <v-chip
              :style="{
                border:
                  amountDeposit == 200 ? '2px dotted rgba(0,123,255,0.7)' : '',
              }"
              style="
                color: #0989ef;
                background-color: rgba(0, 123, 255, 0.1);
                margin-right: 4px;
              "
              @click="setSelectedTopupAmount(200)"
              >200$
            </v-chip>
            <br /><br />

            <small>
              Adding funds to your wallet, means you are buying siders, which is
              the platform currency. 1 sider = 1$.
            </small>
          </center>
        </div>
        <!-- <div class="settings-personal-data-edit-card">
          <h4>
            Methods
            <br />
            <small style="font-size: 10px; font-weight: normal; color: #888"
              >(to add funds to your wallet)</small
            >
          </h4>
          <br />
          <div style="cursor: pointer">
            <div v-if="notAddingCard" @click="setupCardElement">
              <i
                class="fa-light fa-plus-circle"
                style="color: #0989ef"
                aria-hidden="true"
              ></i>
              Add card
            </div>
            <div v-else @click="notAddingCard = true">
              <i
                class="fa-light fa-times"
                style="color: #0989ef"
                aria-hidden="true"
              ></i>
              Cancel
            </div>
          </div>
          <br />
          <div id="card-element" v-if="!notAddingCard"></div>

          <div id="card-errors" role="alert" v-if="!notAddingCard"></div>
          <br v-if="!notAddingCard" />
          <p style="margin-bottom: 8px" v-if="sources.length > 0">
            Select your method:
          </p>
          <div
            v-for="(source, sourceIndex) in sources"
            :key="source.id"
            style="margin-bottom: 10px"
          >
            <div
              @click="selectedSourceIndex = sourceIndex"
              :style="{
                border:
                  selectedSourceIndex == sourceIndex
                    ? '2px dotted rgba(0,123,255,0.7)'
                    : '',
              }"
              style="
                color: #0989ef;
                background-color: rgba(0, 123, 255, 0.1);
                margin-right: 4px;
                padding: 1em;
                border-radius: 5px;
                position: relative;
              "
            >
              <i
                @click="removeCard(source)"
                v-if="selectedSourceIndex == sourceIndex"
                class="fa-light fa-minus-circle"
                style="
                  position: absolute;
                  right: -7px;
                  color: red;
                  top: -5px;
                  background-color: white;
                  border-radius: 50%;
                "
                aria-hidden="true"
              ></i>
              <i
                class="fa fa-cc-visa"
                style="font-size: 20px"
                v-if="source.card.brand == 'visa'"
                aria-hidden="true"
              ></i>
              <i
                class="fa fa-cc-mastercard"
                style="font-size: 20px"
                v-if="source.card.brand == 'mastercard'"
                aria-hidden="true"
              ></i>
              <i
                class="fa fa-cc-amex"
                style="font-size: 20px"
                v-if="source.card.brand == 'amex'"
                aria-hidden="true"
              ></i>
              <i
                class="fa fa-cc-diners-club"
                style="font-size: 20px"
                v-if="source.card.brand == 'diners'"
                aria-hidden="true"
              ></i>
              <i
                class="fa fa-cc-discover"
                style="font-size: 20px"
                v-if="source.card.brand == 'discover'"
                aria-hidden="true"
              ></i>
              <i
                class="fa fa-cc-jcb"
                style="font-size: 20px"
                v-if="source.card.brand == 'jcb'"
                aria-hidden="true"
              ></i>
              <span style="float: right">****{{ source.card.last4 }}</span>
            </div>
          </div>

          <div style="margin-bottom: 10px; opacity: 0.5; cursor: no-drop">
            <div
              style="
                display: flex;
                justify-content: space-between;
                color: #0989ef;
                background-color: rgba(0, 123, 255, 0.1);
                margin-right: 4px;
                padding: 1em;
                border-radius: 5px;
                position: relative;
              "
            >
              <span
                ><i
                  class="fa fa-qrcode"
                  style="font-size: 20px"
                  aria-hidden="true"
                ></i>
                Coming soon...</span
              >
              <span style="margin-left: 8px">Pix</span>
            </div>
          </div>
        </div> -->
        <v-btn
          v-if="notAddingCard"
          :loading="isLoading"
          @click="topup"
          class="btn-topup"
          :disabled="canAddFunds"
          style="
            letter-spacing: 0.5px;
            font-weight: normal;
            font-family: 'Montserrat';
            height: 51px;
            font-size: 16px;
            text-transform: none;
            margin-top: 1em;
            width: 100%;
            padding: 1em;
            background-color: rgb(0, 123, 255);
            color: white;
            border-radius: 5px;
          "
        >
          Add funds with
          <img
            style="width: 96px; margin-left: 16px"
            src="/assets/images/MoonPay.png"
          />
        </v-btn>

        <v-btn
          v-else
          :loading="isLoading"
          @click="saveCard"
          style="
            letter-spacing: 0.5px;
            font-weight: normal;
            font-family: 'Montserrat';
            height: 51px;
            font-size: 16px;
            text-transform: none;
            margin-top: 1em;
            width: 100%;
            padding: 1em;
            background-color: rgb(0, 123, 255);
            color: white;
            border-radius: 5px;
          "
        >
          Add card
        </v-btn>
      </center>
    </v-card>
  </div>
</template>

<script>
export default {
  async mounted() {
    const { transactionId } = this.$route.query;

    if (transactionId) {
      this.$http
        .get(`/api/v1/moonpay/validate?transactionId=${transactionId}`)
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Transaction completed",
            showConfirmButton: false,
            timer: 3000,
          });

          this.$emit("getBalance");
          this.$emit("getTransactions");
          this.$router.replace("/settings/wallet").catch(() => {});
        })
        .catch(() => {
          this.$swal({
            icon: "error",
            title: "Transaction failed",
            showConfirmButton: false,
            timer: 3000,
          });
        });
    }
  },
  props: {
    balance: {
      type: Number,
      default: 0,
    },
    sources: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    canAddFunds() {
      return this.amountDeposit < 30;
    },
  },
  data: () => ({
    moonPaySdk: {},
    amountDeposit: "",
    valueWhenIsEmpty: "",
    selectedSourceIndex: -1,
    notAddingCard: true,
    isLoading: false,
    options: {
      locale: "pt-PT",
      prefix: " $ ",
      suffix: "",
      length: 6,
      precision: 0,
    },
  }),
  methods: {
    saveCard() {
      this.isLoading = true;
      window.stripe
        .createSource(this.card, {
          type: "card",
          owner: {
            email: this.me.email,
          },
        })
        .then((result) => {
          if (result.error) return (this.isLoading = false);
          this.$http
            .post("/api/v1/addCard", {
              source: result.source.id,
            })
            .then(async () => {
              this.isLoading = false;
              this.notAddingCard = true;
              this.$swal({
                icon: "success",
                title: "Card added successfully",
                showConfirmButton: false,
                timer: 3000,
              });
              this.$emit("getSources");
            });
        });
    },
    setupCardElement() {
      this.notAddingCard = false;
      setTimeout(() => {
        var style = {
          base: {
            color: "#0989EF",
            fontWeight: "100",
            fontFamily: "Montserrat, sans-serif",
            fontSize: "13px",
          },
        };

        this.elements = window.stripe.elements();
        style;

        this.card = this.elements.create("card", {
          style: style,
        });
        this.card.mount("#card-element");
      }, 100);
    },
    setSelectedTopupAmount(amountDeposit) {
      this.amountDeposit = amountDeposit;
    },
    topup() {
      this.isLoading = true;
      setTimeout(() => {
        const moonPay = window.MoonPayWebSdk?.init;
        const payload = {
          apiKey: process.env.VUE_APP_MOONPAY_KEY,
          theme: "dark",
          quoteCurrencyAmount: this.amountDeposit,
          externalCustomerId: this.me.id,
          walletAddress: "6ngkXgi1c5m2BJBvMZBVLN6FUGw6t983ighPjYGY9f5p",
          currencyCode: "usdc_sol",
          colorCode: "#007BFF",
          showOnlyCurrencies: false,
          redirectURL: "https://sider.money/settings/wallet",
          showWalletAddressForm: false,
        };
        this.moonPaySdk = moonPay({
          flow: "buy",
          environment: "production",
          variant: "overlay",
          params: payload,
        });
        this.$http
          .post(`/api/v1/moonpay/signature`, {
            signedURL: this.moonPaySdk.generateUrlForSigning(),
          })
          .then(async ({ data: { signature } }) => {
            this.isLoading = false;
            this.moonPaySdk.updateSignature(signature);
            this.moonPaySdk.show();
          })
          .catch(() => {
            this.isLoading = false;
            this.$swal({
              icon: "error",
              title:
                "An error occurred, try refreshing the page, if the problem persists, contact support.",
              showConfirmButton: false,
              timer: 3000,
            });
          });
      }, 1000);
    },

    removeCard(source) {
      this.$http
        .post("/api/v1/removeCard/", {
          source,
        })
        .then(() => {
          this.notAddingCard = true;
        });
    },
  },
  watch: {
    sources: {
      handler() {
        if (this.sources.length > 0) {
          this.selectedSourceIndex = 0;
        }
      },
      immediate: true,
    },
  },
};
</script>
