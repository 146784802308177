<template>
  <div style="padding: 35px;max-width: 800px;">
    <center style="padding-top: 36px;">
      <s-logo />
      <br><br>
      Paste your code here
      <div style="width: 100%;display: grid;grid-template-columns: repeat(6, 1fr);place-items: center;">

        <v-text-field class="inputs-login input-code" maxlength="1" @keydown="nextInput" filled
          style="width: 50px;margin-top: 3em;font-size: 14px;color: #007BFF;" v-model="code.one"></v-text-field>
        <v-text-field class="inputs-login input-code" maxlength="1" @keydown="nextInput" filled
          style="width: 50px;margin-top: 3em;font-size: 14px;color: #007BFF;" v-model="code.two"></v-text-field>
        <v-text-field class="inputs-login input-code" maxlength="1" @keydown="nextInput" filled
          style="width: 50px;margin-top: 3em;font-size: 14px;color: #007BFF;" v-model="code.three"></v-text-field>
        <v-text-field class="inputs-login input-code" maxlength="1" @keydown="nextInput" filled
          style="width: 50px;margin-top: 3em;font-size: 14px;color: #007BFF;" v-model="code.four"></v-text-field>
        <v-text-field class="inputs-login input-code" maxlength="1" @keydown="nextInput" filled
          style="width: 50px;margin-top: 3em;font-size: 14px;color: #007BFF;" v-model="code.five"></v-text-field>
        <v-text-field class="inputs-login input-code" maxlength="1" @keydown="nextInput" filled
          style="width: 50px;margin-top: 3em;font-size: 14px;color: #007BFF;" v-model="code.six"></v-text-field>
      </div>

      <v-btn @click="checkCode" :loading="loading" style="width: 100%;margin: auto;color: white;text-transform: none;"
        color="#007BFF" x-large elevation="0">
        Confirm Code
      </v-btn>
      <div style="display:grid;grid-template-columns: 1fr;margin-bottom: 100px;">
        <div style="display: grid;place-items: center;padding: .8em;">
          <span style="font-size: 13px;color: #999999;cursor: pointer;" @click="$emit('backStep')">Go Back</span>
        </div>
      </div>

      <span style="font-sizxe: 14px;color: #999;">Don't have an account?</span>
      <br>
      <h3 class="text-gradient" style="margin-top: .5em;font-size: 14px;cursor: pointer;"
        @click="$router.push('/register')">Sign Up Sider.Money</h3>
      <br><br><br>

    </center>
  </div>
</template>

<script>
  // import SlideUnlock from "vue2-slide-unlock"
  export default {
    data: () => ({
      email: "",
      password: "",
      rememberMe: false,
      loading: false,
      code: {
        one: "",
        two: "",
        three: "",
        four: "",
        five: "",
        six: ""
      }
    }),
    mounted(){
      
      this.$el.querySelector('input').focus();
      
      document.addEventListener('paste', (e) => {
        let pastedData = e.clipboardData.getData('text');
        if(pastedData.length == 6){
          this.code.one = pastedData[0]
          this.code.two = pastedData[1]
          this.code.three = pastedData[2]
          this.code.four = pastedData[3]
          this.code.five = pastedData[4]
          this.code.six = pastedData[5]
        }
      });
    },
    watch: {
      code: {
        handler: function () {
          if(this.code.six.length > 0 && this.code.five.length > 0 && this.code.four.length > 0 && this.code.three.length > 0 && this.code.two.length > 0 && this.code.one.length > 0){
            this.checkCode()
          }
        },
        deep: true
      }
    },
    computed: {
      verifyInputs() {
        // use a regex to verify if email is valid
        /* eslint-disable-next-line */
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.$store.state.authEmail.trim())) {
          if (this.password) {
            return false;
          }
        }

        return true;
      }
    },
    methods: {
      nextInput(e) {

        var currentInputDiv = e.target.parentElement.parentElement.parentElement.parentElement;
        var previousInputDiv = currentInputDiv.previousElementSibling?.querySelector('input');
        var nextInputDiv = currentInputDiv.nextElementSibling?.querySelector('input');
        
        setTimeout(() => {
          if (e.target.value.length === 1) {
            if (nextInputDiv) {
              nextInputDiv.focus();
            }
          } else {
            if (previousInputDiv && e.keyCode === 8) {
              previousInputDiv.focus();
            }
          }
        }, 50);
        
      },
      checkCode() {
        this.loading = true;
        var code = this.code.one + this.code.two + this.code.three + this.code.four + this.code.five + this.code.six;
        this.$http.post('/api/v1/validateCode', {
          email: this.$store.state.authEmail.toLowerCase().trim(),
          code: code
        }).then(() => {
          this.$store.state.code = code;
          this.$emit('nextStep');
        }).catch(err => {
          if (err && err.response) {
            this.$swal({
              title: 'Error',
              text: err.response.data.error,
              icon: 'error',
              showConfirmButton: false,
              timer: 2000
            })
          }
          this.loading = false;
        })
      },
    }
  }
</script>

<style>
  .footer-out {
    background-color: white;
    width: 100%;
    bottom: 0%;
    left: 0;
    right: 0;
    margin: auto;
  }

  .text-gradient {
    background-color: #f3ec78;
    background-image: linear-gradient(45deg, rgba(21, 168, 216, 1), rgba(72, 79, 248, 1));
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }


  .slideunlock-text {
    font-size: 14px !important;
  }

  .slideunlock {
    background: linear-gradient(90deg, rgba(21, 168, 216, 1) 0%, rgba(72, 79, 248, 1) 100%) !important;
  }

  .slideunlock .slideunlock-handler {
    background-color: rgba(255, 255, 255, 0.329) !important;
    color: white !important;
  }

  :root {
    --su-handler-bg: url("https://i.imgur.com/N0UOZJn.png");
    --su-color-progress-normal-bg: transparent;
    --su-color-progress-complete-bg: transparent;
  }


  .slideunlock .slideunlock-text {
    color: white !important;
  }

  .slideunlock .slideunlock-text:before {
    display: unset;
    padding-top: 1em;
  }

  .inputs-login input {
    padding-left: 10px !important;
  }
</style>

<style scoped>
  .v-label {
    font-size: 14px;
  }
</style>