<template>
  <div class="membership-view">
    <TabComponent :tabs="tabs" title="Membership">
      <template #content="{ tab }">
        <component :is="tab.component"></component>
      </template>
    </TabComponent>
    <br>
  </div>
</template>

<script>
  import TabComponent from '../TabComponent.vue'
  
  import InsightsView from './Insights/InsightsView.vue'
  import MembershipSettingsView from './Settings/MembershipSettingsView.vue'



  export default {
    components: {
      TabComponent
    },
    data() {
      return {
        activeTab: 'Insights',
        tabs: [{
          text: 'Insights',
          component: InsightsView
        }, {
          text: 'Settings',
          component: MembershipSettingsView
        }]
      }
    },
  }
</script>

<style lang="scss">
  .membership-view {

    &__tab-content{
      margin-top: 1rem;
      margin-bottom: 1rem;
      padding: .5rem;
    }



  }
</style>