<template>
  <div style="height: 100vh; position: relative" data-app>
    <v-dialog
      v-model="showPostDialog"
      max-width="768px"
      :fullscreen="windowWidth < 768"
      style="overflow: hidden; color: blue"
    >
      <v-card :style="[{ height: '90vh' }]" style="overflow: hidden">
        <CreatePostPopup
          v-if="showPostDialog"
          :style="[
            {
              padding: '1em',
              height: '100%',
            },
          ]"
          @close="showPostDialog = false"
          v-on="$listeners"
          :chat-id="$store.state.channelOpen.id || title"
        ></CreatePostPopup>

        <!-- @onSuccess="showSuccessSnack"
          @close="closeDialog"
          @reload="reload"
          :editingJob="$store.state.editingMenuItem"
          :viewingJob="$store.state.viewingMenuItem"
          :balance="balance"
          :stock="stock"
          :user="user" -->
      </v-card>
    </v-dialog>
    <div
      class="chat__header"
      ref="chatHeader"
      v-if="$store.state.channelOpen.id || title"
      :style="{ position: iOS() ? 'fixed' : 'fixed' }"
      style="z-index: 1"
    >
      <div
        style="
          display: grid;
          grid-template-columns: 1fr 1fr 9fr 1fr;
          width: 100%;
        "
      >
        <div style="display: flex; place-items: center">
          <v-btn
            elevation="0"
            @click="$emit('closeChat')"
            icon
            style="border-radius: 50px"
            color="#007bff"
          >
            <i
              class="fa fa-chevron-left"
              style="font-size: 17px; color: #888"
            ></i>
          </v-btn>
        </div>

        <div style="display: grid; align-items: center">
          <v-avatar
            size="40px"
            style="border: 2px solid transparent"
            :class="{ ['online-border']: isUserOnline }"
          >
            <v-img :src="channelInfo.avatar || $default.avatar"> </v-img>
          </v-avatar>
        </div>
        <div style="display: grid; align-items: center">
          <div style="margin-left: 20px" class="name">
            {{ channelInfo.name || title }}
            <svg
              v-if="channelInfo.verified_badge"
              fill="#007BFF"
              style="margin-bottom: -2px"
              width="17"
              viewBox="0 0 24 24"
              aria-label="Conta verificada"
              role="img"
              class="r-1cvl2hr r-4qtqp9 r-yyyyoo r-1xvli5t r-f9ja8p r-og9te1 r-bnwqim r-1plcrui r-lrvibr"
            >
              <g>
                <path
                  d="M22.5 12.5c0-1.58-.875-2.95-2.148-3.6.154-.435.238-.905.238-1.4 0-2.21-1.71-3.998-3.818-3.998-.47 0-.92.084-1.336.25C14.818 2.415 13.51 1.5 12 1.5s-2.816.917-3.437 2.25c-.415-.165-.866-.25-1.336-.25-2.11 0-3.818 1.79-3.818 4 0 .494.083.964.237 1.4-1.272.65-2.147 2.018-2.147 3.6 0 1.495.782 2.798 1.942 3.486-.02.17-.032.34-.032.514 0 2.21 1.708 4 3.818 4 .47 0 .92-.086 1.335-.25.62 1.334 1.926 2.25 3.437 2.25 1.512 0 2.818-.916 3.437-2.25.415.163.865.248 1.336.248 2.11 0 3.818-1.79 3.818-4 0-.174-.012-.344-.033-.513 1.158-.687 1.943-1.99 1.943-3.484zm-6.616-3.334l-4.334 6.5c-.145.217-.382.334-.625.334-.143 0-.288-.04-.416-.126l-.115-.094-2.415-2.415c-.293-.293-.293-.768 0-1.06s.768-.294 1.06 0l1.77 1.767 3.825-5.74c.23-.345.696-.436 1.04-.207.346.23.44.696.21 1.04z"
                ></path>
              </g>
            </svg>
            <svg
              v-if="channelInfo.ambassador"
              width="15"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style="margin-left: 4px; transform: translate(0, 1px)"
            >
              <path
                d="M21.001 11C21.001 9.42 20.126 8.05 18.853 7.4C19.007 6.965 19.091 6.495 19.091 6C19.091 3.79 17.381 2.002 15.273 2.002C14.803 2.002 14.353 2.086 13.937 2.252C13.319 0.915 12.011 0 10.501 0C8.991 0 7.685 0.917 7.064 2.25C6.649 2.085 6.198 2 5.728 2C3.618 2 1.91 3.79 1.91 6C1.91 6.494 1.993 6.964 2.147 7.4C0.875 8.05 0 9.418 0 11C0 12.495 0.782 13.798 1.942 14.486C1.922 14.656 1.91 14.826 1.91 15C1.91 17.21 3.618 19 5.728 19C6.198 19 6.648 18.914 7.063 18.75C7.683 20.084 8.989 21 10.5 21C12.012 21 13.318 20.084 13.937 18.75C14.352 18.913 14.802 18.998 15.273 18.998C17.383 18.998 19.091 17.208 19.091 14.998C19.091 14.824 19.079 14.654 19.058 14.485C20.216 13.798 21.001 12.495 21.001 11.001V11ZM14.385 7.666L10.051 14.166C9.906 14.383 9.669 14.5 9.426 14.5C9.283 14.5 9.138 14.46 9.01 14.374L8.895 14.28L6.48 11.865C6.187 11.572 6.187 11.097 6.48 10.805C6.773 10.513 7.248 10.511 7.54 10.805L9.31 12.572L13.135 6.832C13.365 6.487 13.831 6.396 14.175 6.625C14.521 6.855 14.615 7.321 14.385 7.665V7.666Z"
                fill="#007BFF"
              />
              <path
                d="M20 10.8571C20 9.72857 19.3334 8.75 18.3635 8.28571C18.4808 7.975 18.5448 7.63929 18.5448 7.28571C18.5448 5.70714 17.242 4.43 15.636 4.43C15.2779 4.43 14.9351 4.49 14.6182 4.60857C14.1473 3.65357 13.1508 3 12.0004 3C10.85 3 9.85496 3.655 9.38184 4.60714C9.06566 4.48929 8.72206 4.42857 8.36398 4.42857C6.75644 4.42857 5.45517 5.70714 5.45517 7.28571C5.45517 7.63857 5.5184 7.97429 5.63573 8.28571C4.66663 8.75 4 9.72714 4 10.8571C4 11.925 4.59578 12.8557 5.47955 13.3471C5.46431 13.4686 5.45517 13.59 5.45517 13.7143C5.45517 15.2929 6.75644 16.5714 8.36398 16.5714C8.72206 16.5714 9.0649 16.51 9.38108 16.3929C9.85344 17.3457 10.8484 18 11.9996 18C13.1516 18 14.1466 17.3457 14.6182 16.3929C14.9343 16.5093 15.2772 16.57 15.636 16.57C17.2436 16.57 18.5448 15.2914 18.5448 13.7129C18.5448 13.5886 18.5357 13.4671 18.5197 13.3464C19.4019 12.8557 20 11.925 20 10.8579V10.8571ZM14.9595 8.47571L11.6575 13.1186C11.5471 13.2736 11.3665 13.3571 11.1814 13.3571C11.0724 13.3571 10.962 13.3286 10.8644 13.2671L10.7768 13.2L8.93691 11.475C8.71368 11.2657 8.71368 10.9264 8.93691 10.7179C9.16014 10.5093 9.52202 10.5079 9.74449 10.7179L11.093 11.98L14.0071 7.88C14.1824 7.63357 14.5374 7.56857 14.7995 7.73214C15.0631 7.89643 15.1347 8.22929 14.9595 8.475V8.47571Z"
                fill="#007BFF"
              />
              <path
                d="M17 9.85714C17 8.72857 16.3334 7.75 15.3635 7.28571C15.4808 6.975 15.5448 6.63929 15.5448 6.28571C15.5448 4.70714 14.242 3.43 12.636 3.43C12.2779 3.43 11.9351 3.49 11.6182 3.60857C11.1473 2.65357 10.1508 2 9.00038 2C7.84996 2 6.85496 2.655 6.38184 3.60714C6.06566 3.48929 5.72206 3.42857 5.36398 3.42857C3.75644 3.42857 2.45517 4.70714 2.45517 6.28571C2.45517 6.63857 2.5184 6.97429 2.63573 7.28571C1.66663 7.75 1 8.72714 1 9.85714C1 10.925 1.59578 11.8557 2.47955 12.3471C2.46431 12.4686 2.45517 12.59 2.45517 12.7143C2.45517 14.2929 3.75644 15.5714 5.36398 15.5714C5.72206 15.5714 6.0649 15.51 6.38108 15.3929C6.85344 16.3457 7.84844 17 8.99962 17C10.1516 17 11.1466 16.3457 11.6182 15.3929C11.9343 15.5093 12.2772 15.57 12.636 15.57C14.2436 15.57 15.5448 14.2914 15.5448 12.7129C15.5448 12.5886 15.5357 12.4671 15.5197 12.3464C16.4019 11.8557 17 10.925 17 9.85786V9.85714ZM11.9595 7.47571L8.65754 12.1186C8.54707 12.2736 8.36651 12.3571 8.18137 12.3571C8.07243 12.3571 7.96195 12.3286 7.86444 12.2671L7.77682 12.2L5.93691 10.475C5.71368 10.2657 5.71368 9.92643 5.93691 9.71786C6.16014 9.50929 6.52202 9.50786 6.74449 9.71786L8.093 10.98L11.0071 6.88C11.1824 6.63357 11.5374 6.56857 11.7995 6.73214C12.0631 6.89643 12.1347 7.22929 11.9595 7.475V7.47571Z"
                fill="#007BFF"
              />
              <path
                d="M10.939 5.029V5.77C11.5803 5.796 12.135 5.887 12.603 6.043C13.071 6.19033 13.305 6.41567 13.305 6.719C13.305 6.98767 13.214 7.343 13.032 7.785C12.8587 8.227 12.7373 8.461 12.668 8.487C11.94 8.18367 11.3637 8.01467 10.939 7.98V9.449C12.759 9.51833 13.669 10.3937 13.669 12.075C13.669 12.8637 13.4437 13.5397 12.993 14.103C12.5423 14.6663 11.8577 14.9957 10.939 15.091V15.988C10.939 16.144 10.8913 16.248 10.796 16.3C10.7093 16.352 10.6357 16.378 10.575 16.378L10.393 16.326C10.2717 16.2567 10.211 16.144 10.211 15.988V15.13C9.483 15.1127 8.83733 14.9827 8.274 14.74C7.71933 14.4973 7.442 14.1897 7.442 13.817C7.442 13.3317 7.58933 12.842 7.884 12.348C7.91 12.296 7.94467 12.2397 7.988 12.179C8.69 12.6037 9.431 12.842 10.211 12.894V11.464C8.365 11.3513 7.442 10.4067 7.442 8.63C7.442 7.85 7.689 7.20867 8.183 6.706C8.677 6.20333 9.353 5.9 10.211 5.796V5.029C10.211 4.873 10.2543 4.769 10.341 4.717C10.4363 4.665 10.5143 4.639 10.575 4.639L10.757 4.691C10.8783 4.76033 10.939 4.873 10.939 5.029ZM11.862 12.01C11.862 11.8107 11.7927 11.6763 11.654 11.607C11.524 11.529 11.2857 11.4813 10.939 11.464V12.868C11.5543 12.764 11.862 12.478 11.862 12.01ZM9.418 8.773C9.418 9.137 9.68233 9.34933 10.211 9.41V7.993C9.68233 8.07967 9.418 8.33967 9.418 8.773Z"
                fill="white"
              />
            </svg>

            <p
              style="
                font-size: 15px;
                margin-left: 4px;
                transform: translateY(-1px);
                display: inline-block;
              "
              v-if="channelInfo.investor"
            >
              <i
                style=""
                class="fas fa-chart-pie-simple-circle-dollar primary-color"
              ></i>
            </p>
          </div>
          <div style="margin-left: 20px">
            <span style="font-size: 13px; color: gray">
              {{ showCurrentStatus }}</span
            >
          </div>
        </div>
        <div
          style="display: flex; place-items: center; justify-content: flex-end"
        >
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                elevation="0"
                icon
                style="border-radius: 50px"
                color="#007bff"
                v-bind="attrs"
                v-on="on"
              >
                <i
                  class="fa fa-ellipsis-v"
                  style="font-size: 17px; color: #888"
                ></i>
              </v-btn>
            </template>

            <v-list>
              <v-list-item>
                <v-list-item-title>Coming soon</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>

      <!-- {{$store.state.channelOpen.id}} -->
    </div>
    <div
      class="chat__messages"
      ref="chatMessages"
      style="
        background: url('https://web.telegram.org/z/chat-bg-pattern-dark.ad38368a9e8140d0ac7d.png');
        background-size: cover;
      "
      :style="{
        height: this.$store.state.channelOpen.id || title ? '' : '100vh',
      }"
    >
      <div
        v-for="(value, date) in groupedMessages"
        class="chat__message__date"
        :key="`date-${date}`"
      >
        <div class="chat__message__date-wrapper">
          <div class="chat__message__date-divider"></div>
          <div class="chat__message__date-text">
            <v-chip class="chat__message__date-text__chip">
              {{ date }}
            </v-chip>
          </div>
          <div class="chat__message__date-divider"></div>
        </div>
        <div
          class="chat__message"
          v-for="(message, messageIndex) in value"
          :key="messageIndex + hotreloadImages"
          :class="{ sender: message.sender_id == me.id }"
          :style="{
            marginTop: messageIndex == 0 ? '2.5rem' : '0',
          }"
        >
          <div
            class="chat__message__avatar"
            :class="{
              'chat__message__avatar--sender': message.sender_id == me.id,
            }"
          >
            <v-avatar
              style="border: 2px solid transparent"
              size="30px"
              :class="{ ['online-border']: isUserOnline }"
            >
              <v-img :src="message.sender.avatar || $default.avatar"></v-img>
            </v-avatar>
          </div>
          <div
            class="chat__message__content"
            :style="{
              padding: message.payperview ? '0' : '10px',
              'padding-bottom': message.payperview ? '10px' : '10px',
            }"
          >
            <div
              class="chat__message__content__message-options"
              :class="{
                'chat__message__content__message-options--sender':
                  message.sender_id == me.id,
              }"
              v-if="message.sender_id == me.id && !message.transaction"
            >
              <span data-tooltip="Delete Message">
                <v-btn
                  fab
                  x-small
                  elevation="0"
                  :color="message.sender_id == me.id ? 'white' : '#ff7f00'"
                  style="border: 1px solid #007bff"
                  @click="handleDeleteMessage(message)"
                >
                  <i class="fal fa-trash" aria-hidden="true"></i>
                </v-btn>
              </span>
            </div>
            <div class="chat__message__content__text">
              <span v-html="message.message"></span>
              <div
                v-if="message.payperview"
                style="max-width: 300px; position: relative"
              >
                <span
                  v-if="message.payperview.images"
                  style="position: absolute; right: 2%; top: 5%; z-index: 1"
                >
                  {{ message.payperview.images }}
                  <i class="fa-sharp fa-solid fa-image"></i>
                </span>
                <span
                  v-if="message.payperview.videos"
                  style="position: absolute; top: 5%; z-index: 1"
                  :style="{
                    right: message.payperview.images ? '15%' : '2%',
                  }"
                >
                  {{ message.payperview.videos }}
                  <i class="fa-sharp fa-solid fa-video"></i>
                </span>
                <div
                  v-if="message.payperview.paid"
                  @click="$store.commit('setPostId', message.payperview.id)"
                  style="
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                  "
                >
                  <v-img
                    class="chat__message__content__text__preview"
                    v-if="message.payperview.preview"
                    @error="handleImageError(message.id, 'preview')"
                    :src="
                      message.payperview.preview.medium ||
                      message.payperview.preview.thumbnail
                    "
                    lazy-src="/assets/images/processing-post-dark.png"
                    style="width: 100%; border-radius: 5px"
                  ></v-img>
                  <v-img
                    class="chat__message__content__text__preview"
                    v-else-if="message.payperview.files"
                    @error="handleImageError(message.id, 'files')"
                    :src="
                      message.payperview.files[0].blur ||
                      message.payperview.files[0].thumbnail
                    "
                    lazy-src="/assets/images/processing-post-dark.png"
                    style="width: 100%; border-radius: 5px"
                  ></v-img>
                  <i
                    v-if="message.payperview.preview.thumbnail"
                    class="fal fa-play"
                    aria-hidden="true"
                    style="position: absolute; font-size: 50px"
                  ></i>
                  <i
                    v-else
                    class="fal fa-expand"
                    style="position: absolute; font-size: 50px"
                  ></i>
                </div>
                <div v-else>
                  <div
                    v-if="message.payperview.preview"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      font-size: 50px;
                      position: relative;
                    "
                  >
                    <v-img
                      class="chat__message__content__text__preview"
                      @error="handleImageError(message.id, 'preview')"
                      :src="message.payperview.preview.blur"
                      lazy-src="/assets/images/processing-post-dark.png"
                      style="width: 100%; border-radius: 5px"
                    ></v-img>
                    <p style="position: absolute">
                      <i
                        class="fa fa-lock"
                        aria-hidden="true"
                        style="
                          text-shadow: 0 1px 5px rgba(0, 0, 0, 0.402);
                          border-radius: 50%;
                        "
                      ></i>
                    </p>
                  </div>
                  <div
                    v-else
                    class="preview-payperview"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      font-size: 50px;
                      width: 250px;
                      height: 250px;
                      background-size: cover;
                      border-radius: 5px;
                      margin-bottom: 4px;
                    "
                  >
                    <p>
                      <i
                        class="fa fa-lock"
                        aria-hidden="true"
                        style="
                          text-shadow: 0 1px 5px rgba(0, 0, 0, 0.402);
                          border-radius: 50%;
                        "
                      ></i>
                    </p>
                  </div>
                </div>
                <center
                  style="margin: 4px; margin-top: 8px; margin-bottom: 8px"
                >
                  <p>{{ message.payperview.description }}</p>
                </center>
                <center>
                  <v-btn
                    v-if="!message.payperview.paid"
                    elevation="0"
                    rounded
                    color="#007bff"
                    style="color: white"
                    @click="
                      message.payperview.paid
                        ? $store.commit('setPostId', message.payperview.id)
                        : buyPayperview(message.payperview)
                    "
                  >
                    <span
                      >Pay
                      {{
                        parseFloat(message.payperview.pay_per_view).toFixed(2)
                      }}$</span
                    >
                  </v-btn>
                </center>
              </div>
            </div>
            <div
              class="chat__message__content__time"
              :class="{
                'chat__message__content__time--no-padding-left':
                  message.transaction,
              }"
            >
              <span
                v-if="
                  message.payperview &&
                  message.payperview.paid &&
                  message.payperview.owner.id != me.id
                "
                ><i
                  >paid:
                  {{
                    parseFloat(message.payperview.pay_per_view).toFixed(2)
                  }}$</i
                ></span
              >
              <span
                v-else-if="
                  message.payperview &&
                  message.payperview.paid &&
                  message.payperview.owner.id == me.id
                "
                ><i v-if="message.payperview.userPaid">
                  paid:
                  {{
                    parseFloat(message.payperview.pay_per_view).toFixed(2)
                  }}$</i
                >
                <span v-else>
                  {{
                    parseFloat(message.payperview.pay_per_view || 0).toFixed(2)
                  }}$ not paid yet
                </span>
              </span>
              <span v-else></span>
              <span
                v-if="message.transaction"
                style="float: left; margin-right: 24px"
                ><i
                  >TIP:
                  {{ parseFloat(message.transaction.amount).toFixed(2) }}$</i
                ></span
              >
              <span
                >{{
                  new Date(message.createdAt).toLocaleTimeString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="chat__actions"
      ref="chatActions"
      v-if="this.$store.state.channelOpen.id || title"
      :style="{ bottom: iOS() && isPWA ? '20px' : '0' }"
    >
      <div class="spacer">
        <input
          type="file"
          ref="file"
          style="display: none"
          @change="handleFileChange"
        />
        <div class="chat__input" style="position: relative">
          <v-menu
            v-model="menuDonation"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-y
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                style="
                  border-radius: 50px;
                  position: absolute;
                  z-index: 1;
                  top: 30%;
                  right: 5%;
                  transform: translateY(-25%);
                "
                color="rgba(0,0,0,0);"
                elevation="0"
                fab
                icon
                v-bind="attrs"
                v-on="on"
              >
                <i
                  class="fa-light fa-dollar-sign"
                  style="font-size: 18px"
                  :style="{ color: donationAmount > 0 ? '#007bff' : '' }"
                ></i>
                <br />
                <span>
                  {{ donationAmount ? formatValueSymbol(donationAmount) : "" }}
                </span>
              </v-btn>
            </template>

            <v-card style="width: 300px">
              <v-card-actions>
                <v-btn color="primary" text>
                  Balance: {{ balance.toFixed(2) }}$
                </v-btn>
                <v-spacer></v-spacer>
                <i
                  class="fa fa-times text-white feed-comments__pre-values-close"
                  @click="menuDonation = false"
                  aria-hidden="true"
                ></i>
              </v-card-actions>

              <small
                v-if="balance < donationAmount"
                style="color: #ff0000; padding-left: 16px"
              >
                Not enough balance
                <router-link to="/settings/wallet" style="color: #007bff"
                  >add funds.</router-link
                >
              </small>
              <vuetify-money
                style="padding-left: 1em; padding-right: 1em"
                v-model="donationAmount"
                :valueWhenIsEmpty="valueWhenIsEmpty"
                :options="optionsMoney"
                :color="balance < donationAmount ? '#ff0000' : '#007bff'"
                filled
                placeholder="Amount to donate"
                persistent-hint
                hint="Message required to send donation."
              >
              </vuetify-money>

              <div class="feed-comments__pre-values-donation-wrapper">
                <v-btn
                  class="feed-comments__pre-values-donation"
                  elevation="0"
                  v-for="preValue in preValues"
                  :key="preValue"
                  fab
                  small
                  @click="donationAmount = preValue"
                >
                  {{ preValue }}
                </v-btn>
              </div>
            </v-card>
          </v-menu>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                elevation="0"
                icon
                text
                style="
                  border-radius: 50px;
                  position: absolute;
                  z-index: 1;
                  top: 50%;
                  left: 2%;
                  transform: translateY(-25%);
                "
                color="rgba(0,0,0,0);"
              >
                <i class="fa-light fa-paperclip" style="font-size: 18px"></i>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="triggerInputFile">
                <v-list-item-title> Send a file </v-list-item-title>
              </v-list-item>
              <v-list-item @click="showPayperviewModal">
                <v-list-item-title> Send payperview </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-text-field
            @keyup.enter="sendMessage"
            ref="chatInput"
            @focus="onFocus"
            @blur="onLoseFocus"
            style="width: 98%"
            v-model="message"
            solo
            rounded
            elevation="0"
          >
          </v-text-field>
        </div>
      </div>
      <div class="chat__actions__item">
        <div class="chat__actions__item__send">
          <v-btn
            elevation="0"
            style="border-radius: 50px"
            fab
            small
            color="rgba(0,0,0,0)"
            @click="sendMessage"
          >
            <transition name="component-fade" mode="out-in">
              <i
                class="fa-light fa-paper-plane send-icon"
                :class="{ active: message.length > 0 }"
              ></i>
            </transition>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import chatMixin from "@/mixins/chat";
import CreatePostPopup from "@/components/Popups/CreatePostPopup";
export default {
  mixins: [chatMixin],
  components: {
    CreatePostPopup,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    messages: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    isPWA() {
      return (
        window.matchMedia("(display-mode: standalone)").matches ||
        window.navigator.standalone ||
        document.referrer.includes("android-app://")
      );
    },
    isUserOnline() {
      if (!this.$store.state.channelOpen.users) {
        return false;
      }

      const channel = this.$store.state.channelOpen.users;
      const userChannel = channel.find((user) => user.user_id != this.me.id);
      const user = this.$store.state.userSocketIds.find(
        (user) => user.id == userChannel.user_id
      );
      return user ? true : false;
    },
    showCurrentStatus() {
      if (!this.$store.state.channelOpen.users) {
        return this.description || "";
      }
      const userChat = this.$store.state.channelOpen.users.find(
        (user) => user.user_id != this.me.id
      );

      const whoImTakingToIsOnline = this.$store.state.userSocketIds.find(
        (user) => user.id == userChat.user_id
      );

      if (this.whoIsTypingId == userChat.id && whoImTakingToIsOnline) {
        return "Typing...";
      }

      if (whoImTakingToIsOnline) {
        return "Online now";
      }

      return "Offline";
    },
    whoIsTyping() {
      return this.$store.state.channelOpen.users.find(
        (user) => user.user_id == this.whoIsTypingId
      )?.user;
    },
    channelInfo() {
      if (!this.$store.state.channelOpen.users) {
        return { name: "", avatar: "" };
      }
      if (
        this.$store.state.channelOpen.users.filter(
          (user) => user.user_id != this.me.id
        ).length == 1
      ) {
        var { user } = this.$store.state.channelOpen.users.filter(
          (user) => user.user_id != this.me.id
        )[0];
        return {
          name: user.name,
          avatar: user.avatar,
          verified_badge: user.verified_badge,
          ambassador: user.ambassador,
          investor: user.investor,
        };
      } else {
        return { name: "", avatar: "" };
      }
    },
  },
  data: () => ({
    menuDonation: false,
    valueWhenIsEmpty: "",
    optionsMoney: {
      locale: "pt-PT",
      prefix: " $ ",
      suffix: "",
      length: 9,
      precision: 0,
    },
    preValues: [10, 50, 100, 200],
    donationAmount: 0,
    balance: 0,
    hotreloadImages: 0,
    windowWidth: window.innerWidth,
    showPostDialog: false,
    groupedMessages: {},
    menuActionsChat: false,
    message: "",
    height: 0,
    isTyping: false,
    whoIsTypingId: -1,
  }),
  mounted() {
    window.socket.on("new-typing", ({ channel_id, user_id, isTyping }) => {
      if (channel_id == this.$store.state.channelOpen.id) {
        this.isTyping = isTyping;
        this.whoIsTypingId = user_id;

        if (this.isTyping == false) {
          this.whoIsTypingId = -1;
        }
      }
    });
  },
  watch: {
    menuDonation() {
      this.getBalance();
    },
    message() {
      if (this.$store.state.channelOpen.id) {
        if (this.message.length > 0) {
          window.socket.emit("typing", {
            channel_id: this.$store.state.channelOpen.id,
            user_id: this.me.id,
            isTyping: true,
          });
        } else {
          window.socket.emit("typing", {
            channel_id: this.$store.state.channelOpen.id,
            user_id: this.me.id,
            isTyping: false,
          });
        }
      }
    },
    messages: {
      async handler() {
        this.groupedMessages = await this.groupMessages();

        this.$nextTick(() => {
          this.$el.querySelector(".chat__messages").scrollTop =
            this.$el.querySelector(".chat__messages").scrollHeight;
        });
      },
      deep: true,
    },
  },
  methods: {
    formatValueSymbol(val = "") {
      if (val > 999 && val < 1000000) {
        val = (val / 1000).toFixed(1) + "K";
      } else if (val > 999999 && val < 1000000000) {
        val = (val / 1000000).toFixed(1) + "M";
      } else if (val > 999999999 && val < 1000000000000) {
        val = (val / 1000000000).toFixed(1) + "B";
      } else if (val > 999999999999 && val < 1000000000000000) {
        val = (val / 1000000000000).toFixed(1) + "T";
      }

      val = String(val);

      if (
        !val.includes("K") &&
        !val.includes("M") &&
        !val.includes("B") &&
        !val.includes("T")
      ) {
        val = Number(val);
      }

      return val;
    },
    async getBalance() {
      this.balance = await this.$http
        .get("/api/v1/getBalance")
        .then((res) => res.data.credits)
        .catch(() => 0);
    },
    handleImageError(id, key) {
      if (key == "preview") {
        const index = this.messages.findIndex((message) => message.id == id);
        const message = this.messages[index];

        if (message.payperview.preview.medium) {
          message.payperview.preview.medium =
            message.payperview.preview.medium.replace(
              "https://cdn.sider.money/",
              "https://sidermoney.nyc3.digitaloceanspaces.com/"
            );
        }
        if (message.payperview.preview.thumbnail) {
          message.payperview.preview.thumbnail =
            message.payperview.preview.thumbnail.replace(
              "https://cdn.sider.money/",
              "https://sidermoney.nyc3.digitaloceanspaces.com/"
            );
        }
      } else if (key == "files") {
        const index = this.messages.findIndex((message) => message.id == id);
        const message = this.messages[index];
        message.payperview.files[0].blur =
          message.payperview.files[0].blur.replace(
            "https://cdn.sider.money/",
            "https://sidermoney.nyc3.digitaloceanspaces.com/"
          );

        if (message.payperview.files[0].thumbnail) {
          message.payperview.files[0].thumbnail =
            message.payperview.files[0].thumbnail.replace(
              "https://cdn.sider.money/",
              "https://sidermoney.nyc3.digitaloceanspaces.com/"
            );
        }
      }
      setTimeout(() => {
        this.hotreloadImages++;
      }, 3000);
    },
    async buyPayperview(payperview) {
      await this.getBalance();
      if (this.balance < payperview.pay_per_view) {
        this.$swal({
          title: "Insufficient Balance",
          text: "You do not have enough balance to buy this post",
          icon: "error",
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "#888",
          cancelButtonColor: "#007BFF",
          confirmButtonText: "Close",
          cancelButtonText:
            '<i class="fa-light fa-credit-card"></i> Add balance',
        }).then((res) => {
          if (res.isDismissed && res.dismiss === "cancel" && !res.isConfirmed) {
            this.$router.push("/settings/wallet");
          } else {
            this.$emit("close");
          }
        });
        return;
      }

      this.$swal({
        title:
          "Payperview " +
          parseFloat(payperview.pay_per_view || 0).toFixed(2) +
          "$",
        icon: "question",
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: "#888",
        cancelButtonColor: "#007BFF",
        confirmButtonText: "Cancel",
        cancelButtonText:
          "Pay " + parseFloat(payperview.pay_per_view || 0).toFixed(2) + "$",
      }).then((res) => {
        if (res.isDismissed && res.dismiss === "cancel" && !res.isConfirmed) {
          this.$http
            .post("/api/v1/post/buy", {
              post_id: payperview.id,
            })
            .then(async (res) => {
              this.$toast.open({
                type: "success",
                message: "You have successfully bought this payperview",
                duration: 5000,
              });

              let date = [];
              let index = [];

              Object.entries(this.groupedMessages).forEach(([key, value]) => {
                value.forEach((v) => {
                  if (v.payperview_id == payperview.id) {
                    date.push(key);
                    index.push(this.groupedMessages[key].indexOf(v));
                  }
                });
              });

              date.forEach((d) => {
                index.forEach((i) => {
                  if (this.groupedMessages[d] && this.groupedMessages[d][i]) {
                    this.groupedMessages[d][i].payperview.paid = true;
                    this.groupedMessages[d][i].payperview.files =
                      res.data.files;
                  }
                });
              });
            })
            .catch((err) => {
              console.log(err);
              this.$toast.open({
                type: "error",
                message: "Something went wrong",
                duration: 5000,
              });
            });
        } else {
          this.$emit("close");
        }
      });
    },
    async groupMessages() {
      let groupedData = this.messages.reduce((acc, message) => {
        const date = new Date(message.createdAt ? message.createdAt : 0);
        const key = date.toLocaleDateString();
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(message);
        return acc;
      }, {});

      return groupedData;
    },
    handleDeleteMessage(message) {
      this.$emit("deleteMessage", message);
    },
    showPayperviewModal() {
      this.showPostDialog = true;
    },
    handleFileChange(event) {
      if (!event) return;
      let file = event.target.files[0];
      let toast = this.$toast.open({
        type: "info",
        message: "Sending file...",
        duration: 1000000,
      });

      this.$store
        .dispatch("chat/sendFile", file)
        .then(() => {
          this.$emit("messageSent");
          toast.dismiss();
          this.$toast.open({
            type: "success",
            message: "File sent successfully",
          });
        })
        .catch(() => {
          toast.dismiss();
          this.$toast.open({
            type: "error",
            message: "Error sending file, try again later",
          });
        })
        .finally(() => {
          event.target.value = "";
        });
    },
    triggerInputFile() {
      this.$refs.file.click();
    },
    focusInput() {
      this.$refs.chatInput?.focus();
    },
    onLoseFocus() {
      this.$refs.chatHeader.style.top = `0`;
      if (this.iOS() && this.isPWA) {
        this.$refs.chatActions.style.bottom = `20px`;
      }
    },
    onFocus() {
      setTimeout(() => {
        // on focus input scroll chat container
        this.$el.querySelector(".chat__messages").scrollTop =
          this.$el.querySelector(".chat__messages").scrollHeight;

        var height = document
          .getElementsByClassName("chat__messages")[0]
          .getBoundingClientRect().y;
        if (this.iOS() && this.isPWA) {
          this.$refs.chatHeader.style.top = `${-height}px`;
          this.$refs.chatActions.style.bottom = "0";
        }
      }, 400);
    },
    iOS() {
      return (
        ["iPhone Simulator", "iPhone"].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      );
    },
    sendMessage() {
      if (this.message.length <= 0) return;

      if (this.balance < this.donationAmount) {
        this.$swal({
          title: "Insufficient Balance",
          text: "You do not have enough balance to make this donation.",
          icon: "error",
          confirmButtonColor: "#888",
          cancelButtonColor: "#007BFF",
          confirmButtonText: "Close",
          showCancelButton: true,
          cancelButtonText: '<i class="fa-light fa-credit-card"></i> Topup',
        }).then((res) => {
          if (res.value) {
            this.$emit("close");
          } else {
            this.$router.push("/settings/wallet");
          }
        });
        return;
      }

      this.$emit("sendMessage", this.message, this.donationAmount);

      let lastMessage = {
        message: this.message,
        createdAt: new Date(),
        channel_id: this.$store.state.channelOpen.id,
      };

      this.$store.state.channelOpen.lastMessage = lastMessage;

      let chIndex = this.$store.state.channels.findIndex(
        (ch) => ch.id == this.$store.state.channelOpen.id
      );

      if (chIndex != -1) {
        this.$store.state.channels[chIndex].lastMessage = lastMessage;
      }

      this.donationAmount = "";
      this.message = "";
      if (this.$store.state.channelOpen.id) {
        window.socket.emit("typing", {
          channel_id: this.$store.state.channelOpen.id,
          user_id: this.me.id,
          isTyping: false,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}

.component-fade-enter,
  .component-fade-leave-to

  /* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<style lang="scss">
.chat__message {
  &__date {
    display: grid;
  }

  &__date-wrapper {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    padding-top: 10px;
    margin-bottom: 10px;
  }

  &__date-text {
    position: relative;

    &__chip {
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
      position: sticky;

      @media (prefers-color-scheme: dark) {
        background-color: var(--color-black-2) !important;
        color: var(--color-our-white) !important;
      }
    }
  }

  &__date-divider {
    height: 1px;
    // background-color: var(--dark-secondary-color);
    margin-left: 10px;
    margin-right: 10px;
  }
}
</style>

<style lang="scss">
.open-graph {
  display: grid;
  grid-template-columns: 1fr;
  & a {
    color: unset;
    text-decoration: none;
    width: 100%;
    display: grid;

    & .link {
      color: #007bff;
    }

    & img {
      width: 100%;
    }

    & .open-graph-content {
      padding: 0.3rem;
      padding-top: 0.5rem;

      & p {
        word-break: break-word;
      }
    }
  }
}

.preview-payperview {
  min-height: 500px;
  @media (prefers-color-scheme: dark) {
    background-image: url("/assets/images/processing-post-dark.png");
    color: var(--color-our-white);
  }

  @media (prefers-color-scheme: light) {
    background-image: url("/assets/images/processing-post-light.png");
    color: black;
  }
}

/* .open-graph {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
  .open-graph a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #000;
  }
  .open-graph img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
  }
  .open-graph-content {
    margin-left: 10px;
  }
  .open-graph-content h3 {
    margin: 0;
    font-size: 1.2rem;
  }
  .open-graph-content p {
    margin: 0;
    font-size: 1rem;
  } */
</style>
