<template>
  <div style="position: relative; overflow: auto; height: 500px">
    <div class="profile-banner" style="height: 100px">
      <v-img
        :src="`${user.banner}`"
        :lazy-src="`${user.banner}`"
        max-height="284px"
        style="
          position: absolute;
          left: 0;
          width: 100%;
          height: 100px;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        "
      >
      </v-img>

      <v-btn
        class="btn-fab"
        fab
        small
        absolute
        right
        style="top: 10%"
        @click="$emit('close')"
      >
        <i class="fa fa-times" style="font-size: 20px" aria-hidden="true"></i>
      </v-btn>
    </div>

    <v-img
      :src="`${user.avatar}`"
      :lazy-src="`${user.avatar}`"
      class="profile-image"
      style="
        position: absolute;
        margin-left: 2em;
        width: 100px !important;
        height: 100px !important;
      "
    >
    </v-img>

    <div
      style="padding-left: 3em; padding-top: 5em"
      v-if="
        user.subscribedPackages.length > 0 &&
        !getHighestPaidSubscription.canceled &&
        user.subscribedPackages
          .filter((p) => p.bundle_key == 'subscription_price_free')
          .filter((v) => v.canceled == false)
          .flat().length > 0
      "
    >
      <span class="text-white">
        <span v-if="renewPrice > 0"
          >Renews at {{ calcSubscriptionsExpires }} for {{ renewPrice }}$
          <br /><br /><br />
        </span>
        If you unsubscribe you lose:
      </span>

      <br />

      <v-list-item style="padding: 0em">
        <v-list-item-content>
          <v-list-item-title class="text-white" style="word-wrap: wrap">
            <i class="fa fa-times text-primary" aria-hidden="true"></i>
            Access to all content
            <small>(Free subscription gives access only to free posts)</small>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item style="padding: 0em">
        <v-list-item-content>
          <v-list-item-title class="text-white">
            <i class="fa fa-times text-primary" aria-hidden="true"></i>
            Direct message <small>(Free & paid subscription)</small>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>
    <!-- v-if="!user.subscribedPackages.length > 0 && (user.subscription_price > 0 || user.subscription_price_trimester > 0 || user.subscription_price_semester > 0 || user.subscription_price_year > 0) || getHighestPaidSubscription && getHighestPaidSubscription.canceled || (!getHighestPaidSubscription.bundle_key || (user.subscribedPackages.find(p => p.bundle_key == 'subscription_price_free')))" -->
    <div
      style="padding-left: 3em; padding-top: 2em"
      v-if="
        user.subscribedPackages.length == 0 ||
        user.subscribedPackages
          .filter((p) => p.bundle_key == 'subscription_price_free')
          .filter((v) => v.canceled == false)
          .flat().length == 0
      "
    >
      <br /><br />
      <span v-if="getHighestPaidSubscription.canceled" style="color: #cbcfd4">
        Your
        {{ bundleNames[getHighestPaidSubscription.bundle_key] }} subscription
        <br />
        expires at {{ calcSubscriptionsExpires }} <br /><br />
      </span>
      <span style="color: #cbcfd4; font-weight: bolder">
        Subscribe and get the following benefits:
      </span>

      <br /><br />

      <v-list-item style="padding: 0em">
        <v-list-item-content>
          <v-list-item-title style="color: #cbcfd4">
            <i class="fa fa-check text-primary" aria-hidden="true"></i>
            Access to all content
            <small>(Free subscription gives access only to free posts)</small>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item style="padding: 0em">
        <v-list-item-content>
          <v-list-item-title style="color: #cbcfd4">
            <i class="fa fa-check text-primary" aria-hidden="true"></i>
            Direct message <small>(Free & paid subscription)</small>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item style="padding: 0em">
        <v-list-item-content>
          <v-list-item-title style="color: #cbcfd4">
            <i class="fa fa-check text-primary" aria-hidden="true"></i>
            Cancel anytime
            <small>(You will still have access until it expires)</small>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>
    <br />
    <center>
      <v-btn
        :class="{
          'btn-subscribe-active':
            !user.subscribedPackages.find(
              (p) => p.bundle_key == 'subscription_price_free'
            ) || getHighestPaidSubscription.canceled,
          'btn-subscribe-not-active':
            user.subscribedPackages.find(
              (p) => p.bundle_key == 'subscription_price_free'
            ) && !getHighestPaidSubscription.canceled,
        }"
        v-if="hideButtonsBasedOnSubscription('subscription_price_free')"
        @click="subscribe('subscription_price_free')"
        style="
          background-color: rgba(0, 123, 255, 0.2) !important;
          font-weight: bolder;
          color: white;
          width: 90%;
        "
        elevation="0"
        rounded
        large
      >
        <div
          v-if="
            !user.subscribedPackages.find(
              (p) => p.bundle_key == 'subscription_price_free'
            ) ||
            user.subscribedPackages
              .filter((p) => p.bundle_key == 'subscription_price_free')
              .filter((v) => v.canceled == false)
              .flat().length == 0
          "
          style="
            display: flex;
            width: 100%;
            justify-content: space-between;
            text-transform: capitalize;
          "
        >
          <div
            v-if="
              !user.subscribedPackages.find(
                (p) => p.bundle_key == 'subscription_price_free'
              ) ||
              user.subscribedPackages
                .filter((p) => p.bundle_key == 'subscription_price_free')
                .filter((v) => v.canceled == false)
                .flat().length == 0
            "
          >
            <span
              v-if="
                user.subscribedPackages
                  .filter((p) => p.bundle_key == 'subscription_price_free')
                  .filter((v) => v.canceled == true)
                  .flat().length > 0
              "
            >
              Renew
            </span>
            <span v-else> Subscribe </span>
          </div>
          <div
            v-if="
              !user.subscribedPackages.find(
                (p) => p.bundle_key == 'subscription_price_free'
              ) ||
              user.subscribedPackages
                .filter((p) => p.bundle_key == 'subscription_price_free')
                .filter((v) => v.canceled == false)
                .flat().length == 0
            "
          >
            FREE
          </div>
        </div>
        <div
          v-if="
            user.subscribedPackages.find(
              (p) => p.bundle_key == 'subscription_price_free'
            ) &&
            user.subscribedPackages
              .filter((p) => p.bundle_key == 'subscription_price_free')
              .filter((v) => v.canceled == false)
              .flat().length > 0
          "
          style="
            display: flex;
            width: 100%;
            justify-content: center;
            text-transform: capitalize;
          "
        >
          <span>Unsubscribe free</span>
        </div>
      </v-btn>

      <br /><br />

      <v-btn
        :class="{
          'btn-subscribe-active':
            !user.subscribedPackages.find(
              (p) => p.bundle_key == 'subscription_price'
            ) || getHighestPaidSubscription.canceled,
          'btn-subscribe-not-active':
            user.subscribedPackages.find(
              (p) => p.bundle_key == 'subscription_price'
            ) && !getHighestPaidSubscription.canceled,
        }"
        v-if="
          user.subscription_price > 0 &&
          hideButtonsBasedOnSubscription('subscription_price')
        "
        @click="subscribe('subscription_price')"
        style="
          background-color: rgba(0, 123, 255, 0.4) !important;
          font-weight: bolder;
          color: white;
          width: 90%;
        "
        elevation="0"
        rounded
        large
      >
        <div
          v-if="
            !user.subscribedPackages.find(
              (p) => p.bundle_key == 'subscription_price'
            ) ||
            (getHighestPaidSubscription && getHighestPaidSubscription.canceled)
          "
          style="
            display: flex;
            width: 100%;
            justify-content: space-between;
            text-transform: capitalize;
          "
        >
          <div
            v-if="
              !user.subscribedPackages.find(
                (p) => p.bundle_key == 'subscription_price'
              ) ||
              (getHighestPaidSubscription &&
                getHighestPaidSubscription.canceled)
            "
          >
            <template
              v-if="
                user.subscribedPackages.find(
                  (p) => p.bundle_key == 'subscription_price'
                ) &&
                user.subscribedPackages.find(
                  (p) => p.bundle_key == 'subscription_price'
                ).canceled
              "
            >
              Renew
            </template>
            Monthly
          </div>
          <div
            v-if="
              !user.subscribedPackages.find(
                (p) => p.bundle_key == 'subscription_price'
              ) ||
              (getHighestPaidSubscription &&
                getHighestPaidSubscription.canceled)
            "
          >
            {{ user.subscription_price }}$
          </div>
        </div>
        <div
          v-if="
            user.subscribedPackages.find(
              (p) => p.bundle_key == 'subscription_price'
            ) &&
            !user.subscribedPackages.find(
              (p) => p.bundle_key == 'subscription_price'
            ).canceled
          "
          style="
            display: flex;
            width: 100%;
            justify-content: center;
            text-transform: capitalize;
          "
        >
          <span>Unsubscribe Monthly</span>
        </div>
      </v-btn>

      <span v-if="hideButtonsBasedOnSubscription('subscription_price')">
        <br />

        <br />
      </span>

      <v-btn
        :class="{
          'btn-subscribe-active':
            !user.subscribedPackages.find(
              (p) => p.bundle_key == 'subscription_price_trimester'
            ) || getHighestPaidSubscription.canceled,
          'btn-subscribe-not-active':
            user.subscribedPackages.find(
              (p) => p.bundle_key == 'subscription_price_trimester'
            ) && !getHighestPaidSubscription.canceled,
        }"
        @click="subscribe('subscription_price_trimester')"
        v-if="
          user.subscription_price_trimester &&
          hideButtonsBasedOnSubscription('subscription_price_trimester')
        "
        style="
          background-color: rgba(0, 123, 255, 0.6) !important;
          font-weight: bolder;
          color: white;
          width: 90%;
        "
        elevation="0"
        rounded
        large
      >
        <div
          v-if="
            !user.subscribedPackages.find(
              (p) => p.bundle_key == 'subscription_price_trimester'
            ) ||
            (getHighestPaidSubscription && getHighestPaidSubscription.canceled)
          "
          style="
            display: flex;
            width: 100%;
            justify-content: space-between;
            text-transform: capitalize;
          "
        >
          <div
            v-if="
              !user.subscribedPackages.find(
                (p) => p.bundle_key == 'subscription_price_trimester'
              ) ||
              (getHighestPaidSubscription &&
                getHighestPaidSubscription.canceled)
            "
          >
            <template
              v-if="
                user.subscribedPackages.find(
                  (p) => p.bundle_key == 'subscription_price_trimester'
                ) &&
                user.subscribedPackages.find(
                  (p) => p.bundle_key == 'subscription_price_trimester'
                ).canceled
              "
              >Renew</template
            >
            3 Months
            <template v-if="calcDiscount(user.subscription_price_trimester, 3)"
              >({{
                calcDiscount(user.subscription_price_trimester, 3).toFixed(2)
              }}% off)</template
            >
          </div>
          <div
            v-if="
              !user.subscribedPackages.find(
                (p) => p.bundle_key == 'subscription_price_trimester'
              ) ||
              (getHighestPaidSubscription &&
                getHighestPaidSubscription.canceled)
            "
          >
            {{ user.subscription_price_trimester }}$
          </div>
        </div>

        <div
          v-if="
            user.subscribedPackages.find(
              (p) => p.bundle_key == 'subscription_price_trimester'
            ) &&
            getHighestPaidSubscription &&
            !getHighestPaidSubscription.canceled
          "
          style="
            display: flex;
            width: 100%;
            justify-content: center;
            text-transform: capitalize;
          "
        >
          <div
            v-if="
              user.subscribedPackages.find(
                (p) => p.bundle_key == 'subscription_price_trimester'
              )
            "
          >
            <span
              v-if="
                !user.subscribedPackages.find(
                  (p) => p.bundle_key == 'subscription_price_trimester'
                ).canceled
              "
              >Unsubscribe 3 months</span
            >
          </div>
        </div>
      </v-btn>

      <span
        v-if="hideButtonsBasedOnSubscription('subscription_price_trimester')"
      >
        <br />
        <br />
      </span>

      <v-btn
        :class="{
          'btn-subscribe-active':
            !user.subscribedPackages.find(
              (p) => p.bundle_key == 'subscription_price_semester'
            ) || getHighestPaidSubscription.canceled,
          'btn-subscribe-not-active':
            user.subscribedPackages.find(
              (p) => p.bundle_key == 'subscription_price_semester'
            ) && !getHighestPaidSubscription.canceled,
        }"
        @click="subscribe('subscription_price_semester')"
        v-if="
          user.subscription_price_semester &&
          hideButtonsBasedOnSubscription('subscription_price_semester')
        "
        style="
          background-color: rgba(0, 123, 255, 0.8) !important;
          font-weight: bolder;
          color: white;
          width: 90%;
        "
        elevation="0"
        rounded
        large
      >
        <div
          v-if="
            !user.subscribedPackages.find(
              (p) => p.bundle_key == 'subscription_price_semester'
            ) ||
            (getHighestPaidSubscription && getHighestPaidSubscription.canceled)
          "
          style="
            display: flex;
            width: 100%;
            justify-content: space-between;
            text-transform: capitalize;
          "
        >
          <div
            v-if="
              !user.subscribedPackages.find(
                (p) => p.bundle_key == 'subscription_price_semester'
              ) ||
              (getHighestPaidSubscription &&
                getHighestPaidSubscription.canceled)
            "
          >
            <template
              v-if="
                user.subscribedPackages.find(
                  (p) => p.bundle_key == 'subscription_price_semester'
                ) &&
                user.subscribedPackages.find(
                  (p) => p.bundle_key == 'subscription_price_semester'
                ).canceled
              "
              >Renew</template
            >
            6 months
            <template v-if="calcDiscount(user.subscription_price_semester, 6)"
              >({{
                calcDiscount(user.subscription_price_semester, 6).toFixed(2)
              }}% off)</template
            >
          </div>
          <div
            v-if="
              !user.subscribedPackages.find(
                (p) => p.bundle_key == 'subscription_price_semester'
              ) ||
              (getHighestPaidSubscription &&
                getHighestPaidSubscription.canceled)
            "
          >
            {{ user.subscription_price_semester }}$
          </div>
        </div>

        <div
          v-if="
            user.subscribedPackages.find(
              (p) => p.bundle_key == 'subscription_price_semester'
            ) &&
            getHighestPaidSubscription &&
            !getHighestPaidSubscription.canceled
          "
          style="
            display: flex;
            width: 100%;
            justify-content: center;
            text-transform: capitalize;
          "
        >
          <div
            v-if="
              user.subscribedPackages.find(
                (p) => p.bundle_key == 'subscription_price_semester'
              )
            "
          >
            <span
              v-if="
                !user.subscribedPackages.find(
                  (p) => p.bundle_key == 'subscription_price_semester'
                ).canceled
              "
              >Unsubscribe 6 Months</span
            >
          </div>
        </div>
      </v-btn>

      <span
        v-if="hideButtonsBasedOnSubscription('subscription_price_semester')"
      >
        <br />
        <br />
      </span>

      <v-btn
        :class="{
          'btn-subscribe-active':
            !user.subscribedPackages.find(
              (p) => p.bundle_key == 'subscription_price_year'
            ) || getHighestPaidSubscription.canceled,
          'btn-subscribe-not-active':
            user.subscribedPackages.find(
              (p) => p.bundle_key == 'subscription_price_year'
            ) && !getHighestPaidSubscription.canceled,
        }"
        @click="subscribe('subscription_price_year')"
        v-if="user.subscription_price_year"
        style="
          background-color: rgba(0, 123, 255, 1) !important;
          font-weight: bolder;
          color: white;
          width: 90%;
        "
        elevation="0"
        rounded
        large
      >
        <div
          v-if="
            !user.subscribedPackages.find(
              (p) => p.bundle_key == 'subscription_price_year'
            ) ||
            (getHighestPaidSubscription && getHighestPaidSubscription.canceled)
          "
          style="
            display: flex;
            width: 100%;
            justify-content: space-between;
            text-transform: capitalize;
          "
        >
          <div
            v-if="
              !user.subscribedPackages.find(
                (p) => p.bundle_key == 'subscription_price_year'
              ) ||
              (getHighestPaidSubscription &&
                getHighestPaidSubscription.canceled)
            "
          >
            <template
              v-if="
                user.subscribedPackages.find(
                  (p) => p.bundle_key == 'subscription_price_year'
                ) &&
                user.subscribedPackages.find(
                  (p) => p.bundle_key == 'subscription_price_year'
                ).canceled
              "
              >Renew</template
            >
            1 year
            <template v-if="calcDiscount(user.subscription_price_year, 12)"
              >({{ calcDiscount(user.subscription_price_year, 12).toFixed(2) }}%
              off)
            </template>
          </div>
          <div
            v-if="
              !user.subscribedPackages.find(
                (p) => p.bundle_key == 'subscription_price_year'
              ) ||
              (getHighestPaidSubscription &&
                getHighestPaidSubscription.canceled)
            "
          >
            {{ user.subscription_price_year }}$
          </div>
        </div>
        <div
          v-if="
            user.subscribedPackages.find(
              (p) => p.bundle_key == 'subscription_price_year'
            ) &&
            getHighestPaidSubscription &&
            !getHighestPaidSubscription.canceled
          "
          style="
            display: flex;
            width: 100%;
            justify-content: center;
            text-transform: capitalize;
          "
        >
          <div
            v-if="
              user.subscribedPackages.find(
                (p) => p.bundle_key == 'subscription_price_year'
              )
            "
          >
            <span
              v-if="
                !user.subscribedPackages.find(
                  (p) => p.bundle_key == 'subscription_price_year'
                ).canceled
              "
              >Unsubscribe 1 year</span
            >
          </div>
        </div>
      </v-btn>
    </center>
    <br /><br />
  </div>
</template>

<script>
import utilsMixin from "@/mixins/utils";
export default {
  mixins: [utilsMixin],
  data() {
    return {
      bundleNames: {
        subscription_price: "1 month",
        subscription_price_trimester: "3 months",
        subscription_price_semester: "6 months",
        subscription_price_year: "1 year",
      },
    };
  },
  props: {
    user: {
      type: Object,
      default: () => ({
        subscribedPackages: [],
      }),
    },
    balance: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    renewPrice() {
      let amount = 0;
      this.user.subscribedPackages.forEach((p) => {
        amount += this.user[p.bundle_key];
      });
      return parseFloat(amount).toFixed(2);
    },
    getHighestPaidSubscription() {
      let packages = this.user.subscribedPackages.filter(
        (p) => p.bundle_key != "subscription_price_free"
      );
      let highest = {
        canceled: false,
      };
      if (packages.length > 0) {
        // its not price but bundle_key
        highest = packages.reduce((prev, current) =>
          prev.price > current.price ? prev : current
        );
      }
      return highest;
    },
    calcSubscriptionsExpires() {
      if (this.user.subscribedPackages.length > 0) {
        let packages = this.user.subscribedPackages.filter(
          (p) => p.bundle_key != "subscription_price_free"
        );

        const currentDate = new Date();

        // Cria um array com as diferenças entre a data de expiração e a data atual em milissegundos
        const differences = packages.map((p) => {
          const expireDate = new Date(p.expire_at);
          return expireDate.getTime() - currentDate.getTime();
        });

        // Soma todas as diferenças em milissegundos
        const totalMilliseconds = differences.reduce(
          (accumulator, currentValue) => {
            return accumulator + currentValue;
          },
          0
        );

        // Adiciona os milissegundos à data atual para obter a nova data de expiração
        const newExpirationDate = new Date(
          currentDate.getTime() + totalMilliseconds
        );

        // Formata a nova data de expiração como string
        const formattedDate = newExpirationDate.toLocaleDateString("pt-PT", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        });

        // Retorna a nova data de expiração formatada
        return formattedDate;
      }
      return null;
    },
  },
  methods: {
    calcDiscount(discountPrice, multiplier) {
      let basePrice = parseFloat(this.user.subscription_price);
      if (!basePrice) return 0;
      return 100 - (discountPrice * 100) / (basePrice * multiplier);
    },
    hideButtonsBasedOnSubscription(subscription_key) {
      if (!this.getHighestPaidSubscription) return true;

      if (this.getHighestPaidSubscription.canceled) {
        return true;
      }

      if (
        subscription_key == "subscription_price_free" &&
        (this.getHighestPaidSubscription.bundle_key == "subscription_price" ||
          this.getHighestPaidSubscription.bundle_key ==
            "subscription_price_trimester" ||
          this.getHighestPaidSubscription.bundle_key ==
            "subscription_price_semester" ||
          this.getHighestPaidSubscription.bundle_key ==
            "subscription_price_year")
      ) {
        return false;
      }

      if (
        subscription_key == "subscription_price" &&
        (this.getHighestPaidSubscription.bundle_key ==
          "subscription_price_year" ||
          this.getHighestPaidSubscription.bundle_key ==
            "subscription_price_semester" ||
          this.getHighestPaidSubscription.bundle_key ==
            "subscription_price_trimester")
      ) {
        return false;
      }

      if (
        subscription_key == "subscription_price_trimester" &&
        (this.getHighestPaidSubscription.bundle_key ==
          "subscription_price_year" ||
          this.getHighestPaidSubscription.bundle_key ==
            "subscription_price_semester")
      ) {
        return false;
      }

      if (
        subscription_key == "subscription_price_semester" &&
        this.getHighestPaidSubscription.bundle_key == "subscription_price_year"
      ) {
        return false;
      }

      return true;
    },
    subscribe(bundle_key) {
      if (!this.me.id) return this.$router.push("/");
      console.log(this.user.subscribedPackages);
      if (
        this.user.subscribedPackages.find((p) => p.bundle_key == bundle_key) &&
        !this.user.subscribedPackages
          .reverse()
          .find((p) => p.bundle_key == bundle_key).canceled
      ) {
        // TODO UNSUBSCRIBE
        this.$http
          .post("/api/v1/unsubscribe", {
            user_id: this.user.id,
            bundle_key,
          })
          .then(() => {
            this.$swal({
              title: "Success",
              text: "You have successfully unsubscribed from this user",
              icon: "success",
              showConfirmButton: false,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          })
          .catch(() => {
            this.$swal({
              title: "Error",
              text: "An error occurred while unsubscribing from this user",
              icon: "error",
            });
          });
        return;
      }
      let bundlePrice = this.user[bundle_key];
      if (bundle_key == "subscription_price_free") {
        bundlePrice = 0;
      }
      if (this.balance < bundlePrice) {
        this.$store.state.subscribingTo = this.user;
        this.$emit("close");
        this.$swal({
          title: "Insufficient Balance",
          text: "You do not have enough balance to subscribe to this user",
          icon: "error",
          confirmButtonColor: "#888",
          cancelButtonColor: "#007BFF",
          confirmButtonText: "Close",
          showCancelButton: true,
          cancelButtonText: '<i class="fa-light fa-credit-card"></i> Topup',
        }).then((res) => {
          if (res.value) {
            this.$emit("close");
          } else {
            this.$router.push("/settings/wallet");
          }
        });
        return;
      }

      this.$http
        .post("/api/v1/subscribe", {
          user_id: this.user.id,
          bundle_key,
        })
        .then(() => {
          this.$swal({
            title: "Success",
            text: "You have successfully subscribed to this user",
            icon: "success",
            showConfirmButton: false,
          });
          setTimeout(() => {
            this.$emit("close");
            window.location.reload();
          }, 1500);
        })
        .catch(() => {
          this.$swal({
            title: "Error",
            text: "Something went wrong",
            icon: "error",
          });
        });
    },
  },
};
</script>

<style lang="scss">
.btn-subscribe-active {
  background-color: rgba(0, 123, 255, 1) !important;
}
.btn-subscribe-not-active {
  background-color: rgba(0, 123, 255, 0.5) !important;
}
</style>
