<template>
  <div class="invest-view">
    <v-card flat>
      <br />
      <center>
        <div style="display: flex; justify-content: space-around">
          <h1 class="text-white">
            Invested:
            <div style="display: inline-flex; flex-direction: column">
              <span class="primary-color">{{ parseInt(invested) }}$</span>
            </div>
          </h1>
          <h1 class="text-white">
            Dividends:
            <span class="primary-color">{{ dividends.toFixed(2) }}$</span>
          </h1>
        </div>
        <br />
        <div class="settings-personal-data-edit-card">
          <div style="display: flex; justify-content: space-between">
            <h4>Balance:</h4>
            <span style="font-weight: bolder">{{ balance.toFixed(2) }}$</span>
          </div>
          <br />
          <center>
            <vuetify-money
              v-model="amountDeposit"
              color="#0989EF"
              label="$ Amount to invest"
              clearable
              :valueWhenIsEmpty="valueWhenIsEmpty"
              filled
              :options="options"
            />

            <v-chip
              v-for="suggestedAmount in suggestedAmounts"
              :key="suggestedAmount"
              :style="{
                border:
                  amountDeposit == suggestedAmount
                    ? '2px dotted rgba(0,123,255,0.7)'
                    : '',
              }"
              style="
                color: #0989ef;
                background-color: rgba(0, 123, 255, 0.1);
                margin-right: 4px;
              "
              @click="setInvestAmount(suggestedAmount)"
              >{{ formatShares(suggestedAmount) }}$
            </v-chip>
            <br /><br />
            <div style="display: flex; justify-content: space-between">
              <small> 1 $ider = 1 share </small>
              <small>
                Available Shares: {{ formatShares(availableShares) }} /
                {{ formatShares(totalShares) }}
              </small>
            </div>
          </center>
        </div>
      </center>
    </v-card>
    <v-btn
      :loading="isLoading"
      :disabled="cannotInvest"
      @click="invest"
      class="btn-topup"
      :class="{
        'btn-not-enough-balance': !hasEnoughBalance,
      }"
      style="
        letter-spacing: 0.5px;
        font-weight: normal;
        font-family: 'Montserrat';
        height: 51px;
        font-size: 16px;
        text-transform: none;
        margin-top: 1em;
        width: 100%;
        padding: 1em;
        background-color: rgb(0, 123, 255);
        color: white;
        border-radius: 5px;
      "
    >
      Invest on Sider
    </v-btn>
    <template v-if="!hasEnoughBalance">
      <center>
        <small class="not-enough-balance"
          >No enough balance to invest,
          <router-link to="/settings/wallet/?topup=true" class="primary-color"
            >add funds to your account</router-link
          ></small
        >
      </center>
    </template>
    <br />
    <center>
      <small class="text-white"
        >By investing you agree to our
        <router-link to="/terms-invest" class="primary-color">
          Terms of Service</router-link
        ></small
      >

      <br /><br />

      <small class="text-white">
        <b>Benefits</b>: <br />
        <ul
          style="
            display: flex;
            justify-content: space-between;
            text-align: left;
          "
        >
          <div>
            <p>
              <i class="fa fa-check primary-color" aria-hidden="true"></i>
              Investor Badge
            </p>
            <p>
              <i class="fa fa-check primary-color" aria-hidden="true"></i>
              Dividends paid monthly
            </p>
            <p>
              <i class="fa fa-check primary-color" aria-hidden="true"></i>
              Access to the investor's discord
            </p>
            <p>
              <i class="fa fa-check primary-color" aria-hidden="true"></i> Early
              access to new features
            </p>
          </div>
          <div>
            <p>
              <i class="fa fa-check primary-color" aria-hidden="true"></i>
              Exclusive investor-only events
            </p>
            <p>
              <i class="fa fa-check primary-color" aria-hidden="true"></i>
              Priority customer support
            </p>
            <p>
              <i class="fa fa-check primary-color" aria-hidden="true"></i>
              Discounts on future investments
            </p>
            <p>
              <i class="fa fa-check primary-color" aria-hidden="true"></i>
              Voting rights in the DAO
            </p>
          </div>
        </ul>
      </small>

      <br />

      <small class="text-white">
        <p>
          The investment will be made in the name of the company Sider. and the
          investor will have a share in the profits of the company that will
          manage the social network in question.
        </p>
        <br />
        <p>
          The dividends will be credited to your wallet once per month at the
          day 5 of each month.
        </p>
        <br />
        <p>
          The shares can be sold in the future in a marketplace that will be
          created, <br />
          after the shares are sold, the investor will no longer have the
          benefits of being an investor.
        </p>
        <br />
        <p>
          For any aditional questions, please contact us at
          <a href="mailto:investment@sider.money" class="primary-color">
            investment@sider.money
          </a>
        </p>
      </small>
    </center>
  </div>
</template>

<script>
export default {
  props: {
    currentTab: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      invested: 0,
      dividends: 0,
      balance: 0,
      options: {
        locale: "pt-PT",
        prefix: " $ ",
        suffix: "",
        length: 9,
        precision: 0,
      },
      amountDeposit: "",
      valueWhenIsEmpty: "",
      isLoading: false,
      suggestedAmounts: [1000, 5000, 10000, 25000, 50000, 100000],
      availableShares: 0,
      totalShares: 0,
    };
  },
  computed: {
    cannotInvest() {
      if (this.balance < 1) return true;
      if (this.amountDeposit < 1) return true;
      if (this.amountDeposit > this.balance) return true;
      return false;
    },
    hasEnoughBalance() {
      if (this.amountDeposit > this.balance) return false;
      return true;
    },
  },
  async mounted() {
    await this.getInvested();
    await this.getDividends();
    await this.getBalance();
    await this.getAvailableShares();
  },
  methods: {
    formatShares(shares) {
      return shares.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async getAvailableShares() {
      const { availableShares, totalShares } = await this.$http
        .get("/api/v1/getAvailableShares/")
        .then((res) => res.data);

      this.availableShares = availableShares;
      this.totalShares = totalShares;
    },
    async getBalance() {
      this.balance = await this.$http
        .get("/api/v1/getBalance/")
        .then((res) => res.data.credits);
    },
    async getInvested() {
      this.invested = await this.$http
        .get("/api/v1/getInvestments/")
        .then((res) => res.data.investments);
    },
    async getDividends() {
      this.dividends = await this.$http
        .get("/api/v1/getDividends/")
        .then((res) => res.data.dividends);
    },
    setInvestAmount(amount) {
      this.amountDeposit = amount;
    },
    invest(amount) {
      if (amount > this.balance) {
        this.$toast.error("You don't have enough credits to invest");
        return;
      }

      if (amount < 1) {
        this.$toast.error("The minimum amount to invest is 1$");
        return;
      }

      this.isLoading = true;
      this.$http
        .post("/api/v1/invest/", { amount: this.amountDeposit })
        .then((res) => {
          this.$swal.fire({
            title: "Success",
            text: "You have successfully invested",
            icon: "success",
            showConfirmButton: false,
            showCancelButton: false,
            timer: 3000,
          });
          this.getInvested();
          this.getDividends();
          this.getBalance();
          this.getAvailableShares();
          this.isLoading = false;
          this.amountDeposit = "";
          return res;
        })
        .catch((err) => {
          this.$swal.fire({
            title: "Error",
            text: err.response.data.message,
            icon: "error",
            showConfirmButton: false,
            showCancelButton: false,
            timer: 3000,
          });
          this.isLoading = false;
        });
    },
  },
  watch: {
    currentTab: {
      handler() {
        this.getInvested();
        this.getDividends();
        this.getBalance();
        this.getAvailableShares();
        this.$emit("getTransactions");
        this.$emit("getBalance");
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style>
.btn-not-enough-balance {
  border: 1px solid #e74c3c;
}

.not-enough-balance {
  color: #e74c3c;
  font-size: 12px;
  margin-top: 0.5em;
  display: block;
}
</style>
