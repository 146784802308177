<template>
  <div class="footer">
    <div :class="[{ 'footer-active': $route.path == '/' }]" @click="gotoHome()">
      <i class="fa-light fa-house-chimney"></i>
    </div>
    <div :class="[{ 'footer-active': isOnSearch() }]" @click="gotoSearch">
      <i class="fa-light fa-magnifying-glass"></i>
    </div>
    <div
      @click="beginAPost"
      :class="[{ 'footer-active': $store.state.openPopup == 'create-post' }]"
      class="footer-active2"
    >
      <i class="fa-light fa-plus" aria-hidden="true"></i>
    </div>
    <div
      :class="[{ 'footer-active': $route.path == '/chat' }]"
      @click="gotoChat()"
    >
      <i class="fa-light fa-paper-plane"></i>
    </div>
    <div :class="[{ 'footer-active': isMyProfile() }]" @click="gotoProfile">
      <a>
        <v-avatar size="30px">
          <v-img
            :src="me && me.avatar ? me.avatar : defaultImages.avatar"
            :lazy-src="me && me.avatar ? me.avatar : defaultImages.avatar"
          ></v-img>
        </v-avatar>
      </a>
    </div>
  </div>
</template>

<script>
import utilsMixins from "@/mixins/utils";
export default {
  mixins: [utilsMixins],

  computed: {
    defaultImages() {
      return {
        avatar: process.env.VUE_APP_DEFAULT_USER_AVATAR,
      };
    },
  },
  methods: {
    beginAPost() {
      if (this.$route.params.slug) {
        if (this.me) {
          this.$router.push(`/${this.me.slug}`).catch(() => {});
          this.$store.commit("setPopup", "create-post");
        } else {
          this.$router.push("/login");
        }
      } else {
        if (this.me) {
          this.$router
            .push(`/${this.me.slug}`)
            .then(() => {
              setTimeout(() => {
                this.$store.commit("setPopup", "create-post");
              }, 1000);
            })
            .catch(() => {});
        } else {
          this.$router.push("/login");
        }
      }
    },
    iOS() {
      return (
        ["iPhone Simulator", "iPhone"].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      );
    },
    isOnSearch() {
      if (this.$route.path.startsWith("/discover")) {
        return true;
      }
    },
    isMyProfile() {
      if (!this.me.id) return false;
      return (
        (this.me && this.$route.params.slug == this.me.slug) ||
        this.$route.path.startsWith("/settings")
      );
    },
    gotoProfile() {
      if (this.isMyProfile()) {
        return this.scrollTop();
      }
      this.$store.state.search = "";
      this.$store.state.category = "";
      this.$store.state.users = [];
      this.$router.push(this.myUrl()).catch(() => {});
    },
    gotoHome() {
      if (this.$route.path == "/") {
        return this.scrollTop();
      }
      this.$store.state.search = "";
      this.$store.state.category = "";
      this.$store.state.users = [];
      this.$router.push("/").catch(() => {});
    },
    gotoChat() {
      this.$store.state.search = "";
      this.$store.state.category = "";
      this.$router.push("/chat").catch(() => {
        this.$store.state.users = [];
      });
    },
    gotoSearch() {
      if (this.$route.path.startsWith("/discover")) {
        return this.scrollTop();
      }
      this.$store.state.search = "";
      this.$store.state.category = "";
      this.$router.push("/discover").catch(() => {
        this.$store.state.users = [];
      });
    },
    myUrl() {
      if (this.me && this.me.slug) {
        return "/" + this.me.slug;
      } else {
        return "/login";
      }
    },
    scrollTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/styles/variables.scss";
.footer {
  display: grid;
  height: 60px;
  grid-template-columns: repeat(5, 1fr);
  position: fixed;
  bottom: 0;
  width: 100%;
  place-items: center;
  z-index: 3;
  left: 0;

  box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.1);

  @media all and (display-mode: fullscreen) {
    height: 80px;
  }

  & > div {
    width: 60px;
    height: 40px;
    border-radius: 20px;
    display: grid;
    place-items: center;
    transition: 0.5s;
    color: #cbcfd4;
    font-size: 20px;

    @media all and (display-mode: fullscreen) {
      margin-top: -15px;
    }
  }

  & > .footer-active2 {
    color: white;
  }

  & > .footer-active {
    background: $brand-gradient;
    width: 60px;
    height: 40px;
    border-radius: 20px;
    display: grid;
    place-items: center;
    transition: 0.5s;

    color: white;
  }
}
</style>
