export default {
    changeExample: function (state) {
        state.exampleValue = !state.exampleValue
    },
    setToken(_, token) {
        localStorage.setItem('sider-token', token);
    },
    removeToken() {
        localStorage.removeItem('sider-token');
    },
    setPopup(state, popupName) {
        state.openPopup = popupName;
    },
    setEditingMenuItem(state, item) {
        state.editingMenuItem = item;
    },
    setViewingMenuItem(state, item) {
        state.viewingMenuItem = item;
    },
    setTab(state, tab) {
        state.tab = tab;
    },
    setDocument(state, document) {
        state.verificationProcess.document = document;
    },
    setPostId(state, postId) {
        state.postId = postId;
    },
    setCurrentVerificationProcess(state, process) {
        let $cookies = this._vm.$cookies;

        console.log(process)

        if (process.document_type) {
            $cookies.set('sider-verification-process-consent', true);
            $cookies.set('sider-verification-process-document', process.document_type);
            if (process.front) {
                $cookies.set('sider-verification-process-document-front', true);
            }else{
                $cookies.delete('sider-verification-process-document-front');
            }
            if (process.back) {
                $cookies.set('sider-verification-process-document-back', true);
            }else{
                $cookies.delete('sider-verification-process-document-back');
            }
            if (process.selfie) {

                $cookies.set('sider-verification-process-document-selfie', true);
            }else{
                $cookies.delete('sider-verification-process-document-selfie');
            }
        }else{
            // delete cookies
            $cookies.delete('sider-verification-process-consent');
            $cookies.delete('sider-verification-process-document');
            $cookies.delete('sider-verification-process-document-front');
            $cookies.delete('sider-verification-process-document-back');
            $cookies.delete('sider-verification-process-document-selfie');
        }
    }
}