/**
 * Use this file to register any variables or functions that should be available globally
 * ideally you should make it available via the window object
 * as well as the Vue prototype for access throughout the app
 * (register globals with care, only when it makes since to be accessible app wide)
 */

// I recommend you to change the key 🧠

import Vue from "vue";
// import crypto from 'crypto-js';

const decryptData = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

Vue.prototype.decryptData = decryptData;
window.decryptData = decryptData;

Vue.prototype.encryptData = (data, length = 5) => {
  length;
  // let hash = crypto.AES.encrypt(JSON.stringify(data), 'sider').toString();
  return data;
  // return (hash.substring(0, length) + hash.substring(hash.length - length, hash.length)).replace('=','').toUpperCase();
};

if (localStorage.getItem("sider-token")) {
  Vue.prototype.$token = localStorage.getItem("sider-token");
  Vue.prototype.me = Vue.prototype.decryptData(Vue.prototype.$token);
} else {
  Vue.prototype.$token = null;
  Vue.prototype.me = {};
}

Vue.prototype.$cookies = {
  get: (name) => {
    if (
      document.cookie.split(";").filter((item) => item.includes(name)).length
    ) {
      return document.cookie
        .split(";")
        .filter((item) => item.includes(name))[0]
        .split("=")[1];
    }
    return "";
  },
  set: (name, value, days) => {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  },
  delete: (name) => {
    document.cookie = name + "=; Max-Age=Thu, 01 Jan 1970 00:00:00 UTC;";
  },
};
