<template>
  <div class="tab-component">
    <div class="tab-component__header">
      <v-btn
        elevation="0"
        @click="$router.push('/settings')"
        fab
        class="btn-fab tab-component__header__back"
        small
        absolute
      >
        <i class="fa-light fa-chevron-left" aria-hidden="true"></i>
      </v-btn>
      <p class="tab-component__header__title">
        {{ title }}
      </p>
    </div>
    <v-tabs
      v-model="localValue"
      color="#007BFF"
      v-if="tabs.length > 0"
      center-active
      fixed-tabs
    >
      <v-tabs-slider color="#007BFF"></v-tabs-slider>
      <v-tab :href="'#' + tab.text" v-for="tab in tabs" :key="tab.text">
        {{ tab.text }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="localValue" touchless v-if="tabs.length > 0">
      <v-tab-item
        v-for="tab in tabs"
        :key="tab.text"
        :value="tab.text"
        class="tab-component__content"
      >
        <slot name="content" :tab="tab" :currentTab="localValue"></slot>
      </v-tab-item>
    </v-tabs-items>

    <div class="tab-component__content" v-else><slot></slot></div>
    <br />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    tabs: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: "",
    },
  },
  watch: {
    localValue() {
      this.$emit("input", this.localValue);
    },
    "$route.query": {
      handler: function (val) {
        if (val.invest) {
          this.localValue = "Invest";
          this.$router.replace("/settings/wallet").catch(() => {});
        }
        if (val.topup) {
          this.localValue = "Add funds";
          this.$router.replace("/settings/wallet").catch(() => {});
        }
        if(val.settings){
          this.localValue = "Settings"
          this.$router.replace("/settings/membership").catch(() => {});
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
};
</script>

<style lang="scss">
.tab-component {
  margin-top: 2rem;

  &__content {
    padding: 0 1rem;
    padding-bottom: 2rem;
  }

  &__header {
    display: flex;
    margin-bottom: 24px;
    width: 100%;
    align-items: center;
    justify-content: center;

    &__title {
      font-weight: bold;
      font-size: 1.5rem;
    }

    &__back {
      position: absolute;
      left: 0;
      margin-left: 16px;
    }
  }
}
</style>
