<template>
  <v-list
    data-app
    v-if="!mobile"
    class="post-comments feed-comments__list-wrapper"
    style="height: 100%"
  >
    <div class="feed-comments__top-wrapper">
      <v-list-item style="padding-right: 4.5px">
        <v-avatar size="42px" class="feed-comments__avatar">
          <v-img
            v-if="post.owner"
            :src="
              post.owner.avatar
                ? `${post.owner.avatar}` || defaultImages.avatar
                : defaultImages.avatar
            "
            :lazy-src="post.owner.avatar || defaultImages.avatar"
          ></v-img>
        </v-avatar>
        <v-list-item-content style="padding-bottom: 0; padding-top: 0px">
          <v-list-item-title
            class="headline mb-1 text-white"
            style="margin-left: 0.5em"
            v-if="post.owner"
          >
            <router-link
              v-if="post.owner"
              :to="`/${post.owner.slug}`"
              style="text-decoration: none; color: unset"
            >
              <span
                style="font-size: 14px; text-transform: none; font-weight: 600"
                class="text-gray-strong"
                >{{ post.owner.name }}
                <svg
                  v-if="post.owner.verified_badge"
                  fill="#007BFF"
                  style="margin-bottom: -2px"
                  width="17"
                  viewBox="0 0 24 24"
                  aria-label="Conta verificada"
                  role="img"
                  class="r-1cvl2hr r-4qtqp9 r-yyyyoo r-1xvli5t r-f9ja8p r-og9te1 r-bnwqim r-1plcrui r-lrvibr"
                >
                  <g>
                    <path
                      d="M22.5 12.5c0-1.58-.875-2.95-2.148-3.6.154-.435.238-.905.238-1.4 0-2.21-1.71-3.998-3.818-3.998-.47 0-.92.084-1.336.25C14.818 2.415 13.51 1.5 12 1.5s-2.816.917-3.437 2.25c-.415-.165-.866-.25-1.336-.25-2.11 0-3.818 1.79-3.818 4 0 .494.083.964.237 1.4-1.272.65-2.147 2.018-2.147 3.6 0 1.495.782 2.798 1.942 3.486-.02.17-.032.34-.032.514 0 2.21 1.708 4 3.818 4 .47 0 .92-.086 1.335-.25.62 1.334 1.926 2.25 3.437 2.25 1.512 0 2.818-.916 3.437-2.25.415.163.865.248 1.336.248 2.11 0 3.818-1.79 3.818-4 0-.174-.012-.344-.033-.513 1.158-.687 1.943-1.99 1.943-3.484zm-6.616-3.334l-4.334 6.5c-.145.217-.382.334-.625.334-.143 0-.288-.04-.416-.126l-.115-.094-2.415-2.415c-.293-.293-.293-.768 0-1.06s.768-.294 1.06 0l1.77 1.767 3.825-5.74c.23-.345.696-.436 1.04-.207.346.23.44.696.21 1.04z"
                    ></path>
                  </g>
                </svg>
                <svg
                  v-if="post.owner.ambassador"
                  width="15"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.001 11C21.001 9.42 20.126 8.05 18.853 7.4C19.007 6.965 19.091 6.495 19.091 6C19.091 3.79 17.381 2.002 15.273 2.002C14.803 2.002 14.353 2.086 13.937 2.252C13.319 0.915 12.011 0 10.501 0C8.991 0 7.685 0.917 7.064 2.25C6.649 2.085 6.198 2 5.728 2C3.618 2 1.91 3.79 1.91 6C1.91 6.494 1.993 6.964 2.147 7.4C0.875 8.05 0 9.418 0 11C0 12.495 0.782 13.798 1.942 14.486C1.922 14.656 1.91 14.826 1.91 15C1.91 17.21 3.618 19 5.728 19C6.198 19 6.648 18.914 7.063 18.75C7.683 20.084 8.989 21 10.5 21C12.012 21 13.318 20.084 13.937 18.75C14.352 18.913 14.802 18.998 15.273 18.998C17.383 18.998 19.091 17.208 19.091 14.998C19.091 14.824 19.079 14.654 19.058 14.485C20.216 13.798 21.001 12.495 21.001 11.001V11ZM14.385 7.666L10.051 14.166C9.906 14.383 9.669 14.5 9.426 14.5C9.283 14.5 9.138 14.46 9.01 14.374L8.895 14.28L6.48 11.865C6.187 11.572 6.187 11.097 6.48 10.805C6.773 10.513 7.248 10.511 7.54 10.805L9.31 12.572L13.135 6.832C13.365 6.487 13.831 6.396 14.175 6.625C14.521 6.855 14.615 7.321 14.385 7.665V7.666Z"
                    fill="#007BFF"
                  />
                  <path
                    d="M20 10.8571C20 9.72857 19.3334 8.75 18.3635 8.28571C18.4808 7.975 18.5448 7.63929 18.5448 7.28571C18.5448 5.70714 17.242 4.43 15.636 4.43C15.2779 4.43 14.9351 4.49 14.6182 4.60857C14.1473 3.65357 13.1508 3 12.0004 3C10.85 3 9.85496 3.655 9.38184 4.60714C9.06566 4.48929 8.72206 4.42857 8.36398 4.42857C6.75644 4.42857 5.45517 5.70714 5.45517 7.28571C5.45517 7.63857 5.5184 7.97429 5.63573 8.28571C4.66663 8.75 4 9.72714 4 10.8571C4 11.925 4.59578 12.8557 5.47955 13.3471C5.46431 13.4686 5.45517 13.59 5.45517 13.7143C5.45517 15.2929 6.75644 16.5714 8.36398 16.5714C8.72206 16.5714 9.0649 16.51 9.38108 16.3929C9.85344 17.3457 10.8484 18 11.9996 18C13.1516 18 14.1466 17.3457 14.6182 16.3929C14.9343 16.5093 15.2772 16.57 15.636 16.57C17.2436 16.57 18.5448 15.2914 18.5448 13.7129C18.5448 13.5886 18.5357 13.4671 18.5197 13.3464C19.4019 12.8557 20 11.925 20 10.8579V10.8571ZM14.9595 8.47571L11.6575 13.1186C11.5471 13.2736 11.3665 13.3571 11.1814 13.3571C11.0724 13.3571 10.962 13.3286 10.8644 13.2671L10.7768 13.2L8.93691 11.475C8.71368 11.2657 8.71368 10.9264 8.93691 10.7179C9.16014 10.5093 9.52202 10.5079 9.74449 10.7179L11.093 11.98L14.0071 7.88C14.1824 7.63357 14.5374 7.56857 14.7995 7.73214C15.0631 7.89643 15.1347 8.22929 14.9595 8.475V8.47571Z"
                    fill="#007BFF"
                  />
                  <path
                    d="M17 9.85714C17 8.72857 16.3334 7.75 15.3635 7.28571C15.4808 6.975 15.5448 6.63929 15.5448 6.28571C15.5448 4.70714 14.242 3.43 12.636 3.43C12.2779 3.43 11.9351 3.49 11.6182 3.60857C11.1473 2.65357 10.1508 2 9.00038 2C7.84996 2 6.85496 2.655 6.38184 3.60714C6.06566 3.48929 5.72206 3.42857 5.36398 3.42857C3.75644 3.42857 2.45517 4.70714 2.45517 6.28571C2.45517 6.63857 2.5184 6.97429 2.63573 7.28571C1.66663 7.75 1 8.72714 1 9.85714C1 10.925 1.59578 11.8557 2.47955 12.3471C2.46431 12.4686 2.45517 12.59 2.45517 12.7143C2.45517 14.2929 3.75644 15.5714 5.36398 15.5714C5.72206 15.5714 6.0649 15.51 6.38108 15.3929C6.85344 16.3457 7.84844 17 8.99962 17C10.1516 17 11.1466 16.3457 11.6182 15.3929C11.9343 15.5093 12.2772 15.57 12.636 15.57C14.2436 15.57 15.5448 14.2914 15.5448 12.7129C15.5448 12.5886 15.5357 12.4671 15.5197 12.3464C16.4019 11.8557 17 10.925 17 9.85786V9.85714ZM11.9595 7.47571L8.65754 12.1186C8.54707 12.2736 8.36651 12.3571 8.18137 12.3571C8.07243 12.3571 7.96195 12.3286 7.86444 12.2671L7.77682 12.2L5.93691 10.475C5.71368 10.2657 5.71368 9.92643 5.93691 9.71786C6.16014 9.50929 6.52202 9.50786 6.74449 9.71786L8.093 10.98L11.0071 6.88C11.1824 6.63357 11.5374 6.56857 11.7995 6.73214C12.0631 6.89643 12.1347 7.22929 11.9595 7.475V7.47571Z"
                    fill="#007BFF"
                  />
                  <path
                    d="M10.939 5.029V5.77C11.5803 5.796 12.135 5.887 12.603 6.043C13.071 6.19033 13.305 6.41567 13.305 6.719C13.305 6.98767 13.214 7.343 13.032 7.785C12.8587 8.227 12.7373 8.461 12.668 8.487C11.94 8.18367 11.3637 8.01467 10.939 7.98V9.449C12.759 9.51833 13.669 10.3937 13.669 12.075C13.669 12.8637 13.4437 13.5397 12.993 14.103C12.5423 14.6663 11.8577 14.9957 10.939 15.091V15.988C10.939 16.144 10.8913 16.248 10.796 16.3C10.7093 16.352 10.6357 16.378 10.575 16.378L10.393 16.326C10.2717 16.2567 10.211 16.144 10.211 15.988V15.13C9.483 15.1127 8.83733 14.9827 8.274 14.74C7.71933 14.4973 7.442 14.1897 7.442 13.817C7.442 13.3317 7.58933 12.842 7.884 12.348C7.91 12.296 7.94467 12.2397 7.988 12.179C8.69 12.6037 9.431 12.842 10.211 12.894V11.464C8.365 11.3513 7.442 10.4067 7.442 8.63C7.442 7.85 7.689 7.20867 8.183 6.706C8.677 6.20333 9.353 5.9 10.211 5.796V5.029C10.211 4.873 10.2543 4.769 10.341 4.717C10.4363 4.665 10.5143 4.639 10.575 4.639L10.757 4.691C10.8783 4.76033 10.939 4.873 10.939 5.029ZM11.862 12.01C11.862 11.8107 11.7927 11.6763 11.654 11.607C11.524 11.529 11.2857 11.4813 10.939 11.464V12.868C11.5543 12.764 11.862 12.478 11.862 12.01ZM9.418 8.773C9.418 9.137 9.68233 9.34933 10.211 9.41V7.993C9.68233 8.07967 9.418 8.33967 9.418 8.773Z"
                    fill="white"
                  />
                </svg>

                <p
                  style="
                    font-size: 14px;
                    margin-left: 4px;
                    transform: translateY(-2px);
                    display: inline-block;
                  "
                  v-if="post.owner.investor"
                >
                  <i
                    style=""
                    class="fas fa-chart-pie-simple-circle-dollar primary-color"
                  ></i>
                </p>
              </span>
              <br />

              <span
                v-if="post.owner"
                style="font-size: 12px; text-transform: none"
                class="text-gray"
                >@{{ post.owner.slug }}
              </span>
            </router-link>

            <span class="text-gray"> • </span>

            <span>
              <i
                class="fa-solid fa-earth-americas text-gray"
                v-if="!post.subscribers_only"
                style="cursor: pointer; font-size: 12px"
              ></i>
              <i
                class="fa-solid fa-lock-alt text-gray"
                v-else
                style="cursor: pointer; font-size: 12px"
              ></i>
            </span>
            <span class="text-gray"> • </span>
            <span class="text-gray" style="font-size: 12px">{{
              calcPostedAt(post.created_at)
            }}</span>
            <v-chip
              x-small
              v-if="post.paid && post.owner.id != me.id"
              class="text-gray"
              style="
                margin-left: 8px;
                font-size: 12px;
                color: #007bff;
                padding: 0.5rem;
                background-color: rgba(0, 123, 255, 0.1);
                border-radius: 1rem;
              "
            >
              <i>paid {{ post.pay_per_view.toFixed(2) }}$</i>
            </v-chip>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <i
                  v-bind="attrs"
                  v-on="on"
                  class="fa fa-ellipsis-h text-gray edit-post"
                  aria-hidden="true"
                  style="
                    position: absolute;
                    right: 0;
                    top: 50%;
                    right: 15px;
                    font-size: 20px;
                    transform: translateY(-50%);
                    cursor: pointer;
                  "
                ></i>
              </template>
              <v-list>
                <v-list-item
                  @click="deletePost(post.id)"
                  v-if="post.owner.id == me.id"
                >
                  <v-list-item-title
                    ><i class="fal fa-trash" style="margin-right: 8px"></i
                    >Delete</v-list-item-title
                  >
                </v-list-item>
                <v-list-item
                  v-if="post.owner.id != me.id"
                  @click="reportPost(post)"
                >
                  <v-list-item-title>
                    <i class="fal fa-flag" style="margin-right: 8px"></i>
                    Report</v-list-item-title
                  >
                </v-list-item>

                <v-list-item @click="copyPostShareLink(post)">
                  <v-list-item-title>
                    <i class="fal fa-share-alt" style="margin-right: 8px"></i>
                    Share
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list style="padding-top: 0; padding-bottom: 0">
        <v-list-item style="padding-top: 0; padding-bottom: 0">
          <v-list-item-content
            style="padding-top: 0; padding-bottom: 0"
            class="text-white"
          >
            {{ post.description }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title style="display: flex">
              <span
                @click="likePost()"
                :class="{ liked: likedByMe(post.likes) }"
                style="margin-right: 1.5rem; cursor: pointer"
              >
                <i
                  class="fa-light fa-heart"
                  aria-hidden="true"
                  style="margin-right: 0.3em; font-size: 21px"
                  v-if="!likedByMe(post.likes)"
                ></i>
                <i
                  class="fa-solid fa-heart"
                  aria-hidden="true"
                  style="margin-right: 0.3em; font-size: 21px"
                  v-else
                ></i>
                <span style="font-size: 17px">{{
                  post.likesCount
                    ? post.likesCount + post.likes.length
                    : post.likes.length
                }}</span></span
              >
              <span
                @click="(commentsBottomSheet = true), (commentingPost = post)"
                ><i
                  class="fa-light fa-comment"
                  @click="focusInputComments"
                  style="margin-right: 0.3em; font-size: 21px"
                ></i>
                <span style="font-size: 17px">{{
                  post.comments ? post.comments.length : 0
                }}</span></span
              >
              <div class="spacer"></div>
              <span
                :style="{ color: sumDonations(post) > 0 ? '#007bff' : '' }"
                style="font-size: 21px"
                @click="
                  (commentsBottomSheet = true),
                    (commentingPost = post),
                    (automaticallyDonation = true)
                "
              >
                <i class="fas fa-dollar-sign" style="font-size: 21px"></i>
                <span style="font-size: 17px">{{ sumDonations(post) }}</span>
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
    </div>
    <v-list-item-group class="post-comments__list feed-comments__list">
      <transition-group name="list">
        <v-list-item v-for="comment in comments" :key="comment.id">
          <v-avatar size="30px" class="feed-comments__avatar">
            <v-img
              :src="`${comment.user.avatar}` || $default.avatar"
              :lazy-src="comment.user.avatar"
            ></v-img>
          </v-avatar>
          <v-list-item-content>
            <v-list-item-title class="feed-comments__info-wrapper">
              <span
                class="feed-comments__name"
                @click="$router.push('/' + comment.user.slug)"
                >{{ comment.user.name }}
                <svg
                  v-if="comment.user.verified_badge"
                  fill="#007BFF"
                  style="margin-bottom: -2px"
                  width="13"
                  viewBox="0 0 24 24"
                  aria-label="Conta verificada"
                  role="img"
                  class="r-1cvl2hr r-4qtqp9 r-yyyyoo r-1xvli5t r-f9ja8p r-og9te1 r-bnwqim r-1plcrui r-lrvibr"
                >
                  <g>
                    <path
                      d="M22.5 12.5c0-1.58-.875-2.95-2.148-3.6.154-.435.238-.905.238-1.4 0-2.21-1.71-3.998-3.818-3.998-.47 0-.92.084-1.336.25C14.818 2.415 13.51 1.5 12 1.5s-2.816.917-3.437 2.25c-.415-.165-.866-.25-1.336-.25-2.11 0-3.818 1.79-3.818 4 0 .494.083.964.237 1.4-1.272.65-2.147 2.018-2.147 3.6 0 1.495.782 2.798 1.942 3.486-.02.17-.032.34-.032.514 0 2.21 1.708 4 3.818 4 .47 0 .92-.086 1.335-.25.62 1.334 1.926 2.25 3.437 2.25 1.512 0 2.818-.916 3.437-2.25.415.163.865.248 1.336.248 2.11 0 3.818-1.79 3.818-4 0-.174-.012-.344-.033-.513 1.158-.687 1.943-1.99 1.943-3.484zm-6.616-3.334l-4.334 6.5c-.145.217-.382.334-.625.334-.143 0-.288-.04-.416-.126l-.115-.094-2.415-2.415c-.293-.293-.293-.768 0-1.06s.768-.294 1.06 0l1.77 1.767 3.825-5.74c.23-.345.696-.436 1.04-.207.346.23.44.696.21 1.04z"
                    ></path>
                  </g>
                </svg>
                <svg
                  v-if="comment.user.ambassador"
                  width="12"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style="margin-left: 4px; transform: translate(0, 1.5px)"
                >
                  <path
                    d="M21.001 11C21.001 9.42 20.126 8.05 18.853 7.4C19.007 6.965 19.091 6.495 19.091 6C19.091 3.79 17.381 2.002 15.273 2.002C14.803 2.002 14.353 2.086 13.937 2.252C13.319 0.915 12.011 0 10.501 0C8.991 0 7.685 0.917 7.064 2.25C6.649 2.085 6.198 2 5.728 2C3.618 2 1.91 3.79 1.91 6C1.91 6.494 1.993 6.964 2.147 7.4C0.875 8.05 0 9.418 0 11C0 12.495 0.782 13.798 1.942 14.486C1.922 14.656 1.91 14.826 1.91 15C1.91 17.21 3.618 19 5.728 19C6.198 19 6.648 18.914 7.063 18.75C7.683 20.084 8.989 21 10.5 21C12.012 21 13.318 20.084 13.937 18.75C14.352 18.913 14.802 18.998 15.273 18.998C17.383 18.998 19.091 17.208 19.091 14.998C19.091 14.824 19.079 14.654 19.058 14.485C20.216 13.798 21.001 12.495 21.001 11.001V11ZM14.385 7.666L10.051 14.166C9.906 14.383 9.669 14.5 9.426 14.5C9.283 14.5 9.138 14.46 9.01 14.374L8.895 14.28L6.48 11.865C6.187 11.572 6.187 11.097 6.48 10.805C6.773 10.513 7.248 10.511 7.54 10.805L9.31 12.572L13.135 6.832C13.365 6.487 13.831 6.396 14.175 6.625C14.521 6.855 14.615 7.321 14.385 7.665V7.666Z"
                    fill="#007BFF"
                  />
                  <path
                    d="M20 10.8571C20 9.72857 19.3334 8.75 18.3635 8.28571C18.4808 7.975 18.5448 7.63929 18.5448 7.28571C18.5448 5.70714 17.242 4.43 15.636 4.43C15.2779 4.43 14.9351 4.49 14.6182 4.60857C14.1473 3.65357 13.1508 3 12.0004 3C10.85 3 9.85496 3.655 9.38184 4.60714C9.06566 4.48929 8.72206 4.42857 8.36398 4.42857C6.75644 4.42857 5.45517 5.70714 5.45517 7.28571C5.45517 7.63857 5.5184 7.97429 5.63573 8.28571C4.66663 8.75 4 9.72714 4 10.8571C4 11.925 4.59578 12.8557 5.47955 13.3471C5.46431 13.4686 5.45517 13.59 5.45517 13.7143C5.45517 15.2929 6.75644 16.5714 8.36398 16.5714C8.72206 16.5714 9.0649 16.51 9.38108 16.3929C9.85344 17.3457 10.8484 18 11.9996 18C13.1516 18 14.1466 17.3457 14.6182 16.3929C14.9343 16.5093 15.2772 16.57 15.636 16.57C17.2436 16.57 18.5448 15.2914 18.5448 13.7129C18.5448 13.5886 18.5357 13.4671 18.5197 13.3464C19.4019 12.8557 20 11.925 20 10.8579V10.8571ZM14.9595 8.47571L11.6575 13.1186C11.5471 13.2736 11.3665 13.3571 11.1814 13.3571C11.0724 13.3571 10.962 13.3286 10.8644 13.2671L10.7768 13.2L8.93691 11.475C8.71368 11.2657 8.71368 10.9264 8.93691 10.7179C9.16014 10.5093 9.52202 10.5079 9.74449 10.7179L11.093 11.98L14.0071 7.88C14.1824 7.63357 14.5374 7.56857 14.7995 7.73214C15.0631 7.89643 15.1347 8.22929 14.9595 8.475V8.47571Z"
                    fill="#007BFF"
                  />
                  <path
                    d="M17 9.85714C17 8.72857 16.3334 7.75 15.3635 7.28571C15.4808 6.975 15.5448 6.63929 15.5448 6.28571C15.5448 4.70714 14.242 3.43 12.636 3.43C12.2779 3.43 11.9351 3.49 11.6182 3.60857C11.1473 2.65357 10.1508 2 9.00038 2C7.84996 2 6.85496 2.655 6.38184 3.60714C6.06566 3.48929 5.72206 3.42857 5.36398 3.42857C3.75644 3.42857 2.45517 4.70714 2.45517 6.28571C2.45517 6.63857 2.5184 6.97429 2.63573 7.28571C1.66663 7.75 1 8.72714 1 9.85714C1 10.925 1.59578 11.8557 2.47955 12.3471C2.46431 12.4686 2.45517 12.59 2.45517 12.7143C2.45517 14.2929 3.75644 15.5714 5.36398 15.5714C5.72206 15.5714 6.0649 15.51 6.38108 15.3929C6.85344 16.3457 7.84844 17 8.99962 17C10.1516 17 11.1466 16.3457 11.6182 15.3929C11.9343 15.5093 12.2772 15.57 12.636 15.57C14.2436 15.57 15.5448 14.2914 15.5448 12.7129C15.5448 12.5886 15.5357 12.4671 15.5197 12.3464C16.4019 11.8557 17 10.925 17 9.85786V9.85714ZM11.9595 7.47571L8.65754 12.1186C8.54707 12.2736 8.36651 12.3571 8.18137 12.3571C8.07243 12.3571 7.96195 12.3286 7.86444 12.2671L7.77682 12.2L5.93691 10.475C5.71368 10.2657 5.71368 9.92643 5.93691 9.71786C6.16014 9.50929 6.52202 9.50786 6.74449 9.71786L8.093 10.98L11.0071 6.88C11.1824 6.63357 11.5374 6.56857 11.7995 6.73214C12.0631 6.89643 12.1347 7.22929 11.9595 7.475V7.47571Z"
                    fill="#007BFF"
                  />
                  <path
                    d="M10.939 5.029V5.77C11.5803 5.796 12.135 5.887 12.603 6.043C13.071 6.19033 13.305 6.41567 13.305 6.719C13.305 6.98767 13.214 7.343 13.032 7.785C12.8587 8.227 12.7373 8.461 12.668 8.487C11.94 8.18367 11.3637 8.01467 10.939 7.98V9.449C12.759 9.51833 13.669 10.3937 13.669 12.075C13.669 12.8637 13.4437 13.5397 12.993 14.103C12.5423 14.6663 11.8577 14.9957 10.939 15.091V15.988C10.939 16.144 10.8913 16.248 10.796 16.3C10.7093 16.352 10.6357 16.378 10.575 16.378L10.393 16.326C10.2717 16.2567 10.211 16.144 10.211 15.988V15.13C9.483 15.1127 8.83733 14.9827 8.274 14.74C7.71933 14.4973 7.442 14.1897 7.442 13.817C7.442 13.3317 7.58933 12.842 7.884 12.348C7.91 12.296 7.94467 12.2397 7.988 12.179C8.69 12.6037 9.431 12.842 10.211 12.894V11.464C8.365 11.3513 7.442 10.4067 7.442 8.63C7.442 7.85 7.689 7.20867 8.183 6.706C8.677 6.20333 9.353 5.9 10.211 5.796V5.029C10.211 4.873 10.2543 4.769 10.341 4.717C10.4363 4.665 10.5143 4.639 10.575 4.639L10.757 4.691C10.8783 4.76033 10.939 4.873 10.939 5.029ZM11.862 12.01C11.862 11.8107 11.7927 11.6763 11.654 11.607C11.524 11.529 11.2857 11.4813 10.939 11.464V12.868C11.5543 12.764 11.862 12.478 11.862 12.01ZM9.418 8.773C9.418 9.137 9.68233 9.34933 10.211 9.41V7.993C9.68233 8.07967 9.418 8.33967 9.418 8.773Z"
                    fill="white"
                  />
                </svg>

                <p
                  style="
                    font-size: 12px;
                    margin-left: 4px;
                    display: inline-block;
                  "
                  v-if="comment.user.investor"
                >
                  <i
                    style=""
                    class="fas fa-chart-pie-simple-circle-dollar primary-color"
                  ></i>
                </p>
              </span>
              <v-btn
                small
                v-if="comment.transaction"
                style="background-color: #007bff1a"
              >
                <i class="fas fa-dollar-sign" style="color: #007bff"></i>
                <span style="color: #007bff">
                  {{ comment.transaction.amount }}</span
                >
              </v-btn>
              <div class="spacer"></div>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <i
                    v-bind="attrs"
                    v-on="on"
                    class="fa fa-ellipsis-h"
                    aria-hidden="true"
                  ></i>
                </template>
                <v-list>
                  <v-list-item
                    @click="deleteComment(comment)"
                    v-if="comment.user.id == me.id"
                  >
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                  <!-- <v-list-item>
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item> -->
                  <v-list-item
                    @click="reportComment(comment)"
                    v-if="comment.user.id != me.id"
                  >
                    <v-list-item-title>Report</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-title>
            <div class="feed-comments__content-wrapper">
              <span class="feed-comments__content text-white">{{
                comment.content
              }}</span>
              <span class="feed-comments__created-at">{{
                calcPostedAt(comment.createdAt)
              }}</span>
            </div>
          </v-list-item-content>
        </v-list-item>
      </transition-group>

      <br /><br /><br /><br /><br /><br /><br />
    </v-list-item-group>
    <div
      class="feed-comments__input-wrapper"
      style="position: absolute; bottom: 0; left: 0"
    >
      <v-text-field
        ref="inputComments"
        filled
        rounded
        elevation="0"
        hide-details
        v-model="commentValue"
        @blur="removeScrollOnBody"
        @focus="removeScrollOnBody"
        @keypress.enter="sendComment"
        class="feed-comments__input"
      >
        <template #append>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-y
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="transparent"
                style="background-color: inherit"
                elevation="0"
                fab
                icon
                v-bind="attrs"
                v-on="on"
              >
                <i
                  class="fa-light fa-dollar-sign"
                  style="font-size: 20px"
                  :style="{ color: donationAmount > 0 ? '#007bff' : '' }"
                ></i>
                <br />
                <span>
                  {{ donationAmount ? formatValueSymbol(donationAmount) : "" }}
                </span>
              </v-btn>
            </template>

            <v-card style="width: 300px">
              <v-card-actions>
                <v-btn color="primary" text>
                  Balance: {{ balance.toFixed(2) }}$
                </v-btn>
                <v-spacer></v-spacer>
                <i
                  class="fa fa-times text-white feed-comments__pre-values-close"
                  @click="menu = false"
                  aria-hidden="true"
                ></i>
              </v-card-actions>

              <small
                v-if="balance < donationAmount"
                style="color: #ff0000; padding-left: 16px"
              >
                Not enough balance
                <router-link to="/settings/wallet" style="color: #007bff"
                  >add funds.</router-link
                >
              </small>
              <vuetify-money
                style="padding-left: 1em; padding-right: 1em"
                v-model="donationAmount"
                :valueWhenIsEmpty="valueWhenIsEmpty"
                :options="optionsMoney"
                :color="balance < donationAmount ? '#ff0000' : '#007bff'"
                filled
                placeholder="Amount to donate"
                persistent-hint
                hint="Message required to send donation."
              >
              </vuetify-money>

              <div class="feed-comments__pre-values-donation-wrapper">
                <v-btn
                  class="feed-comments__pre-values-donation"
                  elevation="0"
                  v-for="preValue in preValues"
                  :key="preValue"
                  fab
                  small
                  @click="donationAmount = preValue"
                >
                  {{ preValue }}
                </v-btn>
              </div>
            </v-card>
          </v-menu>
        </template>
      </v-text-field>

      <v-btn icon fab @click="sendComment" :loading="loading">
        <i
          class="fa-light fa-paper-plane-top"
          style="font-size: 20px; color: #007bff"
        ></i>
      </v-btn>
    </div>
  </v-list>
  <div v-else>
    <FeedComments
      v-model="commentsBottomSheet"
      :post="commentingPost"
      @newComment="onNewComment"
      @deleteComment="onDeleteComment"
      @clearComments="commentingPost = { comments: [] }"
      :automaticallyDonation="automaticallyDonation"
      @resetAutomaticallyDonation="automaticallyDonation = false"
    ></FeedComments>

    <!-- MOBILE VIEW -->
    <div
      style="
        position: absolute;
        right: 8px;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        z-index: 1;
        max-width: 50px;
      "
      :style="{
        bottom: post.description ? '6.8rem' : '4rem',
      }"
    >
      <div
        @click="likePost()"
        :class="{ liked: likedByMe(post.likes) }"
        style="
          margin-right: 0.5rem;
          cursor: pointer;
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 50px;
          max-width: 50px;
          gap: 0.1rem;
        "
      >
        <i
          class="fas fa-heart"
          aria-hidden="true"
          style="color: white; font-size: 24px"
          v-if="!likedByMe(post.likes)"
        ></i>
        <i
          class="fas fa-heart"
          aria-hidden="true"
          style="font-size: 24px; color: #ff0000"
          v-else
        ></i>
        <p style="color: white; font-size: 20px">
          {{
            post.likesCount
              ? post.likesCount + post.likes.length
              : post.likes.length
          }}
        </p>
      </div>

      <div
        @click="(commentsBottomSheet = true), (commentingPost = post)"
        style="
          margin-right: 0.5rem;
          cursor: pointer;
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 50px;
          max-width: 50px;
          gap: 0.1rem;
        "
      >
        <i class="fas fa-comment" style="color: white; font-size: 24px"></i>
        <p style="color: white; color: white; font-size: 20px">
          {{ post.comments ? post.comments.length : 0 }}
        </p>
      </div>

      <!-- TODO: CHECK IF AFTER WORKING WITH VIDEO THIS KEEPS WORKING, BECAUSE RN ITS NOT OPPENING -->
      <div
        :style="{ color: sumDonations(post) > 0 ? '#007bff' : '' }"
        style="
          margin-right: 0.5rem;
          cursor: pointer;
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 50px;
          max-width: 50px;
          gap: 0.2rem;
        "
        @click="
          (commentsBottomSheet = true),
            (commentingPost = post),
            (automaticallyDonation = true)
        "
      >
        <i class="fas fa-dollar-sign" style="color: white; font-size: 24px"></i>
        <span style="color: white; font-size: 20px">{{
          sumDonations(post)
        }}</span>
      </div>
    </div>
    <div class="feed-comments__top-wrapper feed-comments__top-wrapper--mobile">
      <v-list-item
        style="padding-right: 4.5px"
        class="v-list-item-comments-opacity"
      >
        <v-avatar size="42px" class="feed-comments__avatar">
          <v-img
            v-if="post.owner"
            :src="
              post.owner.avatar
                ? `${post.owner.avatar}` || defaultImages.avatar
                : defaultImages.avatar
            "
            :lazy-src="post.owner.avatar || defaultImages.avatar"
          ></v-img>
        </v-avatar>
        <v-list-item-content style="padding-bottom: 0; padding-top: 0px">
          <v-list-item-title
            class="headline mb-1 text-white"
            style="margin-left: 0.5em"
            v-if="post.owner"
          >
            <router-link
              v-if="post.owner"
              :to="`/${post.owner.slug}`"
              style="text-decoration: none; color: unset"
            >
              <span
                style="
                  font-size: 14px;
                  text-transform: none;
                  font-weight: 600;
                  color: white;
                "
                >{{ post.owner.name }}
                <svg
                  v-if="post.owner.verified_badge"
                  fill="#007BFF"
                  style="margin-bottom: -2px"
                  width="17"
                  viewBox="0 0 24 24"
                  aria-label="Conta verificada"
                  role="img"
                  class="r-1cvl2hr r-4qtqp9 r-yyyyoo r-1xvli5t r-f9ja8p r-og9te1 r-bnwqim r-1plcrui r-lrvibr"
                >
                  <g>
                    <path
                      d="M22.5 12.5c0-1.58-.875-2.95-2.148-3.6.154-.435.238-.905.238-1.4 0-2.21-1.71-3.998-3.818-3.998-.47 0-.92.084-1.336.25C14.818 2.415 13.51 1.5 12 1.5s-2.816.917-3.437 2.25c-.415-.165-.866-.25-1.336-.25-2.11 0-3.818 1.79-3.818 4 0 .494.083.964.237 1.4-1.272.65-2.147 2.018-2.147 3.6 0 1.495.782 2.798 1.942 3.486-.02.17-.032.34-.032.514 0 2.21 1.708 4 3.818 4 .47 0 .92-.086 1.335-.25.62 1.334 1.926 2.25 3.437 2.25 1.512 0 2.818-.916 3.437-2.25.415.163.865.248 1.336.248 2.11 0 3.818-1.79 3.818-4 0-.174-.012-.344-.033-.513 1.158-.687 1.943-1.99 1.943-3.484zm-6.616-3.334l-4.334 6.5c-.145.217-.382.334-.625.334-.143 0-.288-.04-.416-.126l-.115-.094-2.415-2.415c-.293-.293-.293-.768 0-1.06s.768-.294 1.06 0l1.77 1.767 3.825-5.74c.23-.345.696-.436 1.04-.207.346.23.44.696.21 1.04z"
                    ></path>
                  </g>
                </svg>
                <svg
                  v-if="post.owner.ambassador"
                  width="15"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.001 11C21.001 9.42 20.126 8.05 18.853 7.4C19.007 6.965 19.091 6.495 19.091 6C19.091 3.79 17.381 2.002 15.273 2.002C14.803 2.002 14.353 2.086 13.937 2.252C13.319 0.915 12.011 0 10.501 0C8.991 0 7.685 0.917 7.064 2.25C6.649 2.085 6.198 2 5.728 2C3.618 2 1.91 3.79 1.91 6C1.91 6.494 1.993 6.964 2.147 7.4C0.875 8.05 0 9.418 0 11C0 12.495 0.782 13.798 1.942 14.486C1.922 14.656 1.91 14.826 1.91 15C1.91 17.21 3.618 19 5.728 19C6.198 19 6.648 18.914 7.063 18.75C7.683 20.084 8.989 21 10.5 21C12.012 21 13.318 20.084 13.937 18.75C14.352 18.913 14.802 18.998 15.273 18.998C17.383 18.998 19.091 17.208 19.091 14.998C19.091 14.824 19.079 14.654 19.058 14.485C20.216 13.798 21.001 12.495 21.001 11.001V11ZM14.385 7.666L10.051 14.166C9.906 14.383 9.669 14.5 9.426 14.5C9.283 14.5 9.138 14.46 9.01 14.374L8.895 14.28L6.48 11.865C6.187 11.572 6.187 11.097 6.48 10.805C6.773 10.513 7.248 10.511 7.54 10.805L9.31 12.572L13.135 6.832C13.365 6.487 13.831 6.396 14.175 6.625C14.521 6.855 14.615 7.321 14.385 7.665V7.666Z"
                    fill="#007BFF"
                  />
                  <path
                    d="M20 10.8571C20 9.72857 19.3334 8.75 18.3635 8.28571C18.4808 7.975 18.5448 7.63929 18.5448 7.28571C18.5448 5.70714 17.242 4.43 15.636 4.43C15.2779 4.43 14.9351 4.49 14.6182 4.60857C14.1473 3.65357 13.1508 3 12.0004 3C10.85 3 9.85496 3.655 9.38184 4.60714C9.06566 4.48929 8.72206 4.42857 8.36398 4.42857C6.75644 4.42857 5.45517 5.70714 5.45517 7.28571C5.45517 7.63857 5.5184 7.97429 5.63573 8.28571C4.66663 8.75 4 9.72714 4 10.8571C4 11.925 4.59578 12.8557 5.47955 13.3471C5.46431 13.4686 5.45517 13.59 5.45517 13.7143C5.45517 15.2929 6.75644 16.5714 8.36398 16.5714C8.72206 16.5714 9.0649 16.51 9.38108 16.3929C9.85344 17.3457 10.8484 18 11.9996 18C13.1516 18 14.1466 17.3457 14.6182 16.3929C14.9343 16.5093 15.2772 16.57 15.636 16.57C17.2436 16.57 18.5448 15.2914 18.5448 13.7129C18.5448 13.5886 18.5357 13.4671 18.5197 13.3464C19.4019 12.8557 20 11.925 20 10.8579V10.8571ZM14.9595 8.47571L11.6575 13.1186C11.5471 13.2736 11.3665 13.3571 11.1814 13.3571C11.0724 13.3571 10.962 13.3286 10.8644 13.2671L10.7768 13.2L8.93691 11.475C8.71368 11.2657 8.71368 10.9264 8.93691 10.7179C9.16014 10.5093 9.52202 10.5079 9.74449 10.7179L11.093 11.98L14.0071 7.88C14.1824 7.63357 14.5374 7.56857 14.7995 7.73214C15.0631 7.89643 15.1347 8.22929 14.9595 8.475V8.47571Z"
                    fill="#007BFF"
                  />
                  <path
                    d="M17 9.85714C17 8.72857 16.3334 7.75 15.3635 7.28571C15.4808 6.975 15.5448 6.63929 15.5448 6.28571C15.5448 4.70714 14.242 3.43 12.636 3.43C12.2779 3.43 11.9351 3.49 11.6182 3.60857C11.1473 2.65357 10.1508 2 9.00038 2C7.84996 2 6.85496 2.655 6.38184 3.60714C6.06566 3.48929 5.72206 3.42857 5.36398 3.42857C3.75644 3.42857 2.45517 4.70714 2.45517 6.28571C2.45517 6.63857 2.5184 6.97429 2.63573 7.28571C1.66663 7.75 1 8.72714 1 9.85714C1 10.925 1.59578 11.8557 2.47955 12.3471C2.46431 12.4686 2.45517 12.59 2.45517 12.7143C2.45517 14.2929 3.75644 15.5714 5.36398 15.5714C5.72206 15.5714 6.0649 15.51 6.38108 15.3929C6.85344 16.3457 7.84844 17 8.99962 17C10.1516 17 11.1466 16.3457 11.6182 15.3929C11.9343 15.5093 12.2772 15.57 12.636 15.57C14.2436 15.57 15.5448 14.2914 15.5448 12.7129C15.5448 12.5886 15.5357 12.4671 15.5197 12.3464C16.4019 11.8557 17 10.925 17 9.85786V9.85714ZM11.9595 7.47571L8.65754 12.1186C8.54707 12.2736 8.36651 12.3571 8.18137 12.3571C8.07243 12.3571 7.96195 12.3286 7.86444 12.2671L7.77682 12.2L5.93691 10.475C5.71368 10.2657 5.71368 9.92643 5.93691 9.71786C6.16014 9.50929 6.52202 9.50786 6.74449 9.71786L8.093 10.98L11.0071 6.88C11.1824 6.63357 11.5374 6.56857 11.7995 6.73214C12.0631 6.89643 12.1347 7.22929 11.9595 7.475V7.47571Z"
                    fill="#007BFF"
                  />
                  <path
                    d="M10.939 5.029V5.77C11.5803 5.796 12.135 5.887 12.603 6.043C13.071 6.19033 13.305 6.41567 13.305 6.719C13.305 6.98767 13.214 7.343 13.032 7.785C12.8587 8.227 12.7373 8.461 12.668 8.487C11.94 8.18367 11.3637 8.01467 10.939 7.98V9.449C12.759 9.51833 13.669 10.3937 13.669 12.075C13.669 12.8637 13.4437 13.5397 12.993 14.103C12.5423 14.6663 11.8577 14.9957 10.939 15.091V15.988C10.939 16.144 10.8913 16.248 10.796 16.3C10.7093 16.352 10.6357 16.378 10.575 16.378L10.393 16.326C10.2717 16.2567 10.211 16.144 10.211 15.988V15.13C9.483 15.1127 8.83733 14.9827 8.274 14.74C7.71933 14.4973 7.442 14.1897 7.442 13.817C7.442 13.3317 7.58933 12.842 7.884 12.348C7.91 12.296 7.94467 12.2397 7.988 12.179C8.69 12.6037 9.431 12.842 10.211 12.894V11.464C8.365 11.3513 7.442 10.4067 7.442 8.63C7.442 7.85 7.689 7.20867 8.183 6.706C8.677 6.20333 9.353 5.9 10.211 5.796V5.029C10.211 4.873 10.2543 4.769 10.341 4.717C10.4363 4.665 10.5143 4.639 10.575 4.639L10.757 4.691C10.8783 4.76033 10.939 4.873 10.939 5.029ZM11.862 12.01C11.862 11.8107 11.7927 11.6763 11.654 11.607C11.524 11.529 11.2857 11.4813 10.939 11.464V12.868C11.5543 12.764 11.862 12.478 11.862 12.01ZM9.418 8.773C9.418 9.137 9.68233 9.34933 10.211 9.41V7.993C9.68233 8.07967 9.418 8.33967 9.418 8.773Z"
                    fill="white"
                  />
                </svg>

                <p
                  style="
                    font-size: 12px;
                    margin-left: 4px;
                    display: inline-block;
                  "
                  v-if="post.owner.investor"
                >
                  <i
                    style=""
                    class="fas fa-chart-pie-simple-circle-dollar primary-color"
                  ></i>
                </p>
              </span>
              <br />

              <span
                v-if="post.owner"
                style="font-size: 12px; text-transform: none; color: white"
                >@{{ post.owner.slug }}
              </span>
            </router-link>

            <span style="color: white"> • </span>

            <span>
              <i
                class="fa-solid fa-earth-americas"
                v-if="!post.subscribers_only"
                style="cursor: pointer; font-size: 12px; color: white"
              ></i>
              <i
                class="fa-solid fa-lock-alt"
                v-else
                style="cursor: pointer; font-size: 12px; color: white"
              ></i>
            </span>
            <span style="color: white"> • </span>
            <span style="font-size: 12px; color: white">{{
              calcPostedAt(post.created_at)
            }}</span>
            <v-chip
              x-small
              v-if="post.paid && post.owner.id != me.id"
              class="text-gray"
              style="
                margin-left: 8px;
                font-size: 12px;
                color: #007bff;
                padding: 0.5rem;
                background-color: rgba(0, 123, 255, 0.1);
                border-radius: 1rem;
              "
            >
              <i>paid {{ post.pay_per_view.toFixed(2) }}$</i>
            </v-chip>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list
        style="padding-top: 0; padding-bottom: 0; flex-wrap: wrap"
        class="v-list-item-comments-opacity"
      >
        <v-list-item
          style="padding-top: 0; padding-bottom: 0"
          class="v-list-item-comments-opacity"
          v-if="post.description"
        >
          <v-list-item-content style="padding-top: 0; padding-bottom: 0">
            <v-list-item-title
              style="padding-top: 0; padding-bottom: 0; color: white"
              v-if="post.description.length <= 44"
            >
              {{ post.description }}
            </v-list-item-title>
            <span v-else>
              <CollapsableComponent>
                <template #closed>
                  <span class="text-white"
                    >{{ post.description.substring(0, 40) }}...</span
                  >
                </template>
                <template #open>
                  <div class="text-white" style="padding-right: 48px">
                    {{ post.description }}
                  </div>
                </template>
              </CollapsableComponent>
            </span>
          </v-list-item-content>
        </v-list-item>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              fab
              small
              :style="{
                bottom: post.description ? '3.5rem' : '1rem',
              }"
              style="position: absolute;
                            right: 13px;
                            font-size: 20px;
                            cursor: pointer;z-index: 101;position> absolute;"
              icon
            >
              <i
                class="fa fa-ellipsis-h edit-post"
                aria-hidden="true"
                style="color: white"
              ></i>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="deletePost(post.id)"
              v-if="post.owner.id == me.id"
            >
              <v-list-item-title
                ><i class="fal fa-trash" style="margin-right: 8px"></i
                >Delete</v-list-item-title
              >
            </v-list-item>
            <v-list-item v-if="post.owner.id != me.id">
              <v-list-item-title>
                <i class="fal fa-flag" style="margin-right: 8px"></i>
                Report</v-list-item-title
              >
            </v-list-item>

            <v-list-item @click="copyPostShareLink(post)">
              <v-list-item-title>
                <i class="fal fa-share-alt" style="margin-right: 8px"></i>
                Share
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list>
      <!-- <v-list style="padding: 0;"   class="v-list-item-comments-opacity">
      <v-list-item style="min-height: 25px;" class="v-list-item-comments-opacity">
        <v-list-item-content style="padding: 0 !important;">
          <v-list-item-title style="display: flex;">
            <span @click="likePost()" :class="{ liked: likedByMe(post.likes) }" style="margin-right: 1.5rem;cursor: pointer;">
              <i class="fa-light fa-heart" aria-hidden="true" style="margin-right: 0.3em;color: white;font-size: 21px;" v-if="!likedByMe(post.likes)"></i>
              <i class="fa-solid fa-heart" aria-hidden="true" style="margin-right: 0.3em;font-size: 21px;" v-else></i>
              <span style="font-size: 17px;">{{ post.likes ? post.likes.length : 0 }}</span></span>
            <span @click="commentsBottomSheet = true, commentingPost = post"><i class="fa-light fa-comment"  style="margin-right: 0.3em;color: white;font-size: 21px;"></i>
              <span style="font-size: 17px;">{{ post.comments ? post.comments.length : 0 }}</span></span>
            <div class="spacer"></div>
            <span :style="{color: sumDonations(post) > 0 ?'#007bff' : ''}"
              @click="commentsBottomSheet = true, commentingPost = post, automaticallyDonation = true">
              <i class="fas fa-dollar-sign    "  style="font-size: 21px;"></i>
              <span  style="font-size: 17px;">{{ sumDonations(post) }}</span>
            </span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list> -->
    </div>
  </div>
</template>

<script>
import utilsMixin from "@/mixins/utils";
import FeedComments from "../Feed/FeedComments";
import CollapsableComponent from "../../Collapsable/CollapsableComponent.vue";
export default {
  components: {
    FeedComments,
    CollapsableComponent,
  },
  computed: {
    comments() {
      return [...this.post.comments];
    },
    defaultImages() {
      return {
        avatar: process.env.VUE_APP_DEFAULT_USER_AVATAR,
        banner: process.env.VUE_APP_DEFAULT_USER_BANNER,
      };
    },
  },
  mixins: [utilsMixin],
  props: {
    post: {
      type: Object,
      required: true,
      default: () => ({
        files: [],
        comments: [],
        likes: [],
        owner: {
          id: -1,
          slug: "",
        },
      }),
    },
    mobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    formatValueSymbol(val = "") {
      if (val > 999 && val < 1000000) {
        val = (val / 1000).toFixed(1) + "K";
      } else if (val > 999999 && val < 1000000000) {
        val = (val / 1000000).toFixed(1) + "M";
      } else if (val > 999999999 && val < 1000000000000) {
        val = (val / 1000000000).toFixed(1) + "B";
      } else if (val > 999999999999 && val < 1000000000000000) {
        val = (val / 1000000000000).toFixed(1) + "T";
      }

      val = String(val);

      if (
        !val.includes("K") &&
        !val.includes("M") &&
        !val.includes("B") &&
        !val.includes("T")
      ) {
        val = Number(val);
      }

      return val;
    },
    async deletePost(postId) {
      // use $swal
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$http
            .delete(`/api/v1/deletePost`, {
              data: {
                postId,
              },
            })
            .then(() => {
              this.$emit("deletePost", postId);
              window.location.reload();
            });
        }
      });
    },
    reportPost(post) {
      this.$swal({
        title: "Report Comment",
        text: "Are you sure you want to report this comment?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, report it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$http.post("/api/v1/report", {
            post_id: post.id,
          });
          this.$swal({
            title: "Reported!",
            text: "Your report has been sent.",
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
    },
    copyPostShareLink(post) {
      // copy with native clipboard for mac
      if (navigator.clipboard) {
        navigator.clipboard.writeText(`${window.location.origin}/p/${post.id}`);
      } else {
        // fallback for safari
        const el = document.createElement("textarea");
        el.value = `${window.location.origin}/p/${post.id}`;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
      }
      this.$swal({
        title: "Copied!",
        text: "Link to share copied to clipboard",
        icon: "success",
        timer: 2000,
        showCancelButton: false,
        showConfirmButton: false,
      });
    },
    focusInputComments() {
      this.$refs.inputComments.focus();
    },
    async getBalance() {
      this.balance = await this.$http
        .get("/api/v1/getBalance")
        .then((res) => res.data.credits)
        .catch(() => 0);
    },
    sumDonations(post) {
      return (
        post.comments.length > 0
          ? post.comments
              .map((comment) =>
                comment.transaction ? comment.transaction.amount : 0
              )
              .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
          : 0
      ).toFixed(2);
    },
    likedByMe(likes) {
      // console.log(likes)
      if (likes && typeof likes != "undefined") {
        return likes.filter((like) => like.user_id == this.me?.id).length > 0;
      }
      return false;
    },
    async likePost() {
      if (!this.me.id) return this.$router.push("/login");

      if (this.likedByMe(this.post.likes)) {
        this.post.likes = this.post.likes.filter(
          (like) => like.user_id != this.me?.id
        );
      } else {
        if (this.post.likes == null) this.post.likes = [];
        this.post.likes.push({
          user_id: this.me.id,
        });
      }

      await this.$http.post(
        "/api/v1/likePost",
        {
          postId: this.post.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    sendComment() {
      if (!this.me.id) return this.$router.push("/login");
      if (!this.commentValue.length) return;

      var extra = {};

      if (this.donationAmount > 0) {
        if (this.donationAmount > this.balance) {
          this.$swal({
            title: "Insufficient Balance",
            text: "You do not have enough balance to make this donation.",
            icon: "error",
            confirmButtonColor: "#888",
            cancelButtonColor: "#007BFF",
            confirmButtonText: "Close",
            showCancelButton: true,
            cancelButtonText: '<i class="fa-light fa-credit-card"></i> Topup',
          }).then((res) => {
            if (res.value) {
              this.$emit("close");
            } else {
              this.$router.push("/settings/wallet");
            }
          });
          return;
        }

        extra = {
          transaction: {
            amount: this.donationAmount,
          },
        };
      }

      let comment = {
        user_id: this.me.id,
        content: this.commentValue,
        post_id: this.post.id,
        user: {
          avatar: this.me.avatar,
          name: this.me.name,
          slug: this.me.slug,
          id: this.me.id,
          ambassador: this.me.ambassador,
          investor: this.me.investor,
          verified_badge: this.me.verified_badge,
        },
        createdAt: new Date(),
        donationAmount: this.donationAmount,
        ...extra,
        // TODO: Transaction_Id
      };

      this.$http.post(`/api/v1/comment`, comment).then(async (res) => {
        this.loading = true;

        comment.id = res.data.id;

        this.onNewComment(comment);
        this.commentValue = "";
        this.donationAmount = "";

        await this.getBalance();

        this.loading = false;

        // remove focus from input
        let input = document.querySelector(".feed-comments__input");

        input.blur();

        // remove focus on iphon
        document.activeElement.blur();

        //scroll to top of list
        var list = document.querySelector(".feed-comments__list");
        //scroll smooth
        list.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });
    },
    deleteComment(comment) {
      this.$http.delete(`/api/v1/comment/${comment.id}`);
      this.onDeleteComment(comment);
    },

    reportComment(comment) {
      this.$swal({
        title: "Report Comment",
        text: "Are you sure you want to report this comment?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, report it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$http.post("/api/v1/report", {
            comment_id: comment.id,
          });
          this.$swal({
            title: "Reported!",
            text: "Your report has been sent.",
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
        }
      });
    },
    removeScrollOnBody() {
      document.body.style.overflow = "hidden";
    },
    onNewComment(comment) {
      // console.log("Comments.vue -> onNewComment()")
      this.$emit("newComment", comment);
    },
    onDeleteComment(comment) {
      this.$emit("deleteComment", comment);
    },
  },
  data: () => ({
    isDonationMenuOpen: false,
    automaticallyDonation: false,
    commentingPost: {
      comments: [],
    },
    commentsBottomSheet: false,
    commentValue: "",
    donationAmount: 0,
    menu: false,
    valueWhenIsEmpty: "0",
    balance: 0,
    optionsMoney: {
      locale: "pt-PT",
      prefix: " $ ",
      suffix: "",
      length: 9,
      precision: 0,
    },
    preValues: [10, 50, 100, 200],
    loading: false,
  }),
  watch: {
    menu() {
      if (this.menu) {
        this.getBalance();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.post-comments {
  // height: 100%;
  padding-top: 16px;

  @media (max-width: 1000px) {
    display: none;
  }

  &__list {
    height: -webkit-fill-available;
    max-height: 100%;
    overflow: scroll;
    margin-bottom: 100px;
  }
}

.feed-comments__top-wrapper {
  padding: 0;
  position: sticky;
  display: block;
  top: 0;
  left: 0;
  z-index: 1;

  &--mobile {
    position: absolute;
    top: unset;
    bottom: 0;
    width: 100%;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    padding-bottom: 15px;
    // padding-top: 100px;
  }
}
</style>
