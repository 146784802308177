<template>
  <div style="margin: 2rem">
    <v-btn class="btn-fab" fab small @click="$router.go(-1)" elevation="0">
      <i class="fa-regular fa-chevron-left" style="font-size: 20px"></i>
    </v-btn>
    <div>
      <h1 class="title" style="text-align: center; margin: 2rem">
        Frequently asked questions
      </h1>
      <v-expansion-panels elevation="0">
        <v-expansion-panel v-for="(faq, i) in faqs" :key="i">
          <v-expansion-panel-header> {{ faq.title }} </v-expansion-panel-header>
          <v-expansion-panel-content>
            {{ faq.description }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
export default {
  name: "FaqView",
  data() {
    return {
      faqs: [
        {
          title: "What is Sider Money",
          description:
            "Social is a social platform that empowers all creators to share, earn and connect with their fans. We are built for creators and optimised for fans.",
        },
        {
          title: "Who can create on Sider Money?",
          description:
            "Sider Money is a home for all creators, you could be an influencer, adult creator, athlete, artist and so much more. We’ve built a home free of overbearing censorship.",
        },
        {
          title: "How much can I make on Sider Money?",
          description:
            "The total you can earn on Sider Money is unlimited, you’re totally in control. With Sider Money you take home 90% of your earnings. There are creators on the platform making hundreds of thousands each month, the sky's your limit!",
        },
        {
          title: "How long does it take to become a creator?",
          description:
            "Less than 5 minutes! Once you have created a user account, all you need to do is go through our instant ID verification process and you’ll be good to go.",
        },
        {
          title: "How I earn money with likes?",
          description:
            "You can earn money with likes by creating a post. When a user likes your post, your earnings goes to your sider wallet. 100 likes = 1 $ider",
        },
        {
          title: "How does the referral program work?",
          description:
            "You can earn money by inviting your friends to join Sider Money. You will receive 25% of all your friends earnings for life. You can find your referral link on your profile.",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@media (prefers-color-scheme: dark) {
  .v-expansion-panel {
    background-color: #2a2a2a !important;
    color: var(--color-our-white) !important;

    & i {
      color: white !important;
    }
  }
}
@media (prefers-color-scheme: light) {
  .v-expansion-panel {
    background-color: #f2f2f6 !important;
  }
}

.v-expansion-panel:before {
  box-shadow: unset !important;
}

.v-expansion-panel-header {
  font-weight: bolder;
}
</style>
