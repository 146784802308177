<template>
  <div class="collapsable-component">
    <template v-if="hasCustomActivator">
      <div @click="isCollapsed = !isCollapsed">
        <slot name="activator"></slot>
      </div>
    </template>

    <div v-if="isCollapsed" @click="isCollapsed = !isCollapsed">
      <slot name="closed"></slot>
    </div>
    <transition
      enter-active-class="enter-active"
      leave-active-class="leave-active"
      @before-enter="beforeEnter"
      @enter="enter"
      @after-enter="afterEnter"
      @before-leave="beforeLeave"
      @leave="leave"
      @after-leave="afterLeave"
    >
      <div
        v-if="!isCollapsed"
        style="display: inline-block"
        @click="isCollapsed = !isCollapsed"
      >
        <slot name="open"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      isCollapsed: this.modelValue,
    };
  },
  computed: {
    hasCustomActivator() {
      return this.$slots.activator !== undefined;
    },
  },
  methods: {
    collapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    beforeEnter(element) {
      requestAnimationFrame(() => {
        if (!element.style.height) {
          element.style.height = "0px";
        }

        element.style.display = null;
      });
    },
    /**
     * @param {HTMLElement} element
     */
    enter(element) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          element.style.height = `${element.scrollHeight}px`;
        });
      });
    },
    /**
     * @param {HTMLElement} element
     */
    afterEnter(element) {
      element.style.height = null;
    },
    /**
     * @param {HTMLElement} element
     */
    beforeLeave(element) {
      requestAnimationFrame(() => {
        if (!element.style.height) {
          element.style.height = `${element.offsetHeight}px`;
        }
      });
    },
    /**
     * @param {HTMLElement} element
     */
    leave(element) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          element.style.height = "0px";
        });
      });
    },
    /**
     * @param {HTMLElement} element
     */
    afterLeave(element) {
      element.style.height = null;
    },
  },
  watch: {
    isCollapsed() {
      this.$emit("update:modelValue", this.isCollapsed);
    },
    modelValue() {
      this.isCollapsed = this.value;
    },
  },
};
</script>

<style lang="scss">
.enter-active,
.leave-active {
  overflow: hidden;
  transition: height 0.2s linear;
}

.content {
  background: grey;
}
</style>
