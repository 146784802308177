<template>
  <div class="video-container paused" :data-volume-level="volumeIcon" @click="!playedOnce ? !subscriberOnly ? togglePlay() : null : null">
    <v-btn class="btn-fab post-dialog__close" fab v-if="isSlider && windowWidth <= 1000"
      @click="$store.state.isVideoMuted = !$store.state.isVideoMuted" absolute small
      style="left: unset;right: 2%;background-color: transparent !important;text-shadow: 1px 1px 1px black;"
      elevation="0">
      <i class="fal fa-volume-slash text-white" v-if="$store.state.isVideoMuted"></i>
      <i class="fal fa-volume-up text-white" v-else></i>
    </v-btn>
    <v-progress-circular class="video-container__loader" v-if="isLoading && !wasPaused" style="
              " indeterminate color="grey lighten-5" />
    <div class="video-container__blur" :style="{
        backgroundImage: `url('${blur}')`,
        maxWidth: isSlider ? 'unset' : windowWidth > 768 && !isInFullscreen ? '768px' : '100%',
        width: isSlider ? '100%' : windowWidth > 768 ? '768px' : '100%',
      }"></div>
    <div class="video-container__thumbnail" :style="{
        backgroundImage: `url('${thumbnail}')`,
        maxWidth: isSlider ? 'unset' : '768px',
        width: '100%',
        position: !showVideo ? 'relative' : 'absolute',
        height: !showVideo ? isFeed ? '550px' : '100%' : 'auto',
      }"></div>
    <!-- v-if="!isLoading && !isPaused"  -->
    <div class="video-container__buttons-screen" v-if="!subscriberOnly && wasPaused">
      <button class="video-container__play" @click="togglePlay">
        <i class="fad fa-play"></i>
      </button>
      <button class="video-container__pause" @click="togglePlay">
        <i class="fad fa-pause"></i>
      </button>
    </div>
    <!-- v-if="!isSafari()" -->
    <div v-if="!isLoading" class="video-container__controls" :style="{
      // bottom: !isSlider ? isMobile ? '13%' : '0' : 'unset'
      opacity: hovering ? 1 : 0,
    }" :class="{
      'video-container__controls--slider-mobile': isSlider && windowWidth <= 1000,
      'video-container__controls--slider-mobile--paused': wasPaused
    }">
      <div class="video-container__timeline-wrapper" :class="{
        'video-container__timeline-wrapper--paused': wasPaused
      }" @mousemove="onTimeLineHover" @mousedown="toggleScrubbing" @touchmove="toggleScrubbingTouch">
        <div class="video-container__timeline">
          <div class="video-container__thumb-indicator"></div>
        </div>
      </div>
      <div class="video-container__buttons" v-if="!hideControls" :class="{
      'video-container__buttons--hidden': isSlider && windowWidth <= 1000
    }">
        <button class="video-container__play" @click="togglePlay">
          <i class="fad fa-play"></i>
        </button>
        <button class="video-container__pause" @click="togglePlay">
          <i class="fad fa-pause"></i>
        </button>
        <div class="video-container__volume-wrapper">

          <v-menu v-model="menuVolume" :close-on-content-click="false" open-on-hover offset-y top eager>
            <template v-slot:activator="{ on, attrs }">

              <button class="video-container__volume" @click="toggleMute" v-bind="attrs" v-on="on">
                <i class="fad fa-volume-up volume-up"></i>
                <i class="fad fa-volume-down volume-down"></i>
                <i class="fad fa-volume-off volume-off"></i>
                <i class="fad fa-volume-mute volume-mute"></i>
              </button>
            </template>

            <v-card style="height: 100px;">
              <input class="video-container__volume-slider" ref="ranger" type="range" min="0" max="1" step="any"
                value="0" @input="volumeChange">
            </v-card>
          </v-menu>




        </div>
        <div class="video-container__duration" v-if="!hideTimestamp">
          <span class="video-container__duration-current" ref="duration-current">00:00</span>
          <span class="video-container__duration-separator">/</span>
          <span class="video-container__duration-total">{{ durationTotal }}</span>
        </div>
        <div class="spacer"></div>
        <button class="video-container__picture-in-picture" @click="pictureInPicture">
          <i class="fad fa-window-restore"></i>
        </button>
        <button class="video-container__fullscreen" @click="fullscreen">
          <i class="fad fa-expand fullscreen"></i>
          <i class="fad fa-compress exit-fullscreen"></i>
        </button>
      </div>
    </div>
    <!-- :controls="isSafari()" -->
    <!-- :autoplay="autoPlay" -->
    <!-- @loadeddata="onVideoLoadData" -->
    <video v-if="showVideo && !subscriberOnly" @canplay="finishedLoading" preload="auto" class="video-container__video" ref="videoPlayer"
      :class="{
      'video--slider': isSlider
    }" :id="_uid" loop :muted="$store.state.isVideoMuted" playsinline :style="{maxHeight}" @play="onPlay"
      @pause="onPause" @click="togglePlay" @dblclick="onDoubleClick" @timeupdate="onTimeUpdate" @ended="onVideoEnd">
      <source :src="src" :type="
        `video/mp4`
      " @error="onVideoError">
    </video>
  </div>
</template>

<script>
  export default {
    props: {
      subscriberOnly: {
        type: [Number, Boolean],
        required: false,
        default: false
      },
      isSlider: {
        type: Boolean,
        required: false,
        default: false
      },
      autoPlay: {
        type: Boolean,
        required: false,
        default: false
      },
      windowWidth: {
        type: Number,
        required: false,
        default: 0
      },
      keepPaused: {
        type: Boolean,
        required: false,
        default: false
      },
      isMobile: {
        type: Boolean,
        required: false,
        default: false
      },
      src: {
        type: String,
        required: true
      },
      blur: {
        type: String,
        required: false
      },
      thumbnail: {
        type: String,
        required: false
      },
      hideControls: {
        type: Boolean,
        required: false,
        default: false
      },
      maxHeight: {
        type: String,
        required: false,
        default: ''
      },
      isCommenting: {
        type: Boolean,
        required: false,
        default: false
      },
      hideTimestamp: {
        type: Boolean,
        required: false,
        default: false
      },
      noAutoplay: {
        type: Boolean,
        required: false,
        default: false
      },
      muted: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    watch: {
      showVideo(bool){
       this.$nextTick(() => {
          if(bool){
          this.video = this.$el.querySelector('video');

          if(this.$route.path.startsWith('/discover') || this.$route.path.startsWith('/p/')){
              setTimeout(() => {
                if(this.getFirstVisibleVideo() && !this.noAutoplay){
                this.getFirstVisibleVideo().play();
                this.$store.state.isAnyVideoPlaying = true;
              }
            }, 1000);
          }

          this.setupVideoObserver();
        }
        });
      }
    },
    created(){
      if(!this.isFeed){
        this.showVideo = true;
        this.playedOnce = true;
      }
    },
    mounted() {
      this.$el.id = this._uid;

      document.addEventListener('fullscreenchange', this.onFullscreenChange);
      document.addEventListener('mouseup', e => {
        if (this.isScrubbing) {
          this.toggleScrubbing(e);
        }
      })

      document.addEventListener('mousemove', e => {
        if (this.isScrubbing) {
          this.onTimeLineHover(e);
        }
      })

      document.addEventListener('touchend', e => {
        if (this.isScrubbing) {
          this.toggleScrubbingTouch(e);
        }
      })

      if(!this.isFeed){
        this.setupVideoObserver();
      }
    },
    beforeDestroy() {
      // document.removeEventListener('keydown', this.keydown);
    },
    computed: {
      volumeIcon(){
        let icon = 'muted';
        if(this.$store.state.videoVolume > 0.5){
          icon = 'up';
        }else if(this.$store.state.videoVolume > 0.1){
          icon = 'down';
        }else if (this.$store.state.videoVolume > 0){
          icon = 'off';
        }else if (this.$store.state.videoVolume == 0){
          icon = 'muted';
        }
        return icon;
      },
      isFeed(){
        return !this.$route.path.startsWith('/discover') && !this.$route.path.startsWith('/p/')
      },
      isInFullscreen() {
        return document.fullscreenElement;
      },
    },
    methods: {
      setupVideoObserverFeed(){
        // the idea for this observer is, if the video is out of a little bit out of the page, pause the video.
        this.observerFeed = new IntersectionObserver((entries) => {
          const entry = entries[0];
          if (!this.$refs.videoPlayer) {
            return;
          }
          if (entry.isIntersecting && entries.length == 1) {
            this.$refs.videoPlayer.play();
            this.$refs.videoPlayer.muted = this.videoMuted;
            this.$refs.videoPlayer.volume = this.videoMuted ? 0.0 : 1.0;
            return;
          }
          this.$refs.videoPlayer.pause();
          this.removeVideoObserver();
          this.playedOnce = false;
          this.showVideo = false;
          this.isLoading = true;
          this.wasPaused = true;
          this.$el.closest('.video-container').classList.add('paused');
        }, { threshold: 0.5 });

        this.observerFeed.observe(this.$refs.videoPlayer);
      },
      removeVideoObserver(){
        this.observerFeed.unobserve(this.$refs.videoPlayer);
      },
      setupVideoObserver(){
        const observer = new IntersectionObserver(this.onIntersection, { threshold: 0.5 })
        observer.observe(this.$refs.videoPlayer)


        // on hover this.hovering = true, timeout of 5 seconds if user starts moving again reset if not set false
        this.$el.addEventListener('mousemove', () => {
          this.hovering = true;
          clearTimeout(this.hoverTimeout);
          this.hoverTimeout = setTimeout(() => {
            this.hovering = false;
          }, 5000);
        })
      },
      finishedLoading() {
        this.isLoading = false;
        this.onVideoLoadData();
      },
      pauseAllVideos(uid = "") {
        const videos = document.querySelectorAll('video');
        videos.forEach(video => {
          if (video.id != (uid || this._uid)) {
            video.pause();
          }
        })
      },
      onVideoError(e) {
        this.$emit('error', e)
      },
      onVideoEnd() {
        this.$store.state.isAnyVideoPlaying = false;
      },
      onIntersection(entries) {
        const entry = entries[0];
        if (!this.$refs.videoPlayer) {
          return;
        }
        if (!this.$route.path.startsWith('/discover') && !this.$route.path.startsWith('/p/')) {
          // if(!entry.isIntersecting) {
          //   const videos = document.querySelectorAll('video');
          //   videos.forEach(video => {
          //     // and check if the one being paused is the one current playing using the entry target
          //     if(video.id != this._uid && entry.target.id == this._uid){
          //       video.pause();
          //     }
          //   })
          //   // if(!this.$refs.videoPlayer.paused && entry.target.id != this._uid){
          //   //   return;
          //   // }
          //   // this.pauseAllVideos();
          // }
          return;
        }
        if (entry.isIntersecting && entries.length == 1) {


          // pause all other videos on page
          this.pauseAllVideos();

          this.$refs.videoPlayer.play();

          this.$refs.videoPlayer.muted = this.videoMuted;
          this.$refs.videoPlayer.volume = this.videoMuted ? 0.0 : 1.0;


          return;
        }
        this.$refs.videoPlayer.pause();


      },
      onDoubleClick() {
        var e = {
          target: {
            parentElement: this.$el
          }
        };

        this.fullscreen(e);
      },
      toggleScrubbingTouch(e) {
        if (e.touches.length === 0) {
          this.isScrubbing = false;
          this.video.currentTime = this.video.duration * this.touchPercentScrubbing / 100;
          if (this.video.paused) {
            this.video.play();
            this.wasPaused = false;
          }
          return;
        }
        var rect = this.$el.querySelector('.video-container__timeline-wrapper').getBoundingClientRect();
        const percent = Math.min(Math.max(0, e.touches[0].clientX - rect.x), rect.width) / rect.width * 100;

        this.touchPercentScrubbing = percent;

        this.isScrubbing = (e.touches.length > 0);

        if (this.isScrubbing) {
          this.wasPaused = true;
          // this.video.pause();
        } else {
          this.video.currentTime = this.video.duration * percent / 100;
          if (this.wasPaused) {
            this.video.play();
            this.wasPaused = false;
          }
        }

        this.onTimeLineHover(e, true);
      },
      toggleScrubbing(e) {
        var rect = this.$el.querySelector('.video-container__timeline-wrapper').getBoundingClientRect();
        const percent = Math.min(Math.max(0, e.x - rect.x), rect.width) / rect.width * 100;

        this.isScrubbing = (e.buttons & 1) === 1;

        if (this.isScrubbing) {
          this.wasPaused = true;
          this.video.pause();
        } else {
          this.video.currentTime = this.video.duration * percent / 100;
          if (this.wasPaused) {
            this.video.play();
            this.wasPaused = false;
          }
        }

        this.onTimeLineHover(e);
      },
      onTimeLineHover(e, touch = false) {
        var rect = this.$el.querySelector('.video-container__timeline-wrapper').getBoundingClientRect();
        const percent = touch ? (Math.min(Math.max(0, e.touches[0].clientX - rect.x), rect.width) / rect.width * 100) :
          (Math.min(Math.max(0, e.x - rect.x), rect.width) / rect.width * 100);

        this.$el.querySelector('.video-container__timeline-wrapper').style.setProperty('--preview-position', percent /
          100);

        if (this.isScrubbing) {
          if (!touch) {
            e.preventDefault();
          }

          this.$el.querySelector('.video-container__timeline-wrapper').style.setProperty('--progress-position',
            percent / 100);
        }
      },
      onVideoLoadData() {
        this.durationTotal = this.formatTime(this.video.duration);
      },
      onTimeUpdate() {
        if (this.$el.querySelector('.video-container__timeline-wrapper')) {
          this.$refs['duration-current'] ? this.$refs['duration-current'].innerText = this.formatTime(this.video
            .currentTime) : null;
          const percent = this.video.currentTime / this.video.duration * 100;
          this.$el.querySelector('.video-container__timeline-wrapper').style.setProperty('--progress-position',
            percent / 100);
        }
      },
      formatTime(time) {
        var minutes = Math.floor(time / 60);
        var seconds = Math.floor(time - minutes * 60);
        var hours = Math.floor(minutes / 60);

        minutes = minutes - hours * 60;

        if (hours < 10) {
          hours = '0' + hours;
        }

        if (minutes < 10) {
          minutes = '0' + minutes;
        }

        if (seconds < 10) {
          seconds = '0' + seconds;
        }

        if (hours == '00') {
          return minutes + ':' + seconds;
        }

        return hours + ':' + minutes + ':' + seconds;
      },
      getFirstVisibleVideo() {
        // Get all the video elements on the page
        const videos = document.querySelectorAll('video');

        // Check each video to see if it is in the viewport
        for (let i = 0; i < videos.length; i++) {
          const video = videos[i];
          const rect = video.getBoundingClientRect();

          // Check if the video is within the viewport
          if (rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)) {
            // The video is visible, return it
            return video;
          }
        }

        // No visible videos found
        return null;
      },
      keydown(e) {

        if (this.isCommenting) return;

        if (this.hideControls) return;

        // get video in viewport and compare the parentElement id to the current _uid
        const firstVisibleVideo = this.getFirstVisibleVideo();

        if (!firstVisibleVideo) return;

        if (firstVisibleVideo.parentElement.id != this._uid) return;


        if (e.key === ' ') {
          this.togglePlay();

          e.preventDefault();
        } else if (e.key === 'f') {
          this.fullscreen(e);
          e.preventDefault();
        } else if (e.key === 'p') {
          this.pictureInPicture();
        } else if (e.key === 'm') {
          this.toggleMute();
          e.preventDefault();
        } else if (e.key === 'ArrowUp') {
          this.volumeChange(0.1);
          e.preventDefault();
        } else if (e.key === 'ArrowDown') {
          this.volumeChange(-0.1);
          e.preventDefault();
        } else if (e.key === 'ArrowLeft') {
          this.video.currentTime -= 5;
          e.preventDefault();
        } else if (e.key === 'ArrowRight') {
          this.video.currentTime += 5;
          e.preventDefault();
        }
      },
      togglePlay() {
        if(this.hideControls && this.keepPause) return;
        
        if(!this.playedOnce){
          this.showVideo = !this.showVideo;
        }

        this.$nextTick(() => {
          this.$nextTick(() => {
            this.video.paused ? this.video.play() : this.video.pause();
            if(!this.playedOnce && this.isFeed){
              if(!this.video.paused){
                this.setupVideoObserverFeed();
              }
            }
            this.playedOnce = true;
          })
        })

        
      },
      toggleMute() {
        this.video.muted = !this.video.muted;


        this.$store.state.isVideoMuted = this.video.muted;
        this.$store.state.videoVolume = this.video.volume;


        if (this.video.muted) {
          this.$refs.ranger.value = 0;
          this.$el.dataset.volumeLevel = 'muted';

        } else {
          this.$refs.ranger.value = this.$store.state.videoVolume;

          if (this.$store.state.videoVolume > 0.5) {
            this.$el.dataset.volumeLevel = 'up';
          } else if (this.$store.state.videoVolume > 0.1) {
            this.$el.dataset.volumeLevel = 'down';
          } else if (this.$store.state.videoVolume > 0) {
            this.$el.dataset.volumeLevel = 'off';
          } else if (this.$store.state.videoVolume == 0) {
            this.$el.dataset.volumeLevel = 'muted';
          }
        }
      },
      volumeChange(value) {
        var volume = 0;

        if (!isNaN(value)) {
          volume = this.video.volume + value;

          this.$refs.ranger.value = volume;

          this.$store.state.isVideoMuted = false;
        } else {
          volume = this.$refs.ranger.value;
        }

        if (volume > 1) {
          volume = 1;
        } else if (volume < 0) {
          volume = 0;
        }

        if (volume > 0.5) {
          this.$el.dataset.volumeLevel = 'up';
        } else if (volume > 0.1) {
          this.$el.dataset.volumeLevel = 'down';
        } else if (volume > 0) {
          this.$el.dataset.volumeLevel = 'off';
        } else if (volume == 0) {
          this.$el.dataset.volumeLevel = 'muted';
        }

        this.video.volume = volume;

        // create a timeout of 5 seconds, if the user doesn't change the volume, set the menuVolume to false
        clearTimeout(this.menuVolumeTimeout);
        this.menuVolumeTimeout = setTimeout(() => {
          this.menuVolume = false;
        }, 5000);
      },
      onFullscreenChange(e) {
        if (this.isSlider) {
          // exit fullscreen
          // document.exitFullscreen();
          return;
        }
        if (this.iOS()) {
          // TODO: iOS fullscreen
        } else {
          if (document.fullscreenElement) {
            e.target.parentElement.querySelector('.fullscreen').style.display = 'none';
            e.target.parentElement.querySelector('.exit-fullscreen').style.display = 'block';
            this.$el.classList.add('in-fullscreen');
          } else {
            e.target.parentElement.querySelector('.fullscreen').style.display = 'block';
            e.target.parentElement.querySelector('.exit-fullscreen').style.display = 'none';
            this.$el.classList.remove('in-fullscreen');
          }
        }
      },
      iOS() {
        // TODO: Make verification by agent instead of platform
        return (
          [
            "iPhone Simulator",
            "iPhone",
            "iPod Simulator",
            "iPod",
            "iPad Simulator",
            "iPad",
          ].includes(navigator.platform) ||
          // iPad on iOS 13 detection
          (navigator.platform.includes("Mac") && "ontouchend" in document)
        );
      },
      isSafari() {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      },
      fullscreen(e) {
        if (this.video.paused && this.isSafari()) return;
        if (this.isSafari()) {
          if (!document.fullscreenEnabled) {
            this.$el.querySelector('video').webkitEnterFullscreen();
            return;
          } else {
            this.$el.querySelector('video').webkitExitFullscreen();
            return;
          }
        } else {
          if (document.fullscreenElement) {
            e.target.parentElement.querySelector('.fullscreen').style.display = 'none';
            e.target.parentElement.querySelector('.exit-fullscreen').style.display = 'block';
            this.$el.classList.remove('in-fullscreen');
            document.exitFullscreen();
            return;
          } else {
            e.target.parentElement.querySelector('.fullscreen').style.display = 'block';
            e.target.parentElement.querySelector('.exit-fullscreen').style.display = 'none';
            this.$el.classList.add('in-fullscreen');
            this.$el.requestFullscreen();
            return;
          }
        }
      },
      pictureInPicture() {

        // check if video in picture in picture mode
        if (document.pictureInPictureElement) {
          document.exitPictureInPicture();
        } else {
          this.video.requestPictureInPicture();
        }
      },
      onPlay(e) {
        e.target.closest('.video-container').classList.remove('paused');
        this.pauseAllVideos(this._uid);
        this.wasPaused = false;
      },
      onPause(e) {
        if(e.target && e.target.closest('.video-container')){
          e.target.closest('.video-container').classList.add('paused');
        }
        this.$emit('onPause');
        this.wasPaused = true;
      }
    },
    data: () => ({
      playedOnce: false,
      hovering: false,
      durationTotal: "00:00",
      isLoading: true,
      menuVolume: false,
      video: null,
      oldVolumeBeforeMute: 0,
      wasPaused: true,
      isScrubbing: false,
      touchPercentScrubbing: 0,
      menuVolumeTimeout: null,
      showVideo: false,
      observerFeed: null,
    })
  }
</script>

<style lang="scss">
  .video-container {
    width: 100%;
    background-color: transparent;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    // .video--slider {
    //   position: absolute;
    // }

    video {
      width: 100%;
      object-fit: contain;
      min-height: 550px;
      max-height: 550px;
      z-index: 1;

      &::-webkit-media-controls {
        display: none;
      }

      // TODO: PRECISAMOS SABER SE O VIDEO É PORTRAIR OU LANDSCAPE
      @media screen and (max-width: 600px) {
        height: 100vh;
        object-fit: contain;
      }
    }

    &.in-fullscreen {
      video {
        object-fit: contain;
        height: 100%;
        max-height: 100%;

        &::-webkit-media-controls {
          display: none;
        }
      }
    }

    &[data-volume-level="off"] {
      .volume-up {
        display: none;
      }

      .volume-down {
        display: none;
      }

      .volume-off {
        display: block;
      }

      .volume-mute {
        display: none;
      }
    }

    &[data-volume-level="down"] {
      .volume-up {
        display: none;
      }

      .volume-down {
        display: block;
      }

      .volume-off {
        display: none;
      }

      .volume-mute {
        display: none;
      }
    }

    &[data-volume-level="up"] {
      .volume-up {
        display: block;
      }

      .volume-down {
        display: none;
      }

      .volume-off {
        display: none;
      }

      .volume-mute {
        display: none;
      }
    }

    &[data-volume-level="muted"] {
      .volume-up {
        display: none;
      }

      .volume-down {
        display: none;
      }

      .volume-off {
        display: none;
      }

      .volume-mute {
        display: block;
      }
    }

    // &:hover, &:focus-within, &.paused{
    //   .video-container__controls{
    //     opacity: 1;
    //   }
    // }

    &:hover {
      &.paused {
        cursor: pointer;
      }

      .video-container__buttons-screen {
        button {
          background-color: #3295ff;
          &:hover {
            background-color: #4ba2ff;
          }
        }
      }
    }

    &.paused {
      .video-container__pause {
        display: none;
      }
    }

    &:not(.paused) {
      .video-container__pause {
        display: block;
      }

      .video-container__play {
        display: none;
      }

      .video-container__buttons-screen {
        display: none;
      }
    }

    &__controls {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      color: white;
      z-index: 2;
      opacity: 0;
      transition: opacity 150ms ease-in-out;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        background: linear-gradient(to top, rgba(0, 0, 0, .75), transparent);
        width: 100%;
        aspect-ratio: 6 / 1;
        z-index: -1;
        pointer-events: none;
      }

      &--slider-mobile {
        display: none;
        // position: fixed;
        bottom: 130px;
        z-index: 3;
        width: calc(100% - 85px);
        // height: 12px;
        left: 15px;

        &::before {
          background: unset;
        }

        &--paused {
          display: unset;
        }
      }
    }

    &__buttons-screen {
      display: flex;
      justify-content: center;
      padding: .25rem;
      align-items: center;
      position: absolute;
      z-index: 4;

      button {
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        background-color: rgba(0, 0, 0, .45);
        border-radius: 50%;
        font-size: 2rem;
        cursor: pointer;
        height: 64px;
        width: 64px;
        transition: opacity 150ms ease-in-out, background-color 150ms ease-in-out;
      }

      button:hover {
        opacity: 1;
      }
    }

    &__buttons {
      display: flex;
      gap: .5rem;
      padding: .25rem;
      align-items: center;

      button {
        background: none;
        border: none;
        color: white;
        font-size: 1.5rem;
        cursor: pointer;
        height: 32px;
        width: 32px;
        opacity: .55;
        transition: opacity 150ms ease-in-out;
      }

      button:hover {
        opacity: 1;
      }

      .video-container__pause {
        display: none;
      }



      &--hidden {
        display: none;
      }
    }

    &__fullscreen {
      .exit-fullscreen {
        display: none;
      }
    }

    &__volume-wrapper {
      // &:hover, &:focus-within{
      //   .video-container__volume-slider{
      //     opacity: 1;
      //     width: 100px;
      //   }
      // }
      z-index: 1;
    }

    &__volume-slider {
      width: 80px;
      opacity: 1;
      transition: width 150ms ease-in-out, opacity 150ms ease-in-out;
      position: absolute;
      top: 50%;
      left: 0;
      transform: rotate(270deg) translate(10%, -145%);
    }

    &__volume {
      .volume-up {
        display: none;
      }

      .volume-down {
        display: none;
      }

      .volume-off {
        display: none;
      }

      .volume-mute {
        display: none;
      }
    }

    &__duration {
      display: flex;
      align-items: center;
      gap: .5rem;
      padding: .25rem;
      font-size: 1rem;
      color: white;
      opacity: .55;
      transition: opacity 150ms ease-in-out;
    }

    &__timeline-wrapper {
      height: 7px;
      width: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {

        .video-container__timeline {
          height: 100%;
        }

        .video-container__timeline::before {
          display: block;
        }

        .video-container__thumb-indicator {
          --scale: 1;
        }
      }



      &--paused {

        .video-container__timeline {
          height: 100% !important;
        }

        .video-container__thumb-indicator {
          --scale: 1;
        }
      }

      .video-container__timeline {
        height: 3px;
        background-color: rgba(100, 100, 100, .5);
        width: 100%;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: calc(100% - var(--preview-position) * 100%);
          background-color: rgb(150, 150, 150);
          display: none;
        }

        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: calc(100% - var(--progress-position) * 100%);
          background-color: #3295ff;

          border-radius: 2px;

        }

        border-radius: 2px;
      }
    }

    &__thumb-indicator {
      --scale: 0;
      position: absolute;
      transform: translateX(-50%) scale(var(--scale));
      height: 200%;
      top: -50%;
      left: calc(var(--progress-position) * 100%);
      background-color: white;
      border-radius: 50%;
      transition: transform 150ms ease-in-out;
      aspect-ratio: 1 / 1;
      z-index: 1;
    }

    &__blur {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
      pointer-events: none;
      z-index: 0;
    }

    &__thumbnail {
      // width: var(--video-width);
      // min-height: var(--video-height);
      background-position: center;
      background-size: contain;
      pointer-events: none;
      z-index: 0;
      position: absolute;
    }

    &__loader {
      font-size: 100px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 4;
      pointer-events: none;
      animation: spin 1s ease-in-out infinite;

      @keyframes spin {
        to {
          transform: translate(-50%, -50%) rotate(360deg);
        }
      }
    }
  }
</style>