<template>
  <div class="wallet-view" data-app>
    <TabComponent :tabs="tabs" title="Wallet" v-model="currentTab">
      <template #content="{ tab }">
        <component
          @transactionsSearch="searchTransactions = $event"
          @getSources="getSources"
          @getBalance="getBalance"
          @getTransactions="getTransactions"
          @getWithdrawMethods="getWithdrawMethods"
          :is="tab.component"
          :transactionsFilter="transactionsFilter"
          :balance="balance"
          :availableBalance="availableBalance"
          :sources="sources"
          :userWithdrawMethods="userWithdrawMethods"
          :currentTab="currentTab"
        ></component>
      </template>
    </TabComponent>
  </div>
</template>

<script>
import TabComponent from "../TabComponent.vue";
import AddFundsView from "./AddFundsView.vue";
import WithdrawView from "./WithdrawView.vue";
import TransactionsView from "./TransactionsView.vue";
import InvestView from "./InvestView.vue";
export default {
  components: {
    TabComponent,
    AddFundsView,
    WithdrawView,
    TransactionsView,
  },
  computed: {
    transactionsFilter() {
      return this.transactions.filter((transaction) => {
        return (
          transaction.sub_type
            .toLowerCase()
            .includes(this.searchTransactions.toLowerCase()) ||
          transaction.amount
            .toString()
            .includes(this.searchTransactions.toLowerCase()) ||
          transaction.type
            .toLowerCase()
            .includes(this.searchTransactions.toLowerCase()) ||
          transaction.status
            ?.toLowerCase()
            .includes(this.searchTransactions.toLowerCase()) ||
          transaction.subscription?.creator?.name
            ?.toLowerCase()
            .includes(this.searchTransactions.toLowerCase()) ||
          transaction.subscription?.subscriber?.name
            ?.toLowerCase()
            .includes(this.searchTransactions.toLowerCase()) ||
          new Date(transaction.createdAt)
            .toLocaleDateString("pt-PT", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            })
            .includes(this.searchTransactions.toLowerCase())
        );
      });
    },
  },
  async beforeMount() {
    this.user = await this.$http
      .get("/api/v1/getUserProfile/" + this.me.slug)
      .then((res) => res.data);
    await this.getTransactions();
    await this.getWithdrawMethods();
    await this.getBalance();
    await this.getSources();
  },
  watch: {
    amountDeposit() {
      if (!this.amountDeposit) {
        this.selectedSourceIndex = -1;
      } else if (this.amountDeposit && this.amountDeposit > 0) {
        if (this.sources.length == 1) {
          this.selectedSourceIndex = 0;
        }
      }
    },
    notAddingCard() {
      if (this.first) return (this.first = false);
      if (this.notAddingCard) {
        this.card.unmount();
      }
    },
  },
  methods: {
    async getTransactions() {
      this.transactions = await this.$http
        .get("/api/v1/getTransactions")
        .then((res) => res.data);
    },
    async getWithdrawMethods() {
      this.userWithdrawMethods = await this.$http
        .get("/api/v1/getWithdrawMethods/")
        .then((res) => res.data);
    },
    async getBalance() {
      const response = await this.$http
        .get("/api/v1/getBalance/")
        .then((res) => res.data);

      this.balance = response.credits;
      this.availableBalance = response.available;
    },
    save() {
      this.$http.post("/api/v1/updateUserProfile", this.user).then(() => {
        this.editing = false;
      });
    },
    async getSources() {
      // TODO: Remove this
      // this.sources = await this.$http
      //   .get("/api/v1/getCustomerCards/")
      //   .then((res) => res.data.data);
    },
  },
  data: () => ({
    currentTab: "",
    tabs: [
      {
        text: "Add funds",
        component: AddFundsView,
      },
      {
        text: "Withdraw",
        component: WithdrawView,
      },
      {
        text: "Transactions",
        component: TransactionsView,
      },
      {
        text: "Invest",
        component: InvestView,
      },
    ],
    transactions: [],
    searchTransactions: "",
    isLoading: false,
    userWithdrawMethods: {},
    addingWithdrawMethod: false,
    balance: 0,
    availableBalance: 0,
    sources: [],
    notAddingCard: true,
    elements: null,
    card: null,
    user: {},
    editing: false,
  }),
};
</script>

<style lang="scss">
#card-element {
  background-color: #e5f2ff;
  padding: 1em;
  border-radius: 5px;
}

.s-table-historic {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 10px;
}

.s-table-historic td,
th {
  border: 1px solid #0988ef54;
  text-align: left;
  padding: 8px;
}

.s-table-historic tr:nth-child(even) {
  background-color: #0988ef23;
}
</style>
