import sharedMixin from '@/mixins/shared';
export default {
    mixins: [sharedMixin],
    async beforeMount(){
        await this.getUser();
        // await this.getTipMenu();
    },
    watch: {
        async '$route.path'() {
            await this.getUser();
            // await this.getTipMenu();
        },
    },
    methods: {
        async getTipMenu() {
            if(!this.user) return false;
            this.$store.state.tipMenuItems = await this.$http.get('/api/v1/getServices/' + this.user.id).then(res => res.data);
            this.$store.state.reloadTipMenu++;
        },
        async viewMenuItem(item) {
            if(this.isMyProfile()) return;
            this.$store.commit('setViewingMenuItem', item);
            // await this.getServiceStock(job.id);
            this.openPopup('view-menu-item');
        },
        setEditingMenuItem(item) {
            this.$store.commit('setEditingMenuItem', item);
            this.openPopup('edit-menu-item');
        }
    }
}