<template>
  <div
    v-if="
      $route.path != '/login' &&
      $route.path != '/register' &&
      $route.path != '/forgot-password' &&
      $route.path != '/waitlist'
    "
    style="display: grid; width: 100%"
    :style="{
      gridTemplateColumns:
        windowWidth >= 1013
          ? 'minmax(245px, 1fr) 768px 1fr'
          : windowWidth <= 870 && windowWidth > 769
          ? '1fr 1fr 1fr'
          : windowWidth < 769
          ? '1fr'
          : 'minmax(100px, 1fr) 10fr',
      display: $route.path.includes('become-a-creator') ? 'block' : 'grid',
    }"
  >
    <post-view
      @goBackPost="goBackPost"
      @goFowardPost="goFowardPost"
    ></post-view>
    <div
      style="
        width: 100%;
        display: grid;
        justify-content: flex-end;
        justify-items: flex-end;
      "
      v-if="!$route.path.includes('become-a-creator')"
    >
      <sidebar></sidebar>
    </div>
    <div
      style="width: 100%; display: grid"
      :style="{
        gridTemplateColumns: windowWidth <= 768 ? 'auto' : '768px 1fr',
        paddingBottom: paddingBottom,
      }"
    >
      <transition name="fade" mode="out-in">
        <component
          :is="layout"
          class="view-container"
          style="max-width: 768px; min-width: 320px"
          :style="{
            maxWidth:
              windowWidth > 877 && !$route.path.includes('become-a-creator')
                ? '768px'
                : '',
          }"
        >
        </component>
      </transition>
      <!-- <welcome-video width="300px" appendTop v-if="windowWidth > 1215"></welcome-video> -->
      <AdvertiseComponent
        v-if="windowWidth > 1215 && !$route.path.includes('become-a-creator')"
      ></AdvertiseComponent>
      <!-- <tip-menu width="300px" appendTop v-if="windowWidth > 1215 && $route.params.slug"></tip-menu> -->
      <!-- v-else-if="windowWidth > 1215 && !$route.path.includes('become-a-creator')"  -->
    </div>
    <s-footer
      v-if="
        !$store.state.channelOpen.id &&
        !$route.path.includes('become-a-creator')
      "
    ></s-footer>
  </div>
  <div v-else>
    <transition name="fade" mode="out-in">
      <component :is="layout" class="view-container" :windowWidth="windowWidth">
      </component>
    </transition>
  </div>
</template>

<script>
import TipMenu from "@/components/shared/TipMenu.vue";
import WelcomeVideo from "@/components/shared/WelcomeVideo.vue";
import AdvertiseComponent from "@/components/advertise/AdvertiseComponent.vue";
import Sidebar from "@/components/shared/Sidebar.vue";
import PostView from "@/components/shared/Post/PostView.vue";
export default {
  mounted() {
    this.windowWidth = window.innerWidth;
    window.addEventListener("resize", this.onResize);

    if (this.$route.params.postId) {
      this.$store.commit("setPostId", this.$route.params.postId);
    }

    if (this.me && this.me.id) {
      if (window.socket && window.socket.id) {
        this.me.socketId = window.socket.id;
      }
      window.socket.emit("register-connected-user", this.me);
    }

    // get socket id
    window.socket.on("online-users", (userSocketIds) => {
      this.$store.state.userSocketIds = userSocketIds;
    });

    window.socket.on("new-message", this.onNewMessage);

    // socket on reconnect
    window.socket.on("reconnect", () => {
      if (this.me && this.me.id) {
        if (window.socket && window.socket.id) {
          this.me.socketId = window.socket.id;
        }
        window.socket.emit("register-connected-user", this.me);
      }
    });

    if (
      this.me &&
      this.me.id &&
      !localStorage.getItem("sider-asked-interests")
    ) {
      this.$http.get("/api/v1/interests").then(({ data }) => {
        if (data.length <= 0) {
          this.$router.push("/" + this.me.slug).catch(() => {});
          setTimeout(() => {
            this.$store.commit("setPopup", "setup-interests");
          }, 500);
        }
      });
    }
  },
  beforeMount() {
    if (this.$token) {
      this.$http.defaults.headers.common["Authorization"] =
        "Bearer " + this.$token;
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  name: "App",
  computed: {
    layout() {
      return this.$route.meta.layout || "blank-layout";
    },
    paddingBottom() {
      return this.$route.path != "/chat" ? "80px" : "";
    },
  },
  data: () => ({
    windowWidth: 0,
  }),
  methods: {
    onNewMessage(message) {
      if (
        message.receiver.find((u) => u.user.id == this.me.id) &&
        this.$route.path != "/chat"
      ) {
        this.$toast.open({
          message: `
            <div style="display:flex;align-items:center;gap: 8px;font-size: 16px;">
              <img style="width: 30px;border-radius: 50%;" src="${
                message.sender.avatar
              }">
              <p style="font-weight: 600;">${message.sender.name}:</p>
              <p>
                ${
                  message.message.includes("http")
                    ? "Sent you a link"
                    : message.message
                }
                ${
                  message.payperview && message.payperview.id
                    ? `Sent you a pay-per-view`
                    : ""
                }
                </p>
            </div>

            <small style="rgba(255,255,255,.5);">
                ${
                  message.donationAmount
                    ? `<br>TIP: $${message.donationAmount}`
                    : ""
                }</small>
            `,
          type: "info",
          duration: 10000,
          onClick: () => {
            this.$http
              .post("/api/v1/openChat", { id: message.sender.id })
              .then((response) => {
                this.$store.state.channelOpen = response.data;
                this.$router.push("/chat?load=true");
              });
          },
        });
      }
    },
    goBackPost(currentPostId) {
      let postIndex = this.$store.state.discoverPosts.findIndex(
        (post) => post.id == currentPostId
      );

      if (postIndex > 0) {
        this.$store.commit(
          "setPostId",
          this.$store.state.discoverPosts[postIndex - 1].id
        );
      } else {
        this.$store.commit(
          "setPostId",
          this.$store.state.discoverPosts[
            this.$store.state.discoverPosts.length - 1
          ].id
        );
      }
    },
    goFowardPost(currentPostId) {
      let postIndex = this.$store.state.discoverPosts.findIndex(
        (post) => post.id == currentPostId
      );

      if (postIndex < this.$store.state.discoverPosts.length - 1) {
        this.$store.commit(
          "setPostId",
          this.$store.state.discoverPosts[postIndex + 1].id
        );
      } else {
        this.$store.commit("setPostId", this.$store.state.discoverPosts[0].id);
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  components: {
    "tip-menu": TipMenu,
    "welcome-video": WelcomeVideo,
    sidebar: Sidebar,
    AdvertiseComponent,
    PostView,
  },
};
</script>

<style>
body {
  padding: 0;
  margin: 0;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

body::-webkit-scrollbar {
  width: 2px;
  height: 0px;
  /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: transparent;
  /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: transparent;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

input:focus {
  outline: none;
}

/* media query min width 768px */
@media only screen and (min-width: 878px) {
  .view-container {
    /* padding-left: 15px;
      padding-right: 15px; */
  }

  .footer {
    display: none !important;
  }

  /* .navbar {
      display: grid !important;
    } */
}

.v-toast__icon {
  display: none !important;
}

.v-toast__item {
  background-color: #007bff !important;
}
</style>
