<template>
  <div class="membership-settings-view" v-if="show">
    <div
      class="membership-settings-view__prices membership-settings-view__card"
    >
      <p class="membership-settings-view__card__title">Price per month</p>

      <p class="membership-settings-view__card__description">
        Your free followers will always be able to see your free content.
      </p>

      <vuetify-money
        class="membership-settings-view__prices__input membership-settings-view__prices__input--100"
        v-model="subscriptionPrice"
        :valueWhenIsEmpty="valueWhenIsEmpty"
        :options="optionsMoney"
        color="#0989EF"
        filled
        placeholder="$/month"
      ></vuetify-money>
    </div>

    <div
      class="membership-settings-view__prices membership-settings-view__card"
    >
      <p class="membership-settings-view__card__title">Discount Prices</p>
      <p class="membership-settings-view__card__description">
        Defining a price here, you are giving a discount based on the monthly
        subscription.
      </p>
      <vuetify-money
        class="membership-settings-view__prices__input"
        v-model="subscriptionPriceTrimester"
        :valueWhenIsEmpty="valueWhenIsEmpty"
        :options="optionsMoney"
        color="#0989EF"
        filled
        placeholder="$/trimester"
      ></vuetify-money>

      <vuetify-money
        class="membership-settings-view__prices__input"
        v-model="subscriptionPriceSemester"
        :valueWhenIsEmpty="valueWhenIsEmpty"
        :options="optionsMoney"
        color="#0989EF"
        filled
        placeholder="$/semester"
      ></vuetify-money>

      <vuetify-money
        class="membership-settings-view__prices__input"
        v-model="subscriptionPriceYear"
        :valueWhenIsEmpty="valueWhenIsEmpty"
        :options="optionsMoney"
        color="#0989EF"
        filled
        placeholder="$/year"
      ></vuetify-money>
    </div>

    <v-btn
      @click="save"
      class="welcome-card__buttons btn-sider-money"
      block
      large
      elevation="0"
    >
      Save
    </v-btn>
  </div>
</template>

<script>
export default {
  async beforeCreate() {
    let processBack = await this.$http
      .get("/api/v1/getVerificationProcess")
      .then((res) => res.data);
    if (!processBack.creator_category_id) {
      this.$router.go(-1);
      return;
    }
    let prices = await this.$store.dispatch("subscriptionsPrices/get");
    this.subscriptionPrice = prices.subscription_price;
    if (this.subscriptionPrice == 0) this.subscriptionPrice = "";
    this.subscriptionPriceTrimester = prices.subscription_price_trimester;
    this.subscriptionPriceSemester = prices.subscription_price_semester;
    this.subscriptionPriceYear = prices.subscription_price_year;
    this.show = true;
  },
  methods: {
    async save() {
      await this.$store
        .dispatch("subscriptionsPrices/update", {
          subscription_price:
            this.subscriptionPrice == "" ? 0 : this.subscriptionPrice,
          subscription_price_trimester: this.subscriptionPriceTrimester,
          subscription_price_semester: this.subscriptionPriceSemester,
          subscription_price_year: this.subscriptionPriceYear,
        })
        .then(() => {
          this.$swal({
            title: "Success",
            text: "Prices updated successfully",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
        });
    },
  },
  data() {
    return {
      show: false,
      subscriptionPrice: "",
      subscriptionPriceTrimester: "",
      subscriptionPriceSemester: "",
      subscriptionPriceYear: "",
      valueWhenIsEmpty: "",
      optionsMoney: {
        locale: "pt-PT",
        prefix: " $ ",
        suffix: "",
        length: 9,
        precision: 0,
      },
    };
  },
};
</script>

<style lang="scss">
.membership-settings-view {
  padding-top: 1rem;

  &__card {
    text-align: left;
    color: #4f4f4f;
    padding: 1em;
    border-radius: 5px;
    width: 100%;
    max-width: 768px;
    min-width: 300px;
    margin-bottom: 24px;

    @media (prefers-color-scheme: light) {
      background-color: #f2f2f6;
      border: 1px solid rgb(245, 245, 245);
    }

    @media (prefers-color-scheme: dark) {
      border: none;
      background-color: var(--color-black-2);
    }

    &__title {
      font-size: 1.2rem;
      font-weight: bold;
      margin: 0;
      padding: 0;
      width: 100%;

      @media (prefers-color-scheme: light) {
        color: #4f4f4f;
      }

      @media (prefers-color-scheme: dark) {
        color: var(--color-our-white);
      }
    }

    &__description {
      font-size: 0.9rem;
      width: 100%;
      margin-top: -8px;

      @media (prefers-color-scheme: light) {
        color: #4f4f4f;
      }

      @media (prefers-color-scheme: dark) {
        color: gray;
      }
    }
  }

  &__prices {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    &__input {
      &--100 {
        width: 100%;
      }
      width: calc(33% - 0.6rem);
    }
  }
}
</style>
