<template>
  <div class="affiliates-view">
    <TabComponent title="Affiliates">
      <div class="affiliates-view__intro">
        <h2 class="affiliates-view__intro-title">
          <i class="fal fa-screen-users"></i>
        </h2>
        <p class="affiliates-view__intro-description">
          Welcome to your affiliates page. Share your link and get 10% from your
          referrals, subscriptions, donations, even pay per view.
        </p>
        <p class="affiliates-view__intro-description">
          *You will get <b class="affiliates-view__percentage">25%</b> for each
          transaction with your referal code*
        </p>
        <p class="affiliates-view__intro-description">
          Your referal code is:
          <b class="affiliates-view__referal-link"
            >https://sider.money/r/{{ me.slug }}</b
          >
          <button @click="copyText" class="affiliates-view__copy-button">
            <i class="fas fa-copy"></i>
          </button>
        </p>
        <input
          ref="affiliateLink"
          type="hidden"
          :value="`https://sider.money/r/${me.slug}`"
        />
      </div>

      <div class="affiliates-view__cards-wrapper">
        <div class="affiliates-view__card">
          <div class="affiliates-view__card-icon">
            <i class="fal fa-users"></i>
          </div>
          <div class="affiliates-view__card-info">
            <p class="affiliates-view__card-title">Total of users registred</p>
            <p class="affiliates-view__card-value">{{ affiliates.length }}</p>
          </div>
        </div>

        <div class="affiliates-view__card">
          <div class="affiliates-view__card-icon">
            <i class="fal fa-receipt"></i>
          </div>
          <div class="affiliates-view__card-info">
            <p class="affiliates-view__card-title">Total of transactions</p>
            <p class="affiliates-view__card-value">{{ transactions.length }}</p>
          </div>
        </div>

        <div class="affiliates-view__card">
          <div class="affiliates-view__card-icon">
            <i class="fal fa-hand-holding-usd"></i>
          </div>
          <div class="affiliates-view__card-info">
            <p class="affiliates-view__card-title">Total of earnings</p>
            <p class="affiliates-view__card-value">
              ${{ formatValue(earnings) }}
            </p>
          </div>
        </div>

        <!-- <div class="affiliates-view__card affiliates-view__card--rewards">
          Affiliates Rewards Program
          <center><small>Progress of your Invited Friends:</small></center>

          <div class="affiliates-view__progress-bar">
            <div class="affiliates-view__progress-bar-current"></div>

            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                font-size: 12px;
                height: 100%;
                margin: auto;
              "
            >
              <small
                v-for="i in 5"
                :key="i"
                :style="{
                  marginLeft: i === 0 ? '0' : '8px',
                  marginRight: i === 0 ? '0' : '8px',
                }"
                >{{ i }}</small
              >
            </div>
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <p v-for="i in 5" :key="i">{{ 5 * i }} $</p>
          </div>

          <button class="affiliates-view__claim-reward-button btn-sider-money">
            Claim Reward
          </button>
        </div> -->

        <div class="affiliates-view__card affiliates-view__card--table">
          <div class="affiliates-view__card-title">
            <p>Affiliates History</p>
          </div>
          <div class="affiliates-view__card-content">
            <table style="width: 100%">
              <thead>
                <tr>
                  <th class="text-white">Description</th>
                  <th class="text-white">Amount</th>
                  <th class="text-white">Affiliate</th>
                  <th class="text-white">Date</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="transaction in transactions" :key="transaction.id">
                  <td data-column="Transaction ID">{{ transaction.id }}</td>
                  <td data-column="Amount">
                    {{
                      transaction.amount === 0
                        ? "-"
                        : `$${formatValue(transaction.amount)}`
                    }}
                  </td>
                  <td data-column="Referal">
                    <router-link
                      :to="showSlug(transaction)"
                      class="affiliates-view__slug"
                      >@{{ showSlug(transaction) }}
                    </router-link>
                  </td>
                  <td data-column="Date">
                    {{
                      new Date(transaction.createdAt).toLocaleDateString(
                        "pt-PT",
                        {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                        }
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </TabComponent>
  </div>
</template>

<script>
export default {
  async mounted() {
    let response = await this.$store.dispatch("affiliates/getAffiliates");
    this.affiliates = response.users;
    this.transactions = response.transactions;
    this.earnings = response.earnings;
  },
  components: {
    TabComponent: () => import("./TabComponent.vue"),
  },
  data() {
    return {
      affiliates: [],
      transactions: [],
      earnings: 0,
    };
  },
  methods: {
    formatValue(value) {
      return value.toLocaleString("de-DE", {
        maximumFractionDigits: 2,
      });
    },
    showSlug(transaction) {
      if (transaction.payperview) {
        return transaction.payperview.post.owner.slug;
      } else if (transaction.subscription) {
        return transaction.subscription.creator.slug;
      } else if (transaction.parent_transaction) {
        return transaction.parent_transaction.user.slug;
      }
    },
    async copyText() {
      const element = this.$refs.affiliateLink;
      try {
        await navigator.clipboard.writeText(element.value);
        this.$swal({
          title: "Copied!",
          text: "Your referal link was copied to your clipboard",
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
      } catch ($e) {
        this.$swal({
          title: "Error!",
          text: "Something went wrong, try again later",
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.affiliates-view {
  margin-top: 2rem;

  &__slug {
    color: var(--primary-color);
    font-weight: 600;
  }

  &__intro {
    background-color: var(--primary-color-opacity-2);
    padding: 1rem;
    border-radius: 16px;
    margin-bottom: 1rem;

    text-align: center;
  }

  &__intro-title {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  &__intro-description {
    font-size: 1rem;
    color: var(--color-gray-2);
    margin-bottom: 1rem;
  }

  &__referal-link {
    font-weight: 600;
    margin-left: 0.5rem;
  }

  &__copy-button {
    border: none;
    margin-left: 0.5rem;
    cursor: pointer;

    &:hover,
    &:focus {
      outline: none;
      color: var(--primary-color);
    }
  }

  @media (prefers-color-scheme: light) {
    &__referal-link,
    &__percentage {
      color: var(--primary-color);
    }
  }

  &__cards-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 1rem;
    gap: 1rem;
  }

  &__card {
    width: calc(33% - 1rem);
    border-radius: 16px;

    display: flex;
    row-gap: 1rem;
    flex-direction: column;
    padding: 1rem;

    @media (prefers-color-scheme: dark) {
      background-color: var(--color-black-4);
    }

    @media (prefers-color-scheme: light) {
      box-shadow: 0 10px 10px 1px rgba(226, 226, 226, 0.463);
    }

    @media (max-width: 768px) {
      width: 100%;
    }

    &--table {
      width: 100%;
    }

    &--rewards {
      width: 100%;
      row-gap: 0.5rem;
    }
  }

  &__card-title {
    font-size: 1rem;
    color: var(--color-gray-2);
  }

  &__card-value {
    font-size: 1.3rem;
    font-weight: 600;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  &__card-icon {
    background-color: var(--primary-color);
    padding: 1rem;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;

    @media (prefers-color-scheme: light) {
      color: var(--color-white);
    }
  }

  &__card-description {
    font-size: 1rem;
    color: var(--color-gray-2);
  }

  &__card-value {
    font-size: 1.3rem;
    font-weight: 600;
  }

  &__progress-bar {
    width: 100%;
    height: 20px;
    background-color: var(--color-black-3);
    border-radius: 16px;

    position: relative;
  }

  &__progress-bar-current {
    width: 50%;
    height: 100%;
    background-color: var(--primary-color);
    border-radius: 16px;
  }

  &__claim-reward-button {
    height: 40px;
    width: 100%;
    margin-top: 8px;
  }
}
</style>

<style lang="scss" scoped>
tr {
  background: transparent;
}

th {
  background: var(--primary-color-opacity-2);
  font-weight: bold;
}

td,
th {
  padding: 10px;
  border: 2px solid var(--color-black);
  text-align: center;
  font-size: 14px;
}

@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  table {
    width: 100%;
  }

  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    border: 1px solid #ccc;
  }

  /* Behave  like a "row" */
  td {
    border: none;
    border-bottom: 1px solid var(--color-black);
    position: relative;
    padding-left: 50%;
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    /* Label the data */
    content: attr(data-column);
    color: var(--color-our-white);
    font-weight: bold;
  }
}
</style>
